var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user" }, [
    _c("div", { staticClass: "banner" }, [
      _c("img", { attrs: { src: require("./images/banner.png"), alt: "" } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.refresh,
            expression: "refresh",
          },
        ],
        staticStyle: {
          width: "100%",
          height: "100%",
          "padding-top": "30%",
          "text-align": "center",
        },
        on: { click: _vm.goLogin },
      },
      [
        _c("div", [_vm._v("若无反应,")]),
        _vm._v(" "),
        _c("div", [_vm._v("请点击空白处刷新")]),
      ]
    ),
    _vm._v(" "),
    _vm.success === "success"
      ? _c("div", { staticClass: "success" }, [
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.title !== "",
                  expression: "title!==''",
                },
              ],
            },
            [_vm._v("正在获取信息")]
          ),
          _vm._v(" "),
          _c("p", [_vm._v("我们将通过微信服务号给您发送相关业务通知")]),
          _vm._v(" "),
          _c("p", [_vm._v("请您务必关注下方公众号")]),
          _vm._v(" "),
          _c("div", { staticClass: "code" }, [
            _c("img", { attrs: { src: _vm.code, alt: "" } }),
          ]),
        ])
      : _vm.success === "error"
      ? _c("div", { staticClass: "error" }, [
          _c("p", [_vm._v("当前绑定链接已失效")]),
          _vm._v(" "),
          _c("p", [_vm._v("请联系系统管理员重新发送绑定链接")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }