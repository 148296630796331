var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article_box" }, [
    _c("div", { staticClass: "article_header" }, [
      _c("span", { staticClass: "article_header_name" }, [
        _vm._v(_vm._s(_vm.content.title)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "article_content" }, [
      _c("div", { staticClass: "topBox" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("span", { staticClass: "article_title" }, [
          _vm._v(_vm._s(_vm.firstActivity.title)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "article_subtitle" }, [
          _vm._v(_vm._s(_vm.firstActivity.subTitle)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "article_img" }, [
          _c("img", {
            attrs: {
              src: _vm.firstActivity.thumbnail,
              width: "100%",
              height: "100%",
            },
            on: {
              click: function ($event) {
                return _vm.articleClick(_vm.firstActivity.id)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "article_bottom" }, [
        _c("div", { staticClass: "article_bottom_left" }, [
          _c("span", { staticClass: "article_bottom_left_title" }, [
            _vm._v("# 推荐语"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "article_bottom_left_subtitle" }, [
            _vm._v(_vm._s(_vm.firstActivity.recommend)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "article_bottom_right",
            on: {
              click: function ($event) {
                return _vm.articleClick(_vm.firstActivity.id)
              },
            },
          },
          [_vm._v("立即报名")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon_bg" }, [
      _c("img", {
        attrs: {
          src: "https://makey.oss-cn-hangzhou.aliyuncs.com/%E8%AF%BE%E7%A8%8B%20%284%29.png",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }