var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "single_box",
      style: { backgroundColor: _vm.backgroundColor },
    },
    [
      _c("div", { staticClass: "single_box_bgImg" }, [
        _c("img", { attrs: { src: _vm.bgImg, width: "100%", height: "100%" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "single_info" }, [
        _c("div", { staticClass: "single_info_logo" }, [
          _c("img", {
            attrs: { src: _vm.userInfo.mchLogo, width: "100%", height: "100%" },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "single_info_title" }, [
          _vm._v(_vm._s(_vm.userInfo.mchName)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "single_info_subtitle" }, [
          _vm._v(_vm._s(_vm.content.title)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "single_content" },
        _vm._l(_vm.navigationForm, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "single_content_item",
              on: {
                click: function ($event) {
                  return _vm.navigationClick(item)
                },
              },
            },
            [
              _c("div", { staticClass: "single_content_item_box" }, [
                _c("div", { staticClass: "single_content_item_img" }, [
                  _c("img", {
                    attrs: {
                      src: item.thumbnail,
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "single_content_item_title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "single_content_itemBorder" }),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("span", { staticClass: "single_box_subTitlt" }, [
        _vm._v("厦门美心说数字科技有限公司提供技术支持"),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "375px", height: "20px" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }