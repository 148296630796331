"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.category = category;
exports.find = find;
exports.getStudyList = getStudyList;
exports.list = list;
var _request = _interopRequireDefault(require("@/utils/request"));
function list(params) {
  return (0, _request.default)({
    url: '/activity/list',
    method: 'get',
    params: params
  });
}
function find(id) {
  return (0, _request.default)({
    url: '/activity/find/' + id,
    method: 'get'
  });
}
function category(params) {
  return (0, _request.default)({
    url: '/activityCategory/list',
    method: 'get',
    params: params
  });
}
//分页查询学习记录
function getStudyList(params) {
  return (0, _request.default)({
    url: '/member/study/list',
    method: 'get',
    params: params
  });
}