var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "counselor_headerTwo" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          position: "fixed",
          top: "0",
          "z-index": "9999",
          width: "100%",
        },
      },
      [
        _c("category", {
          attrs: { "category-id": _vm.categoryId },
          on: { categoryClick: _vm.categoryClick },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadmore,
            expression: "loadmore",
          },
        ],
        staticClass: "counselor_content_box",
        staticStyle: { "overflow-y": "auto", height: "100vh" },
        attrs: { "infinite-scroll-disabled": "loading" },
      },
      _vm._l(_vm.counselorList, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "counselor_content",
            on: {
              click: function ($event) {
                return _vm.counselorClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "counselor_content_img" }, [
              _c("img", {
                attrs: { src: item.avatar, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "counselor_content_content" }, [
              _c("div", { staticClass: "counselor_content_top" }, [
                _c("span", { staticClass: "counselor_content_name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counselor_content_subtitle" }, [
                  _vm._v(_vm._s(item.spots)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "counselor_content_title_box" }, [
                _c("span", { staticClass: "counselor_content_title" }, [
                  _vm._v(_vm._s(item.introduction)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "counselor_content_bottom" }, [
                _c("span", { staticClass: "counselor_content_price" }),
                _vm._v(" "),
                _c("span", { staticClass: "counselor_content_date" }, [
                  _vm._v(_vm._s(_vm._f("watchMethod")(item.counselorMethod))),
                ]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }