"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _validate = require("@/utils/validate");
var _utils = _interopRequireDefault(require("../../utils/utils.js"));
var _row = _interopRequireDefault(require("element-ui/packages/row/src/row"));
var _col = _interopRequireDefault(require("element-ui/packages/col/src/col"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'serviceLogin',
  props: ['title', 'setName', 'setCode'],
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: '',
        captcha: '',
        captchaUid: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '账号不能为空'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        captcha: [{
          required: true,
          trigger: 'blur',
          message: '验证码不能为空'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
      codeImg: ''
    };
  },
  components: {
    ElCol: _col.default,
    ElRow: _row.default
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
        if (route.query && route.query.xuid) {
          this.redirect = this.redirect + '&xuid=' + route.query.xuid;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.loginForm.verifyCodeKey = _utils.default.getUUID();
    this.createCaptcha();
  },
  destroyed: function destroyed() {},
  methods: {
    forget: function forget() {
      this.$router.push('/password');
    },
    register: function register() {
      this.$router.push('/register');
    },
    // 图片验证码
    createCaptcha: function createCaptcha() {
      var _this = this;
      (0, _user.getRefresh)().then(function (res) {
        _this.codeImg = res.data.captcha;
        _this.loginForm.captchaUid = res.data.captchaUid;
      }).catch(function (err) {});
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.$store.dispatch('user/loginPassword', _this3.loginForm).then(function () {
            // 登录类型 1：账号密码登录
            localStorage.setItem('loginType', 0);
            _this3.loading = false;
            (0, _user.getFinishData)().then(function (data) {
              localStorage.setItem('isAllFinish', data.data.isAllFinish);
              if (data.data.isAllFinish == 1) {
                _this3.$router.push({
                  path: _this3.redirect || '/'
                });
              } else {
                _this3.$router.replace({
                  path: '/material'
                });
              }
            }).catch(function (err) {});
          }).catch(function () {
            _this3.loading = false;
            _this3.createCaptcha();
          });

          // this.$store
          //   .dispatch('user/login', this.loginForm)
          //   .then(() => {
          //     console.log('login success!!')
          //     this.createCaptcha()
          //     this.$router.push({ path: this.redirect || '/' })
          //     this.loading = false
          //   })
          //   .catch(() => {
          //     console.log('login error!!')
          //     this.createCaptcha()
          //     this.loading = false
          //   })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};