"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      number: [],
      optionList: [],
      numberBox: 0
    };
  },
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    },
    percenter: {
      default: ''
    },
    beforeTopic: {
      default: ''
    }
  },
  watch: {
    percents: function percents(newVal, oldVal) {
      this.optionList = [];
      this.reset();
    }
  },
  mounted: function mounted() {
    this.reset();
  },
  methods: {
    topic: function topic() {
      var _this = this;
      console.log(_this.beforeTopic);
      console.log(_this.nowAnswer);
      _this.optionList = [];
      if (!_utils.default.isNull(_this.beforeTopic)) {
        _this.beforeTopic.forEach(function (item) {
          _this.nowAnswer.options.forEach(function (items) {
            if (item.questionId == _this.nowAnswer.id && item.optionId == items.id) {
              items.num = item.num;
              var numberContent = {
                num: item.num,
                optionId: item.id,
                score: item.score,
                gene: item.gene
              };
              _this.optionList.push(numberContent);
            }
          });
        });
      }
      // this.$forceUpdate();
    },
    reset: function reset() {
      this.optionList = [];
      this.nowAnswer.options.forEach(function (item) {
        item.num = 0;
      });
      this.topic();
      this.$forceUpdate();
    },
    sortbotton: function sortbotton(index, num) {
      var _this = this;
      console.log(index);
      _this.number = [];
      _this.optionList = [];
      for (var i = 0; i < _this.nowAnswer.options.length; i++) {
        _this.number.push(_this.nowAnswer.options[i].num);
        var numberContent = {
          num: _this.nowAnswer.options[i].num,
          optionId: _this.nowAnswer.options[i].id,
          score: _this.nowAnswer.options[i].score
        };
        _this.optionList.push(numberContent);
      }
      _this.numberBox = Math.max.apply(null, _this.number);
      if (num == 0) {
        _this.nowAnswer.options[index].num = _this.numberBox + 1;
        _this.number[index] = _this.numberBox + 1;
        _this.optionList[index].num = _this.numberBox + 1;
      } else {
        _this.nowAnswer.options[index].num = 0;
        _this.number[index] = 0;
        _this.optionList[index].num = 0;
        for (var i = 0; i < _this.nowAnswer.options.length; i++) {
          if (num < _this.nowAnswer.options[i].num) {
            _this.nowAnswer.options[i].num = _this.nowAnswer.options[i].num - 1;
          }
        }
        for (var i = 0; i < _this.number.length; i++) {
          if (num < _this.number[i]) {
            _this.number[i] = _this.number[i] - 1;
          }
        }
        for (var i = 0; i < _this.optionList.length; i++) {
          if (num < _this.optionList[i].num) {
            _this.optionList[i].num = _this.optionList[i].num - 1;
          }
        }
      }
      this.$forceUpdate();
    },
    chooseAnswer: function chooseAnswer() {
      var _this = this;
      console.log(_this.optionList);
      for (var i = 0; i < _this.optionList.length; i++) {
        if (_this.optionList[i].num == 0) {
          _this.$message({
            showClose: true,
            message: '请给所有的选项设置顺序！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.optionList.length == 0) {
        _this.$message({
          showClose: true,
          message: '请给所有的选项设置顺序！',
          type: 'warning'
        });
        return;
      }
      var data = {
        optionList: _this.optionList
      };
      console.log(data);
      _this.$emit("chooseAnswer", data);
    }
  }
};