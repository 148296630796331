"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.array.find");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _friendLink = require("@/api/friendLink");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _mchGauge = require("@/api/mchGauge");
var _evaluation = require("@/api/evaluation");
var _payment = require("@/api/payment");
var _share = require("@/utils/share");
var _user = require("@/api/user");
var _vuex = require("vuex");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      friends: [],
      mchGaugeId: '',
      evaluationId: '',
      gaugeData: {},
      paymentSn: '',
      informationBox: '',
      loading: null,
      firstEnter: false
    };
  },
  components: {},
  filters: {},
  beforeRouterEnter: function beforeRouterEnter(to, from, next) {
    if (from.name == null) {
      next(function (vm) {
        vm.firstEnter = true;
      });
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId', 'userId', 'xuid', 'token'])),
  created: function created() {
    this.mchGaugeId = this.$route.query.mchGaugeId;
    if (!_utils.default.isNull(this.$route.query.evaluationId)) {
      this.evaluationId = this.$route.query.evaluationId;
    }
    this.loadFriendLink(this.mchGaugeId);
    this.findMchGauge(this.mchGaugeId);
    this.informationBox = localStorage.getItem('information' + this.evaluationId);
    var _this = this;
    this.getXuid();
  },
  mounted: function mounted() {
    var _this = this;
    //h5支付重定向回来检查是否支付成功
    if (!_utils.default.isNull(this.$route.query.paymentStatus)) {
      if (!_utils.default.isNull(_this.gaugeData.infos) && _utils.default.isNull(_this.informationBox)) {
        _this.goInformationTwo();
      } else {
        _this.$router.replace({
          path: '/gauge/answer',
          query: {
            evaluationId: _this.evaluationId
          }
        });
      }
    }
    // 挂载完成后，判断浏览器是否支持popstate
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  // 页面销毁时，取消监听。否则其他vue路由页面也会被监听
  destroyed: function destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    findMchGauge: function findMchGauge(query) {
      var _this2 = this;
      var _this = this;
      (0, _mchGauge.find)(query).then(function (response) {
        console.log(response);
        _this2.gaugeData = response.data.gauge;
        if (!_utils.default.isNull(_this2.gaugeData.spots)) {
          _this2.gaugeData.spots = _this2.gaugeData.spots.split(',');
        }
        (0, _share.SHARE)(location.href, {
          title: response.data.gauge.title,
          desc: response.data.gauge.subTitle,
          link: _utils.default.host() + "/#/gauge/index?mchGaugeId=".concat(_this2.mchGaugeId, "&xuid=").concat(_this2.userId),
          imgUrl: response.data.gauge.thumbnail
        }, function (res) {});
      }).catch(function (error) {});
    },
    loadFriendLink: function loadFriendLink(query) {
      var _this3 = this;
      var params = new URLSearchParams();
      params.append('mainProductType', 1);
      params.append('mainProductId', query);
      (0, _friendLink.list)(params).then(function (res) {
        _this3.friends = res.data.data;
      });
    },
    toLink: function toLink(item) {
      if (item.linkProductType == 1) {
        this.$router.push({
          path: '/gauge/index',
          query: {
            mchGaugeId: item.linkProductId
          }
        });
        this.findMchGauge(item.linkProductId);
        this.loadFriendLink(item.linkProductId);
      } else if (item.linkProductType == 2) {
        this.$router.push({
          path: '/course/info',
          query: {
            courseId: item.linkProductId
          }
        });
      } else if (item.linkProductType == 3) {
        this.$router.push({
          path: '/article/index',
          query: {
            articleId: item.linkProductId
          }
        });
      }
    },
    getXuid: function getXuid() {
      console.log(this.userId);
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch('user/setXuid', xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== '') {
        // 如果有token并且参数里有xuid
        // alert(this.xuid)
        (0, _member.lockSysUser)({
          'xuid': this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    goBack: function goBack() {
      if (window.history.length == 0) {
        this.$router.replace({
          path: '/'
        });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
    complete: function complete() {
      var _this4 = this;
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchGaugeId', this.mchGaugeId);
      (0, _evaluation.create)(params).then(function (response) {
        console.log(response);
        _this.evaluationId = response.data.evaluation.id;
        // this.$router.push({
        //   path: '/gauge/answer', query:{evaluationId:this.evaluationId}
        // })
        if (!_utils.default.isNull(response.data.payment)) {
          _this.paymentSn = response.data.payment.sn;
          _this.$router.replace({
            path: '/payment',
            query: {
              sn: _this.paymentSn,
              redirect: '/gauge/index?mchGaugeId=' + _this.mchGaugeId + '&evaluationId=' + _this.evaluationId
            }
          });
        } else {
          if (!_utils.default.isNull(_this4.gaugeData.infos) && _utils.default.isNull(_this.informationBox)) {
            _this.goInformation();
          } else {
            _this.$router.push({
              path: '/gauge/answer',
              query: {
                evaluationId: _this.evaluationId
              }
            });
          }
        }
      }).catch(function (error) {});
    },
    goInformation: function goInformation() {
      var _this = this;
      this.$router.push({
        path: '/gauge/info',
        query: {
          infors: _this.gaugeData.infos,
          id: _this.mchGaugeId,
          evaluationId: _this.evaluationId
        }
      });
    },
    goInformationTwo: function goInformationTwo() {
      var _this = this;
      this.$router.replace({
        path: '/gauge/info',
        query: {
          infors: _this.gaugeData.infos,
          id: _this.mchGaugeId,
          evaluationId: _this.evaluationId
        }
      });
    }
  }
};