"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {};
  },
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    }
  },
  components: {},
  created: function created() {},
  methods: {
    chooseAnswer: function chooseAnswer() {
      this.$emit("chooseAnswer", '');
    }
  }
};