"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.array.find");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _prompt = _interopRequireDefault(require("./prompt"));
var _counselor = require("@/api/counselor");
var _utils = _interopRequireDefault(require("../../../utils/utils"));
var _counselorOrder = require("@/api/counselorOrder");
var _agreement = _interopRequireDefault(require("./agreement.vue"));
var _agreeBook = _interopRequireDefault(require("./agreeBook.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      quantity: 1,
      checked: false,
      agreeType: false,
      dateType: false,
      infoList: [],
      sortList: [],
      timeData: '',
      agreementType: false,
      promptType: false,
      title: '预约须知',
      promptData: [],
      dataList: [],
      startX: 0,
      startY: 0
    };
  },
  components: {
    prompt: _prompt.default,
    agreement: _agreement.default,
    agreeBook: _agreeBook.default
  },
  props: ['appointmentDate', 'appointmentHour', 'counselorMethod', 'mchCounselorId', 'timeIndex', 'dateIndex'],
  created: function created() {
    this.findClick();
  },
  filters: {
    watchWeek: function watchWeek(val) {
      switch (val) {
        case 0:
          return "周天";
          break;
        case 1:
          return "周一";
          break;
        case 2:
          return "周二";
          break;
        case 3:
          return "周三";
          break;
        case 4:
          return "周四";
          break;
        case 5:
          return "周五";
          break;
        case 6:
          return "周六";
      }
    },
    watchDateOne: function watchDateOne(val) {
      return val.timeClick.split('-')[0];
    },
    watchDateTwo: function watchDateTwo(val) {
      return val.timeClick.split('-')[1];
    }
  },
  methods: {
    agreeBook: function agreeBook() {
      this.agreeType = true;
    },
    agreement: function agreement() {
      this.agreementType = true;
    },
    findClick: function findClick() {
      var _this2 = this;
      var _this = this;
      (0, _counselor.find)(this.mchCounselorId).then(function (response) {
        response.data.counselor.calendarTable = eval('(' + response.data.counselor.calendarTable + ')');
        _this2.infoList = response.data.counselor;
        _this2.infoList.calendarTable.forEach(function (item) {
          _this2.sortList.push(item.sort(_this2.paixu));
        });
        _this2.infoList.calendarTable = _this2.sortList;
        _this2.getDate();
        _this2.checkExistsClick();
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    paixu: function paixu(a, b) {
      a = a.split("-").shift().slice(0, 2);
      b = b.split("-").shift().slice(0, 2);
      return a - b;
    },
    nextClick: function nextClick() {
      this.$emit('nextClick', 1);
    },
    complete: function complete() {
      var _this = this;
      if (_utils.default.isNull(_this.appointmentHour)) {
        alert('请选择预约时间');
        return;
      }
      if (_utils.default.isNull(_this.counselorMethod)) {
        alert('请选择预约方式');
        return;
      }
      if (_this.checked == false) {
        alert('请同意心理咨询预约协议与知情同意书');
        return;
      }
      var params = new URLSearchParams();
      params.append('appointmentDate', _this.appointmentDate);
      params.append('appointmentHour', _this.appointmentHour.timeClick);
      params.append('mchCounselorId', _this.mchCounselorId);
      params.append('counselorMethod', _this.counselorMethod);
      params.append('quantity', _this.quantity);
      (0, _counselorOrder.create)(params).then(function (response) {
        if (!_utils.default.isNull(response.data.payment)) {
          _this.sn = response.data.payment.sn;
          _this.$router.replace({
            path: '/payment',
            query: {
              sn: _this.sn,
              redirect: '/counselor/steps?mchCounselorId=' + _this.mchCounselorId + '&appointmentDate=' + _this.appointmentDate + '&appointmentHour=' + _this.appointmentHour.timeClick + '&counselorMethod=' + _this.counselorMethod + '&timeIndex=' + _this.timeIndex + '&dateIndex=' + _this.dateIndex
            }
          });
        } else {
          _this.checkExistsClick();
        }
      }).catch(function (error) {});
    },
    checkExistsClick: function checkExistsClick() {
      var _this3 = this;
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchCounselorId', _this.mchCounselorId);
      (0, _counselorOrder.checkExists)(params).then(function (response) {
        if (!_utils.default.isNull(response.data.counselorOrder)) {
          _this3.$emit('nextClick', 1, response.data.counselorOrder.id, _this3.infoList.attributes);
        } else {
          _this3.$emit('nextClick', 0);
        }
      }).catch(function (error) {});
    },
    chooseTimeClick: function chooseTimeClick() {
      this.dateType = !this.dateType;
      // this.stop()
    },
    stopClick: function stopClick() {
      event.stopPropagation();
    },
    chooseClick: function chooseClick(index, indexs) {
      this.dateIndex = index;
      this.timeIndex = indexs;
      this.appointmentDate = this.dataList[index].yearDate;
      this.appointmentHour = this.dataList[index].content[indexs];
      console.log(this.appointmentHour);
      this.timeOut();
    },
    timeOut: function timeOut() {
      var _this = this;
      setTimeout(function () {
        _this.dateType = false;
      }, 500);
    },
    openClick: function openClick() {
      this.promptType = !this.promptType;
      this.promptData = [{
        title: '回应时长',
        subTitle: '咨询师将在收到订单后的3小时内回应是否接受咨询，且在12小时内与来访者协商好咨询时间、地点。'
      }, {
        title: '关于预约变更',
        subTitle: '若因特殊原因，需要变更/取消已协商好的咨询预约，请务必提前48小时（两个工作日）联系。否则咨询将如期开始。'
      }, {
        title: '爽约/迟到',
        subTitle: '若无提前24小时告知情况，爽约/迟到20分钟以上，则默认这次咨询已经完成。其他特殊情况，需协商处理。'
      }];
    },
    downClick: function downClick() {
      this.agreeType = false;
      this.promptType = false;
      this.agreementType = false;
    },
    getDate: function getDate() {
      var _this = this;
      for (var i = 0; i < 14; i++) {
        _this.fun_date(i);
      }
      if (!_utils.default.isNull(_this.infoList.calendarTable)) {
        _this.dataList.forEach(function (item) {
          var list = [];
          if (item.day == '1') {
            if (_this.infoList.calendarTable[0].length > 0) {
              _this.infoList.calendarTable[0].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '2') {
            if (_this.infoList.calendarTable[1].length > 0) {
              _this.infoList.calendarTable[1].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '3') {
            if (_this.infoList.calendarTable[2].length > 0) {
              _this.infoList.calendarTable[2].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '4') {
            if (_this.infoList.calendarTable[3].length > 0) {
              _this.infoList.calendarTable[3].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '5') {
            if (_this.infoList.calendarTable[4].length > 0) {
              _this.infoList.calendarTable[4].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '6') {
            if (_this.infoList.calendarTable[5].length > 0) {
              _this.infoList.calendarTable[5].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            // _this.infoList.calendarTable[5]=list
            item.content = list;
          } else if (item.day == '0') {
            if (_this.infoList.calendarTable[6].length > 0) {
              _this.infoList.calendarTable[6].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          }
        });
      }
      ;
      console.log(_this.dataList);
    },
    fun_date: function fun_date(day) {
      // 获取当前日期
      var nowDate = new Date();
      // 获取day天后的日期信息
      var targetday_milliseconds = nowDate.getTime() + 1000 * 60 * 60 * 24 * day;
      nowDate.setTime(targetday_milliseconds);
      // 格式化星期
      var date = {
        date: nowDate.getDate(),
        day: nowDate.getDay(),
        yearDate: nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate(),
        content: []
      };
      this.dataList.push(date);
    }
  }
};