"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _courseItem = require("@/api/courseItem");
var _mchCourse = require("@/api/mchCourse");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      block: 'A',
      type: '',
      id: '',
      courseItemForm: {},
      pageNum: 1,
      pageSize: 3,
      courseForm: []
    };
  },
  created: function created() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.courseId = this.$route.query.courseId;
    this.find();
    this.courseList();
  },
  components: {
    NoData: _NoData.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  methods: {
    func: function func(c) {
      this.block = c;
    },
    find: function find() {
      var _this = this;
      (0, _courseItem.find)(_this.id).then(function (response) {
        console.log(response);
        _this.courseItemForm = response.data.material;
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    returnClick: function returnClick() {
      this.$router.replace({
        path: '/course/info',
        query: {
          courseId: this.courseId,
          block: 'B'
        }
      });
    },
    courseList: function courseList() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      (0, _mchCourse.list)(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.spots = item.spots.split(',');
        });
        _this.courseForm = response.data.data;
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    promptClick: function promptClick() {
      var _this = this;
      _this.$message({
        message: '功能尚未开发',
        offset: 120,
        center: true
      });
    },
    indexClick: function indexClick() {
      this.$router.push({
        path: '/'
      });
    },
    memberClick: function memberClick() {
      this.$router.push({
        path: '/member/member'
      });
    }
  }
};