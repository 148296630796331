"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _leave = require("@/api/leave");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      list: []
    };
  },
  created: function created() {
    this.Init();
  },
  methods: {
    Init: function Init() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('receiveMsgFlag', 1);
      (0, _leave.usrList)(params).then(function (res) {
        _this.list = res.data;
      }).catch(function (err) {});
    },
    onUser: function onUser(row) {
      if (row.receiveMsgFlag != 1) {
        this.$message('该用户已关闭留言');
        return;
      }
      this.$router.push({
        path: '/leaveList',
        query: {
          id: row.id
        }
      });
    }
  }
};