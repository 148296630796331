var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page homeDetails", attrs: { id: "homeDetails" } },
    [
      _c("div", { staticClass: "homeDetails-header" }, [
        _c("div", { staticClass: "homeDetails-header-imgBox" }, [
          _c("img", {
            attrs: {
              src: _vm._f("thumbnail")(_vm.gaugeData.thumbnail, 750, 360),
              width: "100%",
              height: "100%",
              alt: "",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "homeDetails-title" }, [
        _c("div", { staticClass: "flex-column" }, [
          _c("span", { staticClass: " homeDetails-title-size" }, [
            _vm._v(_vm._s(_vm.gaugeData.title)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: " homeDetails-title-second" }, [
            _vm._v(_vm._s(_vm.gaugeData.subTitle)),
          ]),
        ]),
        _vm._v(" "),
        _vm.gaugeData.spots != ""
          ? _c(
              "div",
              { staticClass: "flex-row homeDetails-title-sign" },
              _vm._l(_vm.gaugeData.spots, function (item) {
                return _c("span", { key: item.id }, [_vm._v(_vm._s(item))])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "contentBox",
        domProps: { innerHTML: _vm._s(_vm.gaugeData.content) },
      }),
      _vm._v(" "),
      _vm.friends.length > 0
        ? _c("div", { staticClass: "recommend-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "recommend-image-content" },
              _vm._l(_vm.friends, function (item) {
                return _c("img", {
                  key: item.id,
                  staticStyle: {
                    "border-radius": "10px",
                    width: "100%",
                    height: "100%",
                    "margin-top": "20px",
                  },
                  attrs: { src: item.thumbnail },
                  on: {
                    click: function ($event) {
                      return _vm.toLink(item)
                    },
                  },
                })
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "homeDetails-footer", style: { zIndex: 99 } }, [
        _c("div", { staticClass: "homeDetails-footer-left" }, [
          _vm.gaugeData.price || 0 > 0
            ? _c("span", [
                _c("span", [_vm._v("¥")]),
                _c("span", [_vm._v(_vm._s(_vm.gaugeData.price))]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "homeDetails-footer-right",
            on: {
              click: function ($event) {
                return _vm.complete()
              },
            },
          },
          [_c("span", [_vm._v("立即测评")])]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          width: "80px",
          "justify-content": "space-between",
          "align-items": "center",
        },
      },
      [
        _c("div", { staticClass: "vertical" }),
        _vm._v(" "),
        _c("div", [_vm._v("相关推荐")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }