var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "announcement_box" },
      [
        _c(
          "el-carousel",
          { attrs: { height: "30px", direction: "vertical", autoplay: true } },
          [
            _c("div", { staticClass: "announcement-cont-title" }, [
              _vm._v("公告"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.announcementMessage, function (item) {
              return _c("el-carousel-item", { key: item }, [
                _c("div", { staticClass: "announcement-cont" }, [
                  _c("div", { domProps: { innerHTML: _vm._s(item.title) } }),
                ]),
              ])
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }