var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.agreementType == true,
          expression: "agreementType == true",
        },
      ],
      staticClass: "prompt_box",
      on: { click: _vm.downClick },
    },
    [
      _c(
        "div",
        { staticClass: "prompt_content", on: { click: _vm.stopClick } },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "prompt_bottom_box" }, [
            _c(
              "div",
              { staticClass: "prompt_bottom", on: { click: _vm.downClick } },
              [
                _c("span", { staticClass: "prompt_bottom_title" }, [
                  _vm._v("知道了"),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt_header" }, [
      _c("span", { staticClass: "prompt_header_title" }, [
        _vm._v("心理咨询预约协议"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt_content_box" }, [
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("一、提示条款"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "在接受本协议之前，应当认真阅读本协议。请用户务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的条款、法律适用、和争议解决条款。免除或限制责任的条款将以粗体下划线标识，应重点阅读。如您未满18周岁，或以其他形式属于限制民事行为能力人，请在监护人或法定代理人的陪同下阅读本协议。\n        如用户对协议有任何疑问，请不要进行后续操作。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [_vm._v("二、定义")]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("美心数科：指厦门市美心说数字科技有限公司。"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "心理咨询师：指同意并承诺遵守本协议规定使用美心数科的心理咨询预约平台向用户提供心理咨询服务的拥有相应资质的咨询师。\n        心理咨询预约平台：指厦门市美心说数字科技有限公司所开发的线上咨询预约系统。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "心理咨询预约服务：指心理咨询师通过本协议服务平台发布咨询信息，向服务平台用户提供心理咨询和科学心理学传播等。\n        心理咨询：指咨询师与用户双方通过面谈、网络视频、语音和图文为媒介，共同探讨引起心理问题的原因，分析问题症结，进而帮助用户寻求摆脱困境解决问题的条件和对策，以便恢复心理平衡、提高对环境的适应能力，增进身心健康。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("三、协议内容"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          '\n        （一）本协议内容包括协议正文以及所有美心数科已经发布的或将来可能发布的各类规则、通知、公告等。所有规则为协议不可分割的组成部分，与协议正文具有同等法律效力。美心数科将就网站制定特别规则（下称"特别规则"），如特别规则与网站其他规则存在冲突，则以特别规则为准。\n      '
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）用户在使用美心数科提供的各项服务的同时，承诺接受并遵守各项相关规则的规定。美心数科有权根据需要不时地制定、修改本协议和/或各类规则，如有任何变更，美心数科将在网站、APP等以公示的方式进行公告，不再单独通知每个用户。变更后的协议和规则一经公布即自动生效，成为本协议的一部分。如用户不同意相关变更，应当立即以书面通知的方式终止本协议；任何修订和新规则一经在网站上公布即自动生效，成为本协议的一部分。如用户登录或继续使用服务的，则视为对修改后的协议和规则不持异议并同意遵守。除另行明确声明外，任何使服务范围扩大或功能增强的新内容均受本协议约束。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）美心数科保留在任何时候自行决定对服务及其相关功能、应用软件变更、升级的权利。美心数科进一步保留在服务中开发新的模块、功能和软件或其它语种服务的权利。上述所有新的模块、功能、软件服务的提供，除非美心数科另有说明，否则仍适用本协议。服务随时可能因美心数科的单方判断而被增加或修改，或因定期、不定期的维护而暂缓提供，用户将会得到相应的变更通知。用户在此同意美心数科在任何情况下都无需向其或第三方在使用服务时对其在传输或联络中的迟延、不准确、错误或疏漏及因此而致使的损害负责。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("四、用户资格"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）用户确认，在完成注册或以美心数科允许的方式实际享受美心数科提供的服务时，用户应当是具备完全民事权利能力和与所从事的民事行为相适应的自然人。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）如用户是一个未成年人，请在享受美心数科提供的服务前取得监护人同意。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）一经发现用户不具备前述主体资格使用美心数科服务或产品的，由此产生一切后果由用户及其监护人承担，且美心数科有权立即注销用户的账户。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("五、账户说明"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）当用户按照本网站的流程完成全部注册程序后，用户可获得本网站账户的使用权并成为美心数科用户。用户可以设置或修改账户密码。用户用对自己的账户和密码的安全负责。除非有法律规定或司法裁定，且征得美心数科的同意，否则，账户不得以任何方式转让、赠与或继承。如发现任何人不当使用用户的账户或有任何其他可能危及用户账户安全的情形时，用户应当立即以有效方式通知美心数科，要求美心数科暂停相关服务。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）美心数科只允许每位用户使用一个账户。如有证据证明或美心数科有理由相信用户存在不当注册或不当使用多个账户的情形，美心数科可注销账户并拒绝提供服务措施，如给美心数科及其相关方造成损失的，用户还应承担赔偿责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）为了使用户更好地使用美心数科提供的服务，保障用户的账户安全，美心数科可要求用户按要求及我国法律规定完成实名认证。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("六、使用规则"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （一）合法性\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1. 用户在使用美心数科服务及产品时，务必遵守中华人民共和国相关法律法规的规定，不得把美心数科提供的服务及产品用于任何违法或不正当或不道德的活动，否则美心数科可采取注销账户并拒绝提供服务措施包括但不限于以下行为：\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （1）违反宪法或法律法规规定的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；损害国家荣誉和利益的，损害公共利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；含有法律、行政法规禁止的其他内容的；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （2）以任何非法目的而使用网络服务系统；\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （3）故意制作、传播计算机病毒等破坏性程序；\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （4）其他危害计算机信息网络安全的行为。\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 用户设置的网站会员名不得违反国家法律法规及本网站相关规则，否则美心数科有权终止用户的该会员名。会员名的终止不影响用户以邮箱、手机号码登录本网站并使用美心数科提供的服务。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （二）真实性\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        用户注册应同意以下事项：提供真实、正确、最新及完整的资料，并随时更新登记资料，确保其为真实、正确、最新及完整的资料。若用户提供任何错误、不实、过时或不完整或具误导性的资料，或者美心数科有理由怀疑前述资料为错误、不实、过时或不完整或具误导性的，美心数科有权立即暂停或终止用户的帐号，并拒绝用户于现在和未来使用美心数科所提供服务之全部或任何部分。适用范围包括：\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1. 在用户注册本网站账户时，用户根据美心数科要求提供的注册信息；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 在用户使用本网站服务，或访问本网站时，本网站自动接收并记录用户的浏览器和计算机上的信息，包括但不限于用户的IP地址、浏览器的类型、使用的语言、使用的语言、访问日期和时间、软硬件特征信息及用户需求的网页记录等数据；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        3. 美心数科通过合法途径从商业伙伴处取得的用户数据。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （三）更新维护\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        用户应当及时更新您提供的信息，美心数科将依法不时地对用户的信息进行检查核实，用户应当配合提供最新、真实、完整、有效的信息。\n        如美心数科按用户最后一次提供的信息与用户联系未果、用户未按美心数科的要求及时提供信息、用户提供的信息存在明显不实或行政司法机关核实用户提供的信息无效的，用户将承担因此对您自身、他人及美心数科造成的全部损失与不利后果。美心数科可向用户发出询问或要求整改的通知，并要求用户进行重新认证，直至中止、终止对用户提供部分或全部服务，美心数科对此不承担责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("七、用户发布的信息"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）用户声明并保证，用户对自己所发表的信息、评论等拥有相应、合法的权利。否则，美心数科有权即时对用户发布的信息进行删除、屏蔽处理。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）用户应当确保您所发布的信息不包含以下内容：\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        1. 违反国家法律法规禁止性规定的；\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        3. 欺诈、虚假、不准确或存在误导性的；\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        4. 侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        4. 侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        6. 存在可能破坏、篡改、删除、影响本网站任何系统正常运行或未经授权秘密获取本网站及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        7. 其他违背社会公共利益或公共道德或依据本网站的规定不适合在网站上发布的\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("八、服务提供"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）用户在接受美心数科提供的服务前，应提供与心理有关的真实资料；如用户故意隐瞒或扭曲资料而造成的不良后果由用户自己负责。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）用户在接受美心数科提供的服务完毕后，应及时在线上点击“确认完成咨询”标识。如用户收到美心数科向用户确认完成咨询的通知之日起7日内未进行确认完成咨询又未向美心数科提出任何异议的，视为该次预约咨询服务完成。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）用户不得未经美心数科预约私下跟咨询师达成交易，否则，因此与咨询师发生退款等纠纷的，用户自行承担一切后果，与美心数科无关，美心数科不负任何责任。如美心数科发现用户存在私下交易的行为，美心数科可即时采取注销账户并拒绝提供服务措施，如给美心数科及其相关方造成损失的，用户还应承担赔偿责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （四）用户理解并同意心理咨询的效果取决于咨询师与用户双方配合作用的结果。无论用户采用什么样的咨询方式，美心数科并不能保证用户接受心理咨询后一定能达到怎样的效果。用户理解并同意：达不到用户心理预期的心理咨询效果，不属于服务质量问题，用户不以此为由要求美心数科退款，且美心数科不承担任何法律上的责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （四）用户理解并同意心理咨询的效果取决于咨询师与用户双方配合作用的结果。无论用户采用什么样的咨询方式，美心数科并不能保证用户接受心理咨询后一定能达到怎样的效果。用户理解并同意：达不到用户心理预期的心理咨询效果，不属于服务质量问题，用户不以此为由要求美心数科退款，且美心数科不承担任何法律上的责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （六）未经用户和咨询师同意，美心数科不对双方的心理咨询录音录制。若用户和咨询师共同认为对心理咨询有录音录像需要的，可向美心数科提出，美心数科可以为双方提供录音录像服务。一旦美心数科为双方提供录音录像服务的，美心数科将严格保密录音录像内容，不会随意泄露或同意他人调取录音录像内容，但经得用户和咨询师共同同意调取或其他法定理由许可的除外。\n         在此强调，此条款涉及隐私问题，为保障您自身的隐私权利，请认真阅读并理解本条款。若您对此条款不同意或不理解，请咨询美心数科，以及立即停止后续操作，终止服务。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （七）用户和咨询师已充分知悉和理解：用户和咨询师使用APP在线语音或视频咨询功能时，会产生缓存信息。美心数科会对缓存信息加密保护，不会随意泄露或同意他人调取。若有特殊情况，并经得用户和咨询师共同同意调取或其他法定理由许可的，美心数科可以配合调取，但是，受缓存时间空间等因素限制，美心数科不保证一定能成功调取缓存信息。\n         在此强调，此条款涉及隐私问题，为保障您自身的隐私权利，请认真阅读并理解本条款。若您对此条款不同意或不理解，请咨询美心数科，以及立即停止后续操作，终止服务。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("九、更改预约"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）用户需要更改预约（包括咨询时间、咨询方式、取消咨询等），需要在预约开始前至少24小时提出。如：原定周三晚上18：00开始的咨询，请在周二晚18：00之前提出更改。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）如用户在可更改时间后提出更改预约，更改无效，壹心理仍收取预约时间段的费用，即使用户缺席。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [_vm._v("十、费用")]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （一）收费标准\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1. 美心数科提供的心理咨询服务按每次50分钟计费，收费标准如页面显示的定价。如用户在线预约了服务，视为用户同意接受页面所示对应的收费标准。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 如美心数科在某个时期有优惠政策，则收费标准按当时的优惠政策执行。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （二）付费方式\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        用户的咨询意味着对美心数科的接纳和信任，咨询费用不仅仅是劳动的报酬，也体现了投入与关注成正比的心理学原则。用户在美心数科网站预约寻找帮助时，应先在美心数科网站上付款后才能接受美心数科提供的付费服务。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （三）关于退费\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        在来访者已付款的情况下，申请退款，需遵循以下规定：\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1.在咨询师尚未接受预约时，来访者决定放弃预约，可私信咨询助理，使订单失效；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2.咨询师已接受预约，但咨询未正式开始，来访者决定放弃预约，可联系咨询师说明原因，使订单失效。请务必在咨询正式开始前，提前至少24小时联系咨询师（不同咨询师要求提前的时间可能不同，以实际为准）。订单失效后，费用将在7个工作日内全额并原路退回。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        3. 在咨询订单尚未最终确认完成时，来访者预约咨询 N 次，并完成 K 次，剩余N-K次咨询，如需取消剩余咨询的预约并收回费用，请来访者与咨询师协商：重新预约K 次咨询并确认完成，同时咨询师取消原\n        N次的预约订单，退款流程同前文所提情况（2），以此达到退回 N-K次咨询费用。例如：来访者预约了 10 次语音咨询询，做完 8次后，咨访双方认为可以结束咨询，那么剩余两次未使用。如果来访者要求退回\n        2次费用，则需要重新预约咨询师 8 次的语音咨询，同时咨询师取消原本 10次的订单。来访者将在 7 个工作日内收到 10 次语音咨询费用的退款。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        4.若咨询订单已最终确认完成（咨询完成后，咨询师首先确认订单完成，来访者会收到通知并有7天时间确认完成，逾期将自动确认），原则上将不可进行退款处理。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （四）费用纠纷\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        有且仅有用户向美心数科网站支付咨询费的咨询服务，美心数科网站才会解决相关服务纠纷。除此之外，美心数科不承担任何责任。特别是用户未经美心数科与咨询师私下交易发生纠纷的，用户同意并且应当自行与咨询师解决，与美心数科无关；与咨询师发生退款纠纷时，亦请自行与咨询师解决，与美心数科网站无关，美心数科没有收取咨询费，无提供服务义务，亦无协助解决义务。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十一、隐私保护"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）请用户妥善保管您的账户及密码信息。若用户发现账户被他人非法使用，应立即通知美心数科。因不可抗力（包括黑客行为、计算机病毒、系统不稳定等）、遭受他人诈骗或用户主动泄露、保管疏忽导致账号、密码被他人非法使用的，美心数科不承担任何责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）美心数科将采取合理的安全手段保护用户已存储的个人信息，除下述信息披露条款外，在未得到用户许可之前，美心数科不会将用户的任何个人信息提供给任何无关的第三方（包括公司或个人）。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        1. 经用户事先同意，向第三方披露；\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 只有通过使用用户的保密信息，才能向用户提供所要求的产品服务；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        3. 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        4. 如用户出现违反中国有关法律、法规或者本网站法律声明、服务协议或相关规则的情况，需要向第三方披露；\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        5. 维护本网站的合法权益；\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        6. 根据本协议相关规定或者本网站认为必要的其他情况。\n        （三）美心数科亦不允许任何第三方以任何手段收集、编辑、出售或无偿传播用户的个人信息。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十二、权利声明"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）美心数科所属公司拥有本网站内的所有产品、技术、程序、资料、信息内容（包括但不限于文字、图片、图像、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有权利（包括但不限于版权、商标权、专利权、商业秘密及其他所有相关权利）。未经权利人许可，任何人不得擅自使用。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）用户在接受本协议时即视为主动将其在本网站发表的任何形式的信息的著作权，包括但不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利无偿独家转让给美心数科所有，若发生第三人侵权，美心数科有权单独提起诉讼并获得全部赔偿。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）用户同意美心数科网站后服务市场的全部运营数据，包括但不限于用户信息、用户列表、用户关系、用户的使用数据、交易数据、订单数据等，其全部的权利均归属于美心数科。未经美心数科事先书面同意，均不得利用用户列表及用户关系、向用户发送信息，不得为任何目的擅自保存、备份、泄露、使用或授权他人使用前述运营数据。本协议自然终止或提前解除后，用户无权要求备份前述运营数据\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （四）用户通过注册所得的本网站账户（即本网站用户ID）所有权归属于本网站公司，用户仅对该账户拥有使用权。用户不能以任何方式转让其账户的使用权，如用户违反上述规定，美心数科有权追究的违约责任，由此产生的一切责任由其自行承担。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （五）对不遵守本协议或其他违法、未经许可或者恶意使用本网站内容者，本网站所属公司保留追究其法律责任的权利。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十三、第三方主张权利"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        若用户认为本网站的内容，或通过本网站得到的内容可能涉及侵犯其合法权益，应该及时以书面形式向美心数科反馈，并提供身份证明、权属证明、具体链接（URL）及详细侵权情况证明，美心数科在收到上述法律文件并经审查同意后，将会在合理时间内依法尽快移除、删除相关涉嫌侵权的内容或断开网络连接。如权利通知的陈述失实，权利通知提交者将承担由此造成的全部法律责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十四、链接"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        用户承认并同意，美心数科并不对网站上其他链接的可用性负责，且不认可链接上任何内容、宣传、产品、服务或其他材料，也不对其负责或承担任何责任。用户进一步承认和同意，对于任何因使用或信赖本网站上获取的此宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，美心数科均不承担责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十五、反贿赂"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）美心数科坚持认真贯彻国家关于反商业贿赂的各项规定，努力建立健全反对不正当交易行为和反商业贿赂的长效机制。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）美心数科坚持严厉打击商业贿赂行为，对违反本声明相关规定的员工，将严肃查处和教育，情节严重的，及时报告相关机构；对违反本声明相关规定的用户或合作伙伴，一经查实，美心数科有权终止对用户提供服务，并保留追究相关法律责任的权利。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）美心数科坚持严厉打击商业贿赂行为，对违反本声明相关规定的员工，将严肃查处和教育，情节严重的，及时报告相关机构；对违反本声明相关规定的用户或合作伙伴，一经查实，美心数科有权终止对用户提供服务，并保留追究相关法律责任的权利。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十六、保密"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        一）用户保证在使用本网站过程中所获悉的属于本网站及他方的且无法自公开渠道获得的商业秘密、专有信息、双方共有的客户信息及其他保密性质的所有信息均为保密信息（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）。未经该信息的原享有方同意，不应以任何形式向第三方披露或泄露保密信息，否则，应承担赔偿损失责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）不论本服务协议是否变更、解除或终止，本条款均由法律效力。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十七、免责条款"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）在咨询过程中，用户因自身原因而突发疾病或意外死亡，或因自身原因造成情绪失控而发生自伤、自残、自杀等后果，美心数科及其咨询师尽可能采取措施控制事态恶化，必要时与相关部门取得联系。由此造成的损害后果，美心数科不承担责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）有自杀倾向或自杀未遂行为的用户前来咨询的，美心数科及其咨询师会尽量为该用户提供帮助，如用户最终选择自杀，责任及后果将由用户本人全部承担，与美心数科及咨询师无关。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）美心数科不保证网络服务的及时性、安全性、准确性。不论在何种情况下，均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。请用户务必及时保存自己的需求，否则因网络服务中断，需要维修、检修、维护等导致损失的，美心数科不承担损失赔偿的责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （四）在任何情况下，对于因使用美心数科网站内容或无法进入本网站而导致的任何直接的、间接的、附带的、给第三人造成的损失（包括但不限于利润损失、信息数据丢失、财产毁坏等损失），美心数科均无须承担法律责任，不论是采用合同之诉、侵权之诉或其他诉讼理由。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （四）在任何情况下，对于因使用美心数科网站内容或无法进入本网站而导致的任何直接的、间接的、附带的、给第三人造成的损失（包括但不限于利润损失、信息数据丢失、财产毁坏等损失），美心数科均无须承担法律责任，不论是采用合同之诉、侵权之诉或其他诉讼理由。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （六）第三方通过服务所作的陈述或行为，美心数科不承担赔偿因此造成损失的责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （七）其他与服务相关事件，包括疏忽等所造成的损害，美心数科不承担赔偿因此造成损失的责任。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （八）美心数科上的心理咨询不属于疾病诊断或治疗活动，不开展诊疗活动，不属于《医疗机构管理条例》的医疗机构。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十八、协议终止"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （一）终止的情形\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1. 【用户发起的终止】用户有权通过以下任一方式终止本协议：\n        （1）变更事项生效前用户停止使用并明示不愿接受变更事项的\n        （2）用户明示不愿继续使用美心数科提供的服务，且符合本网站终止条件的。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 【美心数科发起的终止】出现以下情况时，美心数科有权无须通知用户即终止本协议：\n        （1）违反本协议约定，美心数科依据违约条款终止本协议的；\n        （2）盗用他人账户、发布违禁信息、骗取他人财物、扰乱网站秩序、采取不正当手段谋利等行为，美心数科对用户的账户予以查封的；\n        （3）除上述情形外，若用户多次违反美心数科相关规定且情节严重，美心数科有权对其的账户予以查封的；\n        （4）用户的账户被美心数科依据本协议取消的；\n        （5）有欺诈、发布或侵犯他人合法权益或其他严重违法违约行为的；\n        （6）其它应当终止服务或美心数科拒绝提供服务的情况。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        （二）协议终止后的处理\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1. 【用户信息披露】本协议终止后，除法律有明确规定外，美心数科无义务向用户或用户指定的第三方披露用户账户中的任何信息。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2. 【本网站权利】本协议终止后，美心数科仍享有下列权利：\n        （1）继续保存用户留存于本网站的本协议的各类信息；\n        （2）对于用户过往的违约行为，本网站仍可依据本协议追究其违约责任\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("十九、违约责任"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）用户如在约定好的咨询时间内迟到，美心数科提供的咨询服务仍在原定时间结束，并按照一次50分钟整的价格付费。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）除本协议另有约定之外，如一方发生违约行为，守约方可以书面通知方式要求违约方在指定的时限内停止违约行为，并就违约行为造成的损失（包括但不限于诉讼费、仲裁费、差旅费等）进行索赔，如违约方未能按时停止违约行为，则守约方有权立即终止本协议。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （三）美心数科因违约或侵权依法对用户赔偿损失的，赔偿额以实际最终收取用户款项总额为限。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("二十、有效通知"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （一）用户应当准确填写并及时更新提供给美心数科的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，保证联系方式的有效性及安全性，以便美心数科及咨询师可以通过上述联系方式与自己进行有效联系。如因通过上述联系方式无法与用户取得联系，导致美心数科站产生任何损失或增加费用的，应由用户完全独立承担。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）本协议下所规定的通知有权要求以书面形式通过以下邮址递交收悉，通知的到达以美心数科收悉为准。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        美心数科地址：厦门市启达时尚大厦2楼220\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v("\n        邮编：361001 ； 收件人：美心数科（收）\n      "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        美心数科可自zixun@xinli001.com邮址向用户在美心数科网注册时提供的电子邮件地址发出通知。通知的送达以邮件发出为准。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("二十一、争议及其他"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n          （一）本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以厦门市思明区人民法院为管辖法院。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        （二）如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部分则仍具有法律效力。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_subtitle" }, [
        _vm._v("\n         本协议于2020年8月4日发布并执行。\n       "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }