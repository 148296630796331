"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _navigation = require("@/api/navigation");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      navigationForm: [{
        name: "线上课程",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/022-lesson.png"
      }, {
        name: "面授课程",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/049-teacher-2.png"
      }, {
        name: "专家评估",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/100-professor-1 (1).png"
      }, {
        name: "心理测评",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/052-task.png"
      }, {
        name: "线上课程",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/022-lesson.png"
      }, {
        name: "面授课程",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/049-teacher-2.png"
      }, {
        name: "专家评估",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/100-professor-1 (1).png"
      }, {
        name: "心理测评",
        img: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/052-task.png"
      }],
      pageNum: 1,
      pageSize: 8,
      tagIds: []
    };
  },
  created: function created() {
    this.loadNavigation();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  props: ["content"],
  methods: {
    checkPermission: _permission.default,
    loadNavigation: function loadNavigation() {
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(",");
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append("tagIds", _this.tagIds[i]);
      }
      params.append("sortField", "orders");
      params.append("direction", "asc");
      (0, _navigation.list)(params).then(function (response) {
        console.log(response);
        _this.navigationForm = response.data.data;
        _this.navigationForm.forEach(function (item) {
          item.logo = _utils.default.thumbnail(item.logo, 270, 200);
        });
      }).catch(function (error) {
        console.log("error!!");
      });
    },
    navigationClick: function navigationClick(item) {
      if (item.type == 11) {
        this.$router.push({
          path: "/course/list",
          query: {
            categoryId: item.typeId
          }
        });
      } else if (item.type == 9) {
        this.$router.push({
          path: "/gauge/list",
          query: {
            categoryId: item.typeId
          }
        });
      } else if (item.type == 12) {
        window.location.href = item.url;
      } else if (item.type == 0) {
        this.$router.push({
          path: "/article/list",
          query: {
            categoryId: item.typeId
          }
        });
      } else if (item.type == 10) {
        if (this.checkPermission(["counselor2-list"])) {
          this.$router.push({
            path: "/counselorNew/list"
          });
        } else if (this.checkPermission(["counselor-list"])) {
          this.$router.push({
            path: "/counselor/list"
          });
        }
      } else if (item.type == 2) {
        this.$router.push({
          path: "/index/wxPage",
          query: {
            id: item.typeId
          }
        });
      } else if (item.type == 3) {
        this.$router.push({
          path: "/activitySy/list",
          query: {
            activityCategoryId: item.typeId
          }
        });
      } else if (item.type == 4) {
        this.$router.push({
          path: "/column/columnCategery",
          query: {
            activeScrollId: item.typeId
          }
        });
      }
    }
  }
};