var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "categoty" }, [
      _vm.checkPermission(["course-list"])
        ? _c(
            "div",
            {
              staticStyle: {
                "border-radius": "10px",
                overflow: "hidden",
                "background-color": "white",
              },
            },
            [
              _c("div", { staticClass: "categoty_content" }, [
                _c(
                  "div",
                  {
                    staticClass: "categoty_content_header_box",
                    on: { click: _vm.courseClick },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.courseCategoryForm.length != 0,
                          expression: "courseCategoryForm.length != 0",
                        },
                      ],
                      class: [
                        _vm.courseType == true
                          ? "el-icon-arrow-right"
                          : "el-icon-arrow-down",
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.courseType == false,
                        expression: "courseType == false",
                      },
                    ],
                    staticClass: "categoty_content_content_box",
                  },
                  [
                    _vm._l(_vm.courseCategoryForm, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: [
                            (index + 1) % 3 === 0
                              ? "categoty_content_contentTwo"
                              : "categoty_content_content",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.courseItem(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "categoty_content_content_img" },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: item.thumbnail,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "categoty_content_content_name" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: (_vm.courseCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "(courseCategoryForm.length + 2) % 3 === 0",
                        },
                      ],
                      staticClass: "categoty_content_content",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.courseCategoryForm.length + 1) % 3 === 0 ||
                            (_vm.courseCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "\n              (courseCategoryForm.length + 1) % 3 === 0 ||\n                (courseCategoryForm.length + 2) % 3 === 0\n            ",
                        },
                      ],
                      staticClass: "categoty_content_contentTwo",
                    }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checkPermission(["article-list"])
        ? _c(
            "div",
            {
              staticStyle: {
                "border-radius": "10px",
                overflow: "hidden",
                "background-color": "white",
                "margin-top": "15px",
              },
            },
            [
              _c("div", { staticClass: "categoty_content" }, [
                _c(
                  "div",
                  {
                    staticClass: "categoty_content_header_box",
                    on: { click: _vm.articleClick },
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.articleCategoryForm.length != 0,
                          expression: "articleCategoryForm.length != 0",
                        },
                      ],
                      class: [
                        _vm.articleType == true
                          ? "el-icon-arrow-right"
                          : "el-icon-arrow-down",
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.articleType == false,
                        expression: "articleType == false",
                      },
                    ],
                    staticClass: "categoty_content_content_box",
                  },
                  [
                    _vm._l(_vm.articleCategoryForm, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: [
                            (index + 1) % 3 === 0
                              ? "categoty_content_contentTwo"
                              : "categoty_content_content",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.articleItem(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "categoty_content_content_img" },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: item.thumbnail,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "categoty_content_content_name" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: (_vm.articleCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "(articleCategoryForm.length + 2) % 3 === 0",
                        },
                      ],
                      staticClass: "categoty_content_content",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.articleCategoryForm.length + 1) % 3 === 0 ||
                            (_vm.articleCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "\n              (articleCategoryForm.length + 1) % 3 === 0 ||\n                (articleCategoryForm.length + 2) % 3 === 0\n            ",
                        },
                      ],
                      staticClass: "categoty_content_contentTwo",
                    }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "categoty" }, [
      _vm.checkPermission(["gauge-list"])
        ? _c(
            "div",
            {
              staticStyle: {
                "border-radius": "10px",
                overflow: "hidden",
                "background-color": "white",
              },
            },
            [
              _c("div", { staticClass: "categoty_content" }, [
                _c(
                  "div",
                  {
                    staticClass: "categoty_content_header_box",
                    on: { click: _vm.gaugeClick },
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.courseCategoryForm.length != 0,
                          expression: "courseCategoryForm.length != 0",
                        },
                      ],
                      class: [
                        _vm.gaugeType == true
                          ? "el-icon-arrow-right"
                          : "el-icon-arrow-down",
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.gaugeType == false,
                        expression: "gaugeType == false",
                      },
                    ],
                    staticClass: "categoty_content_content_box",
                  },
                  [
                    _vm._l(_vm.gaugeCategoryForm, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: [
                            (index + 1) % 3 === 0
                              ? "categoty_content_contentTwo"
                              : "categoty_content_content",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.gaugeItme(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "categoty_content_content_img" },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: item.thumbnail,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "categoty_content_content_name" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: (_vm.gaugeCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "(gaugeCategoryForm.length + 2) % 3 === 0",
                        },
                      ],
                      staticClass: "categoty_content_content",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.gaugeCategoryForm.length + 1) % 3 === 0 ||
                            (_vm.gaugeCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "\n              (gaugeCategoryForm.length + 1) % 3 === 0 ||\n                (gaugeCategoryForm.length + 2) % 3 === 0\n            ",
                        },
                      ],
                      staticClass: "categoty_content_contentTwo",
                    }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checkPermission(["counselor-list", "counselor2-list"])
        ? _c(
            "div",
            {
              staticStyle: {
                "border-radius": "10px",
                overflow: "hidden",
                "background-color": "white",
                "margin-top": "20px",
              },
            },
            [
              _c("div", { staticClass: "categoty_content" }, [
                _c(
                  "div",
                  {
                    staticClass: "categoty_content_header_box",
                    on: { click: _vm.counselorClick },
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.courseCategoryForm.length != 0,
                          expression: "courseCategoryForm.length != 0",
                        },
                      ],
                      class: [
                        _vm.counselorType == true
                          ? "el-icon-arrow-right"
                          : "el-icon-arrow-down",
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.counselorType == false,
                        expression: "counselorType == false",
                      },
                    ],
                    staticClass: "categoty_content_content_box",
                  },
                  [
                    _vm._l(_vm.counselorCategoryForm, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: [
                            (index + 1) % 3 === 0
                              ? "categoty_content_contentTwo"
                              : "categoty_content_content",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.promptClick(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "categoty_content_content_img" },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: item.thumbnail,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "categoty_content_content_name" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.counselorCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "(counselorCategoryForm.length + 2) % 3 === 0",
                        },
                      ],
                      staticClass: "categoty_content_content",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.counselorCategoryForm.length + 1) % 3 === 0 ||
                            (_vm.counselorCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "\n              (counselorCategoryForm.length + 1) % 3 === 0 ||\n                (counselorCategoryForm.length + 2) % 3 === 0\n            ",
                        },
                      ],
                      staticClass: "categoty_content_contentTwo",
                    }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "categoty" }, [
      _vm.checkPermission(["activity-list"])
        ? _c(
            "div",
            {
              staticStyle: {
                "border-radius": "10px",
                overflow: "hidden",
                "background-color": "white",
                "margin-top": "15px",
              },
            },
            [
              _c("div", { staticClass: "categoty_content" }, [
                _c(
                  "div",
                  {
                    staticClass: "categoty_content_header_box",
                    on: { click: _vm.activityClick },
                  },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activityCategoryForm.length != 0,
                          expression: "activityCategoryForm.length != 0",
                        },
                      ],
                      class: [
                        _vm.activityType == true
                          ? "el-icon-arrow-right"
                          : "el-icon-arrow-down",
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activityType == false,
                        expression: "activityType == false",
                      },
                    ],
                    staticClass: "categoty_content_content_box",
                  },
                  [
                    _vm._l(_vm.activityCategoryForm, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: [
                            (index + 1) % 3 === 0
                              ? "categoty_content_contentTwo"
                              : "categoty_content_content",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.activityItem(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "categoty_content_content_img" },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: item.thumbnail,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "categoty_content_content_name" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.activityCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "(activityCategoryForm.length + 2) % 3 === 0",
                        },
                      ],
                      staticClass: "categoty_content_content",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.activityCategoryForm.length + 1) % 3 === 0 ||
                            (_vm.activityCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "\n              (activityCategoryForm.length + 1) % 3 === 0 ||\n                (activityCategoryForm.length + 2) % 3 === 0\n            ",
                        },
                      ],
                      staticClass: "categoty_content_contentTwo",
                    }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "categoty" }, [
      _vm.checkPermission(["column-list"])
        ? _c(
            "div",
            {
              staticStyle: {
                "border-radius": "10px",
                overflow: "hidden",
                "background-color": "white",
                "margin-top": "15px",
              },
            },
            [
              _c("div", { staticClass: "categoty_content" }, [
                _c(
                  "div",
                  {
                    staticClass: "categoty_content_header_box",
                    on: { click: _vm.columnClick },
                  },
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.columnCategoryForm.length != 0,
                          expression: "columnCategoryForm.length != 0",
                        },
                      ],
                      class: [
                        _vm.columnType == true
                          ? "el-icon-arrow-right"
                          : "el-icon-arrow-down",
                      ],
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.columnType == false,
                        expression: "columnType == false",
                      },
                    ],
                    staticClass: "categoty_content_content_box",
                  },
                  [
                    _vm._l(_vm.columnCategoryForm, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          class: [
                            (index + 1) % 3 === 0
                              ? "categoty_content_contentTwo"
                              : "categoty_content_content",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.columnItem(item.id)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "categoty_content_content_img" },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  height: "100%",
                                  src: item.thumbnail,
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "categoty_content_content_name" },
                            [_vm._v(_vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: (_vm.columnCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "(columnCategoryForm.length + 2) % 3 === 0",
                        },
                      ],
                      staticClass: "categoty_content_content",
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            (_vm.columnCategoryForm.length + 1) % 3 === 0 ||
                            (_vm.columnCategoryForm.length + 2) % 3 === 0,
                          expression:
                            "\n              (columnCategoryForm.length + 1) % 3 === 0 ||\n                (columnCategoryForm.length + 2) % 3 === 0\n            ",
                        },
                      ],
                      staticClass: "categoty_content_contentTwo",
                    }),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { width: "100%", height: "60px" } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categoty_content_header" }, [
      _c("div", { staticClass: "categoty_content_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/-s-icon_xskc.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "categoty_content_header_name" }, [
        _vm._v("线上课程"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categoty_content_header" }, [
      _c("div", { staticClass: "categoty_content_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/-s-icon_hd.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "categoty_content_header_name" }, [
        _vm._v("活动报名"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categoty_content_header" }, [
      _c("div", { staticClass: "categoty_content_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/-s-icon_cp.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "categoty_content_header_name" }, [
        _vm._v("心理测评"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categoty_content_header" }, [
      _c("div", { staticClass: "categoty_content_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/-s-icon_zx.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "categoty_content_header_name" }, [
        _vm._v("咨询服务"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categoty_content_header" }, [
      _c("div", { staticClass: "categoty_content_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/-s-icon_kepu.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "categoty_content_header_name" }, [
        _vm._v("科普文章"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "categoty_content_header" }, [
      _c("div", { staticClass: "categoty_content_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/-s-icon_zlfw.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "categoty_content_header_name" }, [
        _vm._v("专栏服务"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }