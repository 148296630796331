"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminList = adminList;
exports.category = category;
exports.find = find;
exports.findWithInfo = findWithInfo;
exports.getPhone = getPhone;
exports.list = list;
exports.serviceList = serviceList;
var _request = _interopRequireDefault(require("@/utils/request"));
function list(params) {
  return (0, _request.default)({
    url: "/mchCounselor/list",
    method: "get",
    params: params
  });
}
function find(id) {
  return (0, _request.default)({
    url: "/mchCounselor/find/" + id,
    method: "get"
  });
}
function findWithInfo(id) {
  return (0, _request.default)({
    url: "/mchCounselor/findWithInfo/" + id,
    method: "get"
  });
}
function adminList(params) {
  return (0, _request.default)({
    url: "admin/mchCounselor/list",
    method: "get",
    params: params
  });
}
function category() {
  return (0, _request.default)({
    url: "/mchCounselorCategory/list",
    method: "get"
  });
}
function getPhone(id) {
  return (0, _request.default)({
    url: "mchCounselor/getPhone/" + id,
    method: "get"
  });
}
function serviceList(params) {
  return (0, _request.default)({
    url: "/counselorBusiness/list/",
    method: "get",
    params: params
  });
}