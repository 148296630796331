var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "counselor_box" },
    [
      _c("div", { staticClass: "counselor_header_box" }, [
        _c("div", { staticClass: "counselor_header" }, [
          _c("span", { staticClass: "counselor_header_name" }, [
            _vm._v(_vm._s(_vm.content.title)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { on: { click: _vm.listClick } }, [
          _c("span", { staticClass: "counselor_header_more" }, [
            _vm._v("查看更多"),
          ]),
          _vm._v(" "),
          _c("i", { staticClass: "el-icon-arrow-right counselor_header_more" }),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.counselorForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "counselor_content",
            on: {
              click: function ($event) {
                return _vm.indexClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "counselor_content_img" }, [
              _c("img", {
                attrs: { src: item.avatar, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "counselor_content_content" }, [
              _c("div", { staticClass: "counselor_content_top" }, [
                _c("span", { staticClass: "counselor_content_name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counselor_content_subtitle" }, [
                  _vm._v(_vm._s(item.spots)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "counselor_content_title_box" }, [
                _c("span", { staticClass: "counselor_content_title" }, [
                  _vm._v(_vm._s(item.introduction)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "counselor_content_bottom" }, [
                _c("span", { staticClass: "counselor_content_price" }, [
                  _vm._v("¥" + _vm._s(item.price)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counselor_content_date" }, [
                  _vm._v(_vm._s(_vm._f("watchMethod")(item.counselorMethod))),
                ]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }