"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "FirstCategery",
  data: function data() {
    return {
      dataNum: [],
      deviceWH: 0,
      xyScroll: 0,
      oldxyScroll: 0,
      scrollWH: 0,
      scrollNav: {},
      scrollBox: {},
      scrollItemArr: [],
      startPointer: 0,
      movePointer: 0,
      endPointer: 0,
      useNavWH: 0,
      initinval: {},
      stepIntval: {},
      activeScrollId: this.activeIndexParent
    };
  },
  props: {
    activeIndexParent: {
      type: Number,
      required: false,
      default: 0
    },
    colorText: {
      type: String,
      required: false,
      default: "#222"
    },
    colorBack: {
      type: String,
      required: false,
      default: "whitesmoke"
    },
    colorActiveText: {
      type: String,
      required: false,
      default: "#fff"
    },
    colorActiveBack: {
      type: String,
      required: false,
      default: "#999"
    },
    columnCategeryScrollItem: {
      type: Array,
      required: true
    },
    navWH: {
      type: Number,
      required: false,
      default: 0
    },
    scrollMargin: {
      type: Number,
      required: false,
      default: 0
    },
    scrollWay: {
      type: String,
      required: false,
      default: "horizontal"
    },
    itemPadding: {
      type: String,
      required: false,
      default: "5px 10px"
    },
    itemStr: {
      type: String,
      required: false,
      default: ""
    }
  },
  watch: {
    columnCategeryScrollItem: function columnCategeryScrollItem(newValue, oldValue) {
      if (newValue != oldValue) {
        this.refresh();
      }
    },
    scrollWay: function scrollWay(newValue, oldValue) {
      if (newValue != oldValue) {
        this.refresh();
      }
    },
    navWH: function navWH(newValue, oldValue) {
      if (newValue != oldValue) {
        this.refresh();
      }
    },
    scrollMargin: function scrollMargin(newValue, oldValue) {
      if (newValue != oldValue) {
        this.refresh();
      }
    },
    itemPadding: function itemPadding(newValue, oldValue) {
      if (newValue != oldValue) {
        this.refresh();
      }
    },
    activeIndexParent: function activeIndexParent(newValue, oldValue) {
      if (newValue != oldValue) {
        this.activeScrollId = newValue;
      }
    }
  },
  beforeMount: function beforeMount() {},
  mounted: function mounted() {
    var _this = this;
    this.deviceWH = this.scrollWay == "vertical" ? document.body.clientHeight : document.body.clientWidth;
    this.useNavWH = this.navWH == 0 ? this.deviceWH : this.navWH;
    this.scrollNav = this.$refs.scrollNav;
    this.scrollBox = this.$refs.scrollBox;
    this.scrollItemArr = document.querySelectorAll(".scroll-item");
    window.onresize = function () {
      return function () {
        _this.deviceWH = _this.scrollWay == "vertical" ? document.body.clientHeight : document.body.clientWidth;
        _this.useNavWH = _this.navWH == 0 ? _this.deviceWH : _this.navWH;
      }();
    };
    this.scrollItemArr.forEach(function (val) {
      if (_this.scrollWay == "vertical") {
        _this.scrollWH += val.offsetHeight + _this.scrollMargin * 2;
      } else {
        _this.scrollWH += val.offsetWidth + _this.scrollMargin * 2;
      }
    });
    this.scrollBox.addEventListener("touchstart", function (e) {
      _this.scrollStart(e);
    });
    this.scrollBox.addEventListener("touchmove", function (e) {
      _this.scrollMove(e);
    });
    this.scrollBox.addEventListener("touchend", function (e) {
      _this.scrollEnd(e);
    });
  },
  methods: {
    refresh: function refresh() {
      var _this2 = this;
      this.deviceWH = this.scrollWay == "vertical" ? document.body.clientHeight : document.body.clientWidth;
      this.useNavWH = this.navWH == 0 ? this.deviceWH : this.navWH;
      this.scrollNav = this.$refs.scrollNav;
      this.scrollBox = this.$refs.scrollBox;
      this.scrollItemArr = document.querySelectorAll(".scroll-item");
      this.scrollItemArr.forEach(function (val) {
        if (_this2.scrollWay == "vertical") {
          _this2.scrollWH += val.offsetHeight + _this2.scrollMargin * 2;
        } else {
          _this2.scrollWH += val.offsetWidth + _this2.scrollMargin * 2;
        }
      });
    },
    chooseItem: function chooseItem(e, val, index) {
      if (this.scrollWay == "vertical") {
        this.activeScrollId = val;
        if (this.useNavWH < this.scrollWH) {
          if (e.target.offsetTop + e.target.offsetHeight / 2 > this.useNavWH / 2) {
            if (this.scrollWH - (e.target.offsetTop + e.target.offsetHeight / 2) > this.useNavWH / 2) {
              var num = this.useNavWH / 2 - (e.target.offsetTop + e.target.offsetHeight / 2) - this.xyScroll;
              this.animateScroll(num);
              // this.xyScroll = this.useNavWH/2 - (e.target.offsetTop + e.target.offsetHeight/2);
            } else {
              var _num = this.useNavWH - this.scrollWH - this.xyScroll;
              this.animateScroll(_num);
              // this.xyScroll = this.useNavWH - this.scrollWH;
            }
          } else {
            var _num2 = 0 - this.xyScroll;
            this.animateScroll(_num2);
            // this.xyScroll = 0;
          }
        }
      } else {
        this.activeScrollId = val;
        if (this.useNavWH < this.scrollWH) {
          if (e.target.offsetLeft + e.target.offsetWidth / 2 > this.useNavWH / 2) {
            if (this.scrollWH - (e.target.offsetLeft + e.target.offsetWidth / 2) > this.useNavWH / 2) {
              var _num3 = this.useNavWH / 2 - (e.target.offsetLeft + e.target.offsetWidth / 2) - this.xyScroll;
              this.animateScroll(_num3);
              // this.xyScroll = this.useNavWH/2 - (e.target.offsetLeft + e.target.offsetWidth/2);
            } else {
              var _num4 = this.useNavWH - this.scrollWH - this.xyScroll;
              this.animateScroll(_num4);
              // this.xyScroll = this.useNavWH - this.scrollWH;
            }
          } else {
            var _num5 = 0 - this.xyScroll;
            this.animateScroll(_num5);
            // this.xyScroll = 0;
          }
        }
      }
      this.$emit("update: activeIndexParent", index);
      var newArr = [val, this.columnCategeryScrollItem[index]];
      this.$emit("choose-item", newArr);
      console.log(e);
    },
    animateScroll: function animateScroll(steps) {
      var _this3 = this;
      if (JSON.stringify(this.stepIntval) != "{}") {
        clearInterval(this.stepIntval);
      }
      this.scrollBox.style.transitionDuration = "500ms";
      this.scrollBox.style.transitionTimingFunction = "ease";
      this.xyScroll = this.xyScroll + steps;
      setTimeout(function () {
        _this3.scrollBox.style.transitionDuration = "";
        _this3.scrollBox.style.transitionTimingFunction = "";
      }, 500);
    },
    scrollStart: function scrollStart(e) {
      if (this.scrollWH == 0) {
        this.refresh();
      }
      if (this.scrollWay == "vertical") {
        this.startPointer = e.changedTouches[0].clientY;
        this.oldxyScroll = JSON.parse(JSON.stringify(this.xyScroll));
      } else {
        this.startPointer = e.changedTouches[0].clientX;
        this.oldxyScroll = JSON.parse(JSON.stringify(this.xyScroll));
      }
    },
    scrollMove: function scrollMove(e) {
      if (this.scrollWay == "vertical") {
        if (this.useNavWH < this.scrollWH) {
          this.movePointer = e.changedTouches[0].clientY - this.startPointer;
          if (this.oldxyScroll + this.movePointer < this.useNavWH - this.scrollWH) {
            var overWidth = this.useNavWH - this.scrollWH - (this.oldxyScroll + this.movePointer);
            this.xyScroll = this.useNavWH - this.scrollWH - Math.sqrt(overWidth * 6);
          } else if (this.oldxyScroll + this.movePointer > 0) {
            var _overWidth = this.oldxyScroll + this.movePointer;
            this.xyScroll = 0 + Math.sqrt(_overWidth * 6);
          } else {
            this.xyScroll = this.oldxyScroll + this.movePointer;
          }
        }
      } else {
        if (this.useNavWH < this.scrollWH) {
          this.movePointer = e.changedTouches[0].clientX - this.startPointer;
          if (this.oldxyScroll + this.movePointer < this.useNavWH - this.scrollWH) {
            var _overWidth2 = this.useNavWH - this.scrollWH - (this.oldxyScroll + this.movePointer);
            this.xyScroll = this.useNavWH - this.scrollWH - Math.sqrt(_overWidth2 * 6);
          } else if (this.oldxyScroll + this.movePointer > 0) {
            var _overWidth3 = this.oldxyScroll + this.movePointer;
            this.xyScroll = 0 + Math.sqrt(_overWidth3 * 6);
          } else {
            this.xyScroll = this.oldxyScroll + this.movePointer;
          }
        }
      }
    },
    scrollEnd: function scrollEnd(e) {
      var _this4 = this;
      if (this.scrollWay == "vertical") {
        if (this.useNavWH < this.scrollWH) {
          if (JSON.stringify(this.initinval) != "{}") {
            clearInterval(this.initinval);
          }
          if (this.xyScroll < this.useNavWH - this.scrollWH) {
            var overNum = parseInt(this.xyScroll - (this.useNavWH - this.scrollWH));
            this.initinval = setInterval(function () {
              if (overNum > 0) {
                if (overNum == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll + 1;
                overNum = overNum - 1;
              } else {
                if (overNum == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll + 1;
                overNum = overNum + 1;
              }
            }, 5);
          } else if (this.xyScroll > 0) {
            var _overNum = parseInt(JSON.parse(JSON.stringify(this.xyScroll)));
            this.initinval = setInterval(function () {
              if (_overNum > 0) {
                if (_overNum == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll - 1;
                _overNum = _overNum - 1;
              } else {
                if (_overNum == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll - 1;
                _overNum = _overNum + 1;
              }
            }, 5);
          }
        }
        this.endPointer = e.changedTouches[0].clientY;
      } else {
        if (this.useNavWH < this.scrollWH) {
          if (JSON.stringify(this.initinval) != "{}") {
            clearInterval(this.initinval);
          }
          if (this.xyScroll < this.useNavWH - this.scrollWH) {
            var _overNum2 = parseInt(this.xyScroll - (this.useNavWH - this.scrollWH));
            this.initinval = setInterval(function () {
              if (_overNum2 > 0) {
                if (_overNum2 == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll + 1;
                _overNum2 = _overNum2 - 1;
              } else {
                if (_overNum2 == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll + 1;
                _overNum2 = _overNum2 + 1;
              }
            }, 5);
          } else if (this.xyScroll > 0) {
            var _overNum3 = parseInt(JSON.parse(JSON.stringify(this.xyScroll)));
            this.initinval = setInterval(function () {
              if (_overNum3 > 0) {
                if (_overNum3 == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll - 1;
                _overNum3 = _overNum3 - 1;
              } else {
                if (_overNum3 == 0) {
                  clearInterval(_this4.initinval);
                }
                _this4.xyScroll = _this4.xyScroll - 1;
                _overNum3 = _overNum3 + 1;
              }
            }, 5);
          }
        }
        this.endPointer = e.changedTouches[0].clientX;
      }
    }
  },
  components: {}
};