var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "login-form" },
      [
        _c("div", { staticClass: "login-form-title" }, [
          _vm._v("欢迎登录心理测评系统"),
        ]),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "login-form",
            attrs: {
              model: _vm.loginForm,
              rules: _vm.loginRules,
              autocomplete: "on",
              "label-position": "left",
            },
          },
          [
            _c("el-form-item", { attrs: { prop: "username" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginForm.username,
                    expression: "loginForm.username",
                  },
                ],
                ref: "username",
                staticClass: "login-form-input",
                attrs: {
                  placeholder: "请输入" + (_vm.setName || "账号"),
                  name: "username",
                  autocomplete: "on",
                },
                domProps: { value: _vm.loginForm.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.loginForm, "username", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { prop: "password" } }, [
              _vm.passwordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.loginForm.password,
                        expression: "loginForm.password",
                      },
                    ],
                    key: _vm.passwordType,
                    ref: "password",
                    staticClass: "login-form-input",
                    attrs: {
                      placeholder: "请输入" + (_vm.setCode || "密码"),
                      name: "password",
                      autocomplete: "on",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.loginForm.password)
                        ? _vm._i(_vm.loginForm.password, null) > -1
                        : _vm.loginForm.password,
                    },
                    on: {
                      blur: function ($event) {
                        _vm.capsTooltip = false
                      },
                      change: function ($event) {
                        var $$a = _vm.loginForm.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.loginForm,
                                "password",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.loginForm,
                                "password",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.loginForm, "password", $$c)
                        }
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          return _vm.checkCapslock($event)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      ],
                    },
                  })
                : _vm.passwordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.loginForm.password,
                        expression: "loginForm.password",
                      },
                    ],
                    key: _vm.passwordType,
                    ref: "password",
                    staticClass: "login-form-input",
                    attrs: {
                      placeholder: "请输入" + (_vm.setCode || "密码"),
                      name: "password",
                      autocomplete: "on",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.loginForm.password, null) },
                    on: {
                      blur: function ($event) {
                        _vm.capsTooltip = false
                      },
                      change: function ($event) {
                        return _vm.$set(_vm.loginForm, "password", null)
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          return _vm.checkCapslock($event)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      ],
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.loginForm.password,
                        expression: "loginForm.password",
                      },
                    ],
                    key: _vm.passwordType,
                    ref: "password",
                    staticClass: "login-form-input",
                    attrs: {
                      placeholder: "请输入" + (_vm.setCode || "密码"),
                      name: "password",
                      autocomplete: "on",
                      type: _vm.passwordType,
                    },
                    domProps: { value: _vm.loginForm.password },
                    on: {
                      blur: function ($event) {
                        _vm.capsTooltip = false
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.loginForm, "password", $event.target.value)
                      },
                    },
                    nativeOn: {
                      keyup: [
                        function ($event) {
                          return _vm.checkCapslock($event)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      ],
                    },
                  }),
            ]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "loginButton",
                attrs: { type: "primary" },
                on: { click: _vm.handleLogin },
              },
              [_vm._v("登录")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "login-footer" }, [
      _vm._v("医事达备案号：皖ICP备2024050967号"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-head" }, [
      _c("div", { staticClass: "login-logo" }),
      _vm._v(" "),
      _c("div", { staticClass: "login-mian-title" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }