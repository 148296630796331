"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _ad = require("@/api/ad");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 1,
      pageSize: 2,
      adForm: [{
        thumbnail: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png"
      }, {
        thumbnail: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png"
      }],
      tagIds: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  created: function created() {
    this.loadAd();
  },
  props: ["content"],
  methods: {
    loadAd: function loadAd() {
      var _this2 = this;
      var _this = this;
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(",");
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append("tagIds", _this.tagIds[i]);
      }
      params.append("sortField", "orders");
      params.append("direction", "asc");
      (0, _ad.list)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.data)) {
          _this2.adForm = response.data.data;
          _this.adForm.forEach(function (item) {
            item.thumbnail = _utils.default.thumbnail(item.thumbnail, 672, 360);
          });
        }
      }).catch(function (error) {
        console.log("error!!");
      });
    },
    adClick: function adClick(item) {
      console.log(item);
      if (item.type == 5) {
        this.$router.push({
          path: "/course/info",
          query: {
            courseId: item.typeId
          }
        });
      } else if (item.type == 6) {
        this.$router.push({
          path: "/gauge/index",
          query: {
            mchGaugeId: item.typeId
          }
        });
      } else if (item.type == 12) {
        window.location.href = item.path;
      } else if (item.type == 1) {
        this.$router.push({
          path: "/article/index",
          query: {
            articleId: item.typeId
          }
        });
      } else if (item.type == 9) {
        this.$router.push({
          path: "/index/wxPage",
          query: {
            id: item.typeId
          }
        });
      } else if (item.type == 13) {
        this.$router.push({
          path: "/activitySy/details",
          query: {
            activityId: item.typeId
          }
        });
      } else if (item.type == 14) {
        this.$router.push({
          path: "/column/columnSignUp",
          query: {
            mchColumnId: item.typeId
          }
        });
      }
    }
  }
};