"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es7.string.pad-start");
var _myBottom = _interopRequireDefault(require("./components/myBottom.vue"));
var _memberDeal = require("@/api/memberDeal");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {
    myBottom: _myBottom.default
  },
  filters: {
    formatDate: function formatDate(value) {
      if (value !== undefined) {
        var date = new Date(value);
        var year = date.getFullYear();
        var month = date.getMonth() + 1 + '';
        var day = date.getDate() + '';
        var hour = date.getHours() + '';
        var minute = date.getMinutes() + '';
        var second = date.getSeconds() + '';
        return "".concat(year, ".").concat(month.padStart(2, '0'), ".").concat(day.padStart(2, '0'), " ").concat(hour.padStart(2, '0'), ":").concat(minute.padStart(2, '0'));
      }
    }
  },
  data: function data() {
    return {
      isActive: 1,
      status: 1,
      bottomText: false,
      memberDealList: [],
      pageNum: 1,
      pageSize: 20
    };
  },
  created: function created() {
    this.getMemberList();
    this.pullUpload();
  },
  destroyed: function destroyed() {
    // 销毁页面时候移除滚动事件,避免影响其他页面
    window.removeEventListener('scroll', this._scroll, false);
  },
  methods: {
    isShowBottomText: function isShowBottomText(size) {
      // 是否显示底部text
      if (size < this.pageSize) {
        this.bottomText = true;
      } else {
        this.bottomText = false;
      }
    },
    memberDeal: function memberDeal() {
      this.isActive = 1;
      this.status = 1;
      this.pageNum = 1;
      this.getMemberList();
    },
    memberNoDeal: function memberNoDeal() {
      this.isActive = 2;
      this.status = 0;
      this.pageNum = 1;
      this.getMemberList();
    },
    getMemberList: function getMemberList() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('pageNum', this.pageNum);
      params.append('pageSize', this.pageSize);
      params.append('status', this.status);
      (0, _memberDeal.list)(params).then(function (res) {
        console.log(res);
        _this.isShowBottomText(res.data.data.length);
        _this.memberDealList = res.data.data;
        _this.pageNum++;
      }).catch(function (err) {
        console.log(err);
      });
    },
    pullUpload: function pullUpload() {
      var _this2 = this;
      // 上拉加载更多
      this._scroll = function () {
        // 把移动事件赋值给_scroll
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条到底部的条件
        // console.log(scrollTop , windowHeight, scrollHeight)
        // 可视区域(四舍五入,避免误差) + 已滚动区域 < 可滚动的高度 以下+1-1表示四舍五入之后可能的误差
        if (Math.round(scrollTop) + windowHeight == scrollHeight || Math.round(scrollTop) + windowHeight == scrollHeight + 1 || Math.round(scrollTop) + windowHeight == scrollHeight - 1) {
          //已滚动底部
          console.log('触底');
          var params = new URLSearchParams();
          params.append('pageNum', _this2.pageNum);
          params.append('pageSize', _this2.pageSize);
          params.append('status', _this2.status);
          (0, _memberDeal.list)(params).then(function (res) {
            console.log(res);
            _this2.isShowBottomText(res.data.data.length);
            _this2.memberDealList = [].concat((0, _toConsumableArray2.default)(_this2.memberDealList), (0, _toConsumableArray2.default)(res.data.data));
            console.log(_this2.memberDealList);
            _this2.pageNum++;
            console.log(_this2.pageNum);
          }).catch(function (err) {
            console.log(err);
          });
        }
      };
      window.addEventListener("scroll", this._scroll); // 把移动事件挂载到this上
    }
  }
};