"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.array.find");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _prompt = _interopRequireDefault(require("./prompt"));
var _counselor = require("@/api/counselor");
var _utils = _interopRequireDefault(require("../../../utils/utils"));
var _counselorOrder = require("@/api/counselorOrder");
var _agreement = _interopRequireDefault(require("./agreement.vue"));
var _agreeBook = _interopRequireDefault(require("./agreeBook.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      quantity: 1,
      duration: "",
      checked: false,
      agreeType: false,
      dateType: false,
      infoList: [],
      sortList: [],
      timeData: "",
      agreementType: false,
      promptType: false,
      title: "预约须知",
      promptData: [],
      dataList: [],
      startX: 0,
      startY: 0,
      date: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() <= Date.now() - 8.64e7;
        }
      }
    };
  },
  components: {
    prompt: _prompt.default,
    agreement: _agreement.default,
    agreeBook: _agreeBook.default
  },
  props: ["appointmentDate", "appointmentHour", "counselorMethod", "mchCounselorId", "timeIndex", "dateIndex", "serviceName", "serviceType", "servicePrice", "counselorBusinessId", "serviceTime", "limitNum", "firstDiscount"],
  created: function created() {
    console.log(this.firstDiscount);
    this.duration = this.serviceTime;
    this.quantity = this.limitNum;
    this.findClick();
  },
  methods: {
    // disableDate(time){
    //   console.log(123456,time,time.getTime() < Date.now())
    //   return time.getTime() < Date.now();
    // },
    agreeBook: function agreeBook() {
      this.agreeType = true;
    },
    agreement: function agreement() {
      this.agreementType = true;
    },
    findClick: function findClick() {
      var _this2 = this;
      var _this = this;
      (0, _counselor.find)(this.mchCounselorId).then(function (response) {
        console.log(response, "first数据");
        _this2.infoList = response.data.counselor;
        _this2.checkExistsClick();
      }).catch(function (error) {
        console.log(error);
      });
    },
    paixu: function paixu(a, b) {
      a = a.split("-").shift().slice(0, 2);
      b = b.split("-").shift().slice(0, 2);
      return a - b;
    },
    nextClick: function nextClick() {
      this.$emit("nextClick", 1);
    },
    watchData: function watchData(date) {
      date = new Date(date);
      var fmt = "YYYY-mm-dd HH:MM:ss";
      var ret;
      var opt = {
        "Y+": date.getFullYear().toString(),
        // 年
        "m+": (date.getMonth() + 1).toString(),
        // 月
        "d+": date.getDate().toString(),
        // 日
        "H+": date.getHours().toString(),
        // 时
        "M+": date.getMinutes().toString(),
        // 分
        "s+": date.getSeconds().toString() // 秒
      };
      for (var k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
        }
      }
      this.date = fmt;
    },
    complete: function complete() {
      var _this = this;
      if (_utils.default.isNull(_this.date)) {
        alert("请选择预约时间");
        return;
      }
      _this.watchData(_this.date);
      console.log(_this.date);
      if (_utils.default.isNull(_this.counselorMethod)) {
        alert("请选择预约方式");
        return;
      }
      if (_this.checked == false) {
        alert("请同意心理咨询预约协议与知情同意书");
        return;
      }
      var params = new URLSearchParams();
      params.append("appointmentTime", _this.date);
      params.append("counselorBusinessId", _this.counselorBusinessId);
      params.append("mchCounselorId", _this.mchCounselorId);
      params.append("counselorMethod", _this.counselorMethod);
      params.append("duration", _this.serviceTime);
      params.append("quantity", _this.limitNum);
      (0, _counselorOrder.createNew)(params).then(function (response) {
        if (!_utils.default.isNull(response.data.payment)) {
          _this.sn = response.data.payment.sn;
          _this.$router.replace({
            path: "/payment",
            query: {
              sn: _this.sn,
              redirect: "/counselorNew/steps?mchCounselorId=" + _this.mchCounselorId + "&appointmentTime=" + _this.date + "&counselorMethod=" + _this.counselorMethod + "&timeIndex=" + _this.timeIndex + "&dateIndex=" + _this.dateIndex + "&serviceName=" + _this.serviceName + "&serviceType=" + _this.serviceType + "serviceTime=" + _this.serviceTime + "limitNum=" + _this.limitNum + "firstDiscount=" + _this.firstDiscount
            }
          });
        } else {
          _this.checkExistsClick();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    checkExistsClick: function checkExistsClick() {
      var _this3 = this;
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchCounselorId", _this.mchCounselorId);
      (0, _counselorOrder.checkExists)(params).then(function (response) {
        if (!_utils.default.isNull(response.data.counselorOrder)) {
          _this3.$emit("nextClick", 1, response.data.counselorOrder.id, _this3.infoList.attributes);
        } else {
          _this3.$emit("nextClick", 0);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    openClick: function openClick() {
      this.promptType = !this.promptType;
      this.promptData = [{
        title: "回应时长",
        subTitle: "咨询师将在收到订单后的3小时内回应是否接受咨询，且在12小时内与来访者协商好咨询时间、地点。"
      }, {
        title: "关于预约变更",
        subTitle: "若因特殊原因，需要变更/取消已协商好的咨询预约，请务必提前48小时（两个工作日）联系。否则咨询将如期开始。"
      }, {
        title: "爽约/迟到",
        subTitle: "若无提前24小时告知情况，爽约/迟到20分钟以上，则默认这次咨询已经完成。其他特殊情况，需协商处理。"
      }];
    },
    downClick: function downClick() {
      this.agreeType = false;
      this.promptType = false;
      this.agreementType = false;
    }
  }
};