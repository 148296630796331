"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _category = _interopRequireDefault(require("./components/category"));
var _article = require("@/api/article");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      categoryId: '',
      articleCategoryId: '',
      pageNum: 0,
      pageSize: 10,
      articleForm: [],
      loading: true
    };
  },
  components: {
    Category: _category.default,
    NoData: _NoData.default
  },
  created: function created() {
    this.categoryId = this.$route.query.categoryId;
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  methods: {
    categoryClick: function categoryClick(id) {
      this.articleCategoryId = id;
      this.pageNum = 0;
      this.articleForm = [];
      this.loadmore();
    },
    loadarticle: function loadarticle() {
      var _this2 = this;
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('articleCategoryId', _this.articleCategoryId);
      params.append('mchId', _this.mchId);
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      (0, _article.list)(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 650, 312);
          _this.articleForm.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this2.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.loadarticle();
    },
    courseClick: function courseClick(id) {
      this.$router.push({
        path: '/article/index',
        query: {
          articleId: id
        }
      });
    }
  }
};