"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _centerBottom = _interopRequireDefault(require("./components/centerBottom.vue"));
var _vuex = require("vuex");
var _share = require("@/utils/share");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _member = require("@/api/member");
var _user = require("@/api/user");
var _promote = require("@/api/promote");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "",
  components: {
    centerBottom: _centerBottom.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["userId", "xuid", "token", "mchId"])),
  filters: {
    filterCont: function filterCont(val) {
      if (val === null) {
        return "0";
      } else {
        return val;
      }
    },
    filtersNumber: function filtersNumber(val) {
      if (val === 0 || isNaN(val) === true) {
        return 0;
      } else {
        return parseFloat(val).toFixed(2);
      }
    }
  },
  data: function data() {
    return {
      productList: [{
        title: "推广活动-活动报名",
        itemList: []
      }, {
        title: "推广测评-心理测评",
        itemList: []
      }, {
        title: "推广课程-线上课程",
        itemList: []
      }, {
        title: "推广专栏",
        itemList: []
      }],
      pageSize: 3,
      memberInfo: {},
      member: 0,
      balance: 0,
      rebate: 0,
      order: 0,
      percentage: 0 // 佣金百分比
    };
  },
  created: function created() {
    this.getPromoteInfo();
    this.getXuid();
    this.getShareInfo();
  },
  methods: {
    getXuid: function getXuid() {
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch("user/setXuid", xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== "") {
        // 如果有token并且参数里有xuid
        // alert(this.xuid)
        (0, _member.lockSysUser)({
          xuid: this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
      console.log("xuid", this.xuid);
    },
    getShareInfo: function getShareInfo() {
      (0, _share.SHARE)(location.href, {
        title: null,
        desc: null,
        link: _utils.default.host() + "/#/index?xuid=".concat(this.userId),
        imgUrl: null
      });
    },
    getPromoteInfo: function getPromoteInfo() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("pageSize", this.pageSize);
      params.append("mchId", this.mchId);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      /* 会员信息 */
      (0, _promote.getInfo)().then(function (res) {
        console.log("info", res);
        _this.memberInfo = res.data.member;
        _this.balance = res.data.balance;
      }).catch(function (err) {
        console.log(err);
      });
      /* 累计 */
      (0, _promote.summary)().then(function (res) {
        console.log("订单", res);
        _this.member = res.data.member;
        _this.rebate = res.data.rebate;
        _this.order = res.data.order;
      }).catch(function (err) {
        console.log(err);
      });
      /* 推广 */
      (0, _promote.gauge)(params).then(function (res) {
        console.log(res);
        _this.productList[1].itemList = res.data.data;
      }).catch(function (err) {
        console.log(err);
      });
      (0, _promote.course)(params).then(function (res) {
        console.log(res);
        _this.productList[2].itemList = res.data.data;
      }).catch(function (err) {
        console.log(err);
      });
      (0, _promote.column)(params).then(function (res) {
        console.log(res);
        _this.productList[3].itemList = res.data.data;
      }).catch(function (err) {
        console.log(err);
      });
      (0, _promote.promotion)(params).then(function (res) {
        console.log(res);
        _this.productList[0].itemList = res.data.data;
      }).catch(function (err) {
        console.log(err);
      });
      (0, _promote.percentage)().then(function (res) {
        console.log(res);
        _this.percentage = res.data.data[0].percent1;
      }).catch(function (err) {
        console.log(err);
      });
    },
    memberDealClick: function memberDealClick() {
      // 累计客户
      this.$router.push({
        path: "/distribution/memberDeal"
      });
    },
    closingOrderClick: function closingOrderClick() {
      // 推广-已成交
      this.$router.push({
        path: "/distribution/promoteOrder"
      });
    },
    promoteRulesClick: function promoteRulesClick() {
      // 推广规则
      this.$router.push({
        path: "/distribution/promoteRules"
      });
    },
    cashClick: function cashClick() {
      // 提现
      this.$router.push({
        path: "/distribution/cash"
      });
    },
    cashSetupClick: function cashSetupClick() {
      // 提现设置
      this.$router.push({
        path: "/distribution/cashSetup"
      });
    },
    promoteClick: function promoteClick(item) {
      console.log(item);
      var promoteType = "";
      if ("mchGaugeCategoryName" in item) {
        // 测评
        promoteType = "/gauge/index?mchGaugeId=".concat(item.id);
      } else if ("mchCourseCategoryName" in item) {
        // 课程
        promoteType = "/course/info?courseId=".concat(item.id);
      } else if ("articleCategoryName" in item) {
        // 活动
        promoteType = "/article/index?articleId=".concat(item.id);
      }
      console.log(promoteType);
      this.$router.push({
        path: promoteType
      });
      // SHARE(location.href, {
      //   title: item.title,
      //   desc: item.subTitle,
      //   link: utils.host() + promoteType,
      //   imgUrl: item.thumbnail
      // })
    }
  }
};