"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  mchId: function mchId(state) {
    return state.user.mchId;
  },
  mchName: function mchName(state) {
    return state.user.mchName;
  },
  mchLogo: function mchLogo(state) {
    return state.user.mchLogo;
  },
  menus: function menus(state) {
    return state.user.menus;
  },
  userId: function userId(state) {
    return state.user.userId;
  },
  xuid: function xuid(state) {
    return state.user.xuid;
  },
  enableAuth: function enableAuth(state) {
    return state.user.enableAuth;
  },
  isAllFinish: function isAllFinish(state) {
    return state.user.isAllFinish;
  },
  institutionsType: function institutionsType(state) {
    return state.user.institutionsType;
  },
  isH5: function isH5(state) {
    return state.user.h5IndexPageCode;
  },
  wechatFlag: function wechatFlag(state) {
    return state.user.wechatFlag;
  }
};
var _default = exports.default = getters;