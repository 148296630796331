var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "itemView" },
    [
      _c("svg-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$route.name == _vm.routeName,
            expression: "$route.name == routeName",
          },
        ],
        staticClass: "itemIcon",
        class: [_vm.$route.name == _vm.routeName ? "primary" : "grey"],
        attrs: { "icon-class": _vm.iconTwo },
      }),
      _vm._v(" "),
      _c("svg-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$route.name != _vm.routeName,
            expression: "$route.name != routeName",
          },
        ],
        staticClass: "itemIcon",
        class: [_vm.$route.name == _vm.routeName ? "primary" : "grey"],
        attrs: { "icon-class": _vm.icon },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "itemSpan",
          class: [_vm.$route.name == _vm.routeName ? "primary" : "grey"],
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }