"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _article = require("@/api/article");
var _enrollOrder = require("@/api/enrollOrder");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vuex = require("vuex");
var _friendLink = require("@/api/friendLink");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _share = require("@/utils/share");
var _user = require("@/api/user");
var _member = require("@/api/member");
var _enterprise = require("@/api/enterprise");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      contact: '',
      phone: '',
      // userId: '',
      friends: [],
      accordingType: false,
      infoData: {
        price: 0
      },
      articleMeta: {},
      sn: '',
      paymentType: false,
      mch: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId', 'userId', 'xuid', 'token'])),
  components: {
    NoData: _NoData.default
  },
  created: function created() {
    this.getXuid();
    this.articleId = this.$route.query.articleId;
    this.find(this.articleId);
    this.getInfoClick();
    this.loadFriendLink(this.articleId);
  },
  mounted: function mounted() {
    var _this = this;
    if (!_utils.default.isNull(this.$route.query.paymentStatus)) {
      _this.paymentType = true;
    }
    _this.checkExistsClick();
  },
  methods: {
    getXuid: function getXuid() {
      console.log(this.userId);
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch('user/setXuid', xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== '') {
        // 如果有token并且参数里有xuid
        // alert(this.xuid)
        (0, _member.lockSysUser)({
          'xuid': this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    toLink: function toLink(item) {
      if (item.linkProductType == 1) {
        this.$router.push({
          path: '/gauge/index',
          query: {
            mchGaugeId: item.linkProductId
          }
        });
      } else if (item.linkProductType == 2) {
        this.$router.push({
          path: '/course/info',
          query: {
            courseId: item.linkProductId
          }
        });
      } else if (item.linkProductType == 3) {
        this.$router.push({
          path: '/article/index',
          query: {
            articleId: item.linkProductId
          }
        });
        this.find(item.linkProductId);
        this.loadFriendLink(item.linkProductId);
      }
    },
    accordingClick: function accordingClick() {
      var _this = this;
      if (_this.paymentType == false) {
        this.accordingType = !this.accordingType;
      } else if (_this.paymentType == true) {
        _this.$router.push({
          path: '/article/order'
        });
      }
    },
    find: function find(query) {
      var _this2 = this;
      var _this = this;
      (0, _article.find)(query).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.article.thumbnail)) {
          response.data.article.thumbnail = _utils.default.thumbnail(response.data.article.thumbnail, 750, 360);
        }
        _this.infoData = response.data.article;
        _this.articleMeta = response.data.article.articleMeta;
        (0, _share.SHARE)(location.href, {
          title: response.data.article.title,
          desc: response.data.article.subTitle,
          link: _utils.default.host() + "/#/article/index?articleId=".concat(_this2.articleId, "&xuid=").concat(_this2.userId),
          imgUrl: response.data.article.thumbnail
        }, function (res) {});
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    loadFriendLink: function loadFriendLink(query) {
      var _this3 = this;
      var params = new URLSearchParams();
      params.append('mainProductType', 3);
      params.append('mainProductId', query);
      (0, _friendLink.list)(params).then(function (res) {
        _this3.friends = res.data.data;
      });
    },
    complete: function complete() {
      var _this = this;
      if (_this.contact == '') {
        _this.$message({
          message: '请输入姓名',
          offset: 120,
          center: true
        });
        return;
      }
      if (_this.phone == '') {
        _this.$message({
          message: '请输入电话',
          offset: 120,
          center: true
        });
        return;
      }
      var params = new URLSearchParams();
      params.append('contact', _this.contact);
      params.append('phone', _this.phone);
      // params.append('userId', _this.userId);
      params.append('articleId', _this.articleId);
      (0, _enrollOrder.create)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.payment)) {
          _this.sn = response.data.payment.sn;
          _this.$router.replace({
            path: '/payment',
            query: {
              sn: _this.sn,
              redirect: '/article/index?articleId=' + _this.articleId
            }
          });
        } else {
          _this.checkExistsClick();
        }
      }).catch(function (error) {});
    },
    checkExistsClick: function checkExistsClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('articleId', _this.articleId);
      (0, _enrollOrder.checkExists)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.enrollOrder)) {
          _this.paymentType = true;
          _this.accordingType = false;
          _this.$message('报名成功');
        } else {
          _this.accordingType = false;
          _this.paymentType = false;
        }
      }).catch(function (error) {});
    },
    getInfoClick: function getInfoClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchId', _this.mchId);
      (0, _enterprise.getInfo)(params).then(function (res) {
        console.log(res);
        if (res.type == 'success') {
          _this.mch = res.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};