"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cashSetup = require("@/api/cashSetup");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      amount: '',
      form: {},
      balance: 0
    };
  },
  created: function created() {
    this.getBalance();
  },
  methods: {
    getBalance: function getBalance() {
      var _this = this;
      (0, _cashSetup.getInfo)().then(function (res) {
        console.log(res);
        _this.balance = res.data.balance;
      }).catch(function (err) {
        console.log(err);
      });
    },
    getCash: function getCash() {
      var _this2 = this;
      console.log(this.amount);
      if (this.amount === '') {
        alert('请输入提现金额');
      } else if (this.amount < 2) {
        alert('低于2元,无法提现');
      } else if (this.balance === 0) {
        alert('余额低于2元,无法提现');
      } else if (this.amount > this.balance) {
        alert('余额不足');
      } else if (this.amount > 20000) {
        alert('单日提现不得超过20000元');
      } else {
        (0, _cashSetup.calculate)(this.amount).then(function (res) {
          console.log(res);
          _this2.$set(_this2.form, 'amount', _this2.amount);
          _this2.$set(_this2.form, 'paymentPluginId', 'weixinWalletPlugin');
          var popup = confirm("\u63D0\u73B0\u91D1\u989D".concat(_this2.form.amount, "\u5143, \u53BB\u9664\u624B\u7EED\u8D39").concat(res.data, "\u4E4B\u540E\u53EF\u83B7\u53D6").concat(_this2.form.amount - res.data, "\u5143"));
          if (popup === true) {
            (0, _cashSetup.transfer)(_this2.form.amount).then(function (res) {
              console.log(res);
              _this2.getBalance();
            }).catch(function (err) {
              console.log(err);
            });
          } else {
            _this2.getBalance();
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  }
};