"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SHARE = SHARE;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.regexp.split");
var _weixinJsSdk = _interopRequireDefault(require("weixin-js-sdk"));
var _utils = _interopRequireDefault(require("./utils.js"));
var _user = require("@/api/user");
var _store = _interopRequireDefault(require("../store"));
function SHARE(url, conf, func) {
  var _this = this;
  if (_utils.default.isWeiXin()) {
    var params = new URLSearchParams();
    params.append('url', url.split('#')[0]);
    params.append('pluginId', 'weixinOAuth2Plugin');
    params.append('mchId', _store.default.getters.mchId);
    (0, _user.getConfig)(params).then(function (response) {
      if (!_utils.default.isNull(conf) && !_utils.default.isNull(conf.imgUrl) && conf.imgUrl.indexOf('?x-oss-') == -1) {
        conf.imgUrl = _utils.default.thumbnail(conf.imgUrl, 100, 100);
      } else {
        if (!_utils.default.isNull(response.data) && !_utils.default.isNull(response.data.imgUrl) && response.data.imgUrl.indexOf('?x-oss-') == -1) {
          response.data.imgUrl = _utils.default.thumbnail(response.data.imgUrl, 100, 100);
        }
      }
      _weixinJsSdk.default.config({
        debug: false,
        appId: response.data.appId,
        // 和获取Ticket的必须一样------必填，公众号的唯一标识
        timestamp: response.data.timestamp,
        // 必填，生成签名的时间戳
        nonceStr: response.data.nonceStr,
        // 必填，生成签名的随机串
        signature: response.data.signature,
        // 必填，签名，见附录1
        //需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
        jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline', 'scanQRCode', 'chooseWXPay']
      });
      _weixinJsSdk.default.error(function (res) {
        // alert(JSON.stringify(res));
      });
      //处理验证成功的信息
      _weixinJsSdk.default.ready(function () {
        //分享到朋友圈
        _weixinJsSdk.default.onMenuShareTimeline({
          title: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.title) ? conf.title : response.data.title,
          // 分享标题
          link: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.link) ? conf.link : _utils.default.host(),
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.imgUrl) ? conf.imgUrl : response.data.imgUrl,
          // 分享图标
          success: function success(res) {
            func(true);
          },
          cancel: function cancel(res) {
            func(false);
          }
        });
        //分享给朋友
        _weixinJsSdk.default.onMenuShareAppMessage({
          title: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.title) ? conf.title : response.data.title,
          // 分享标题
          desc: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.desc) ? conf.desc : response.data.desc,
          // 分享描述
          link: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.link) ? conf.link : _utils.default.host(),
          // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: !_utils.default.isNull(conf) && !_utils.default.isNull(conf.imgUrl) ? conf.imgUrl : response.data.imgUrl,
          // 分享图标
          type: 'link',
          // 分享类型,music、video或link，不填默认为link
          dataUrl: '',
          // 如果type是music或video，则要提供数据链接，默认为空
          success: function success(res) {
            func(true);
          },
          cancel: function cancel(res) {
            func(false);
          }
        });
      });
    }).catch(function (error) {
      //alert(error);
    });
  }
}