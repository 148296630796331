"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _enrollOrder = require("@/api/enrollOrder");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 0,
      pageSize: 10,
      articleOrderForm: [],
      loading: true
    };
  },
  created: function created() {
    this.loadArticle();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  components: {
    NoData: _NoData.default
  },
  methods: {
    loadArticle: function loadArticle() {
      var _this2 = this;
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      (0, _enrollOrder.list)(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 270, 200);
          _this.articleOrderForm.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this2.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.loadArticle();
    }
  },
  mounted: function mounted() {
    document.querySelector('body').setAttribute('style', 'background-color:#f2f2f2');
  },
  beforeDestroy: function beforeDestroy() {
    document.querySelector('body').removeAttribute('style');
  }
};