var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: { title: "学习记录", "left-arrow": "", fixed: "" },
        on: {
          "click-left": function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "top" }),
      _vm._v(" "),
      _vm._l(_vm.activityOrderForm, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              click: function ($event) {
                return _vm.courseClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "body" }, [
              _c("div", { staticClass: "tit_num" }, [
                _c("div", { staticClass: "tit" }, [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(_vm._f("dateFilter")(item.createDate))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "body-img" }, [
                _c("img", { attrs: { src: item.thumbnail, alt: "" } }),
              ]),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "end" }, [_vm._v("已经到底啦~")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }