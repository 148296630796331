"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.string.pad-start");
var _promoteOrder = require("@/api/promoteOrder");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = (0, _defineProperty2.default)((0, _defineProperty2.default)({
  name: "",
  components: {},
  filters: {},
  // 过滤器
  data: function data() {
    return {
      isActive: -1,
      bottomText: true,
      tabs: [{
        text: "测评"
      }, {
        text: "课程"
      }, {
        text: "咨询"
      }, {
        text: "报名"
      }, {
        text: "专栏"
      }],
      promoteOrderList: [],
      pageNum: 1,
      pageSize: 20,
      title: "测评"
    };
  },
  watch: {},
  created: function created() {
    this.getEvaluationOrder();
    this.pullUpload();
  },
  mounted: function mounted() {
    console.log(this.$refs);
    this.tabsLine();
  },
  destroyed: function destroyed() {
    // 销毁页面时候移除滚动事件,避免影响其他页面
    window.removeEventListener("scroll", this._scroll, false);
  }
}, "filters", {
  formatDate: function formatDate(value) {
    if (value !== undefined) {
      var date = new Date(value);
      var year = date.getFullYear();
      var month = date.getMonth() + 1 + "";
      var day = date.getDate() + "";
      var hour = date.getHours() + "";
      var minute = date.getMinutes() + "";
      var second = date.getSeconds() + "";
      return "".concat(year, ".").concat(month.padStart(2, "0"), ".").concat(day.padStart(2, "0"), " ").concat(hour.padStart(2, "0"), ":").concat(minute.padStart(2, "0"));
    }
  }
}), "methods", {
  isShowBottomText: function isShowBottomText(size) {
    // 是否显示底部text
    if (size < this.pageSize) {
      this.bottomText = true;
    } else {
      this.bottomText = false;
    }
  },
  getEvaluationOrder: function getEvaluationOrder() {
    var _this = this;
    // 测评订单
    var params = new URLSearchParams();
    params.append("pageNum", this.pageNum);
    params.append("pageSize", this.pageSize);
    (0, _promoteOrder.evaluation)(params).then(function (res) {
      console.log(res);
      _this.isShowBottomText(res.data.data.length);
      _this.promoteOrderList = res.data.data;
      _this.pageNum++;
    }).catch(function (err) {
      console.log(err);
    });
  },
  getCourseOrder: function getCourseOrder() {
    var _this2 = this;
    // 课程订单
    var params = new URLSearchParams();
    params.append("pageNum", this.pageNum);
    params.append("pageSize", this.pageSize);
    (0, _promoteOrder.course)(params).then(function (res) {
      console.log(res);
      _this2.isShowBottomText(res.data.data.length);
      _this2.promoteOrderList = res.data.data;
      _this2.pageNum++;
    }).catch(function (err) {
      console.log(err);
    });
  },
  //咨询订单
  getCounselorOrder: function getCounselorOrder() {
    var _this3 = this;
    var params = new URLSearchParams();
    params.append("pageNum", this.pageNum);
    params.append("pageSize", this.pageSize);
    (0, _promoteOrder.counselor)(params).then(function (res) {
      // console.log(res);
      _this3.isShowBottomText(res.data.data.length);
      _this3.promoteOrderList = res.data.data;
      _this3.pageNum++;
      console.log(_this3.promoteOrderList, "咨询师");
    });
  },
  getColumnOrder: function getColumnOrder() {
    var _this4 = this;
    // 专栏订单
    var params = new URLSearchParams();
    params.append("pageNum", this.pageNum);
    params.append("pageSize", this.pageSize);
    (0, _promoteOrder.column)(params).then(function (res) {
      console.log(res);
      _this4.isShowBottomText(res.data.data.length);
      _this4.promoteOrderList = res.data.data;
      _this4.pageNum++;
    }).catch(function (err) {
      console.log(err);
    });
  },
  getEnrollOrder: function getEnrollOrder() {
    var _this5 = this;
    // 报名记录
    var params = new URLSearchParams();
    params.append("pageNum", this.pageNum);
    params.append("pageSize", this.pageSize);
    (0, _promoteOrder.enroll)(params).then(function (res) {
      console.log(res);
      _this5.isShowBottomText(res.data.data.length);
      _this5.promoteOrderList = res.data.data;
      _this5.pageNum++;
    }).catch(function (err) {
      console.log(err);
    });
  },
  getGoodsOrder: function getGoodsOrder() {
    var _this6 = this;
    // 商品记录
    var params = new URLSearchParams();
    params.append("pageNum", this.pageNum);
    params.append("pageSize", this.pageSize);
    (0, _promoteOrder.goods)(params).then(function (res) {
      console.log(res);
      _this6.isShowBottomText(res.data.data.length);
      _this6.promoteOrderList = res.data.data;
      _this6.pageNum++;
    }).catch(function (err) {
      console.log(err);
    });
  },
  tabsLine: function tabsLine(index) {
    var tabLength = this.$refs.tabsRef.innerText.split(/[\n,][\n,]/g).length; // 按回车切割字符串,判断有几个元素
    var lineWidth = this.$refs.tabRef[0].offsetWidth; // 拿到单独一个元素的宽度,用于计算线的宽度
    var lineMargin = (this.$refs.tabsRef.offsetWidth / tabLength - lineWidth) / 2; // 拿到元素两边的距离
    var tabWidth = this.$refs.tabsRef.offsetWidth / tabLength; // 拿到元素的宽高
    this.$refs.tabsLine.style.width = lineWidth + "px"; // 设置线的宽度
    if (index) {
      // 如果有点击
      this.$refs.tabsLine.style.marginLeft = tabWidth * index + lineMargin + "px"; // 设置线的margin
    } else {
      // 第一次进入
      this.$refs.tabsLine.style.marginLeft = lineMargin + "px"; // 设置线的margin
    }
  },
  tabsFn: function tabsFn(index) {
    // this.isActive = index
    this.pageNum = 1;
    this.tabsLine(index);
    if (this.tabs[index].text === "测评") {
      this.getEvaluationOrder();
      this.title = "测评";
    } else if (this.tabs[index].text === "课程") {
      this.getCourseOrder();
      this.title = "课程";
    } else if (this.tabs[index].text === "报名") {
      this.getEnrollOrder();
      this.title = "报名";
    } else if (this.tabs[index].text === "专栏") {
      this.getColumnOrder();
      this.title = "专栏";
    } else if (this.tabs[index].text === "咨询") {
      this.getCounselorOrder();
      this.title = "咨询";
    }
  },
  enter: function enter(el, done) {
    //进入动画执行过程中
    el.offsetHeight;
    el.style.transition = "all 0.25s";
    setTimeout(function () {
      done();
    }, 0);
  },
  pullUpload: function pullUpload() {
    var _this7 = this;
    // 上拉加载更多
    this._scroll = function () {
      // 把移动事件赋值给_scroll
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条到底部的条件
      // console.log(scrollTop , windowHeight, scrollHeight)
      // 可视区域(四舍五入,避免误差) + 已滚动区域 < 可滚动的高度 以下+1-1表示四舍五入之后可能的误差
      if (Math.round(scrollTop) + windowHeight == scrollHeight || Math.round(scrollTop) + windowHeight == scrollHeight + 1 || Math.round(scrollTop) + windowHeight == scrollHeight - 1) {
        //已滚动底部
        console.log("触底");
        var params = new URLSearchParams();
        params.append("pageNum", _this7.pageNum);
        params.append("pageSize", _this7.pageSize);
        if (_this7.title === "测评") {
          (0, _promoteOrder.evaluation)(params).then(function (res) {
            console.log(res);
            _this7.isShowBottomText(res.data.data.length);
            // this.promoteOrderList.push(res.data.data)
            _this7.promoteOrderList = [].concat((0, _toConsumableArray2.default)(_this7.promoteOrderList), (0, _toConsumableArray2.default)(res.data.data));
            console.log(_this7.promoteOrderList);
            _this7.pageNum++;
          }).catch(function (err) {
            console.log(err);
          });
        } else if (_this7.title === "课程") {
          (0, _promoteOrder.course)(params).then(function (res) {
            console.log(res);
            _this7.isShowBottomText(res.data.data.length);
            _this7.promoteOrderList = [].concat((0, _toConsumableArray2.default)(_this7.promoteOrderList), (0, _toConsumableArray2.default)(res.data.data));
            console.log(_this7.promoteOrderList);
            _this7.pageNum++;
          }).catch(function (err) {
            console.log(err);
          });
        } else if (_this7.title === "报名") {
          (0, _promoteOrder.enroll)(params).then(function (res) {
            console.log(res);
            _this7.isShowBottomText(res.data.data.length);
            _this7.promoteOrderList = [].concat((0, _toConsumableArray2.default)(_this7.promoteOrderList), (0, _toConsumableArray2.default)(res.data.data));
            console.log(_this7.promoteOrderList);
            _this7.pageNum++;
          }).catch(function (err) {
            console.log(err);
          });
        } else if (_this7.title === "专栏") {
          (0, _promoteOrder.column)(params).then(function (res) {
            console.log(res);
            _this7.isShowBottomText(res.data.data.length);
            _this7.promoteOrderList = [].concat((0, _toConsumableArray2.default)(_this7.promoteOrderList), (0, _toConsumableArray2.default)(res.data.data));
            console.log(_this7.promoteOrderList);
            _this7.pageNum++;
          }).catch(function (err) {
            console.log(err);
          });
        } else if (_this7.title === "咨询") {
          (0, _promoteOrder.counselor)(params).then(function (res) {
            console.log(res);
            _this7.isShowBottomText(res.data.data.length);
            _this7.promoteOrderList = [].concat((0, _toConsumableArray2.default)(_this7.promoteOrderList), (0, _toConsumableArray2.default)(res.data.data));
            console.log(_this7.promoteOrderList);
            _this7.pageNum++;
          }).catch(function (err) {
            console.log(err);
          });
        }
      }
    };
    window.addEventListener("scroll", this._scroll); // 把移动事件挂载到this上
  }
});