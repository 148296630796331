var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "payment_bottom" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", round: "" },
            on: { click: _vm.continueClick },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payment_name_box" }, [
      _c("span", { staticClass: "payment_name" }, [
        _vm._v("3-7岁儿童感统训练"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "payment_price" }, [_vm._v("¥99.0")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payment_info_box" }, [
      _c("div", { staticClass: "payment_info_content" }, [
        _c("span", { staticClass: "payment_info_left" }, [_vm._v("订单编号")]),
        _vm._v(" "),
        _c("span", { staticClass: "payment_info_left" }, [
          _vm._v("100107203212239201"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "payment_info_content" }, [
        _c("span", { staticClass: "payment_info_left" }, [_vm._v("下单时间")]),
        _vm._v(" "),
        _c("span", { staticClass: "payment_info_left" }, [
          _vm._v("2020-03-18 18:23"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "payment_info_content" }, [
        _c("span", { staticClass: "payment_info_left" }, [_vm._v("当前状态")]),
        _vm._v(" "),
        _c("span", { staticClass: "payment_info_left" }, [_vm._v("付款成功")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "payment_info_subtitle" }, [
        _vm._v("您已成功购买，建议您关注公众号获取后续服务。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payment_info_img_box" }, [
      _c("div", { staticClass: "payment_info_img" }, [
        _c("span", [_vm._v("二维码")]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "payment_info_img_subtitle" }, [
        _vm._v("长按识别二维码关注公众号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }