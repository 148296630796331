var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category_box" }, [
    _c("div", { staticClass: "headerBox" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "flex-end",
          },
        },
        [
          _c("span", { staticClass: "header_name" }, [
            _vm._v(_vm._s(_vm.content.title)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "header_subName" }, [
            _vm._v("心理健康从检测开始"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "categoryContent" },
      _vm._l(_vm.navigationForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "category",
            on: {
              click: function ($event) {
                return _vm.navigationClick(item)
              },
            },
          },
          [
            _c("div", { staticClass: "category_Img" }, [
              _c("img", {
                staticStyle: { display: "block" },
                attrs: { src: item.logo, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "category_NameBox" }, [
              _c("span", { staticClass: "category_Name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _vm._v(" "),
              item.subName
                ? _c("span", { staticClass: "category_subName" }, [
                    _vm._v(_vm._s(item.subName)),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_titleIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/classification.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }