"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _first = _interopRequireDefault(require("./components/first"));
var _second = _interopRequireDefault(require("./components/second"));
var _third = _interopRequireDefault(require("./components/third"));
var _fourth = _interopRequireDefault(require("./components/fourth"));
var _utils = _interopRequireDefault(require("../../utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      attributes: [],
      active: 0,
      componentName: 'first',
      appointmentDate: '',
      appointmentHour: '',
      counselorMethod: '',
      mchCounselorId: '',
      timeIndex: '',
      dateIndex: '',
      orderId: ''
    };
  },
  components: {
    first: _first.default,
    second: _second.default,
    third: _third.default,
    fourth: _fourth.default
  },
  created: function created() {
    if (!_utils.default.isNull(this.$route.query.yearDate)) {
      this.appointmentDate = this.$route.query.yearDate;
    }
    if (!_utils.default.isNull(this.$route.query.dateTime)) {
      this.appointmentHour = this.$route.query.dateTime;
    }
    if (!_utils.default.isNull(this.$route.query.counselorMethod)) {
      this.counselorMethod = this.$route.query.counselorMethod;
    }
    if (!_utils.default.isNull(this.$route.query.timeIndex)) {
      this.timeIndex = this.$route.query.timeIndex;
    }
    if (!_utils.default.isNull(this.$route.query.dateIndex)) {
      this.dateIndex = this.$route.query.dateIndex;
    }
    if (!_utils.default.isNull(this.$route.query.active)) {
      this.active = this.$route.query.active;
      if (this.$route.query.active == 0) {
        this.componentName = 'first';
      } else if (this.$route.query.active == 1) {
        this.componentName = 'second';
      } else if (this.$route.query.active == 2) {
        this.componentName = 'third';
      } else if (this.$route.query.active == 3) {
        this.componentName = 'fourth';
      }
    }
    if (!_utils.default.isNull(this.$route.query.mchCounselorId)) {
      this.mchCounselorId = this.$route.query.mchCounselorId;
      console.log(this.mchCounselorId);
    }
  },
  methods: {
    next: function next() {
      if (this.active++ > 2) this.active = 0;
      if (this.active == 0) {
        this.componentName = 'first';
      } else if (this.active == 1) {
        this.componentName = 'second';
      } else if (this.active == 2) {
        this.componentName = 'third';
      } else if (this.active == 3) {
        this.componentName = 'fourth';
      }
    },
    nextClick: function nextClick(data, id, attributes) {
      console.log(data);
      this.active = data;
      this.orderId = id;
      this.attributes = attributes;
      if (data == 0) {
        this.componentName = 'first';
      } else if (data == 1) {
        this.componentName = 'second';
      } else if (data == 2) {
        this.componentName = 'third';
      } else if (data == 3) {
        this.componentName = 'fourth';
      }
      window.scrollTo(0, 0);
    }
  }
};