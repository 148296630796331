var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consulting_box" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.consultingForm, function (item) {
        return _c("div", { key: item.id, staticClass: "consulting_content" }, [
          _c("div", { staticClass: "consulting_content_img" }, [
            _c("img", {
              attrs: { src: item.img, width: "100%", height: "100%" },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "consulting_content_content" }, [
            _c("div", { staticClass: "consulting_content_top" }, [
              _c("span", { staticClass: "consulting_content_title" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "consulting_content_subtitle" }, [
                _vm._v(_vm._s(item.subtitle)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "consulting_content_bottom" }, [
              _c("div", { staticClass: "consulting_content_label" }, [
                _c("span", { staticClass: "consulting_content_labelTitle" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
              ]),
            ]),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "consulting_header" }, [
      _c("div", { staticClass: "consulting_header_box" }, [
        _c("div", { staticClass: "consulting_header_line" }),
        _vm._v(" "),
        _c("span", { staticClass: "consulting_header_name" }, [
          _vm._v("心理咨询"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "consulting_header_more" }, [
          _vm._v("查看更多"),
        ]),
        _vm._v(" "),
        _c("i", { staticClass: "el-icon-arrow-right consulting_header_more" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }