"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _columnItemCatalog = require("@/api/columnItemCatalog");
var _courseItem = require("@/api/courseItem");
var _evaluation = require("@/api/evaluation");
var _courseOrder = require("@/api/courseOrder");
var _enrollOrder = require("@/api/enrollOrder");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      columnId: "",
      columnOrderId: "",
      pageNum: 1,
      pageSize: 100,
      stageTitle: "",
      stageSubtitle: "",
      columnSubjectStageStage: [],
      columnItems: [],
      courseItemId: "",
      courseItemlist: [],
      mark: 1,
      markOver: ""
    };
  },
  created: function created() {
    this.columnId = this.$route.query.columnId;
    this.columnOrderId = this.$route.query.columnOrderId;
    this.stageTitle = this.$route.query.stageTitle;
    this.stageSubtitle = this.$route.query.stageSubtitle;
    this.getColumnGatalog();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId", "userId", "xuid", "token"])),
  mounted: function mounted() {},
  methods: {
    getMark: function getMark() {
      var _this2 = this;
      var itemWidth = document.querySelector(".column-subject-stage__stage-item").clientWidth;
      this.columnSubjectStageStage.forEach(function (e, i) {
        var dom = document.querySelector("#scroll-".concat(i));
        dom.addEventListener("scroll", function () {
          var removing = dom.scrollLeft;
          _this2.$set(e, "mark", parseInt((removing - 15 + itemWidth / 2) / itemWidth) + 1);
          // console.log(removing);
          _this2.markOver = dom.scrollWidth - removing == dom.clientWidth;
        });
      });
    },
    getColumnGatalog: function getColumnGatalog() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      params.append("columnId", _this.columnId);
      var m = 0;
      (0, _columnItemCatalog.list)(params).then(function (res) {
        _this.columnSubjectStageStage = res.data.data.map(function (e) {
          e.mark = 1;
          e.markOver = "";
          e.columnItems.map(function (a, i) {
            a.index = i + 1 + m;
            if (a.index < 10) {
              a.index = "0" + a.index;
            }
          });
          m += e.columnItems.length;
          return e;
        });
        _this.$nextTick(function () {
          _this.getMark();
        });
      }).catch(function (error) {});
    },
    columnCreateCourse: function columnCreateCourse(courseId, catalog, title) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("columnOrderId", _this.columnOrderId);
      params.append("courseId", courseId);
      (0, _courseOrder.columnCreateCourse)(params).then(function (response) {
        console.log(response.data.courseOrder.id);
        _this.$router.push({
          path: "/column/columnStageDetail",
          query: {
            courseId: courseId,
            columnName: _this.stageTitle,
            columncatalog: catalog,
            courseTitle: title,
            courseOrderId: response.data.courseOrder.id
          }
        });
      }).catch(function (error) {});
    },
    columnCreateEvaluation: function columnCreateEvaluation(gaugeId) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("columnOrderId", _this.columnOrderId);
      params.append("gaugeId", gaugeId);
      (0, _evaluation.columnCreate)(params).then(function (response) {
        console.log(response);
        console.log(response.data.evaluation.id);
        _this.$router.push({
          path: "/gauge/answer",
          query: {
            evaluationId: response.data.evaluation.id
          }
        });
      }).catch(function (error) {});
    },
    columnCreateEnrollOrder: function columnCreateEnrollOrder(articleId) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("columnOrderId", _this.columnOrderId);
      params.append("articleId", articleId);
      (0, _enrollOrder.columnCreateEnroll)(params).then(function (res) {
        console.log(res);
        _this.$router.push({
          path: "/article/index",
          query: {
            articleId: articleId
          }
        });
      }).catch(function (error) {});
    },
    goColumnStageDetail: function goColumnStageDetail(catalog, tagType, title, materialId) {
      var _this = this;
      console.log(tagType);
      if (tagType == 6) {
        _this.columnCreateCourse(materialId, catalog, title);
      } else if (tagType == 1 || tagType == 2 || tagType == 3) {
        _this.$router.push({
          path: "/column/columnSubjectStudy",
          query: {
            type: tagType,
            id: materialId,
            columnId: _this.columnId,
            stageTitle: _this.stageTitle,
            stageSubtitle: _this.stageSubtitle
          }
        });
      } else if (tagType == 0) {
        _this.columnCreateEvaluation(materialId);
      } else if (tagType == 4) {
        _this.columnCreateEnrollOrder(materialId);
      } else if (tagType == 5) {
        _this.$router.push({
          path: "/activitySy/details",
          query: {
            activityId: materialId
          }
        });
      }
    }
  }
};