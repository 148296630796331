var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "userInfoBox" }, [
      _c("div", { staticClass: "userLogo" }, [
        _vm.avatar
          ? _c("img", {
              attrs: { src: _vm.avatar, width: "100%", height: "100%" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          attrs: {
            else: "",
            src: require("./images/default_avatar.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "userName" }, [_vm._v(_vm._s(_vm.name))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cellListBox" }, [
      _c("div", { staticClass: "cellBoxOne" }, [
        _vm._m(1),
        _vm._v(" "),
        _vm.checkPermission(["evaluation-list"])
          ? _c(
              "div",
              {
                staticClass: "iconCellBox",
                on: {
                  click: function ($event) {
                    return _vm.gaugeOrderClick(1)
                  },
                },
              },
              [_vm._m(2), _vm._v(" "), _vm._m(3)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["evaluation-list"])
          ? _c(
              "div",
              {
                staticClass: "iconCellBox",
                on: {
                  click: function ($event) {
                    return _vm.gaugeOrderClick(2)
                  },
                },
              },
              [_vm._m(4), _vm._v(" "), _vm._m(5)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["evaluation-list"])
          ? _c(
              "div",
              {
                staticClass: "iconCellBox",
                on: {
                  click: function ($event) {
                    return _vm.gaugeOrderClick(3)
                  },
                },
              },
              [_vm._m(6), _vm._v(" "), _vm._m(7)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["courseOrder-list"])
          ? _c(
              "div",
              {
                staticClass: "iconCellBox",
                on: { click: _vm.courseOrderClick },
              },
              [_vm._m(8), _vm._v(" "), _vm._m(9)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["counselorOrder-list", "counselor2Order-list"])
          ? _c(
              "div",
              { staticClass: "iconCellBox", on: { click: _vm.advisoryClick } },
              [_vm._m(10), _vm._v(" "), _vm._m(11)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["enrollOrder-list"])
          ? _c(
              "div",
              { staticClass: "iconCellBox", on: { click: _vm.articleClick } },
              [_vm._m(12), _vm._v(" "), _vm._m(13)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["columnOrder-list"])
          ? _c(
              "div",
              { staticClass: "iconCellBox", on: { click: _vm.columnClick } },
              [_vm._m(14), _vm._v(" "), _vm._m(15)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.checkPermission(["study-list"])
          ? _c(
              "div",
              {
                staticClass: "iconCellBox",
                on: { click: _vm.onLearningRecord },
              },
              [_vm._m(16), _vm._v(" "), _vm._m(17)]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cellBoxTwo" }, [
        _vm.checkPermission(["myTask-list"])
          ? _c(
              "div",
              {
                staticClass: "iconCellBox",
                on: {
                  click: function ($event) {
                    return _vm.clickTask()
                  },
                },
              },
              [_vm._m(18), _vm._v(" "), _vm._m(19)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "iconCellBox",
            on: {
              click: function ($event) {
                return _vm.onSetSystem()
              },
            },
          },
          [_vm._m(20), _vm._v(" "), _vm._m(21)]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "iconCellBox", on: { click: _vm.billClickOut } },
          [_vm._m(22), _vm._v(" "), _vm._m(23)]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerBg" }, [
      _c("img", {
        attrs: {
          src: require("./images/mineBg.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerCellBox" }, [
      _c("div", { staticClass: "cellLine" }),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleBold" }, [_vm._v("心理档案")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/groupIcon.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("团体心理测评")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/personalIcon.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("个人心理测评")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/selfTestIcon.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("心理自测")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-2.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("心理课程")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-3.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("心理咨询")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-4.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("活动报名")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-5.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("我的专栏")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-6.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleNormal" }, [_vm._v("学习记录")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellLine" }),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleBold" }, [_vm._v("我的任务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellLine" }),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleBold" }, [_vm._v("设置")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellLine" }),
      _vm._v(" "),
      _c("span", { staticClass: "cellTitleBold" }, [_vm._v("退出")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }