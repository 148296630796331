var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "guide-content" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { staticClass: "guide-content-title" }, [_vm._v("指导语")]),
      _vm._v(" "),
      _c("div", {
        staticClass: "guide-content-html",
        domProps: { innerHTML: _vm._s(_vm.nowAnswer.title) },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "satisfactionTitle_BottonBox", style: { zIndex: 3 } },
      [
        _c(
          "div",
          {
            staticClass: "satisfactionTitle_Botton",
            on: { click: _vm.chooseAnswer },
          },
          [_c("span", [_vm._v("明白了，开始答题")])]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "guide-content-img" }, [
      _c("img", {
        attrs: {
          width: "100%",
          height: "100%",
          src: require("../../assets/mxs_images/img_test.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }