import { render, staticRenderFns } from "./activityStyle2.vue?vue&type=template&id=bf023aec&scoped=true"
import script from "./activityStyle2.vue?vue&type=script&lang=js"
export * from "./activityStyle2.vue?vue&type=script&lang=js"
import style0 from "./activityStyle2.vue?vue&type=style&index=0&id=bf023aec&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf023aec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\28658\\Desktop\\美心数科开发\\gitee\\mxs-online-h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bf023aec')) {
      api.createRecord('bf023aec', component.options)
    } else {
      api.reload('bf023aec', component.options)
    }
    module.hot.accept("./activityStyle2.vue?vue&type=template&id=bf023aec&scoped=true", function () {
      api.rerender('bf023aec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/index/components/acticityTimeArticle/activityStyle2.vue"
export default component.exports