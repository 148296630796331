var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "member-deal" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "tabs" }, [
          _c("div", { staticClass: "tabs-button" }, [
            _c(
              "p",
              {
                class: [{ editFont: _vm.isActive === 1 }],
                on: { click: _vm.memberDeal },
              },
              [_vm._v("已成交客户")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                class: [{ editFont: _vm.isActive === 2 }],
                on: { click: _vm.memberNoDeal },
              },
              [_vm._v("未成交客户")]
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            class: [_vm.isActive === 1 ? "tabs-line1" : "tabs-line2"],
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "main" },
          _vm._l(_vm.memberDealList, function (item, i) {
            return _c("div", { key: i, staticClass: "item" }, [
              _c("img", { attrs: { src: item.avatar, alt: "" } }),
              _vm._v(" "),
              _c("div", { staticClass: "item-right" }, [
                _c("div", [
                  _c("p", [_vm._v(_vm._s(item.name))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "绑定时间: " +
                        _vm._s(_vm._f("formatDate")(item.createDate))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isActive === 2,
                        expression: "isActive===2",
                      },
                    ],
                  },
                  [_vm._v("可被抢客")]
                ),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.bottomText,
                expression: "bottomText",
              },
            ],
            staticClass: "bottomText",
          },
          [_vm._v("——    没有更多了    ——")]
        ),
      ]),
      _vm._v(" "),
      _c("my-bottom"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }