var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.agreeType == true,
          expression: "agreeType == true",
        },
      ],
      staticClass: "prompt_box",
      on: { click: _vm.downClick },
    },
    [
      _c(
        "div",
        { staticClass: "prompt_content", on: { click: _vm.stopClick } },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "prompt_bottom_box" }, [
            _c(
              "div",
              { staticClass: "prompt_bottom", on: { click: _vm.downClick } },
              [
                _c("span", { staticClass: "prompt_bottom_title" }, [
                  _vm._v("知道了"),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt_header" }, [
      _c("span", { staticClass: "prompt_header_title" }, [
        _vm._v("咨询同意书"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "prompt_content_box" }, [
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("您好！在开始咨询前，请先阅读这份同意书。"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [_vm._v("一、服务")]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "1.您接受的是非药物的专业心理咨询工作，心理咨询师的资质已经在专门的文件中介绍，请您了解。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "2.咨询的第一步是了解和收集您的情况，可能需要一段时间来发展出有效的咨询方案。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "3.咨询师是您的咨询者和顾问，您愿意对自己的选择和行为负责：在咨询过程中发挥您的主动性，觉察和接纳自己，与咨询师的专业经验结合，来达到咨询目标。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [_vm._v("二、保密")]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n       1.您个人的信息、资料等，本中心咨询师都会保密。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n       2.咨询师可能会跟其他专业咨询师或督导师讨论，使他们能在技能上更好地发展，以更好地服务来访者，但在讨论中会隐去来访者的个人信息，并征得您的同意。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n      3.录音、录像都需要经过您的同意，而且绝对保密。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n      4.保密例外：如果您曾经有过伤害自己和他人的记录或意向，咨询师必须向有关人员报告，但咨询师会事先告知您。当咨询师发现有危急状况时，也会立刻采取行动。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("三、咨询时间"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        1.一般情况下，咨询频率为每周一次，每次咨询时间为50分钟。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        2.每次咨询都是事先约定的。每次咨询结束约定下次咨询时间。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        3.每次咨询，请提前5-10分钟到来，调整状态，做好准备，以保证咨询效果。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n       3.预约、取消或更改预约时间，请提前24小时通知咨询助理。如因特殊情况迟到，请及时告知助理与咨询师协调时间。无故取消咨询且未告知，将收取全额咨询费。如咨询师失约，双方共同协商进行弥补和赔偿。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_title" }, [
        _vm._v("四、咨询收费"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_content_subtitle" }, [
        _vm._v(
          "\n        咨询费用需提前缴纳，咨询助理确认收到费用后咨询预约有效，并通知咨询师。\n      "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "prompt_subtitle" }, [
        _vm._v("\n         我已读过、明白并同意以上所有内容。\n       "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }