var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.promptType == true,
          expression: "promptType == true",
        },
      ],
      staticClass: "prompt_box",
      on: { click: _vm.downClick },
    },
    [
      _c(
        "div",
        { staticClass: "prompt_content", on: { click: _vm.stopClick } },
        [
          _c("div", { staticClass: "prompt_header" }, [
            _c("span", { staticClass: "prompt_header_title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.promptData, function (item) {
            return _c("div", { staticClass: "prompt_content_box" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.title != "",
                      expression: "item.title !=''",
                    },
                  ],
                  staticClass: "prompt_content_title",
                },
                [_vm._v(_vm._s(item.title))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "prompt_content_subtitle" }, [
                _vm._v(_vm._s(item.subTitle)),
              ]),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "prompt_bottom_box" }, [
            _c(
              "div",
              { staticClass: "prompt_bottom", on: { click: _vm.downClick } },
              [
                _c("span", { staticClass: "prompt_bottom_title" }, [
                  _vm._v("知道了"),
                ]),
              ]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }