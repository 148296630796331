"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _store = _interopRequireDefault(require("@/store"));
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["name", "avatar", "menus", "mchId"])),
  created: function created() {},
  methods: {
    checkPermission: _permission.default,
    gaugeOrderClick: function gaugeOrderClick(tabIndex) {
      this.$router.push({
        path: '/gauge/order?tabIndex=' + tabIndex
      });
    },
    courseOrderClick: function courseOrderClick() {
      this.$router.push({
        path: '/course/order'
      });
    },
    advisoryClick: function advisoryClick() {
      if (this.checkPermission(['counselor2-list'])) {
        this.$router.push({
          path: '/counselorNew/order/orderList'
        });
      } else if (this.checkPermission(['counselor-list'])) {
        this.$router.push({
          path: '/counselor/order/orderList'
        });
      }
    },
    articleClick: function articleClick() {
      this.$router.push({
        path: '/article/order'
      });
    },
    columnClick: function columnClick() {
      this.$router.push({
        path: '/column/columnOrder'
      });
    },
    onLearningRecord: function onLearningRecord() {
      this.$router.push({
        path: '/activitySy/learningRecord'
      });
    },
    onSetSystem: function onSetSystem() {
      this.$router.push({
        path: '/system'
      });
    },
    clickSetting: function clickSetting() {
      this.$router.push({
        path: "/member/setting"
      });
    },
    clickTask: function clickTask() {
      this.$router.push({
        path: "/member/task"
      });
    },
    // 退出登录
    billClickOut: function billClickOut() {
      var _this = this;
      _store.default.dispatch('user/resetToken').then(function () {
        localStorage.removeItem('companyEmployee');
        _this.$router.replace({
          path: '/'
        });
      });
    }
  }
};