"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasUnfinished = hasUnfinished;
exports.list = list;
exports.lockSysUser = lockSysUser;
exports.updateStatus = updateStatus;
var _request = _interopRequireDefault(require("@/utils/request"));
function lockSysUser(params) {
  return (0, _request.default)({
    url: "/member/lockSysUser",
    method: "post",
    params: params
  });
}
function list(params) {
  return (0, _request.default)({
    url: "/member/counselorOrderTask/list",
    method: "get",
    params: params
  });
}
function hasUnfinished(params) {
  return (0, _request.default)({
    url: "/member/counselorOrderTask/hasUnfinished",
    method: "post",
    params: params
  });
}
function updateStatus(params) {
  return (0, _request.default)({
    url: "/member/counselorOrderTask/updateStatus",
    method: "post",
    params: params
  });
}