"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
//参数:{pageId},不传代表读首页
function getInfo(params) {
  return (0, _request.default)({
    url: '/enterprise/getInfo',
    method: 'get',
    params: params
  });
}