"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _prompt = _interopRequireDefault(require("./prompt"));
var _counselorOrder = require("../../../api/counselorOrder");
var _utils = _interopRequireDefault(require("../../../utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      clicked: false,
      timeIndex: '',
      isSpeciality: true,
      // specialityList:[],
      label: [{
        name: '婚姻家庭'
      }, {
        name: '性心理'
      }, {
        name: '恋爱心理'
      }],
      rules: {
        basisName: [{
          required: true,
          message: '请填写姓名',
          trigger: 'blur'
        }],
        basisPhone: [{
          required: true,
          message: '请填写电话',
          trigger: 'blur'
        }],
        basisAge: [{
          required: true,
          message: '请填写年龄',
          trigger: 'blur'
        }],
        basisGender: [{
          required: true,
          message: '请填写性别',
          trigger: 'blur'
        }],
        emergencyName: [{
          required: true,
          message: '请填写姓名',
          trigger: 'blur'
        }],
        emergencyPhone: [{
          required: true,
          message: '请填写电话',
          trigger: 'blur'
        }],
        emergencyRelationship: [{
          required: true,
          message: '请填写联关系',
          trigger: 'blur'
        }]
      },
      counselorOrder: {
        textarea1: '',
        textarea2: '',
        problemType: '',
        basisName: '',
        basisPhone: '',
        basisAge: '',
        basisGender: '',
        emergencyName: '',
        emergencyPhone: '',
        emergencyRelationship: ''
      },
      promptType: false,
      title: '为什么要填写真实资料',
      promptData: [{
        title: '',
        subTitle: '1.填写真实信息有助于在出现法律纠纷的情况下，保障来访者的合法权益。'
      }, {
        title: '',
        subTitle: '2.若来访者未满18周岁，请承诺在预约之前已经征得其监护人同意。'
      }, {
        title: '',
        subTitle: '3.来访者的个人信息将被严格保密。'
      }, {
        title: '',
        subTitle: '4.填写真实的紧急联系人信息，能在出现法律纠纷，来访者自伤，来访者伤害他人等情况时保障来访者的安全和合法权益。'
      }, {
        title: '',
        subTitle: '5.若随意填写，咨询师有权利拒绝该次预约咨询。'
      }, {
        title: '',
        subTitle: '6.紧急联系人等个人信息将被严格保密。'
      }, {
        title: '',
        subTitle: '7.咨询师会对整个咨询过程严格保密，若无危机情况，咨询师承诺不会联系紧急联系人。'
      }]
    };
  },
  components: {
    prompt: _prompt.default
  },
  props: ['orderId', 'mchCounselorId'],
  created: function created() {
    if (!_utils.default.isNull(this.$route.query.id)) {
      console.log(this.$route.query.id);
      this.orderId = this.$route.query.id;
    }
    if (!_utils.default.isNull(this.$route.query.orderStatus)) {
      console.log(this.$route.query.orderStatus);
      this.orderStatus = this.$route.query.orderStatus;
    }
    if (!_utils.default.isNull(this.$route.query.mchCounselorId)) {
      console.log(this.$route.query.mchCounselorId);
      this.mchCounselorId = this.$route.query.mchCounselorId;
    }
  },
  methods: {
    choseProblemType: function choseProblemType(item, index) {
      this.counselorOrder.problemType = item;
      this.timeIndex = index;
    },
    // attributesList(){
    //    this.attributes.forEach(item=>{
    //      if (item.dictType==='speciality'){
    //        this.specialityList.push(item.dictName)
    //      }
    //    })
    // },
    nextClick: function nextClick(counselorOrder) {
      var _this2 = this;
      var _this = this;
      this.$refs[counselorOrder].validate(function (vail) {
        if (vail) {
          _this2.clicked = true;
          var counselor = {
            id: _this2.orderId,
            mchCounselorId: _this2.mchCounselorId,
            expectResult: _this2.counselorOrder.textarea2,
            problemType: _this2.counselorOrder.problemType,
            problemDescr: _this2.counselorOrder.textarea1,
            selfAge: _this2.counselorOrder.basisAge,
            selfGender: _this2.counselorOrder.basisGender,
            selfPhone: _this2.counselorOrder.basisPhone,
            selfRealName: _this2.counselorOrder.basisName,
            urgentPhone: _this2.counselorOrder.emergencyPhone,
            urgentRealName: _this2.counselorOrder.emergencyName,
            urgentRelation: _this2.counselorOrder.emergencyRelationship,
            orderStatus: '1'
          };
          (0, _counselorOrder.update)(counselor).then(function (response) {
            _this2.clicked = false;
            console.log(_this.$route.query.orderStatus);
            if (_utils.default.isNull(_this.$route.query.orderStatus)) {
              console.log('1111');
              _this2.$emit('nextClick', 2, response.data.id);
            } else {
              console.log('222222');
              _this2.$router.push({
                path: '/counselor/third/' + _this2.orderId,
                query: {
                  id: _this2.orderId,
                  orderStatus: '2',
                  mchCounselorId: _this2.mchCounselorId
                }
              });
            }
          });
        } else {
          _this2.$message({
            message: '请完善信息',
            type: 'warning'
          });
        }
      });
    },
    openClick: function openClick() {
      this.promptType = !this.promptType;
    },
    downClick: function downClick() {
      this.promptType = false;
    }
  }
};