var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "steps_header" },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, "align-center": "" } },
            [
              _c("el-step", { attrs: { title: "预约下单" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "完善信息" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "咨询确认" } }),
              _vm._v(" "),
              _c("el-step", { attrs: { title: "完成咨询" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_vm.componentName, {
        tag: "component",
        attrs: {
          attributes: _vm.attributes,
          appointmentDate: _vm.appointmentDate,
          orderId: _vm.orderId,
          appointmentHour: _vm.appointmentHour,
          counselorMethod: _vm.counselorMethod,
          dateIndex: _vm.dateIndex,
          timeIndex: _vm.timeIndex,
          mchCounselorId: _vm.mchCounselorId,
          serviceName: _vm.serviceName,
          serviceType: _vm.serviceType,
          servicePrice: _vm.servicePrice,
          serviceTime: _vm.serviceTime,
          limitNum: _vm.limitNum,
          firstDiscount: _vm.firstDiscount,
          counselorBusinessId: _vm.counselorBusinessId,
        },
        on: { nextClick: _vm.nextClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }