var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "counselor-task__category" },
      _vm._l(_vm.taskCategory, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "counselor-task__category-item",
            class: {
              "counselor-task__category-item--active":
                _vm.categoryActiveId == item.id,
            },
            on: {
              click: function ($event) {
                return _vm.categoryClick(item)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(item.tit) + "\n    ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "counselor-task__body" }, [
      _c("div", { staticClass: "counselor-task__prompt" }, [
        _c("div", { staticClass: "counselor-task__prompt-tit" }, [
          _vm._v("咨询师向您发起了任务"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "counselor-task__prompt-number" }, [
          _c("span", [_vm._v(_vm._s(_vm.taskUndone.length) + " ")]),
          _vm._v(" / " + _vm._s(_vm.taskData.length) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _vm.taskStatus == 11
        ? _c(
            "div",
            _vm._l(_vm.taskData, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "counselor-task__item" },
                [
                  _c("div", { staticClass: "counselor-task__item-tit" }, [
                    _vm._v("\n          " + _vm._s(item.title) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-task__item-prompt" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.subTitle) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.materialType
                    ? _c("div", { staticClass: "counselor-task__item__main" }, [
                        _c(
                          "div",
                          { staticClass: "counselor-task__item__main-img" },
                          [
                            _c("img", {
                              attrs: { src: item.material.thumbnail, alt: "" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "counselor-task__item__main-right" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item__main-right-tit",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.material.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item__main-right-subtit",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.material.subTitle) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-task__item-botm" }, [
                    _c(
                      "div",
                      { staticClass: "counselor-task__item-botm__time" },
                      [
                        _c("span", [_vm._v("发布时间：")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("watchData")(item.createDate)) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "counselor-task__item-botm__btn" },
                      [
                        item.type != 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item-botm__btn-jump counselor-task__btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.goLearn(item)
                                  },
                                },
                              },
                              [
                                item.type == 1
                                  ? _c("span", [_vm._v("去测试")])
                                  : _c("span", [_vm._v("去学习")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-task__item-botm__btn-over counselor-task__btn",
                          },
                          [
                            item.status == 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "undone",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickFinished(item)
                                      },
                                    },
                                  },
                                  [_vm._v("完成打卡")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.status == 1
                              ? _c("span", { staticClass: "finished" }, [
                                  _vm._v("已完成"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.taskStatus == 12
        ? _c(
            "div",
            _vm._l(_vm.taskUndone, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "counselor-task__item" },
                [
                  _c("div", { staticClass: "counselor-task__item-tit" }, [
                    _vm._v("\n          " + _vm._s(item.title) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-task__item-prompt" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.subTitle) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.materialType
                    ? _c("div", { staticClass: "counselor-task__item__main" }, [
                        _c(
                          "div",
                          { staticClass: "counselor-task__item__main-img" },
                          [
                            _c("img", {
                              attrs: { src: item.material.thumbnail, alt: "" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "counselor-task__item__main-right" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item__main-right-tit",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.material.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item__main-right-subtit",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.material.subTitle) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-task__item-botm" }, [
                    _c(
                      "div",
                      { staticClass: "counselor-task__item-botm__time" },
                      [
                        _c("span", [_vm._v("发布时间：")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("watchData")(item.createDate)) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "counselor-task__item-botm__btn" },
                      [
                        item.type != 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item-botm__btn-jump counselor-task__btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.goLearn(item)
                                  },
                                },
                              },
                              [
                                item.type == 1
                                  ? _c("span", [_vm._v("去测试")])
                                  : _c("span", [_vm._v("去学习")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-task__item-botm__btn-over counselor-task__btn",
                          },
                          [
                            item.status == 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "undone",
                                    on: {
                                      click: function ($event) {
                                        return _vm.clickFinished(item)
                                      },
                                    },
                                  },
                                  [_vm._v("完成打卡")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.status == 1
                              ? _c("span", { staticClass: "finished" }, [
                                  _vm._v("已完成"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.taskStatus == 13
        ? _c(
            "div",
            _vm._l(_vm.taskFinished, function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "counselor-task__item" },
                [
                  _c("div", { staticClass: "counselor-task__item-tit" }, [
                    _vm._v("\n          " + _vm._s(item.title) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-task__item-prompt" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.subTitle) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.materialType
                    ? _c("div", { staticClass: "counselor-task__item__main" }, [
                        _c(
                          "div",
                          { staticClass: "counselor-task__item__main-img" },
                          [
                            _c("img", {
                              attrs: { src: item.material.thumbnail, alt: "" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "counselor-task__item__main-right" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item__main-right-tit",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.material.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item__main-right-subtit",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.material.subTitle) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-task__item-botm" }, [
                    _c(
                      "div",
                      { staticClass: "counselor-task__item-botm__time" },
                      [
                        _c("span", [_vm._v("发布时间：")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("watchData")(item.createDate)) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "counselor-task__item-botm__btn" },
                      [
                        item.type != 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-task__item-botm__btn-jump counselor-task__btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.goLearn(item)
                                  },
                                },
                              },
                              [
                                item.type == 1
                                  ? _c("span", [_vm._v("去测试")])
                                  : _c("span", [_vm._v("去学习")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._m(0, true),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "counselor-task__item-botm__btn-over counselor-task__btn",
      },
      [_c("span", { staticClass: "finished" }, [_vm._v("已完成")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }