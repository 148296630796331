var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "counselor-info" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "counselor-info__avatar" }, [
          _c("img", { attrs: { src: _vm.counselorData.avatar, alt: "" } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "counselor-info__right" }, [
          _c("div", { staticClass: "counselor-info__right__top" }, [
            _c("div", { staticClass: "counselor-info__name" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.counselorData.name) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _vm.counselorData.voice
              ? _c(
                  "div",
                  {
                    staticClass: "counselor-info__audio",
                    on: { click: _vm.audioClick },
                  },
                  [
                    _c("audio", {
                      staticClass: "counselor-info__audio-cont",
                      attrs: { src: _vm.counselorData.voice },
                    }),
                    _vm._v(" "),
                    _vm.audioPlay == false
                      ? _c(
                          "div",
                          { staticClass: "counselor-info__audio__icon" },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__audio__icon.png",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.audioPlay == true
                      ? _c(
                          "div",
                          {
                            staticClass: "counselor-info__audio__icon",
                            staticStyle: { width: "3.5vw", height: "4.933vw" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__audio__icon-gif.gif",
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "counselor-info__audio__text" }, [
                      _vm._v("咨询师说"),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "counselor-info__right__grade" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.counselorData.spots) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "counselor-info__right__box" }, [
            _c(
              "div",
              { staticClass: "counselor-info__right__box__area-name" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "counselor-info__right__box__area-name_name" },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.counselorData.areaName) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "counselor-info__right__box__counselor-method" },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "counselor-info__right__box__counselor-method_name",
                  },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm._f("watchMethod")(
                            _vm.counselorData.counselorMethod
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "counselor-info__right__time" }, [
            _c("div", { staticClass: "counselor-info__right__time__year" }, [
              _c("span", [_vm._v(_vm._s(_vm.counselorData.experienceYears))]),
              _vm._v("\n            从业年限(年)\n          "),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "counselor-info__right__time__partition",
            }),
            _vm._v(" "),
            _c("div", { staticClass: "counselor-info__right__time__hour" }, [
              _c("span", [_vm._v(_vm._s(_vm.counselorData.experienceHours))]),
              _vm._v("\n            个案时长(小时)\n          "),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.counselorDetailShow == false
        ? _c(
            "div",
            {
              staticClass: "counselor-info__detail",
              on: { click: _vm.counselorDetailOpen },
            },
            [
              _c("div", { staticClass: "counselor-info__detail__text" }, [
                _vm._v("查看详细介绍"),
              ]),
              _vm._v(" "),
              _vm._m(3),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.counselorDetailShow == true
        ? _c("div", { staticClass: "counselor-detail" }, [
            _c("div", { staticClass: "counselor-detail__tit" }, [
              _vm._v("咨询师介绍"),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "counselor-detail__cont",
              domProps: { innerHTML: _vm._s(_vm.counselorData.content) },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "counselor-detail__off",
                on: { click: _vm.counselorDetailOff },
              },
              [_vm._m(4)]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.counselorDetailShow == true
        ? _c("div", { staticClass: "counselor-indo__body-fal" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.counselorDetailShow == false,
              expression: "counselorDetailShow == false",
            },
          ],
          staticClass: "counselor-info__body",
        },
        [
          _c(
            "div",
            { staticClass: "counselor-info__category" },
            _vm._l(_vm.category, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "counselor-info__category__item",
                  class: {
                    "counselor-info__category__item--active":
                      _vm.activeCategoryId == item.id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.infoCategoryClick(item)
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(item.tit) + "\n        ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "counselor-info__category__second" },
            _vm._l(_vm.categorySecond, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "counselor-info__category__second__item",
                  class: {
                    "counselor-info__category__second__item--active":
                      _vm.activeSecondCategoryId == item.id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.infoSecondCategoryClick(item)
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "counselor-info__service counselor-info__body-cont",
            },
            [
              _vm._l(_vm.serviceData, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "counselor-info__service__item",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "counselor-info__service__item__cont" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-info__service__item__cont-tit",
                          },
                          [
                            item.type == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "counselor-info__service__item__cont-tit-span",
                                  },
                                  [
                                    _vm._v(
                                      "\n                套餐\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(item.title) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-info__service__item__cont-bottom",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-info__service__item__cont-bottom__price",
                              },
                              [
                                _c("span", [_vm._v("￥ ")]),
                                _vm._v(_vm._s(item.price) + "\n              "),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-info__service__item__cont-bottom__time",
                              },
                              [
                                _c("span", [_vm._v("/")]),
                                _vm._v(
                                  " " +
                                    _vm._s(item.duration) +
                                    "分钟\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "counselor-info__service__item__btn",
                        on: {
                          click: function ($event) {
                            return _vm.toReservation(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-info__service__item__btn-txt",
                          },
                          [_vm._v("预约")]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.serviceData == ""
                ? _c("div", { staticClass: "counselor-info__service--air" }, [
                    _vm._v("\n          暂无服务\n        "),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "counselor-info__case" }, [
            _vm.caseData != ""
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "counselor-info__case__tit counselor-info__body-tit",
                    },
                    [_vm._v("\n            案例分享\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "counselor-info__case__cont" },
                    _vm._l(_vm.caseData, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "counselor-info__case__cont__item",
                          on: {
                            click: function ($event) {
                              return _vm.toCase(item)
                            },
                          },
                        },
                        [
                          _vm._m(5, true),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__case__cont__item-time",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm._f("watchData")(item.createDate)) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__case__cont__item-tit",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.title) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__case__cont__item-txt",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.content) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "counselor-info__case__declare" }, [
                    _vm._v(
                      "\n            【声明】本人承诺所有案例分享均已获得来访者同意\n          "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.caseData != ""
            ? _c("div", {
                staticStyle: { height: "10px", "background-color": "#f4f4f4" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "counselor-info__assess" }, [
            _vm.assessData != ""
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "counselor-info__assess__tit counselor-info__body-tit",
                    },
                    [_vm._v("\n            用户评价\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "counselor-info__assess__cont" },
                    _vm._l(_vm.assessData, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "counselor-info__assess__cont__item",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__assess__cont__item-name",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.memberName) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__assess__cont__item-tim",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm._f("watchData")(item.createDate)) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__assess__cont__item-tit",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.businessCategoryName) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__assess__cont__item-text",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.content) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.assessData != ""
            ? _c("div", {
                staticStyle: { height: "10px", "background-color": "#f4f4f4" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "counselor-info__activity counselor-info__body-cont",
            },
            [
              _vm.activityData != ""
                ? _c(
                    "div",
                    {
                      staticClass: "counselor-info__activity__item-box",
                      class: {
                        "counselor-info__activity__item-more": _vm.activityOpen,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "counselor-info__activity__tit counselor-info__body-tit",
                        },
                        [_vm._v("\n            推荐文章\n          ")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.activityData, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "counselor-info__activity__item",
                            on: {
                              click: function ($event) {
                                return _vm.activityClick(item.id)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-info__activity__item-img",
                              },
                              [
                                _c("img", {
                                  attrs: { src: item.thumbnail, alt: "" },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "counselor-info__activity__item-cont",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "counselor-info__activity__item-cont__tit",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.title) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "counselor-info__activity__item-cont__text",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.subTitle) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.activityData != ""
            ? _c("div", { staticClass: "counselor-info__activity__more" }, [
                _c(
                  "div",
                  {
                    staticClass: "counselor-info__activity__more-text",
                    on: { click: _vm.activityMore },
                  },
                  [_vm._v("\n          查看更多\n        ")]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activityData != ""
            ? _c("div", {
                staticStyle: { height: "10px", "background-color": "#f4f4f4" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "counselor-info__recom counselor-info__body-cont" },
            [
              _vm.recomData != ""
                ? _c(
                    "div",
                    { staticClass: "counselor-info__recom__item-box" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "counselor-info__recom__tit counselor-info__body-tit",
                        },
                        [_vm._v("\n            我的推荐\n          ")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.recomData, function (item, index) {
                        return index < 3 || _vm.recomOpen == true
                          ? _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "counselor-info__recom__item",
                                on: {
                                  click: function ($event) {
                                    return _vm.recomClick(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "counselor-info__recom__talk",
                                  },
                                  [
                                    _c("span", [_vm._v("推荐语：")]),
                                    _vm._v(
                                      _vm._s(item.recommend) + "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "counselor-info__recom__main",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counselor-info__recom__main__img",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: item.material.thumbnail,
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counselor-info__recom__main__cont",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "counselor-info__recom__main__cont-tit",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.material.title) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "counselor-info__recom__main__cont-subtit",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(item.material.subTitle) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "counselor-info__recom__main__cont-skill",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("watchRecomType")(
                                                    item.material.type
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                item.material.type == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counselor-info__recom__but",
                                      },
                                      [
                                        _vm._v(
                                          "\n              去测试\n            "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "counselor-info__recom__but",
                                      },
                                      [_c("div", [_vm._v("去学习")])]
                                    ),
                              ]
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "counselor-info__recom__more" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "counselor-info__recom__more-text",
                              on: { click: _vm.recomMore },
                            },
                            [_vm._v("\n              查看更多\n            ")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "el-icon-arrow-right" }),
                        ]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.recomData != ""
            ? _c("div", {
                staticStyle: { height: "10px", "background-color": "#f4f4f4" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._m(6),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "counselor-info__bottom-category" }, [
            _c(
              "div",
              {
                staticClass: "counselor-info__bottom-category__task",
                on: { click: _vm.toTask },
              },
              [
                _vm.unfinished == true
                  ? _c("div", {
                      staticClass:
                        "counselor-info__bottom-category__task-unfinished",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(8),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "counselor-info__bottom-category__task-text" },
                  [_vm._v("咨询任务")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "counselor-info__bottom-category__btn" }, [
              _c(
                "div",
                {
                  staticClass:
                    "\n              counselor-info__bottom-category__btn__service\n              bottom-category__btn\n            ",
                  on: {
                    click: function ($event) {
                      _vm.servicePopShow = true
                    },
                  },
                },
                [_vm._v("\n            咨询与预约\n          ")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.toTopShow
        ? _c(
            "div",
            { staticClass: "counselor-info__to-top", on: { click: _vm.toTop } },
            [
              _c("img", {
                attrs: {
                  src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__to-top.png",
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.servicePopShow
        ? _c(
            "div",
            {
              staticClass: "counselor-info__service-pop",
              on: {
                touchmove: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("div", { staticClass: "counselor-info__service-pop__blank" }, [
                _c(
                  "div",
                  { staticClass: "counselor-info__service-pop__main" },
                  [
                    _c(
                      "div",
                      { staticClass: "counselor-info__service-pop__main-tit" },
                      [_vm._v("专属顾问")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "counselor-info__service-pop__main-phone",
                      },
                      [
                        _vm._v("\n            顾问电话："),
                        _c(
                          "a",
                          {
                            staticStyle: { color: "rgba(64, 158, 252, 1)" },
                            attrs: { href: "tel:" + _vm.phone },
                          },
                          [_vm._v(_vm._s(_vm.phone))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "counselor-info__service-pop__main-btn" },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "rgba(64, 158, 252, 1)" },
                            attrs: { href: "tel:" + _vm.phone },
                          },
                          [_vm._v("拨打电话")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "counselor-info__service-pop__main-vx" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-info__service-pop__main-vx-tit",
                          },
                          [_vm._v("\n              顾问微信：\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "counselor-info__service-pop__main-vx-img",
                          },
                          [_c("img", { attrs: { src: _vm.qrcode, alt: "" } })]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "counselor-info__service-pop__off",
                        on: {
                          click: function ($event) {
                            _vm.servicePopShow = false
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          staticClass: "popup-box",
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "lt" }, [_vm._v("咨询服务")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "rt",
                on: {
                  click: function ($event) {
                    _vm.show = false
                  },
                },
              },
              [_c("van-icon", { attrs: { name: "clear", size: "20px" } })],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "body-box" }, [
            _c(
              "div",
              {
                staticClass:
                  "counselor-info__service counselor-info__body-cont",
              },
              [
                _vm._l(_vm.serviceData, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "counselor-info__service__item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "counselor-info__service__item__cont" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__service__item__cont-tit",
                            },
                            [
                              item.type == 2
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "counselor-info__service__item__cont-tit-span",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  套餐\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.title) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__service__item__cont-bottom",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "counselor-info__service__item__cont-bottom__price",
                                },
                                [
                                  _c("span", [_vm._v("￥ ")]),
                                  _vm._v(
                                    _vm._s(item.price) + "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "counselor-info__service__item__cont-bottom__time",
                                },
                                [
                                  _c("span", [_vm._v("/")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(item.duration) +
                                      "分钟\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "counselor-info__service__item__btn",
                          on: {
                            click: function ($event) {
                              return _vm.toReservation(item)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "counselor-info__service__item__btn-txt",
                            },
                            [_vm._v("预约")]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.serviceData == ""
                  ? _c("div", { staticClass: "counselor-info__service--air" }, [
                      _vm._v("\n            暂无服务\n          "),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "counselor-info__bgc" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-detail__bgc.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "counselor-info__right__box__area-name_icon" },
      [
        _c("img", {
          attrs: {
            src: "\nhttps://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__right__box__area-name_icon.png",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "counselor-info__right__box__counselor-method_icon" },
      [
        _c("img", {
          attrs: {
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__right__box__counselor-method_icon.png",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "counselor-info__detail__icon" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__detail__icon.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "counselor-detail__off__icon" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-detail__off__icon-false.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "counselor-info__case__cont__item-bgc" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__case__cont__item-bgc.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "counselor-info__notice counselor-info__body-cont" },
      [
        _c(
          "div",
          {
            staticClass: "counselor-info__notice__tit counselor-info__body-tit",
          },
          [_vm._v("\n          咨询须知\n        ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "counselor-info__notice__cont" }, [
          _c("div", [
            _vm._v(
              "\n            1、咨询为预约式服务，您可选择咨询师可约时间段，预约下单。\n            每次咨询时长以30或50分钟为单位。\n          "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n            2、订单支付成功之后，咨询助理将会来电和您确认咨询时间、方 式。\n          "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n            3、平台咨询方式分为线上、面对面两种形式，具体订单的咨询形\n            式依据咨询师展示的可选方式选择。\n          "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n            4、若因不可抗力因素，导致需要变更或取消已协商好的咨询预约，\n            请提前24小时告知咨询师更改服务时间，以免耽误后续预约时间。\n          "
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "counselor-info__ad" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__ad.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "counselor-info__bottom-category__task-img" },
      [
        _c("img", {
          attrs: {
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-info__bottom-category__task-img.png",
            alt: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }