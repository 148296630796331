var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "column-stage-detail-hd" }, [
      _c("div", { staticClass: "column-stage-detail-hd__tit" }, [
        _vm._v(_vm._s(_vm.columnName)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column-stage-detail-hd__stage" }, [
        _c("div", { staticClass: "point-left" }),
        _vm._v(" "),
        _c("div", { staticClass: "column-stage-detail-hd__stage-name" }, [
          _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.columncatalog) + "\n\t\t\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "point-right" }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column-stage-detail-bd" },
      [
        _c("div", { staticClass: "column-stage-detail-hd__summary" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "column-stage-detail-hd__summary-name" }, [
            _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.courseTitle) + "\n\t\t\t\t"),
          ]),
        ]),
        _vm._v(" "),
        _vm._l(_vm.courseItemlist, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "column-stage-detail-bd__list",
              on: {
                click: function ($event) {
                  return _vm.goColumnSubjectStage(item)
                },
              },
            },
            [
              !item.tag
                ? _c(
                    "div",
                    { staticClass: "column-stage-detail-bd__list-type" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm._f("watchType")(item.tagType)) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              item.tag
                ? _c(
                    "div",
                    { staticClass: "column-stage-detail-bd__list-type" },
                    [_vm._v("\n\t\t\t\t\t" + _vm._s(item.tag) + "\n\t\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "column-stage-detail-bd__list-tit",
                  style: { color: item.status == 1 ? "#999" : "" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(item.orders) +
                      "." +
                      _vm._s(item.title) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-stage-detail-hd__summary-icon" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/columnSDSummary.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }