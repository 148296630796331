import { render, staticRenderFns } from "./search_focus.vue?vue&type=template&id=4b3933f4&scoped=true"
import script from "./search_focus.vue?vue&type=script&lang=js"
export * from "./search_focus.vue?vue&type=script&lang=js"
import style0 from "./search_focus.vue?vue&type=style&index=0&id=4b3933f4&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b3933f4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\28658\\Desktop\\美心数科开发\\gitee\\mxs-online-h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b3933f4')) {
      api.createRecord('4b3933f4', component.options)
    } else {
      api.reload('4b3933f4', component.options)
    }
    module.hot.accept("./search_focus.vue?vue&type=template&id=4b3933f4&scoped=true", function () {
      api.rerender('4b3933f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/index/components/search/search_focus.vue"
export default component.exports