"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      shareTitle: "",
      shareName: "",
      shareTime: "",
      hits: "",
      shareContent: ""
    };
  },
  filters: {
    watchData: function watchData(date) {
      date = new Date(date);
      var fmt = "YYYY/mm/dd";
      var ret;
      var opt = {
        "Y+": date.getFullYear().toString(),
        // 年
        "m+": (date.getMonth() + 1).toString(),
        // 月
        "d+": date.getDate().toString() // 日
        // "H+": date.getHours().toString(), // 时
        // "M+": date.getMinutes().toString() // 分
      };
      for (var k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
        }
      }
      return fmt;
    }
  },
  created: function created() {
    this.shareTitle = this.$route.query.title;
    this.shareName = this.$route.query.shareName;
    this.shareTime = this.$route.query.shareTime;
    this.hits = this.$route.query.hits;
    this.shareContent = this.$route.query.content;
  },
  methods: {
    goBack: function goBack() {
      window.history.go(-1);
    }
  }
};