"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _courseItem = require("@/api/courseItem");
var _evaluation = require("@/api/evaluation");
var _enrollOrder = require("@/api/enrollOrder");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "",
  props: {},
  data: function data() {
    return {
      courseId: "",
      courseItemlist: [],
      columnName: "",
      columncatalog: "",
      courseTitle: "",
      courseOrderId: "",
      pageNum: 1,
      pageSize: 100
    };
  },
  filters: {
    watchType: function watchType(val) {
      if (val == 3) {
        return "视频";
      } else if (val == 2) {
        return "音频";
      } else if (val == 1) {
        return "图文";
      } else if (val == 0) {
        return "量表";
      } else if (val == 4) {
        return "活动";
      } else if (val == 5) {
        return "文章";
      }
    } // watchCantry(val) {
    //   if (val == 0) {
    //     return '付费解锁'
    //   } else if (val == 1) {
    //     return '试听'
    //   } else if (val == 2) {
    //     return '分享解锁'
    //   }
    // },
  },
  // 方法集合
  methods: {
    // stageDatailShow(item) {
    //   item.status = !item.status;
    // },
    goColumnSubjectStage: function goColumnSubjectStage(item) {
      var _this = this;
      if (item.status == 0) {
        var params = new URLSearchParams();
        params.append("itemId", item.id);
        params.append("status", "1");
        (0, _courseItem.updateStatus)(params).then(function (res) {}).catch(function (err) {
          console.log(err);
        });
      }
      if (item.tagType == 0) {
        _this.courseCreateClick(item.materialId);
      } else if (item.tagType == 4) {
        _this.courseCreateEnrollOrder(item.materialId);
      } else if (item.tagType == 5) {
        _this.$router.push({
          path: "/activitySy/details",
          query: {
            activityId: item.materialId
          }
        });
      } else {
        this.$router.push({
          path: "/column/columnSubjectStudy",
          query: {
            type: item.tagType,
            id: item.id
          }
        });
      }
    },
    courseItem: function courseItem() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("courseId", _this.courseId);
      (0, _courseItem.list)(params).then(function (response) {
        console.log(response);
        _this.courseItemlist = response.data.data;
      }).catch(function (error) {});
    },
    courseCreateClick: function courseCreateClick(materialId) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("courseOrderId", _this.courseOrderId);
      params.append("gaugeId", materialId);
      (0, _evaluation.courseCreate)(params).then(function (response) {
        console.log(response);
        _this.$router.push({
          path: "/gauge/answer",
          query: {
            evaluationId: response.data.evaluation.id
          }
        });
      }).catch(function (error) {});
    },
    courseCreateEnrollOrder: function courseCreateEnrollOrder(materialId) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("courseOrderId", _this.courseOrderId);
      params.append("articleId", materialId);
      (0, _enrollOrder.courseCreateEnroll)(params).then(function (res) {
        console.log(res);
        _this.$router.push({
          path: "/article/index",
          query: {
            articleId: materialId
          }
        });
      }).catch(function (error) {});
    }
  },
  // 计算属性
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId", "userId", "xuid", "token"])),
  // 侦听
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created: function created() {
    this.courseId = this.$route.query.courseId;
    this.columnName = this.$route.query.columnName;
    this.columncatalog = this.$route.query.columncatalog;
    this.courseTitle = this.$route.query.courseTitle;
    this.courseOrderId = this.$route.query.courseOrderId;
    this.courseItem();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted: function mounted() {},
  // 生命周期 - 创建之前
  beforeCreate: function beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount: function beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate: function beforeUpdate() {},
  // 生命周期 - 更新之后
  updated: function updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy: function beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed: function destroyed() {},
  // 如果页面有keep-alive缓存功能，该函数会触发
  activated: function activated() {}
};