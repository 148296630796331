var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "center-title" }, [
            _c("div", { staticClass: "title-left" }, [
              _c("img", { attrs: { src: _vm.memberInfo.avatar, alt: "" } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.memberInfo.name))]),
              _vm._v(" "),
              _c("span", [_vm._v("推广员-学员")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "center-banner" }, [
            _c("div", { staticClass: "banner-center" }, [
              _c("span", [_vm._v("钱包余额(元)")]),
              _vm._v(" "),
              _c("div", { staticClass: "banner-center-top" }, [
                _c("span", [_vm._v(_vm._s(_vm.balance))]),
                _vm._v(" "),
                _c("span", { on: { click: _vm.cashClick } }, [
                  _vm._v("立即提现"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "banner-center-bottom" }, [
                _c("div", { on: { click: _vm.memberDealClick } }, [
                  _c("span", [_vm._v("累计客户(人)")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("filterCont")(_vm.member))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.closingOrderClick } }, [
                  _c("span", [_vm._v("累计收益(元)")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("filterCont")(_vm.rebate))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.closingOrderClick } }, [
                  _c("span", [_vm._v("累计订单(笔)")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm._f("filterCont")(_vm.order)))]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.productList, function (productItem) {
            return _c(
              "div",
              { key: productItem.id, staticClass: "goods-list" },
              [
                _c("span", { staticClass: "goods-list-title" }, [
                  _vm._v(_vm._s(productItem.title)),
                ]),
                _vm._v(" "),
                _vm._l(productItem.itemList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "goods-item",
                      on: {
                        click: function ($event) {
                          return _vm.promoteClick(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "goods-list-img" }, [
                        _c("img", { attrs: { src: item.thumbnail, alt: "" } }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-right" }, [
                        _c("span", { staticClass: "item-title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "pric" }, [
                          _vm._v(
                            _vm._s(productItem.title.substring(2, 4)) +
                              "：¥" +
                              _vm._s(item.price ? item.price : item.amount)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "promote" }, [
                          _c("div", [
                            _c("span", [
                              _vm._v("可赚佣金："),
                              _c("span", [
                                _vm._v(
                                  "¥" +
                                    _vm._s(
                                      _vm._f("filtersNumber")(
                                        item.price
                                          ? item.price * _vm.percentage * 0.01
                                          : item.amount * _vm.percentage * 0.01
                                      )
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "promote-btn" }, [
                            _vm._v("推广"),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("center-bottom"),
      _vm._v(" "),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }