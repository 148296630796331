"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Layout',
  components: {
    Navbar: _components.Navbar,
    AppMain: _components.AppMain
  },
  mixins: [_ResizeHandler.default],
  computed: {
    device: function device() {
      return this.$store.state.app.device;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj: function classObj() {
      return {
        mobile: this.device === 'mobile'
      };
    }
  },
  methods: {}
};