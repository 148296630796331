var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "15px 0", "background-color": "#fafafa" } },
    [
      _c("div", { staticClass: "activityQy_head" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "activityQy_head_tit" }, [
          _c("div", { staticClass: "activityQy_head_tit_txt" }, [
            _vm._v("\n        " + _vm._s(_vm.content.title) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "activityQy-head__change-moer",
            on: { click: _vm.activityComponentchange },
          },
          [
            _c("span", [_vm._v("更多")]),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "activityQy_body" },
        _vm._l(_vm.activityOrderForm, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "activityQy_info",
              on: {
                click: function ($event) {
                  return _vm.goActivityDetails(item.id)
                },
              },
            },
            [
              _c("div", { staticClass: "activityComponent_info_img" }, [
                _c("img", { attrs: { src: item.thumbnail, alt: "" } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "activityQy_info_title" }, [
                _c("div", { staticClass: "activityQy_info_tit" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "activityQy_info_subtit" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "activityQy_head_bg" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/activityComponent-bg.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }