"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.starts-with");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../utils/utils.js"));
var _user = require("@/api/user");
var _gaugePlan = require("@/api/gaugePlan");
var _serviceLogin = _interopRequireDefault(require("./serviceLogin.vue"));
var _evalLogin = _interopRequireDefault(require("./evalLogin.vue"));
var _ysdLogin = _interopRequireDefault(require("./ysdLogin.vue"));
var _infoCollect = _interopRequireDefault(require("./common/infoCollect.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  components: {
    serviceLogin: _serviceLogin.default,
    evalLogin: _evalLogin.default,
    ysdLogin: _ysdLogin.default,
    autoLogin: _infoCollect.default
  },
  data: function data() {
    return {
      // 570100001
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          message: '请输入账号'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: '请输入密码'
        }]
      },
      loading: false,
      redirect: undefined,
      title: '欢迎使用心理测评系统',
      setName: '',
      setCode: '',
      system: process.env.VUE_APP_BASE_API,
      record: (process.env.VUE_APP_RECORD || 1) == 1
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId', 'isH5', 'token'])),
  created: function created() {
    console.log(this.isH5);
    //this.loginDetermine()
    this.getMchInfo();
  },
  watch: {
    $route: {
      handler: function handler(route) {
        /*this.redirect = route.query && route.query.redirect
        if (route.query.redirect) {
          sessionStorage.setItem('redirectUrl', route.query.redirect)
        }*/
        //this.redirect = utils.getUrlParameter('redirect') || '/'
        //if (this.redirect) {
        // this.redirect = utils.getUrlPathWithParameter(this.redirect)
        //sessionStorage.setItem('redirectUrl', this.redirect)
        //}

        this.redirect = _utils.default.getUrlParameter('redirect');
        if (!this.redirect) {
          this.redirect = route.query && route.query.redirect ? route.query.redirect : '' || '/';
          if (route.query && route.query.xuid) {
            this.redirect = this.redirect + '&xuid=' + route.query.xuid;
          }
        }
        if (this.redirect) {
          sessionStorage.setItem('redirectUrl', this.redirect);
        }
      },
      immediate: true
    }
  },
  methods: {
    loginDetermine: function loginDetermine() {
      // 微信授权登录成功以后，会莫名奇妙的跳转到这个页面来，因此在这里判断一下，如果是已经登录，并且是微信的话，直接跳转回原来的地址
      if (!!this.token && _utils.default.isWeiXin()) {
        var redirectUrl = sessionStorage.getItem('Auth-redirect') || '/index';
        if (redirectUrl.startsWith('http://') || redirectUrl.startsWith('https://')) {
          location.href = redirectUrl;
        } else {
          this.$router.replace({
            path: redirectUrl
          });
        }
      }
    },
    getMchInfo: function getMchInfo() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchId', this.mchId);
      (0, _gaugePlan.iframeGetSysMchTitleInfo)(params).then(function (res) {
        if (res.data.sysMchTitle) {
          _this.title = res.data.sysMchTitle.mchTitle;
          _this.setCode = res.data.sysMchTitle.mchCode || '密码';
          _this.setName = res.data.sysMchTitle.mchName || '账号';
        }
        _this.sysMchTitle = res.data.sysMchTitle;
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.$store.dispatch('user/loginPassword', _this2.loginForm).then(function () {
            // 登录类型 1：账号密码登录
            localStorage.setItem('loginType', 0);
            _this2.loading = false;
            (0, _user.getFinishData)().then(function (data) {
              localStorage.setItem('isAllFinish', data.data.isAllFinish);
              if (data.data.isAllFinish == 1) {
                var redirectUrl = _this2.redirect || '/';
                _this2.$router.push({
                  path: redirectUrl
                });
              } else {
                var redirectUrl = '/material' + (_this2.redirect ? '?redirect=' + _this2.redirect : '');
                _this2.$router.replace({
                  path: redirectUrl
                });
              }
            }).catch(function (err) {});
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};