"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.array.find");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _timeList = _interopRequireDefault(require("../timeList/timeList"));
var _counselor = require("../../../api/counselor");
var _utils = _interopRequireDefault(require("../../../utils/utils"));
var _counselorOrder = require("@/api/counselorOrder");
var _share = require("@/utils/share");
var _vuex = require("vuex");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "info",
  data: function data() {
    return {
      id: '',
      radio: "",
      phone: '',
      qrcode: '',
      specialityList: [],
      dataList: [],
      infoList: [],
      kfShow: false,
      sortList: [],
      isTimeList: false,
      isActivity: false,
      payType: false,
      counselorMethod: '',
      clock: '',
      day: '',
      clickList: [],
      isShow: false
    };
  },
  filters: {
    watchWeek: function watchWeek(val) {
      switch (val) {
        case 0:
          return "周天";
          break;
        case 1:
          return "周一";
          break;
        case 2:
          return "周二";
          break;
        case 3:
          return "周三";
          break;
        case 4:
          return "周四";
          break;
        case 5:
          return "周五";
          break;
        case 6:
          return "周六";
      }
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['userId', 'xuid', 'token'])),
  created: function created() {
    this.id = this.$route.query.id;
    this.loadPhone();
    this.getInfo();
    var nowDate = new Date();
    this.clock = nowDate.getHours();
    this.day = nowDate.getDate();
    this.getXuid();
  },
  components: {
    timeList: _timeList.default
  },
  methods: {
    loadPhone: function loadPhone() {
      var _this2 = this;
      (0, _counselor.getPhone)(this.id).then(function (res) {
        _this2.phone = res.data.phone;
        _this2.qrcode = res.data.qrcode;
      });
    },
    kefuClick: function kefuClick() {
      this.kfShow = true;
    },
    stopClick: function stopClick() {
      event.stopPropagation();
    },
    kfHidden: function kfHidden() {
      this.kfShow = false;
    },
    getXuid: function getXuid() {
      console.log(this.userId);
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch('user/setXuid', xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== '') {
        // 如果有token并且参数里有xuid
        // alert(this.xuid)
        (0, _member.lockSysUser)({
          'xuid': this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _counselor.find)(this.id).then(function (response) {
        console.log(response);
        response.data.counselor.calendarTable = eval('(' + response.data.counselor.calendarTable + ')');
        _this3.infoList = response.data.counselor;
        if (_this3.infoList.calendarTable) {
          _this3.infoList.calendarTable.forEach(function (item) {
            _this3.sortList.push(item.sort(_this3.paixu));
          });
        }
        _this3.infoList.calendarTable = _this3.sortList;
        response.data.counselor.attributes.forEach(function (item) {
          if (item.dictType === 'speciality') {
            _this3.specialityList.push(item.dictName);
          }
        });
        (0, _share.SHARE)(location.href, {
          title: response.data.counselor.name,
          desc: response.data.counselor.introduction,
          link: _utils.default.host() + "/#/counselor/info?id=".concat(_this3.id, "&xuid=").concat(_this3.userId),
          imgUrl: response.data.counselor.avatar
        }, function (res) {});
        _this3.getDate();
        _this3.checkExistsClick();
      });
    },
    paixu: function paixu(a, b) {
      a = a.split("-").shift().slice(0, 2);
      b = b.split("-").shift().slice(0, 2);
      return a - b;
    },
    checkExistsClick: function checkExistsClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchCounselorId', _this.id);
      (0, _counselorOrder.checkExists)(params).then(function (response) {
        if (!_utils.default.isNull(response.data.counselorOrder)) {
          _this.payType = true;
        } else {
          _this.payType = false;
        }
      }).catch(function (error) {});
    },
    isHidden: function isHidden(data) {
      this.isTimeList = data;
    },
    clickTimeList: function clickTimeList() {
      var _this4 = this;
      this.isTimeList = true;
      this.dataList.forEach(function (item) {
        if (_this4.day == item.date) {
          if (item.content.length > 0) {
            item.content.forEach(function (item1) {
              var bigTime = '';
              bigTime = item1.timeClick.split("-").shift().slice(0, 2);
              if (bigTime < _this4.clock) {
                item1.isClick = false;
              }
            });
          }
        }
      });
      console.log(this.dataList);
    },
    control: function control() {
      this.isActivity = !this.isActivity;
    },
    getDate: function getDate() {
      var _this = this;
      for (var i = 0; i < 14; i++) {
        _this.fun_date(i);
      }
      if (!_utils.default.isNull(_this.infoList.calendarTable)) {
        _this.dataList.forEach(function (item) {
          var list = [];
          if (item.day == '1') {
            if (_this.infoList.calendarTable[0].length > 0) {
              _this.infoList.calendarTable[0].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '2') {
            if (_this.infoList.calendarTable[1].length > 0) {
              _this.infoList.calendarTable[1].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '3') {
            if (_this.infoList.calendarTable[2].length > 0) {
              _this.infoList.calendarTable[2].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '4') {
            if (_this.infoList.calendarTable[3].length > 0) {
              _this.infoList.calendarTable[3].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '5') {
            if (_this.infoList.calendarTable[4].length > 0) {
              _this.infoList.calendarTable[4].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          } else if (item.day == '6') {
            if (_this.infoList.calendarTable[5].length > 0) {
              _this.infoList.calendarTable[5].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            // _this.infoList.calendarTable[5]=list
            item.content = list;
          } else if (item.day == '0') {
            if (_this.infoList.calendarTable[6].length > 0) {
              _this.infoList.calendarTable[6].forEach(function (item1) {
                var obj = {
                  isClick: true,
                  timeClick: item1
                };
                list.push(obj);
              });
            }
            item.content = list;
          }
        });
      }
      ;
      console.log(_this.dataList);
    },
    fun_date: function fun_date(day) {
      // 获取当前日期
      var nowDate = new Date();
      // 获取day天后的日期信息
      var targetday_milliseconds = nowDate.getTime() + 1000 * 60 * 60 * 24 * day;
      nowDate.setTime(targetday_milliseconds);
      // 格式化星期
      var date = {
        date: nowDate.getDate(),
        day: nowDate.getDay(),
        yearDate: nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate(),
        content: []
      };
      this.dataList.push(date);
    },
    payClick: function payClick() {
      if (this.payType == false) {
        this.$router.push({
          path: '/counselor/steps',
          query: {
            mchCounselorId: this.id,
            counselorMethod: this.counselorMethod,
            dateIndex: 50,
            timeIndex: 50
          }
        });
      } else {
        this.$router.push({
          path: '/counselor/order/orderList'
        });
      }
    }
  }
};