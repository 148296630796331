"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _columnItemController = require("@/api/columnItemController");
var _mchColumn = require("@/api/mchColumn");
var _columnOrder = require("@/api/columnOrder");
var _payment = require("@/api/payment");
var _share = require("@/utils/share");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _user = require("@/api/user");
var _member = require("@/api/member");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 1,
      pageSize: 100,
      mchColumnId: "",
      columnSignUpData: {},
      spots: [],
      columnId: "",
      sn: "",
      columnOrderId: "",
      paymentType: false
    };
  },
  created: function created() {
    this.mchColumnId = this.$route.query.mchColumnId;
    this.find(this.mchColumnId);
    this.getXuid();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId", "userId", "xuid", "token"])),
  methods: {
    checkExistsClick: function checkExistsClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchColumnId", _this.mchColumnId);
      (0, _columnOrder.checkExists)(params).then(function (response) {
        // console.log(response);
        if (!_utils.default.isNull(response.data.columnOrder)) {
          _this.paymentType = true;
          _this.columnOrderId = response.data.columnOrder.id;
          console.log(_this.columnOrderId);
        } else {
          _this.paymentType = false;
        }
      }).catch(function (error) {});
    },
    find: function find(id) {
      var _this2 = this;
      var _this = this;
      (0, _mchColumn.find)(id).then(function (response) {
        // console.log();
        _this.spots = response.data.column.spots.split(",");
        if (!_utils.default.isNull(response.data.column.thumbnail)) {
          response.data.column.thumbnail = _utils.default.thumbnail(response.data.column.thumbnail, 1500, 720);
        }
        _this.columnSignUpData = response.data.column;
        _this.columnId = response.data.columnId;
        // _this.getColumnItem();
        _this.checkExistsClick();
        (0, _share.SHARE)(location.href, {
          title: response.data.column.title,
          desc: response.data.column.subTitle,
          link: _utils.default.host() + "/#/column/columnSignUp?mchColumnId=".concat(_this2.mchColumnId, "&xuid=").concat(_this2.userId),
          imgUrl: response.data.column.thumbnail
        }, function (res) {});
      }).catch(function (error) {
        console.log("error!!");
      });
    },
    getXuid: function getXuid() {
      console.log(this.userId);
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch("user/setXuid", xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== "") {
        // 如果有token并且参数里有xuid
        // alert(this.xuid)
        (0, _member.lockSysUser)({
          xuid: this.xuid
        }).then(function (res) {
          // console.log(res);
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    goHome: function goHome() {
      window.scroll(0, 0);
    },
    startStudy: function startStudy() {
      this.$router.push({
        path: "/column/columnSubjectStage",
        query: {
          columnId: this.columnId,
          columnOrderId: this.columnOrderId,
          stageTitle: this.columnSignUpData.title,
          stageSubtitle: this.columnSignUpData.subTitle
        }
      });
      // console.log("startStudy");
    },
    signUp: function signUp() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchColumnId", _this.mchColumnId);
      (0, _columnOrder.create)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.payment)) {
          _this.sn = response.data.payment.sn;
          _this.$router.replace({
            path: "/payment",
            query: {
              sn: _this.sn,
              redirect: "/column/columnSignUp?mchColumnId=" + _this.mchColumnId
            }
          });
        } else {
          _this.checkExistsClick();
        }
      }).catch(function (error) {});
    }
  }
};