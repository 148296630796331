var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "30px" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "column-subject-stage__tit" }, [
        _vm._v(_vm._s(_vm.stageTitle)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column-subject-stage__subtit" }, [
        _vm._v("\n    " + _vm._s(_vm.stageSubtitle) + "\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.columnSubjectStageStage, function (item, x) {
        return _c(
          "div",
          { key: item, staticClass: "column-subject-stage__stage" },
          [
            _c("div", { staticClass: "column-subject-stage__stage-tit" }, [
              _vm._v("\n      " + _vm._s(item.name) + "\n      "),
              _c(
                "div",
                { staticClass: "column-subject-stage__stage-tit__num" },
                [
                  item.markOver == false
                    ? _c("span", [_vm._v(_vm._s(item.mark))])
                    : _vm._e(),
                  item.markOver == true
                    ? _c("span", [_vm._v(_vm._s(item.columnItems.length))])
                    : _vm._e(),
                  _c("span", [_vm._v(" / ")]),
                  _c("span", [_vm._v(_vm._s(item.columnItems.length))]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "overflow-y": "scroll",
                  "padding-left": "15px",
                },
                attrs: { id: "scroll-" + x },
              },
              _vm._l(item.columnItems, function (i, index) {
                return _c(
                  "div",
                  {
                    key: i.id,
                    staticClass: "column-subject-stage__stage-item",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "column-subject-stage__stage-item-main" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "column-subject-stage__stage-item-main__img",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "column-subject-stage__stage-item-main-number",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(i.index) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            index < 8
                              ? _c("img", {
                                  attrs: {
                                    src: require("./imgs/" + index + ".jpg"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            index > 7
                              ? _c("img", {
                                  attrs: {
                                    src: require("./imgs/" +
                                      (index % 4) +
                                      ".jpg"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "column-subject-stage__stage-item-main__summary",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(i.title) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "column-subject-stage__stage-item-main__tit",
                          },
                          _vm._l(i.courseItemList, function (n) {
                            return _c(
                              "div",
                              {
                                key: n.id,
                                staticClass:
                                  "column-subject-stage__stage-item-main__tit-item",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(n.title) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "column-subject-stage__stage-item-main__btn",
                            on: {
                              click: function ($event) {
                                return _vm.goColumnStageDetail(
                                  item.name,
                                  i.tagType,
                                  i.title,
                                  i.materialId
                                )
                              },
                            },
                          },
                          [_vm._v("\n            进入学习\n          ")]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-subject-stage__bg" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/column-backg.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }