"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QRcode = QRcode;
exports.autoLogin = autoLogin;
var _request = _interopRequireDefault(require("@/utils/request"));
// 自动登录
function autoLogin(data) {
  // console.log('params', params)
  return (0, _request.default)({
    url: '/openApi/shareAutoLogin',
    method: 'post',
    data: data
  });
}

//获取任务
function QRcode() {
  return (0, _request.default)({
    url: '/openApi/QRcode',
    method: 'post'
  });
}