"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vant = require("vant");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vue = _interopRequireDefault(require("vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

_vue.default.use(_vant.DatetimePicker).use(_vant.ActionSheet).use(_vant.Picker);
var _default = exports.default = {
  data: function data() {
    return {
      showBirthDay: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      birthDay: new Date(2021, 0, 17),
      birthDayText: '',
      showSex: false,
      genderList: ['男', '女'],
      gender: '',
      education: ['文盲', '小学', '初中', '高中或中专', '大专', '本科', '硕士研究生', '博士研究生'],
      showEdu: false,
      eduText: '',
      familyList: ['核心家庭（父母+未婚独生子女）', '核心家庭（父母+未婚多子女）', '夫妻家庭（无子女）', '主干家庭（父母和1对已婚子女组成家庭）', '联合家庭（父母和2对及以上已婚子女组成家庭）', '单亲家庭', '离异家庭', '重组家庭', '留守家庭', '其他'],
      showFamily: false,
      contactList: ['和睦', '一般', '父母关系不和', '与父亲关系不和', '与母亲关系不和'],
      contact: '',
      showContact: false,
      showPassword: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["name", "avatar", "menus", "mchId"])),
  created: function created() {},
  methods: {
    /** 选择生日**/onSelectBirthDay: function onSelectBirthDay() {
      this.birthDayText = this.formatter(this.birthDay);
      this.showBirthDay = false;
    },
    /** 格式化选择器日期**/formatter: function formatter(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      minute = minute < 10 ? '0' + minute : minute;
      var second = date.getSeconds();
      second = minute < 10 ? '0' + second : second;
      var value = y + '-' + m + '-' + d;
      return value;
    },
    /** 选择性别**/genderDone: function genderDone(val) {
      this.gender = val;
      this.showSex = false;
    },
    /** 选择学历**/eduDone: function eduDone(val) {
      this.eduText = val;
      this.showEdu = false;
    },
    /** 家庭结构**/familyDone: function familyDone(val) {
      this.family = val;
      this.showFamily = false;
    },
    /** 家庭关系**/contactDone: function contactDone(val) {
      this.contact = val;
      this.showContact = false;
    }
  }
};