"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _ad = require("@/api/ad");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      navigationForm: [],
      pageNum: 1,
      pageSize: 8,
      tagIds: [],
      navigationOne: [],
      userInfo: {},
      backgroundColor: '',
      bgImg: ''
    };
  },
  created: function created() {
    this.loadNavigation();
    this.getInfo();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId', 'mchName', 'mchLogo'])),
  props: ['content'],
  methods: {
    loadNavigation: function loadNavigation() {
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(',');
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append('tagIds', _this.tagIds[i]);
      }
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      (0, _ad.list)(params).then(function (response) {
        response.data.data.forEach(function (item, index) {
          if (index == 0) {
            _this.backgroundColor = item.backgroundColor;
            _this.bgImg = item.thumbnail;
            console.log(_this.bgImg);
          } else {
            item.thumbnail = _utils.default.thumbnail(item.thumbnail, 74, 74);
            _this.navigationForm.push(item);
          }
        });
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    navigationClick: function navigationClick(item) {
      console.log(item);
      if (item.type == 5) {
        this.$router.push({
          path: '/course/info',
          query: {
            courseId: item.typeId
          }
        });
      } else if (item.type == 6) {
        this.$router.push({
          path: '/gauge/index',
          query: {
            mchGaugeId: item.typeId
          }
        });
      } else if (item.type == 12) {
        window.location.href = item.path;
      } else if (item.type == 1) {
        this.$router.push({
          path: '/article/index',
          query: {
            articleId: item.typeId
          }
        });
      } else if (item.type == 9) {
        this.$router.push({
          path: '/index/wxPage',
          query: {
            id: item.typeId
          }
        });
      }
    },
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _user.getInfo)().then(function (res) {
        console.log(res);
        _this2.userInfo = res.data;
        if (_this2.userInfo.logo == null) {
          _this2.userInfo.logo = 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png';
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};