"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {};
  },
  props: ['promptType', 'promptData', 'title'],
  methods: {
    downClick: function downClick() {
      this.promptType = !this.promptType;
      this.$emit('downClick', this.promptType);
    },
    stopClick: function stopClick() {
      event.stopPropagation();
    }
  }
};