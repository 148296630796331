var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#fafafa", padding: "7.5px 0" } },
    [
      _c(
        "div",
        { staticClass: "consulting_options_body" },
        _vm._l(_vm.navigationForm, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "consulting_options_content",
              on: {
                click: function ($event) {
                  return _vm.navigationClick(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "consulting_options_title",
                  style: { color: item.color },
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "consulting_options_subtitle" }, [
                _vm._v(_vm._s(item.subName)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "consulting_options_logo" }, [
                _c("img", { attrs: { src: item.logo, alt: "" } }),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }