var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _vm._m(4),
    _vm._v(" "),
    _c("div", { staticClass: "success_info_botton_box" }, [
      _c(
        "div",
        { staticClass: "success_info_botton", on: { click: _vm.successClick } },
        [
          _c("span", { staticClass: "success_info_botton_title" }, [
            _vm._v("开始学习"),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success_header" }, [
      _c("span", { staticClass: "success_header_title" }, [_vm._v("购买成功")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success_info" }, [
      _c("div", { staticClass: "success_info_box" }, [
        _c("div", { staticClass: "success_info_img" }),
        _vm._v(" "),
        _c("div", { staticClass: "success_info_content" }, [
          _c("span", { staticClass: "success_info_content_title" }, [
            _vm._v("3-7岁儿童感统训练"),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "success_info_content_subtitle" }, [
            _vm._v("感觉统合，抹平孩子能力的德鲁克的说法地方撒上"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "success_info_price_box" }, [
            _c("span", { staticClass: "success_info_date" }, [
              _vm._v("24课时"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "success_info_price" }, [
              _vm._v("¥99.0"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success_info_subtitle_box" }, [
      _c("span", { staticClass: "success_info_subtitle" }, [
        _vm._v("您已成功购买课程，建议您关注公众号以获取更好的服务。"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success_info_code_box" }, [
      _c("div", { staticClass: "success_info_code" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "success_info_code_subtitle_box" }, [
      _c("span", { staticClass: "success_info_code_subtitle" }, [
        _vm._v("长按识别二维码关注公众号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }