var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    _vm._l(_vm.routes, function (route) {
      return _c("navbar-item", {
        key: route.path,
        attrs: { item: route, "base-path": route.path },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }