"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _user = require("@/api/user");
var _Distpicker = _interopRequireDefault(require("../login/common/Distpicker.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _data = new Date().getFullYear();
var _default = exports.default = {
  data: function data() {
    return {
      fullscreenLoading: false,
      // 页面加载
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(_data, 11, 31),
      currentTime: '',
      minWidth: 0,
      showDate: false,
      // 显示日期
      show: false,
      passForm: {
        newPassword: '',
        oriPassword: ''
      },
      confirmPassword: '',
      district: {
        province: '',
        city: '',
        area: ''
      },
      allList: [],
      dataList: [],
      requireList: [],
      //是否必填
      form: {
        empName: '',
        sex: '',
        phone: '',
        birthday: '',
        national: '',
        registerPlace: '',
        maritalStatus: '',
        workPlace: '',
        education: '',
        job: '',
        salary: '',
        political: '',
        height: '',
        weight: '',
        hc: '',
        childbirthType: '',
        foster: '',
        motherChildbirthAge: '',
        brotherNum: '',
        familyStructure: '',
        familyIncome: '',
        certificatesType: '',
        certificatesNo: '',
        sin: '',
        gestationalAge: '',
        company: '',
        studyNo: '',
        health: '',
        personalNeuropathy: '',
        familyNeuropathy: '',
        learning: '',
        address: '',
        parentContact: '',
        emergencyContact: '',
        familyRelation: '',
        examineeNumber: ''
      },
      sexList: ['男', '女'],
      nations: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族'],
      placeTyoe: ['城市', '农村'],
      marriage: ['单身（无恋爱经验）', '单身（有恋爱经验）', '恋爱', '已婚（未育）', '已婚（已育）', '离异', '∂丧偶'],
      education: ['文盲', '小学', '初中', '高中或中专', '大专', '本科', '硕士研究生', '博士研究生'],
      professional: ['国家机关、党群组织、企业、事业单位负责人', '专业技术人员', '办事人员和有关人员', '商业、服务业人员', '农、林、牧、渔、水利业生产人员', '生产、运输设备操作人员及有关人员', '军人', '学生', '其他职业'],
      income: ['2-4K', '4-6K', '6-8K', '8K-1万', '1-5万', '5-10万', '10-30万', '30万以上'],
      political: ['群众', '无党派人士', '共青团员', '中共党员（预备党员）', '其他党派/团体'],
      childbirthType: ['顺产', '剖腹产'],
      raising: ['爸爸', '妈妈', '爷爷奶奶', '外公外婆', '其他（如保姆）'],
      Brothers: ['独生子女', '1个', '2个', '3个', '4个及以上'],
      family: ['核心家庭（父母+未婚独生子女）', '核心家庭（父母+未婚多子女）', '夫妻家庭（无子女）', '主干家庭（父母和1对已婚子女组成家庭）', '联合家庭（父母和2对及以上已婚子女组成家庭）', '单亲家庭', '离异家庭', '重组家庭', '留守家庭', '其他'],
      total: ['0.3万及以下', '0-3万', '3-8万', '8-15万', '15-30万', '30-100万', '100-500万', '500万以上'],
      documentList: ['居民二代身份证', '港澳台通行证'],
      learningList: ['优秀', '良好', '合格', '待合格'],
      treeList: [],
      RelationList: ['和睦', '一般', '父母关系不和', '与父亲关系不和', '与母亲关系不和'],
      loading: false,
      //防止按钮重复点击
      loginType: 0
    };
  },
  components: {
    Distpicker: _Distpicker.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'avatar', 'mchId'])),
  created: function created() {
    this.Init();
  },
  methods: {
    Init: function Init() {
      var _this = this;
      // 登录类型 0:账号登录; 1:微信登录
      this.loginType = localStorage.getItem('loginType') || 0;
      this.fullscreenLoading = true;
      //   获取基础数据
      (0, _user.getFinishData)().then(function (res) {
        _this.fullscreenLoading = false;
        _this.allList = res.data.fieldList;
        _this.dataList = [];
        _this.requireList = [];
        res.data.fieldList.forEach(function (item) {
          _this.dataList.push(item.label);
          _this.requireList.push(item.required);
          if (item.field == 'empName') {
            _this.form.empName = item.value;
          } else if (item.field == 'sex') {
            _this.form.sex = item.value;
          } else if (item.field == 'phone') {
            _this.form.phone = item.value;
          } else if (item.field == 'birthday') {
            _this.form.birthday = item.value;
          } else if (item.field == 'national') {
            _this.form.national = item.value;
          } else if (item.field == 'registerPlace') {
            _this.form.registerPlace = item.value;
          } else if (item.field == 'maritalStatus') {
            _this.form.maritalStatus = item.value;
          } else if (item.field == 'workPlace') {
            _this.form.workPlace = item.value;
            var val = item.value.split('-');
            _this.district.province = val[0];
            _this.district.city = val[1];
            _this.district.area = val[2];
          } else if (item.field == 'education') {
            _this.form.education = item.value;
          } else if (item.field == 'job') {
            _this.form.job = item.value;
          } else if (item.field == 'salary') {
            _this.form.salary = item.value;
          } else if (item.field == 'political') {
            _this.form.political = item.value;
          } else if (item.field == 'height') {
            _this.form.height = item.value;
          } else if (item.field == 'weight') {
            _this.form.weight = item.value;
          } else if (item.field == 'hc') {
            _this.form.hc = item.value;
          } else if (item.field == 'childbirthType') {
            _this.form.childbirthType = item.value;
          } else if (item.field == 'foster') {
            _this.form.foster = item.value;
          } else if (item.field == 'motherChildbirthAge') {
            _this.form.motherChildbirthAge = item.value;
          } else if (item.field == 'brotherNum') {
            _this.form.brotherNum = item.value;
          } else if (item.field == 'familyStructure') {
            _this.form.familyStructure = item.value;
          } else if (item.field == 'familyIncome') {
            _this.form.familyIncome = item.value;
          } else if (item.field == 'certificatesType') {
            _this.form.certificatesType = item.value;
          } else if (item.field == 'certificatesNo') {
            _this.form.certificatesNo = item.value;
          } else if (item.field == 'sin') {
            _this.form.sin = item.value;
          } else if (item.field == 'gestationalAge') {
            _this.form.gestationalAge = item.value;
          } else if (item.field == 'company') {
            _this.form.company = item.value ? parseInt(item.value) : item.value;
          } else if (item.field == 'studyNo') {
            _this.form.studyNo = item.value;
          } else if (item.field == 'health') {
            _this.form.health = item.value;
          } else if (item.field == 'personalNeuropathy') {
            _this.form.personalNeuropathy = item.value;
          } else if (item.field == 'familyNeuropathy') {
            _this.form.familyNeuropathy = item.value;
          } else if (item.field == 'learning') {
            _this.form.learning = item.value;
          } else if (item.field == 'address') {
            _this.form.address = item.value;
          } else if (item.field == 'parentContact') {
            _this.form.parentContact = item.value;
          } else if (item.field == 'emergencyContact') {
            _this.form.emergencyContact = item.value;
          } else if (item.field == 'familyRelation') {
            _this.form.familyRelation = item.value;
          } else if (item.field == 'examineeNumber') {
            _this.form.examineeNumber = item.value;
          }
        });
        //   console.log(this.dataList)
      }).catch(function (err) {});
      (0, _user.getOrgTree)().then(function (res) {
        _this.treeList = res.data;
      }).catch(function (err) {});
    },
    // 判断是否是必填
    getReqire: function getReqire(val) {
      var lable = '';
      this.allList.forEach(function (item) {
        if (val == item.label) {
          lable = item.required;
        }
      });
      return lable == 1 ? true : false;
    },
    // 点击显示日期组件
    onBirthday: function onBirthday() {
      this.currentTime = new Date(this.form.birthday);
      this.showDate = true;
    },
    // 确定
    onDataCofig: function onDataCofig(val) {
      this.showDate = false;
      this.form.birthday = this.timeFormat(val);
    },
    // 时间格式化
    timeFormat: function timeFormat(time) {
      // 时间格式化 2019-09-08
      var year = time.getFullYear();
      var month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
      var day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
      return year + '-' + month + '-' + day;
    },
    // 城市选择
    onSelected: function onSelected(val) {
      this.form.workPlace = val.province.value + '-' + val.city.value + '-' + val.area.value;
    },
    setMinWidth: function setMinWidth(val) {
      this.minWidth = val.srcElement.clientWidth;
    },
    onEdit: function onEdit() {
      this.show = true;
    },
    // 保存
    addSubmit: function addSubmit() {
      var _this2 = this;
      this.loading = true;
      (0, _user.addFinishData)(this.form).then(function (res) {
        if (res.type == 'success') {
          _this2.loading = false;
          _this2.$message({
            message: res.content,
            type: 'success'
          });
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 修改密码
    addEditSubmit: function addEditSubmit() {
      var _this3 = this;
      if (!this.passForm.oriPassword) {
        this.$message({
          message: '请输入旧密码',
          type: 'warning'
        });
        return;
      }
      if (!this.passForm.newPassword) {
        this.$message({
          message: '请输入新密码',
          type: 'warning'
        });
        return;
      }
      if (this.passForm.newPassword != this.confirmPassword) {
        this.$message({
          message: '两次密码不一致',
          type: 'warning'
        });
        return;
      }
      this.loading = true;
      (0, _user.onUpdPwd)(this.passForm).then(function (res) {
        _this3.loading = false;
        _this3.show = false;
        _this3.$message({
          message: '修改成功',
          type: 'success'
        });
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};