"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "timeList",
  data: function data() {
    return {
      dataList: [],
      isHidden: false,
      dateTime: '',
      dateIndex: '',
      timeIndex: '',
      yearDate: ''
    };
  },
  created: function created() {},
  updated: function updated() {
    console.log(this.dataList);
  },
  filters: {
    watchWeek: function watchWeek(val) {
      switch (val) {
        case 0:
          return "周天";
          break;
        case 1:
          return "周一";
          break;
        case 2:
          return "周二";
          break;
        case 3:
          return "周三";
          break;
        case 4:
          return "周四";
          break;
        case 5:
          return "周五";
          break;
        case 6:
          return "周六";
      }
    },
    watchDateOne: function watchDateOne(val) {
      return val.split('-')[0];
    },
    watchDateTwo: function watchDateTwo(val) {
      return val.split('-')[1];
    }
  },
  props: ['dataList', 'infoList', 'id', 'payType', 'counselorMethod'],
  methods: {
    cancelClick: function cancelClick() {
      this.$emit('isHidden', this.isHidden);
    },
    dateClick: function dateClick(index, indexs) {
      this.dateTime = this.dataList[index].content[indexs];
      this.dateIndex = index;
      this.timeIndex = indexs;
      this.yearDate = this.dataList[index].yearDate;
    },
    saveClick: function saveClick() {
      if (this.payType == false) {
        this.$emit('isHidden', this.isHidden);
        this.$router.push({
          path: '/counselor/steps',
          query: {
            dateTime: this.dateTime,
            yearDate: this.yearDate,
            counselorMethod: this.counselorMethod,
            dateIndex: this.dateIndex,
            timeIndex: this.timeIndex,
            mchCounselorId: this.id
          }
        });
      }
    },
    stopClick: function stopClick() {
      event.stopPropagation();
    }
  }
};