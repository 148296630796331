"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _mchCourse = require("@/api/mchCourse");
var _utils = _interopRequireDefault(require("../../../../utils/utils"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "coursesTimeArticle",
  data: function data() {
    return {
      articleForm: [],
      pageNum: 1,
      pageSize: 1,
      tagIds: [],
      firstActivity: [{
        thumbnail: '',
        id: ''
      }]
    };
  },
  created: function created() {
    this.loadArticle();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  props: ['content'],
  methods: {
    articleClick: function articleClick(id) {
      console.log(22222222);
      this.$router.push({
        path: '/course/info',
        query: {
          courseId: id
        }
      });
    },
    loadArticle: function loadArticle() {
      var _this2 = this;
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(',');
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append('tagIds', _this.tagIds[i]);
      }
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      (0, _mchCourse.list)(params).then(function (response) {
        console.log(response);
        _this.articleForm = response.data.data;
        if (_this.articleForm.length > 0) {
          _this2.firstActivity = response.data.data[0];
          _this.articleForm.forEach(function (item) {
            item.thumbnail = _utils.default.thumbnail(item.thumbnail, 330, 210);
          });
        }
      }).catch(function (error) {
        console.log('error!!');
      });
    }
  }
};