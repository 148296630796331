"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _activitySyQy = require("@/api/activitySyQy");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vuex = require("vuex");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _share = require("@/utils/share");
var _friendLink = require("@/api/friendLink");
var _member = require("@/api/member");
var _enterprise = require("@/api/enterprise");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activityId: "",
      // userId: '',
      friends: [],
      accordingType: false,
      infoData: {},
      activityMeta: {},
      sn: "",
      paymentType: false,
      mch: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId", "userId", "xuid", "token"])),
  components: {
    NoData: _NoData.default
  },
  created: function created() {
    this.getXuid();
    this.activityId = this.$route.query.activityId;
    this.find(this.activityId);
    this.getInfoClick();
    this.loadFriendLink(this.activityId);
    this.loginDetermine();
  },
  methods: {
    getXuid: function getXuid() {
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid !== undefined && xuid != null && xuid !== '') {
        this.$store.dispatch("user/setXuid", xuid);
      } else {
        return;
      }
      if (this.token !== undefined && this.token !== null && this.token !== "") {
        // 如果有token并且参数里有xuid

        (0, _member.lockSysUser)({
          xuid: this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
      console.log("xuid", this.xuid);
    },
    loginDetermine: function loginDetermine() {
      if (this.token == "") {
        if (_utils.default.isWeiXin()) {
          this.$router.push('/auth-redirect');
        } else {
          this.$router.push('/newLogin');
        }
        console.log('跳转登录');
      }
    },
    toLink: function toLink(item) {
      if (item.linkProductType == 1) {
        this.$router.push({
          path: "/gauge/index",
          query: {
            mchGaugeId: item.linkProductId
          }
        });
      } else if (item.linkProductType == 2) {
        this.$router.push({
          path: "/course/info",
          query: {
            courseId: item.linkProductId
          }
        });
      } else if (item.linkProductType == 3) {
        this.$router.push({
          path: "/article/index",
          query: {
            articleId: item.linkProductId
          }
        });
        this.find(item.linkProductId);
        this.loadFriendLink(item.linkProductId);
      } else if (item.linkProductType == 4) {
        this.$router.push({
          path: "/activitySy/details",
          query: {
            activityId: item.linkProductId
          }
        });
        this.find(item.linkProductId);
        this.loadFriendLink(item.linkProductId);
      }
    },
    accordingClick: function accordingClick() {
      var _this = this;
      if (_this.paymentType == false) {
        this.accordingType = !this.accordingType;
      } else if (_this.paymentType == true) {
        _this.$router.push({
          path: "/activity/order"
        });
      }
    },
    find: function find(query) {
      var _this2 = this;
      var _this = this;
      this.activityId = this.$route.query.activityId;
      console.log(_this.activityId);
      (0, _activitySyQy.find)(query).then(function (response) {
        console.log(response);
        _this.infoData = response.data.activity;
        (0, _share.SHARE)(location.href, {
          title: response.data.activity.title,
          desc: response.data.activity.subTitle,
          link: _utils.default.host() + "/#/activitySy/details?activityId=".concat(_this2.activityId, "&xuid=").concat(_this2.userId),
          imgUrl: response.data.activity.thumbnail
        }, function (res) {});
      }).catch(function (error) {
        console.log("error!!");
      });
    },
    loadFriendLink: function loadFriendLink(query) {
      var _this3 = this;
      var params = new URLSearchParams();
      params.append("mainProductType", 4);
      params.append("mainProductId", query);
      (0, _friendLink.list)(params).then(function (res) {
        _this3.friends = res.data.data;
      });
    },
    getInfoClick: function getInfoClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchId', _this.mchId);
      (0, _enterprise.getInfo)(params).then(function (res) {
        console.log(res);
        if (res.type == 'success') {
          _this.mch = res.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};