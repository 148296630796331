"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _counselorCategory = require("@/api/counselorCategory");
var _counselor = require("@/api/counselor");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      categoryData: [],
      counselorList: [],
      tagIds: [],
      activ_index: 0,
      mchCounselorGategoryId: "",
      pageNum: 0,
      pageSize: 3
    };
  },
  props: ["content"],
  created: function created() {
    this.loadGaugeCategory();
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])), {}, {
    scrollLeft: function scrollLeft() {
      if (this.activ_index < 3) {
        return 0;
      }
      if (this.activ_index > this.categoryData.length - 3) {
        return this.activ_index - 3;
      }
      return -this.activ_index * 70 + "px";
    }
  }),
  methods: {
    checkPermission: _permission.default,
    scrollSlide: function scrollSlide(index, id) {
      this.activ_index = index;
      document.querySelector(".consultantqy_scroll").scrollLeft = this.activ_index * 70;
      this.mchCounselorGategoryId = id;
      this.counselorList = [];
      this.loadCounselor();
    },
    loadGaugeCategory: function loadGaugeCategory() {
      var _this2 = this;
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _counselorCategory.list)(params).then(function (response) {
        response.data.forEach(function (item) {
          _this.categoryData.push(item);
        });
        _this.mchCounselorGategoryId = response.data[0].id;
        _this2.loadCounselor();
      }).catch(function (error) {
        console.log(error);
      });
    },
    loadCounselor: function loadCounselor() {
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(",");
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      params.append("mchCounselorGategoryId", _this.mchCounselorGategoryId);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append("tagIds", _this.tagIds[i]);
      }
      (0, _counselor.list)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.counselorList.push(item);
          item.specialitys = item.specialitys.split(",");
          console.log(item);
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    counselorClick: function counselorClick(id) {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/info/info",
          query: {
            id: id
          }
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/info",
          query: {
            id: id
          }
        });
      }
    },
    consultantQychange: function consultantQychange() {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/list"
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/list"
        });
      }
    }
  }
};