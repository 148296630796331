var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "category_box" },
    _vm._l(_vm.navigationForm, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "category",
          on: {
            click: function ($event) {
              return _vm.navigationClick(item)
            },
          },
        },
        [
          _c("div", { staticClass: "category_Img" }, [
            _c("img", {
              attrs: { src: item.logo, width: "100%", height: "100%" },
            }),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "category_Name" }, [
            _vm._v(_vm._s(item.name)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }