var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      {
        staticClass: "contentBox",
        on: {
          click: function ($event) {
            return _vm.clickTask()
          },
        },
      },
      [
        _c("div", { staticClass: "titleSubTBox" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "subTitle" }, [
            _vm._v(
              "您有" +
                _vm._s(
                  _vm.enrollOrderCount +
                    _vm.courseOrderCount +
                    _vm.columnOrderCount +
                    _vm.counselorOrderCount +
                    _vm.evaluationCount
                ) +
                "个任务需完成"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "redDot" }),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBox" }, [
      _c("span", { staticClass: "titleText" }, [_vm._v("我的任务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleIcon" }, [
      _c("img", {
        staticStyle: { display: "block" },
        attrs: {
          width: "100%",
          height: "100%",
          src: require("./images/myTaskTitleIcon.png"),
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrowIcon" }, [
      _c("img", {
        staticStyle: { display: "block" },
        attrs: {
          width: "100%",
          height: "100%",
          src: require("./images/myTaskArrow.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }