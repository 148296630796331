var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "column-sign-up__top" }, [
      _c("img", { attrs: { src: _vm.columnSignUpData.thumbnail, alt: "" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column-sign-up__main" }, [
      _c("div", { staticClass: "column-sign-up__main-tit" }, [
        _vm._v("\n      " + _vm._s(_vm.columnSignUpData.title) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column-sign-up__main-sub-tit" }, [
        _vm._v("\n      " + _vm._s(_vm.columnSignUpData.subTitle) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "flex", "align-items": "center" } }, [
        _c(
          "div",
          { staticClass: "column-sign-up__main-tag" },
          _vm._l(_vm.spots, function (item) {
            return _c(
              "div",
              { key: item, staticClass: "column-sign-up__main-tag-item" },
              [_vm._v("\n          " + _vm._s(item) + "\n        ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { "font-size": "12px", color: "#ccc" } }, [
          _c("span", [_vm._v(_vm._s(_vm.columnSignUpData.published))]),
          _vm._v("人已学\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "column-sign-up__main__line" }),
      _vm._v(" "),
      _c("div", { staticClass: "column-sign-up__main__introduction" }, [
        _c("div", { staticClass: "column-sign-up__main__introduction-tit" }, [
          _vm._v("专栏介绍"),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "column-sign-up__main__introduction-cont",
          domProps: { innerHTML: _vm._s(_vm.columnSignUpData.content) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column-sign-up__btn" }, [
      _c(
        "div",
        {
          staticClass: "column-sign-up__btn__go-home",
          on: { click: _vm.goHome },
        },
        [
          _c("i", { staticClass: "el-icon-upload2" }),
          _vm._v(" "),
          _c("div", { staticClass: "column-sign-up__btn__go-home-txt" }, [
            _vm._v("回到顶部"),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.paymentType == true
        ? _c(
            "div",
            {
              staticClass: "column-sign-up__btn__Start",
              on: { click: _vm.startStudy },
            },
            [_vm._v("\n      开始学习\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.paymentType == false
        ? _c(
            "div",
            {
              staticClass: "column-sign-up__btn__Start",
              on: { click: _vm.signUp },
            },
            [
              _vm._v(
                "\n      ￥" +
                  _vm._s(_vm.columnSignUpData.price) +
                  "立即报名\n    "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }