var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category_headerImg" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "category_header_content" },
      _vm._l(_vm.categoryForm, function (item) {
        return _c("div", { key: item.id, staticClass: "category_box" }, [
          _c("div", { staticClass: "category_box_img" }, [
            _c("img", {
              attrs: { width: "100%", height: "100%", src: item.img },
            }),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "category_box_name" }, [
            _vm._v(_vm._s(item.name)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "category_header_img" }, [
      _c("img", {
        attrs: {
          width: "1005",
          height: "100%",
          src: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/%E5%9B%BE%E5%83%8F%203@2x.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }