"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// get token from cookie
// import { getInfo } from '@/api/gaugePlan'
var _default = exports.default = {
  data: function data() {
    return {
      callMeDialogShow: false,
      kefu: '',
      // 获取客服信息
      user: {
        name: '',
        avatar: ''
      }
    };
  },
  created: function created() {
    this.Init();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  props: [],
  methods: {
    Init: function Init() {
      var _this = this;
      var token = (0, _auth.getToken)();
      if (!!token) {
        (0, _user.getInfo)(location.hostname).then(function (res) {
          var userInfo = res.data.userInfo || {};
          _this.user.name = userInfo.nickname;
          _this.user.avatar = userInfo.avatar;
        });
      }
      // 获取客服信息
      (0, _user.getkefu)({
        mchId: this.mchId
      }).then(function (res) {
        _this.kefu = res.data;
      }).catch(function (err) {});
    },
    copy: function copy() {
      var url = this.$refs.copy;
      url.select();
      document.execCommand('copy');
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    }
  }
};