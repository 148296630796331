var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.adForm, function (item) {
      return _c(
        "div",
        { key: item.id, staticStyle: { width: "100%", height: "100%" } },
        [
          _c("img", {
            attrs: { src: item.thumbnail, width: "100%", height: "100%" },
            on: {
              click: function ($event) {
                return _vm.adClick(item)
              },
            },
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }