"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.array.find");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
var _prompt = _interopRequireDefault(require("./prompt"));
var _counselorOrder = require("../../../api/counselorOrder");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: this.$route.params.id,
      yearTime: '',
      orderItems: [],
      dataTime: '',
      radio: '1',
      num: 0,
      orderInfo: [],
      checked: true,
      label: [{
        name: '婚姻家庭'
      }, {
        name: '性心理'
      }, {
        name: '恋爱心理'
      }],
      textarea1: '',
      textarea2: '',
      basis: {
        name: '',
        phone: '',
        age: '',
        gender: ''
      },
      emergency: {
        name: '',
        phone: '',
        relationship: ''
      },
      promptType: false,
      title: '',
      promptData: []
    };
  },
  components: {
    prompt: _prompt.default
  },
  filters: {
    watchData: function watchData(date) {
      date = new Date(date);
      var fmt = "YYYY-mm-dd HH:MM";
      var ret;
      var opt = {
        "Y+": date.getFullYear().toString(),
        // 年
        "m+": (date.getMonth() + 1).toString(),
        // 月
        "d+": date.getDate().toString(),
        // 日
        "H+": date.getHours().toString(),
        // 时
        "M+": date.getMinutes().toString() // 分
      };
      for (var k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
        }
        ;
      }
      ;
      return fmt;
    },
    watchCounselorMethod: function watchCounselorMethod(val) {
      if (val === 0) {
        return '面对面/线上咨询';
      } else if (val === 1) {
        return '面对面咨询';
      } else if (val === 2) {
        return '线下咨询';
      }
    }
  },
  created: function created() {
    this.loadCounselorOrder();
  },
  methods: {
    loadCounselorOrder: function loadCounselorOrder() {
      var _this = this;
      (0, _counselorOrder.find)(this.id).then(function (response) {
        console.log(response);
        _this.orderInfo = response.data.counselorOrder;
        _this.orderItems = response.data.orderItems;
        _this.yearTime = _this.orderItems[0].appointmentDate;
        _this.dataTime = _this.orderItems[0].appointmentHour;
        _this.num = _this.orderInfo.quantity;
      });
    },
    openClick: function openClick(type) {
      this.promptType = !this.promptType;
      if (type == '1') {
        this.title = '预约须知';
        this.promptData = [{
          title: '回应时长',
          subTitle: '咨询师将在收到订单后的3小时内回应是否接受咨询，且在12小时内与来访者协商好咨询时间、地点。'
        }, {
          title: '可预约时间',
          subTitle: '目前提供每天19:00-21:00的咨询时间，具体咨询时间预约后商定。'
        }, {
          title: '关于预约变更',
          subTitle: '若因特殊原因，需要变更/取消已协商好的咨询预约，请务必提前48小时（两个工作日）联系。否则咨询将如期开始。'
        }, {
          title: '爽约/迟到',
          subTitle: '若无提前24小时告知情况，爽约/迟到20分钟以上，则默认这次咨询已经完成。其他特殊情况，需协商处理。'
        }];
      } else {
        this.title = '为什么要填写真实资料';
        this.promptData = [{
          title: '',
          subTitle: '1.填写真实信息有助于在出现法律纠纷的情况下，保障来访者的合法权益。'
        }, {
          title: '',
          subTitle: '2.若来访者未满18周岁，请承诺在预约之前已经征得其监护人同意。'
        }, {
          title: '',
          subTitle: '3.来访者的个人信息将被严格保密。'
        }, {
          title: '',
          subTitle: '4.填写真实的紧急联系人信息，能在出现法律纠纷，来访者自伤，来访者伤害他人等情况时保障来访者的安全和合法权益。'
        }, {
          title: '',
          subTitle: '5.若随意填写，咨询师有权利拒绝该次预约咨询。'
        }, {
          title: '',
          subTitle: '6.紧急联系人等个人信息将被严格保密。'
        }, {
          title: '',
          subTitle: '7.咨询师会对整个咨询过程严格保密，若无危机情况，咨询师承诺不会联系紧急联系人。'
        }];
      }
    },
    downClick: function downClick() {
      this.promptType = false;
    }
  }
};