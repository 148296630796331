"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      categoryForm: [{
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/%E5%9B%BE%E5%83%8F%203@2x.png',
        name: '心理评估预警'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/%E5%9B%BE%E5%83%8F%203@2x.png',
        name: '心理评估预警'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/%E5%9B%BE%E5%83%8F%203@2x.png',
        name: '心理评估预警'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/%E5%9B%BE%E5%83%8F%203@2x.png',
        name: '心理评估预警'
      }]
    };
  }
};