var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "banner-four" },
      _vm._l(_vm.adForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "banner-four__item",
            on: {
              click: function ($event) {
                return _vm.adClick(item)
              },
            },
          },
          [
            _vm._m(0, true),
            _vm._v(" "),
            _c("div", { staticClass: "img" }, [
              _c("img", {
                attrs: { src: item.thumbnail, width: "100%", height: "100%" },
              }),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBox" }, [
      _c("span", { staticClass: "title" }, [_vm._v("咨询专题")]),
      _vm._v(" "),
      _c("span", { staticClass: "subTitle" }, [_vm._v("首次咨询半价")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }