var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "audioBox" }, [
    _c(
      "div",
      {
        staticClass: "audioBox-content flex-space-between",
        on: {
          click: function ($event) {
            return _vm.openPlayer()
          },
        },
      },
      [
        _c("div", { staticClass: "flex-row" }, [
          _c("div", { staticClass: "audioBox-content-img-box" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "audioBox-content-imgBgBox" }, [
              _c("img", {
                staticClass: "audioBox-content-imgBg",
                attrs: {
                  width: "100%",
                  height: "100%",
                  src: _vm.audioStatus,
                  alt: "",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: " audioBox-content-info" }, [
            _c("span", { staticClass: "audioBox-content-info-title" }, [
              _vm._v(_vm._s(_vm.musicName)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "audioBox-content-info-author" }, [
              _vm._v("美心说心理测评"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "audioBox-content-info-time" }, [
          _vm._v(_vm._s(_vm._f("watchTime")(_vm.musicTime))),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("audio", {
      staticStyle: { display: "block" },
      attrs: {
        id: "audio",
        preload: "auto",
        loop: "loop",
        src: _vm.nowAnswer.mediaUrl,
        controls: "controls",
      },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "audioBox-content-imgBox" }, [
      _c("img", {
        staticClass: "audioBox-content-img",
        attrs: {
          width: "100%",
          height: "100%",
          src: require("../../assets/mxs_images/audioImg.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }