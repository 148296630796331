"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      courseItemNumber: 0,
      chooseWhite: 0
    };
  },
  methods: {
    aaa: function aaa() {
      if (this.courseItemNumber < 24) {
        this.courseItemNumber += 1;
      } else {
        this.courseItemNumber = 0;
      }
      this.chooseWhite = this.courseItemNumber / 24 * 100;
    },
    detailsClick: function detailsClick(type) {
      this.$router.push({
        path: '/course/courseDetails',
        query: {
          type: type
        }
      });
    }
  }
};