var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article_content" }, [
    _c("div", { staticClass: "courses_header" }, [
      _c("div", { staticStyle: { display: "flex", "flex-direction": "row" } }, [
        _c("div", { staticClass: "courses_header_line" }),
        _vm._v(" "),
        _c("span", { staticClass: "courses_header_name" }, [
          _vm._v(_vm._s(_vm.content.title)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "courses_header_more",
          on: {
            click: function ($event) {
              return _vm.listClick()
            },
          },
        },
        [_vm._v("查看更多>>")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "article_box" },
      _vm._l(_vm.articleForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "article_item",
            on: {
              click: function ($event) {
                return _vm.articleClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "article_item_image" }, [
              _c("img", { attrs: { src: item.thumbnail, height: "100%" } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "article_item_tittle" }, [
              _c("div", { staticClass: "flex-column" }, [
                _c("span", { staticClass: "item_tittle_on" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "item_subTittle_on" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item_tittle_down" }, [
                _c("div", { staticClass: "tittle_down_left" }, [
                  _c("span", [_vm._v(_vm._s(item.sales) + "人已购")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tittle_down_right" }, [
                  _c("span", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          "font-weight": "400",
                        },
                      },
                      [_vm._v("¥ ")]
                    ),
                    _vm._v(_vm._s(item.price)),
                  ]),
                ]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }