"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      listData: [{
        id: 1,
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '呼吸放松训练',
        subTitle: '呼吸放松训练备份'
      }, {
        id: 2,
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '正念冥想练习',
        subTitle: '正念冥想练习'
      }],
      pageNum: 1,
      pageSize: 2,
      tagIds: []
    };
  },
  created: function created() {},
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  props: ['content'],
  methods: {}
};