var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column_box" }, [
    _c("div", { staticClass: "column_header" }, [
      _c("span", { staticClass: "column_header_name" }, [
        _vm._v(_vm._s(_vm.content.title)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column_content" },
      _vm._l(_vm.columnForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "column_content_item",
            on: {
              click: function ($event) {
                return _vm.indexClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "column_content_img" }, [
              _c("img", {
                attrs: { src: item.thumbnail, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column_content_box" }, [
              _c("div", { staticClass: "flex-column" }, [
                _c("span", { staticClass: "column_content_title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "column_content_subTitle" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column_content_price_box" }, [
                _c("span", { staticClass: "column_content_price" }, [
                  _vm._v("¥" + _vm._s(item.price)),
                ]),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }