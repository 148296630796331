var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swiperBox" },
    [
      _c(
        "swiper",
        {
          attrs: { options: _vm.swiperOption },
          on: { swiper: _vm.onSwiper, slideChange: _vm.onSlideChange },
        },
        _vm._l(_vm.adForm, function (item) {
          return _c(
            "swiper-slide",
            { key: item.id, staticClass: "swiper-slide" },
            [
              _c("div", { staticClass: "img" }, [
                _c("img", {
                  attrs: {
                    src: item.thumbnail,
                    width: "100%",
                    height: "100%",
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.adClick(item)
                    },
                  },
                }),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }