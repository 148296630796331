import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removeToken } from '@/utils/auth';
import { getFinishData, addFinishData, getOrgTree } from '@/api/user';
import Distpicker from "../common/Distpicker.vue";
var _data = new Date().getFullYear();
export default {
  name: 'info',
  data: function data() {
    return {
      agreement_checked: false,
      dialogVisible: (process.env.VUE_APP_RECORD || 0) == 1,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(_data, 11, 31),
      show: false,
      currentTime: '',
      allList: [],
      dataList: [],
      requireList: [],
      //是否必填
      form: {
        empName: '',
        sex: '',
        phone: '',
        birthday: '',
        national: '',
        registerPlace: '',
        maritalStatus: '',
        workPlace: '',
        education: '',
        job: '',
        salary: '',
        political: '',
        height: '',
        weight: '',
        hc: '',
        childbirthType: '',
        foster: '',
        motherChildbirthAge: '',
        brotherNum: '',
        familyStructure: '',
        familyIncome: '',
        certificatesType: '',
        certificatesNo: '',
        sin: '',
        gestationalAge: '',
        company: '',
        studyNo: '',
        health: '',
        personalNeuropathy: '',
        familyNeuropathy: '',
        learning: '',
        address: '',
        parentContact: '',
        emergencyContact: '',
        familyRelation: '',
        examineeNumber: ''
      },
      redirect: undefined,
      fullscreenLoading: false,
      // 页面加载
      total: [{
        marriageName: '0.3万及以下'
      }, {
        marriageName: '0-3万'
      }, {
        marriageName: '3-8万'
      }, {
        marriageName: '8-15万'
      }, {
        marriageName: '15-30万'
      }, {
        marriageName: '30-100万'
      }, {
        marriageName: '100-500万'
      }, {
        marriageName: '500万以上'
      }],
      Brothers: [{
        marriageName: '独生子女'
      }, {
        marriageName: '1个'
      }, {
        marriageName: '2个'
      }, {
        marriageName: '3个'
      }, {
        marriageName: '4个及以上'
      }],
      family: [{
        marriageName: '核心家庭（父母+未婚独生子女）'
      }, {
        marriageName: '核心家庭（父母+未婚多子女）'
      }, {
        marriageName: '夫妻家庭（无子女）'
      }, {
        marriageName: '主干家庭（父母和1对已婚子女组成家庭）'
      }, {
        marriageName: '联合家庭（父母和2对及以上已婚子女组成家庭）'
      }, {
        marriageName: '单亲家庭'
      }, {
        marriageName: '离异家庭'
      }, {
        marriageName: '重组家庭'
      }, {
        marriageName: '留守家庭'
      }, {
        marriageName: '其他'
      }],
      raising: [{
        marriageName: '爸爸'
      }, {
        marriageName: '妈妈'
      }, {
        marriageName: '爷爷奶奶'
      }, {
        marriageName: '外公外婆'
      }, {
        marriageName: '其他（如保姆）'
      }],
      political: [{
        marriageName: '群众'
      }, {
        marriageName: '无党派人士'
      }, {
        marriageName: '共青团员'
      }, {
        marriageName: '中共党员（预备党员）'
      }, {
        marriageName: '其他党派/团体'
      }],
      income: [{
        marriageName: '2-4K'
      }, {
        marriageName: '4-6K'
      }, {
        marriageName: '6-8K'
      }, {
        marriageName: '8K-1万'
      }, {
        marriageName: '1-5万'
      }, {
        marriageName: '5-10万'
      }, {
        marriageName: '10-30万'
      }, {
        marriageName: '30万以上'
      }],
      marriage: [{
        marriageName: '单身（无恋爱经验）'
      }, {
        marriageName: '单身（有恋爱经验）'
      }, {
        marriageName: '恋爱'
      }, {
        marriageName: '已婚（未育）'
      }, {
        marriageName: '已婚（已育）'
      }, {
        marriageName: '离异'
      }, {
        marriageName: '丧偶'
      }],
      education: [{
        marriageName: '文盲'
      }, {
        marriageName: '小学'
      }, {
        marriageName: '初中'
      }, {
        marriageName: '高中或中专'
      }, {
        marriageName: '大专'
      }, {
        marriageName: '本科'
      }, {
        marriageName: '硕士研究生'
      }, {
        marriageName: '博士研究生'
      }],
      professional: [{
        marriageName: '国家机关、党群组织、企业、事业单位负责人'
      }, {
        marriageName: '专业技术人员'
      }, {
        marriageName: '办事人员和有关人员'
      }, {
        marriageName: '商业、服务业人员'
      }, {
        marriageName: '农、林、牧、渔、水利业生产人员'
      }, {
        marriageName: '生产、运输设备操作人员及有关人员'
      }, {
        marriageName: '军人'
      }, {
        marriageName: '学生'
      }, {
        marriageName: '其他职业'
      }],
      nations: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族'],
      documentList: ['居民二代身份证', '港澳台通行证'],
      learningList: ['优秀', '良好', '合格', '待合格'],
      RelationList: ['和睦', '一般', '父母关系不和', '与父亲关系不和', '与母亲关系不和'],
      minWidth: 0,
      district: {
        province: '',
        city: '',
        area: ''
      },
      treeList: []
    };
  },
  components: {
    Distpicker: Distpicker
  },
  created: function created() {
    this.init();
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  methods: {
    // 点击显示日期组件
    onBirthday: function onBirthday() {
      this.currentTime = new Date(this.form.birthday);
      this.show = true;
    },
    // 确定
    onDataCofig: function onDataCofig(val) {
      this.show = false;
      this.form.birthday = this.timeFormat(val);
    },
    formatter: function formatter(type, val) {
      if (type === 'year') {
        return "".concat(val, "\u5E74");
      } else if (type === 'month') {
        return "".concat(val, "\u6708");
      } else if (type === 'day') {
        return "".concat(val, "\u65E5");
      }
      return val;
    },
    // 时间格式化
    timeFormat: function timeFormat(time) {
      // 时间格式化 2019-09-08
      var year = time.getFullYear();
      var month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
      var day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
      return year + '-' + month + '-' + day;
    },
    // 城市选择
    onSelected: function onSelected(val) {
      this.form.workPlace = val.province.value + '-' + val.city.value + '-' + val.area.value;
    },
    // 点击显示弹框
    bouncedCancel: function bouncedCancel() {
      this.marriageStatus = false;
    },
    init: function init() {
      var _this = this;
      // 获取需要完成的字段
      this.fullscreenLoading = true;
      getFinishData().then(function (data) {
        _this.fullscreenLoading = false;
        _this.allList = data.data.fieldList;
        _this.dataList = [];
        _this.requireList = [];
        data.data.fieldList.forEach(function (item) {
          _this.dataList.push(item.label);
          _this.requireList.push(item.required);
          if (item.field == 'empName') {
            _this.form.empName = item.value;
          } else if (item.field == 'sex') {
            _this.form.sex = item.value;
          } else if (item.field == 'phone') {
            _this.form.phone = item.value;
          } else if (item.field == 'birthday') {
            _this.form.birthday = item.value;
          } else if (item.field == 'national') {
            _this.form.national = item.value;
          } else if (item.field == 'registerPlace') {
            _this.form.registerPlace = item.value;
          } else if (item.field == 'maritalStatus') {
            _this.form.maritalStatus = item.value;
          } else if (item.field == 'workPlace') {
            _this.form.workPlace = item.value;
          } else if (item.field == 'education') {
            _this.form.education = item.value;
          } else if (item.field == 'job') {
            _this.form.job = item.value;
          } else if (item.field == 'salary') {
            _this.form.salary = item.value;
          } else if (item.field == 'political') {
            _this.form.political = item.value;
          } else if (item.field == 'height') {
            _this.form.height = item.value;
          } else if (item.field == 'weight') {
            _this.form.weight = item.value;
          } else if (item.field == 'hc') {
            _this.form.hc = item.value;
          } else if (item.field == 'childbirthType') {
            _this.form.childbirthType = item.value;
          } else if (item.field == 'foster') {
            _this.form.foster = item.value;
          } else if (item.field == 'motherChildbirthAge') {
            _this.form.motherChildbirthAge = item.value;
          } else if (item.field == 'brotherNum') {
            _this.form.brotherNum = item.value;
          } else if (item.field == 'familyStructure') {
            _this.form.familyStructure = item.value;
          } else if (item.field == 'familyIncome') {
            _this.form.familyIncome = item.value;
          } else if (item.field == 'certificatesType') {
            _this.form.certificatesType = item.value;
          } else if (item.field == 'certificatesNo') {
            _this.form.certificatesNo = item.value;
          } else if (item.field == 'sin') {
            _this.form.sin = item.value;
          } else if (item.field == 'gestationalAge') {
            _this.form.gestationalAge = item.value;
          } else if (item.field == 'company') {
            _this.form.company = item.value ? parseInt(item.value) : item.value;
          } else if (item.field == 'studyNo') {
            _this.form.studyNo = item.value;
          } else if (item.field == 'health') {
            _this.form.health = item.value;
          } else if (item.field == 'personalNeuropathy') {
            _this.form.personalNeuropathy = item.value;
          } else if (item.field == 'familyNeuropathy') {
            _this.form.familyNeuropathy = item.value;
          } else if (item.field == 'learning') {
            _this.form.learning = item.value;
          } else if (item.field == 'address') {
            _this.form.address = item.value;
          } else if (item.field == 'parentContact') {
            _this.form.parentContact = item.value;
          } else if (item.field == 'emergencyContact') {
            _this.form.emergencyContact = item.value;
          } else if (item.field == 'familyRelation') {
            _this.form.familyRelation = item.value;
          } else if (item.field == 'examineeNumber') {
            _this.form.examineeNumber = item.value;
          }
        });
        localStorage.setItem('isAllFinish', data.data.isAllFinish);
        // 等于0的时候，说明没有找到成员，可能是因为是老账号，要退出重新登录
        if (data.data.companyEmployeeId == 0) {
          removeToken();
          _this.$router.push({
            path: _this.redirect || '/'
          });
        }
        // 等于1时候  就是完成资料 跳转到首页
        if (data.data.isAllFinish == 1) {
          _this.$router.push({
            path: _this.redirect || '/'
          });
        }
      }).catch(function (err) {});
      getOrgTree().then(function (res) {
        _this.treeList = res.data;
      }).catch(function (err) {});
    },
    // 保存
    addSubmit: function addSubmit() {
      var _this2 = this;
      this.isSubmit = true;
      addFinishData(this.form).then(function (res) {
        if (res.type = 'success') {
          _this2.isSubmit = false;
          _this2.$message({
            message: res.content,
            type: 'success'
          });
          localStorage.setItem('isAllFinish', 1);
          var reUrl = sessionStorage.getItem('redirectUrl');
          setTimeout(function () {
            if (!!reUrl) {
              sessionStorage.removeItem('redirectUrl');
              _this2.$router.push({
                path: reUrl
              });
            } else {
              _this2.$router.push({
                path: _this2.redirect || '/'
              });
            }
          });
        }
        // console.log(res.type)
      }).catch(function (err) {
        _this2.isSubmit = false;
      });
    },
    getReqire: function getReqire(val) {
      var lable = '';
      this.allList.forEach(function (item) {
        if (val == item.label) {
          lable = item.required;
        }
      });
      return lable == 1 ? true : false;
    },
    setMinWidth: function setMinWidth(val) {
      this.minWidth = val.srcElement.clientWidth;
    }
  }
};