"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      rangeValue: 0,
      optionList: [],
      average: 0
    };
  },
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    },
    percenter: {
      default: ''
    },
    beforeTopic: {
      default: ''
    }
  },
  mounted: function mounted() {
    this.reset();
  },
  watch: {
    percents: function percents(newVal, oldVal) {
      this.reset();
    }
  },
  components: {},
  methods: {
    topic: function topic() {
      var _this = this;
      console.log(_this.beforeTopic);
      console.log(_this.nowAnswer);
      if (!_utils.default.isNull(_this.beforeTopic)) {
        _this.beforeTopic.forEach(function (item) {
          _this.nowAnswer.options.forEach(function (items) {
            if (item.questionId == _this.nowAnswer.id && item.optionId == items.id) {
              items.rangeValue = Math.round(item.score / items.score * (parseInt(items.max) - parseInt(items.min)) * 100) / 100;
              items.rangeValues = Math.round((item.score / items.score * (parseInt(items.max) - parseInt(items.min)) + parseInt(items.min)) * 100) / 100;
            }
          });
        });
      }
    },
    reset: function reset() {
      var _this = this;
      console.log(this.nowAnswer);
      this.nowAnswer.options.forEach(function (item) {
        item.minBox = 0;
        item.maxBox = parseInt(item.max) - parseInt(item.min);
        _this.average = item.maxBox / 5;
        item.Two = parseInt(item.min) + _this.average;
        item.three = parseInt(item.min) + _this.average * 2;
        item.four = parseInt(item.min) + _this.average * 3;
        item.five = parseInt(item.min) + _this.average * 4;
        item.rangeValue = 0;
        item.rangeValues = parseInt(item.min);
        if (item.maxBox % 5 === 0) {
          item.aliquot = true;
        } else {
          item.aliquot = false;
        }
      });
      this.topic();
      this.$forceUpdate();
      console.log(this.nowAnswer);
    },
    ontouchmove: function ontouchmove(index) {
      console.log(index);
      var _this = this;
      _this.nowAnswer.options[index].rangeValues = 0;
      for (var i = 0; i < _this.nowAnswer.options.length; i++) {
        _this.nowAnswer.options[i].rangeValues = _this.nowAnswer.options[i].rangeValue + parseInt(_this.nowAnswer.options[i].min);
      }
      console.log(_this.nowAnswer);
      this.$forceUpdate();
    },
    search: function search(index) {
      var _this = this;
      console.log(index);
      // if (this.rangeValue < parseInt(_this.nowAnswer.content[0].min)) {
      //   _this.rangeValue = parseInt(_this.nowAnswer.content[0].min)
      // }else if(_this.rangeValue > parseInt(_this.nowAnswer.content[0].max)){
      //   _this.rangeValue = parseInt(_this.nowAnswer.content[0].max)
      // }
      // this.nowAnswer.content[0].rangeValue = 0
      // this.nowAnswer.content[0].rangeValue  = Number(this.rangeValue) - parseInt(this.nowAnswer.content[0].min)

      if (_this.nowAnswer.options[index].rangeValues < parseInt(_this.nowAnswer.options[index].min)) {
        _this.nowAnswer.options[index].rangeValues = parseInt(_this.nowAnswer.options[index].min);
      } else if (_this.nowAnswer.options[index].rangeValues > parseInt(_this.nowAnswer.options[index].max)) {
        _this.nowAnswer.options[index].rangeValues = parseInt(_this.nowAnswer.options[index].max);
      }
      this.nowAnswer.options[index].rangeValue = 0;
      this.nowAnswer.options[index].rangeValue = Number(this.nowAnswer.options[index].rangeValues) - parseInt(this.nowAnswer.options[index].min);
      console.log(_this.nowAnswer);
      this.$forceUpdate();
    },
    chooseAnswer: function chooseAnswer() {
      var _this = this;
      _this.optionList = [];
      _this.nowAnswer.options.forEach(function (item) {
        console.log(parseInt(item.rangeValue) + parseInt(item.min) - parseInt(item.min));
        console.log(parseInt(item.max) - parseInt(item.min));
        console.log(item.score);
        console.log((parseInt(item.rangeValue) + parseInt(item.min) - parseInt(item.min)) / (parseInt(item.max) - parseInt(item.min)) * item.score);
        var data = {
          gene: item.gene,
          optionId: item.id,
          rangeValue: Math.round((parseInt(item.rangeValue) + parseInt(item.min) - parseInt(item.min)) / (parseInt(item.max) - parseInt(item.min)) * Number(item.score) * 100) / 100
        };
        _this.optionList.push(data);
      });
      var data = {
        optionList: _this.optionList
      };
      _this.$emit("chooseAnswer", data);
    }
  }
};