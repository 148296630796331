"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _counselor = require("@/api/counselor");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      counselorForm: [],
      pageNum: 1,
      pageSize: 3,
      tagIds: []
    };
  },
  created: function created() {
    this.loadCounselor();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  filters: {
    watchMethod: function watchMethod(val) {
      if (val === 0) {
        return "面对面/线上咨询";
      } else if (val === 1) {
        return "面对面咨询";
      } else if (val === 2) {
        return "线上咨询";
      }
    }
  },
  props: ["content"],
  methods: {
    checkPermission: _permission.default,
    loadCounselor: function loadCounselor() {
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(",");
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", this.mchId);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append("tagIds", _this.tagIds[i]);
      }
      (0, _counselor.list)(params).then(function (response) {
        console.log(response);
        _this.counselorForm = response.data.data;
        _this.counselorForm.forEach(function (item) {
          item.avatar = _utils.default.thumbnail(item.avatar, 128, 128);
          item.specialitys = item.specialitys.split(",");
        });
      }).catch(function (error) {
        console.log("error!!");
      });
    },
    indexClick: function indexClick(id) {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/info/info",
          query: {
            id: id
          }
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/info",
          query: {
            id: id
          }
        });
      }
    },
    listClick: function listClick() {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/list"
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/list"
        });
      }
    }
  }
};