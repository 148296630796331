"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _activitySyQy = require("@/api/activitySyQy");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 1,
      pageSize: 3,
      activityOrderForm: []
    };
  },
  props: ["content"],
  created: function created() {
    this.loadactivity();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId", "token"])),
  methods: {
    goActivityDetails: function goActivityDetails(id) {
      this.$router.push({
        path: "/activitySy/details",
        query: {
          activityId: id
        }
      });
    },
    loadactivity: function loadactivity() {
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(',');
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append('tagIds', _this.tagIds[i]);
      }
      /* params.append("activityCategoryId", _this.activityCategoryId); */
      (0, _activitySyQy.list)(params).then(function (response) {
        /*  if (this.pageNum == 1) {
          this.activityOrderForm = [];
        } */
        // response.data.data.forEach(function(item) {
        //   item.thumbnail = utils.thumbnail(item.thumbnail, 188, 94);
        //   item.thumbnail = encodeURI(item.thumbnail);
        //   _this.activityOrderForm.push(item);
        //   console.log(response);
        // });

        _this.activityOrderForm = response.data.data.map(function (e) {
          e.thumbnail = _utils.default.thumbnail(e.thumbnail, 188, 94);
          e.thumbnail = encodeURI(e.thumbnail);
          return e;
        });
        /*   _this.loading = response.data.data.length < _this.pageSize; */
      }).catch(function (error) {
        /* this.loading = false */
      });
    }
  }
};