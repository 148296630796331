"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      coursesForm: [{
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）大范德萨范德萨发生大事撒大发生的发生',
        subtitle: '全国小学生必背经典内容发达撒发生的发生的范德萨发发呆萨芬撒的发生范德萨范德萨范德萨地方三大范德萨发顺丰',
        label: '24课时',
        price: '¥99.0'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）',
        subtitle: '全国小学生必背经典内容',
        label: '小班教学',
        price: '¥99.0'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）',
        subtitle: '全国小学生必背经典内容',
        label: '小班教学',
        price: '¥99.0'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）',
        subtitle: '全国小学生必背经典内容',
        label: '小班教学',
        price: '¥99.0'
      }]
    };
  }
};