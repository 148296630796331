"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _gaugeCategory = require("@/api/gaugeCategory");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      categoryData: [],
      subCategoryData: [],
      categoryIndex: 0,
      //当前选中下标
      subCategoryIndex: 0,
      oneScrollLeft: 0,
      //以免滚动栏的长度达不到清除计时器的所要求长度。避免无限死循环，判断上次滑动的距离跟这次滑动的距离来判断是否到底。
      twoScrollLeft: 0,
      loading: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  props: {
    categoryId: {
      type: String,
      default: ''
    }
  },
  mounted: function mounted() {
    this.loadGaugeCategory();
  },
  destroyed: function destroyed() {},
  methods: {
    loadGaugeCategory: function loadGaugeCategory() {
      var _this2 = this;
      var _this = this;
      var params = new URLSearchParams();
      params.append('categoryId', this.categoryId);
      params.append('mchId', _this.mchId);
      (0, _gaugeCategory.list)(params).then(function (response) {
        console.log(response);
        _this2.categoryData = response.data;
        if (_utils.default.isNull(_this.categoryId)) {
          _this.chooseCategory(0, _this.categoryData[0].id);
        } else {
          _this.categoryData.forEach(function (item, index) {
            if (item.id == _this.categoryId) {
              _this.chooseCategory(index, _this.categoryId);
            }
          });
        }
        // this.categoryData = response.data;
        // let categoryId = localStorage.getItem('categoryId');
        // if(!utils.isNull(categoryId)){ //如果有一级分类缓存 模仿点击一级分类
        //     console.log('有分类缓存')
        //     _this.categoryData.forEach(function (item,index) {
        //       if(item.id == categoryId){
        //         _this.chooseCategory(index,categoryId);
        //       }
        //     });
        //   setTimeout(function () { //如果有二级分类缓存 模仿点击二级分类
        //     if (!utils.isNull(localStorage.getItem('subCategoryId'))) {
        //       let subCategoryId = localStorage.getItem('subCategoryId');
        //       if (!utils.isNull(_this.subCategoryData)) {
        //         _this.subCategoryData.forEach(function (item, index) {
        //           if (item.id == subCategoryId) {
        //             _this.chooseSubCategory(index, subCategoryId);
        //           }
        //         });
        //       }
        //     }
        //   },200)
        // }else {
        //   this.chooseCategory(0,this.categoryData[0].id);
        // }
      }).catch(function (error) {});
    },
    //      一级分类点击事件
    chooseCategory: function chooseCategory(index, id) {
      var _this3 = this;
      var _this = this;
      if (_this.loading == true) {
        return;
      }
      _this.loading = true;
      setTimeout(function () {
        _this.loading = false;
      }, 500);
      this.subCategoryIndex = 0;
      var arr = [{
        id: id,
        name: "全部"
      }];
      this.categoryData[index].childrens.forEach(function (item) {
        arr.push(item);
      });
      this.subCategoryData = arr;
      this.$emit('categoryClick', id);

      // 为了回到本页 还有上次已选的分类 把一级分类存入缓存
      localStorage.setItem('categoryId', id);
      if (this.categoryIndex == index) {
        return;
      }
      var timer = null;
      var oneClassify = this.$refs.oneClassify;
      //        点击最右边
      if (index <= 1) {
        if (!_utils.default.isNull(timer)) {
          window.clearInterval(timer);
          timer = null;
        }
        timer = setInterval(function () {
          oneClassify.scrollLeft = oneClassify.scrollLeft - 3;
          if (oneClassify.scrollLeft <= 0) {
            window.clearInterval(timer);
            timer = null;
          }
        }, 1);
      } else if (index > 1) {
        //          向右滑动
        if (index > this.categoryIndex) {
          if (!_utils.default.isNull(timer)) {
            window.clearInterval(timer);
            timer = null;
          }
          timer = setInterval(function () {
            oneClassify.scrollLeft = oneClassify.scrollLeft + 3;
            //              console.log(oneClassify.scrollLeft)
            //              组件当前宽度
            //              console.log(oneClassify.clientWidth)
            if (oneClassify.scrollLeft >= index * 69 || _this3.oneScrollLeft == oneClassify.scrollLeft) {
              window.clearInterval(timer);
              timer = null;
            } else {
              //                用onescrollLeft来控制是否到最右边了
              _this3.oneScrollLeft = oneClassify.scrollLeft;
            }
            //            },20)
          }, 1);
        } else {
          //            向左滑动
          if (!_utils.default.isNull(timer)) {
            window.clearInterval(timer);
            timer = null;
          }
          timer = setInterval(function () {
            //              oneClassify.scrollLeft =  oneClassify.scrollLeft - 15
            oneClassify.scrollLeft = oneClassify.scrollLeft - 3;
            if (oneClassify.scrollLeft <= index * 69 || _this3.oneScrollLeft == oneClassify.scrollLeft) {
              window.clearInterval(timer);
              timer = null;
            } else {
              //                用onescrollLeft来控制是否到最左边了
              _this3.oneScrollLeft = oneClassify.scrollLeft;
            }
          }, 1);
        }
      }
      this.categoryIndex = index;
    },
    //      二级分类点击事件
    chooseSubCategory: function chooseSubCategory(index, id) {
      var _this4 = this;
      if (this.subCategoryIndex == index) {
        return;
      }
      // 为了回到本页 还有上次已选的分类 把二级分类存入缓存
      localStorage.setItem('subCategoryId', id);
      var timer = null;
      //        改变变量的指向
      var oneClassify = this.$refs.twoClassify;
      //        点击最右边
      if (index <= 1) {
        if (!_utils.default.isNull(timer)) {
          window.clearInterval(timer);
          timer = null;
        }
        timer = setInterval(function () {
          oneClassify.scrollLeft = oneClassify.scrollLeft - 3;
          if (oneClassify.scrollLeft <= 0) {
            window.clearInterval(timer);
            timer = null;
          }
        }, 1);
        //          滑倒最右边
      } else if (index >= this.subCategoryData.length - 2) {
        if (!_utils.default.isNull(timer)) {
          window.clearInterval(timer);
          timer = null;
        }
        timer = setInterval(function () {
          oneClassify.scrollLeft = oneClassify.scrollLeft + 3;
          if (oneClassify.scrollLeft >= oneClassify.clientWidth || _this4.twoScrollLeft == oneClassify.scrollLeft) {
            window.clearInterval(timer);
            timer = null;
          } else {
            _this4.twoScrollLeft = oneClassify.scrollLeft;
          }
        }, 1);
      } else if (index > 1) {
        //          向右滑动
        if (index > this.subCategoryIndex) {
          if (!_utils.default.isNull(timer)) {
            window.clearInterval(timer);
            timer = null;
          }
          timer = setInterval(function () {
            oneClassify.scrollLeft = oneClassify.scrollLeft + 3;
            //              console.log(oneClassify.scrollLeft)
            //              组件当前宽度
            //              console.log(oneClassify.clientWidth)
            if (oneClassify.scrollLeft >= index * 80 || _this4.twoScrollLeft == oneClassify.scrollLeft) {
              window.clearInterval(timer);
              timer = null;
            } else {
              //                用onescrollLeft来控制是否到最右边了
              _this4.twoScrollLeft = oneClassify.scrollLeft;
            }
          }, 1);
        } else {
          //            向左滑动
          if (!_utils.default.isNull(timer)) {
            window.clearInterval(timer);
            timer = null;
          }
          timer = setInterval(function () {
            oneClassify.scrollLeft = oneClassify.scrollLeft - 3;
            if (oneClassify.scrollLeft <= index * 80 || _this4.twoScrollLeft == oneClassify.scrollLeft) {
              window.clearInterval(timer);
              timer = null;
            } else {
              //                用onescrollLeft来控制是否到最左边了
              _this4.twoScrollLeft = oneClassify.scrollLeft;
            }
          }, 1);
        }
      }
      this.subCategoryIndex = index;
      this.$emit('categoryClick', id);
    }
  }
};