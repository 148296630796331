"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _gaugePlan = require("@/api/gaugePlan");
var _companyEmployee = require("@/api/companyEmployee");
var _auth = require("@/utils/auth");
var _store = _interopRequireDefault(require("@/store"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      gaugeList: [],
      pageNum: 1,
      pageSize: 10,
      loading: true,
      isMaskType: false,
      empName: '',
      empNo: '',
      title: '',
      name: '',
      code: '',
      userInfo: {},
      getUser: '' // 获取登录信息
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  created: function created() {
    var company = localStorage.getItem('companyEmployee');
    if (!company) {
      (0, _auth.removeToken)();
      this.$router.replace({
        path: '/newLogin?redirect=assessment/personalList?id=' + (this.$route.query.id || '')
      });
      return;
      // store.dispatch('user/resetToken').then(() => {
      //   removeToken()
      //   this.$router.replace({
      //     path: '/newLogin?redirect=assessment/list'
      //   })
      // })
    }
    // this.loadIsBind()
    this.getMchInfo();
    if (this.$route.query.mchId) {
      this.$store.commit('SET_MCHID', this.$route.query.mchId);
    }
    this.isMaskType = this.$route.query.isMaskType == 1;
    // if (this.isMaskType === true) {
    //   this.load()
    //   if (JSON.parse(localStorage.getItem('assessment-list-account'))) {
    //     this.userInfo = JSON.parse(
    //       localStorage.getItem('assessment-list-account')
    //     )
    //   }
    // }
    this.load();
  },
  filters: {
    watchStatus: function watchStatus(val) {
      switch (val) {
        case 0:
          return '未完成';
          break;
        case 1:
          return '已完成';
          break;
      }
    },
    dateTimeFormatter: function dateTimeFormatter(data) {
      if (!data) {
        return;
      }
      var dt = new Date(data);
      var yyyy = dt.getFullYear();
      var MM = (dt.getMonth() + 1).toString().padStart(2, '0');
      var dd = dt.getDate().toString().padStart(2, '0');
      var h = dt.getHours().toString().padStart(2, '0');
      var m = dt.getMinutes().toString().padStart(2, '0');
      var s = dt.getSeconds().toString().padStart(2, '0');
      return yyyy + '年' + MM + '月' + dd + '日' + ' ' + h + ':' + m;
    }
  },
  methods: {
    getMchInfo: function getMchInfo() {
      var _this2 = this;
      console.log(window.frames.length);
      var params = new URLSearchParams();
      params.append('mchId', this.mchId);
      //   if (window.frames.length != parent.frames.length) {
      //     iframeGetSysMchTitleInfo(params)
      //       .then((res) => {
      //         if (res.data.sysMchTitle) {
      //           this.title = res.data.sysMchTitle.mchTitle
      //           this.code = res.data.sysMchTitle.mchCode
      //           this.name = res.data.sysMchTitle.mchName
      //         }
      //         this.sysMchTitle = res.data.sysMchTitle
      //       })
      //       .catch((err) => {
      //         console.log(err)
      //       })
      //   } else {
      (0, _gaugePlan.getSysMchTitleInfo)(params).then(function (res) {
        if (res.data.sysMchTitle) {
          _this2.title = res.data.sysMchTitle.mchTitle;
          _this2.code = res.data.sysMchTitle.mchCode;
          _this2.name = res.data.sysMchTitle.mchName;
        }
        _this2.sysMchTitle = res.data.sysMchTitle;
        _this2.getMy();
      }).catch(function (err) {
        console.log(err);
      });
      //   }
    },
    // 获取登录成员信息
    getMy: function getMy() {
      var _this3 = this;
      (0, _gaugePlan.getInfo)().then(function (res) {
        _this3.getUser = res.data;
      }).catch(function (err) {});
    },
    logoutClick: function logoutClick() {
      // store.dispatch('user/resetToken').then(() => {
      localStorage.removeItem('companyEmployee');
      (0, _auth.removeToken)();
      this.$router.replace({
        path: '/newLogin?redirect=assessment/list'
      });
      // })
      //   localStorage.removeItem('assessment-list-account')
      //   this.userInfo = {}
      //   this.empName = ''
      //   this.empNo = ''
      //   this.isMaskType = false
      //   this.gaugeList = []
      //   this.pageNum = 1
      //   this.pageSize = 10
      //   this.loading = true
    },
    loadIsBind: function loadIsBind() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchId', _this.mchId);
      (0, _companyEmployee.isBind)(params).then(function (response) {
        if (response.data == true) {
          _this.isMaskType = true;
          _this.load();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    submitClick: function submitClick() {
      var _this = this;
      if (_utils.default.isNull(_this.empName) || _utils.default.isNull(_this.empNo)) {
        this.$message('请输入姓名和编号');
        return;
      }
      var params = new URLSearchParams();
      params.append('empName', _this.empName);
      params.append('empNo', _this.empNo);
      params.append('mchId', this.mchId);
      //        if (window.frames.length != parent.frames.length) {
      (0, _gaugePlan.iframeBind)(params).then(function (response) {
        (0, _auth.setToken)(response.data.token);
        localStorage.setItem('assessment-list-account', JSON.stringify({
          name: _this.empName,
          code: _this.empNo
        }));
        _this.userInfo = JSON.parse(localStorage.getItem('assessment-list-account'));
        _this.isMaskType = true;
        _this.load();
      }).catch(function (error) {
        console.log(error);
        //          this.$router.go(-1)
      });
      //        }else {
      //          bind(params).then(response => {
      //            localStorage.setItem('assessment-list-account', JSON.stringify({name: _this.empName,code:_this.empNo}));
      //            _this.userInfo = JSON.parse(localStorage.getItem('assessment-list-account'));
      //            _this.isMaskType = true;
      //            _this.load()
      //          }).catch(error => {
      //            console.log(error)
      ////          this.$router.go(-1)
      //          });
      //        }
    },
    assessmentInfo: function assessmentInfo(id, reportFlag) {
      var _this = this;
      var params = new URLSearchParams();
      params.append('planId', id);
      (0, _gaugePlan.create)(params).then(function (response) {
        var evaluation = response.data.evaluation;
        var attrUnfillFlag = response.data.attrUnfillFlag;
        var mchGauge = response.data.mchGauge;
        var gauge = response.data.gauge;
        if (attrUnfillFlag == 1) {
          _this.$router.replace({
            path: '/gauge/info',
            query: {
              infors: gauge.infos,
              id: mchGauge.id,
              evaluationId: evaluation.id,
              reportFlag: reportFlag,
              planId: id,
              isTeam: 2,
              // 是个人
              type: 1
            }
          });
          return;
        }
        _this.$router.replace({
          path: '/gauge/answer',
          query: {
            evaluationId: evaluation.id,
            planId: id,
            reportFlag: reportFlag,
            isTeam: 2 // 是个人
          }
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    load: function load() {
      var _this = this;
      this.loading = true;
      console.log(_this.mchId);
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', this.mchId);
      //  专题id
      params.append('specialTopicId', this.$route.query.id || '');
      //   if (window.frames.length != parent.frames.length) {
      //     iframeList(params)
      //       .then((response) => {
      //         response.data.data.forEach(function (item) {
      //           _this.gaugeList.push(item)
      //         })
      //         _this.loading = response.data.data.length < _this.pageSize
      //         console.log(_this.loading)
      //         console.log(_this.gaugeList)
      //       })
      //       .catch((error) => {
      //         _this.loading = false
      //       })
      //   } else {
      (0, _gaugePlan.perList)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.gaugeList.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
        console.log(_this.loading);
        console.log(_this.gaugeList);
      }).catch(function (error) {
        _this.loading = false;
      });
      //   }
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.load();
    }
  }
};