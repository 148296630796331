var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cash" },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "banner" }, [
          _c("span", [_vm._v("可提现余额(元)")]),
          _vm._v(" "),
          _c("span", [_vm._v("¥" + _vm._s(_vm.balance))]),
          _vm._v(" "),
          _c("button", { on: { click: _vm.goCashSetup } }, [_vm._v("提现")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "records" },
          [
            _c("span", { staticClass: "recordTitle" }, [_vm._v("账单记录")]),
            _vm._v(" "),
            _vm._l(_vm.recordList, function (item, i) {
              return _c("div", { key: i, staticClass: "record" }, [
                _c("div", { staticClass: "record-top" }, [
                  _c("span", [_vm._v(_vm._s(item.memo))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.credit - item.debit))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "record-bottom" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("formatDate")(item.createDate))),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(" 余额: " + _vm._s(item.balance))]),
                ]),
              ])
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.bottomText,
                expression: "bottomText",
              },
            ],
            staticClass: "bottomText",
          },
          [_vm._v("——    没有更多了    ——")]
        ),
      ]),
      _vm._v(" "),
      _c("my-bottom"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }