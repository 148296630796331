"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindUser = bindUser;
exports.findCounselor = findCounselor;
exports.getQrcode = getQrcode;
var _request = _interopRequireDefault(require("@/utils/request"));
function bindUser(id) {
  return (0, _request.default)({
    url: '/user/wxbind/' + id,
    method: 'post'
  });
}
function findCounselor(id) {
  return (0, _request.default)({
    url: '/member/counselor/wxbind/' + id,
    method: 'post'
  });
}
function getQrcode(params) {
  return (0, _request.default)({
    url: '/enterprise/getQrcode',
    method: 'get',
    params: params
  });
}