"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  data: function data() {
    return {};
  },
  methods: {
    indexClick: function indexClick() {
      this.$router.push({
        path: '/'
      });
    },
    memberClick: function memberClick() {
      this.$router.push({
        path: '/member/member'
      });
    },
    promptClick: function promptClick() {
      var _this = this;
      _this.$message({
        message: 'ild:p',
        offset: 120,
        center: true
      });
    }
  }
};