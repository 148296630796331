import request from '@/utils/request';
export function list(params) {
  return request({
    url: '/member/leaveMsg/list',
    method: 'get',
    params: params
  });
}
export function usrList(params) {
  return request({
    url: '/member/sysUser/listAdmin',
    method: 'get',
    params: params
  });
}

//  获取留言对话框消息
export function getList(params) {
  return request({
    url: '/member/leaveMsg/dialogMsg',
    method: 'get',
    params: params
  });
}

// 回复消息
export function send(data) {
  return request({
    url: '/admin/leaveMsg/send',
    method: 'post',
    data: data
  });
}