"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      input2: ''
    };
  },
  methods: {
    promptClick: function promptClick() {
      var _this = this;
      _this.$message({
        message: '功能尚未开发',
        offset: 120,
        center: true
      });
    }
  }
};