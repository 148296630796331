var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "assessment_info_content" }, [
        _c("div", { staticClass: "assessment-box" }, [
          _c("span", { staticClass: "assessment_info_content_title" }, [
            _vm.getReqire("性别")
              ? _c("span", { staticClass: "red" }, [_vm._v("*")])
              : _vm._e(),
            _vm._v("性别"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "assessment_info_content_input" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sex", $$v)
                    },
                    expression: "form.sex",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "男" } }, [_vm._v("男")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: "女" } }, [_vm._v("女")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm.getReqire("出生日期")
                ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                : _vm._e(),
              _vm._v("出生日期"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c("el-input", {
                  staticClass: "assessment_info_content_input_box",
                  attrs: { placeholder: "请选择出生日期", readonly: "" },
                  on: { focus: _vm.onBirthday },
                  model: {
                    value: _vm.form.birthday,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "birthday", $$v)
                    },
                    expression: "form.birthday",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("文化程度"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择文化程度" },
                    model: {
                      value: _vm.form.education,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "education", $$v)
                      },
                      expression: "form.education",
                    },
                  },
                  _vm._l(_vm.education, function (item) {
                    return _c("el-option", {
                      key: item.marriageName,
                      attrs: {
                        label: item.marriageName,
                        value: item.marriageName,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("职业"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择职业" },
                    on: { focus: _vm.setMinWidth },
                    model: {
                      value: _vm.form.job,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "job", $$v)
                      },
                      expression: "form.job",
                    },
                  },
                  _vm._l(_vm.professional, function (item) {
                    return _c("el-option", {
                      key: item.marriageName,
                      style: { width: _vm.minWidth + 2 + "px" },
                      attrs: {
                        label: item.marriageName,
                        value: item.marriageName,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("月收入"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择月收入" },
                    model: {
                      value: _vm.form.salary,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "salary", $$v)
                      },
                      expression: "form.salary",
                    },
                  },
                  _vm._l(_vm.income, function (item) {
                    return _c("el-option", {
                      key: item.marriageName,
                      attrs: {
                        label: item.marriageName,
                        value: item.marriageName,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("婚姻状况"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择婚姻状况" },
                    model: {
                      value: _vm.form.maritalStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "maritalStatus", $$v)
                      },
                      expression: "form.maritalStatus",
                    },
                  },
                  _vm._l(_vm.marriage, function (item) {
                    return _c("el-option", {
                      key: item.marriageName,
                      attrs: {
                        label: item.marriageName,
                        value: item.marriageName,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("民族"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择民族" },
                    model: {
                      value: _vm.form.national,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "national", $$v)
                      },
                      expression: "form.national",
                    },
                  },
                  _vm._l(_vm.nations, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("宗教信仰"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "宗教信仰" },
                    model: {
                      value: _vm.form.religion,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "religion", $$v)
                      },
                      expression: "form.religion",
                    },
                  },
                  _vm._l(_vm.religion, function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item, value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "assessment-box" }, [
            _c("span", { staticClass: "assessment_info_content_title" }, [
              _vm._v("居住地址(可不填)"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "assessment_info_content_input" },
              [
                _c("el-input", {
                  staticClass: "assessment_info_content_input_box",
                  attrs: { placeholder: "请输入居住地址" },
                  model: {
                    value: _vm.form.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "address", $$v)
                    },
                    expression: "form.address",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "assessment_info_botton",
            attrs: { loading: _vm.isSubmit },
            on: { click: _vm.addSubmit },
          },
          [_vm._v("\n      提交\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          style: { height: "320px" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择年月日",
              formatter: _vm.formatter,
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.onDataCofig,
              cancel: function ($event) {
                _vm.show = false
              },
            },
            model: {
              value: _vm.currentTime,
              callback: function ($$v) {
                _vm.currentTime = $$v
              },
              expression: "currentTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "心理测验知情同意书",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            center: true,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", { staticClass: "agreement_content" }, [
            _c("div", [_vm._v("尊敬的参与者：")]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v("您好，感谢您参与本次心理测验研究！"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "本知情同意书旨在向您详细说明此次测验的目的、过程以及您的权利和义务，请您仔细阅读与知晓。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "本次测验所得数据仅用于科学研究。通过您的认真参与，我们能够获得宝贵的数据资源，这将对我们的研究提供有力的支持。通过数据收集与进一步分析所得出的最终结论将推动心理健康评估领域的进步，对未来心理健康检测有重要的启示作用。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "本次测验内容主要有基本信息收集、一系列的心理量表测试与阅读一段话的录音数据采集。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "我们将严格遵守保密原则，所有收集到的信息将仅用于专业人员的科学研究，并且不会透露给无关人员。除非法律或政府机关要求，否则不会泄露您的个人资料与数据。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "参与此次心理测验完全基于您的自愿。请您详细阅读以上说明，您有权随时决定是否继续参与。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "您已经仔细阅读了本知情同意书，并且了解本次测验的目的、内容等信息。您已充分理解并同意参与此次心理测验。再次感谢您的配合与参与！"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "agree" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { "text-color": "#818181" },
                  model: {
                    value: _vm.agreement_checked,
                    callback: function ($$v) {
                      _vm.agreement_checked = $$v
                    },
                    expression: "agreement_checked",
                  },
                },
                [_vm._v("我已仔细阅读并同意")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "agreement_tltle" }, [
                _vm._v("《读一段话测试协议》"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "primary",
                    round: "true",
                    disabled: !_vm.agreement_checked,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("同 意")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "assessment_info_header" }, [
      _c("div", { staticClass: "assessment_info_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            alt: "",
            src: "http://rzico.oss-cn-shenzhen.aliyuncs.com/meixinshuo/assessment_header.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "assessment_info_header_box" }, [
        _c("div", { staticClass: "assessment_info_header_title" }, [
          _vm._v(
            "\n        为了提高测验的有效性和准确性，我们将收集您以下信息，这些信息只会被用于测验本身，并严格保密，请放心填写。\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }