"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      number: []
    };
  },
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    },
    percenter: {
      default: ''
    },
    beforeTopic: {
      default: ''
    }
  },
  mounted: function mounted() {
    this.reset();
  },
  watch: {
    percents: function percents(newVal, oldVal) {
      this.reset();
    }
  },
  methods: {
    topic: function topic() {
      var _this = this;
      console.log(_this.beforeTopic);
      console.log(_this.nowAnswer);
      if (!_utils.default.isNull(_this.beforeTopic)) {
        _this.beforeTopic.forEach(function (item) {
          _this.nowAnswer.options.forEach(function (items) {
            if (item.questionId == _this.nowAnswer.id && item.optionId == items.id) {
              items.status = true;
            }
          });
        });
      }
    },
    reset: function reset() {
      this.nowAnswer.options.forEach(function (item) {
        item.status = false;
      });
      this.topic();
      this.$forceUpdate();
    },
    sortbotton: function sortbotton(index) {
      var _this = this;
      if (_this.nowAnswer.options[index].status == false) {
        _this.nowAnswer.options[index].status = true;
      } else {
        _this.nowAnswer.options[index].status = false;
      }
      this.$forceUpdate();
    },
    chooseAnswer: function chooseAnswer() {
      var _this = this;
      _this.optionList = [];
      _this.nowAnswer.options.forEach(function (item) {
        if (item.status == true) {
          var numberContent = {
            optionId: item.id,
            score: item.score,
            gene: item.gene
          };
          _this.optionList.push(numberContent);
        }
      });
      if (_this.optionList.length <= 1) {
        console.log(_this.optionList);
        _this.$message({
          showClose: true,
          message: '请至少选择两个选项！',
          type: 'warning'
        });
        return;
      }
      var data = {
        optionList: _this.optionList
      };
      console.log(data);
      _this.$emit("chooseAnswer", data);
    }
  }
};