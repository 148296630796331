var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "login-infoBox" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                autocomplete: "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "login-title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.title || "心理测评系统") +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-item-box" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "username",
                        attrs: {
                          placeholder: "请输入" + _vm.setName || "账号",
                          name: "username",
                          type: "text",
                          tabindex: "1",
                          autocomplete: "on",
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "Caps lock is On",
                    placement: "right",
                    manual: "",
                  },
                  model: {
                    value: _vm.capsTooltip,
                    callback: function ($$v) {
                      _vm.capsTooltip = $$v
                    },
                    expression: "capsTooltip",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-item-box" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "password" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: _vm.passwordType,
                              placeholder: "请输入" + _vm.setCode || "密码",
                              name: "password",
                              tabindex: "2",
                              autocomplete: "on",
                            },
                            on: {
                              blur: function ($event) {
                                _vm.capsTooltip = false
                              },
                            },
                            nativeOn: {
                              keyup: [
                                function ($event) {
                                  return _vm.checkCapslock($event)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              ],
                            },
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "password", $$v)
                              },
                              expression: "loginForm.password",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-item-box" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "captcha" } },
                            [
                              _c("el-input", {
                                staticClass: "verifyCode",
                                staticStyle: {
                                  width: "100%",
                                  "padding-left": "10px",
                                },
                                attrs: {
                                  type: "text",
                                  placeholder: "请输入验证码",
                                },
                                model: {
                                  value: _vm.loginForm.captcha,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "captcha", $$v)
                                  },
                                  expression: "loginForm.captcha",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 11, offset: 1 } }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "codeImg",
                              on: { click: _vm.createCaptcha },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.codeImg,
                                  width: "100%",
                                  height: "100%",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "loginButton", on: { click: _vm.handleLogin } },
                [
                  _c("img", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      alt: "",
                      src: require("./images/login-button.png"),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerBox" }, [
      _c("img", {
        staticClass: "login-topImg",
        attrs: { src: require("./images/login-topImg.png") },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "login-left-mainImg" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            src: require("./images/login-mainImg.png"),
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }