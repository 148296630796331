"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkExists = checkExists;
exports.columnCreateCourse = columnCreateCourse;
exports.count = count;
exports.courseCreate = courseCreate;
exports.create = create;
exports.find = find;
exports.list = list;
var _request = _interopRequireDefault(require("@/utils/request"));
function list(params) {
  return (0, _request.default)({
    url: "/member/courseOrder/list",
    method: "get",
    params: params
  });
}
function count(params) {
  return (0, _request.default)({
    url: '/member/courseOrder/count',
    method: 'get',
    params: params
  });
}
function find(id) {
  return (0, _request.default)({
    url: "/member/courseOrder/find/" + id,
    method: "get"
  });
}
function create(params) {
  return (0, _request.default)({
    url: "/member/courseOrder/create",
    method: "post",
    data: params
  });
}
function columnCreateCourse(params) {
  return (0, _request.default)({
    url: "/member/courseOrder/columnCreate",
    method: "post",
    data: params
  });
}
function courseCreate(params) {
  return (0, _request.default)({
    url: "/member/courseOrder/counselorCreate",
    method: "post",
    data: params
  });
}
function checkExists(params) {
  return (0, _request.default)({
    url: "/member/courseOrder/checkExists",
    method: "get",
    params: params
  });
}