var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "weightContent_Box" },
      _vm._l(_vm.nowAnswer.options, function (item, index) {
        return _c("div", { staticClass: "weightContent" }, [
          _c("span", { staticClass: "weightContent_Title" }, [
            _vm._v(_vm._s(item.name)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "weightContent_Content" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: item.rangeValue,
                  expression: "item.rangeValue",
                },
              ],
              staticClass: "weightContent_ContentTitle",
              attrs: { type: "number" },
              domProps: { value: item.rangeValue },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(item, "rangeValue", $event.target.value)
                  },
                  function ($event) {
                    return _vm.search(index)
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "weight_Box",
                on: {
                  touchmove: function ($event) {
                    return _vm.ontouchmove(index)
                  },
                },
              },
              [
                _c(
                  "el-slider",
                  {
                    style: { zIndex: 1 },
                    attrs: {
                      min: item.one,
                      max: _vm.nowAnswer.options[0].score,
                      step: 10,
                    },
                    model: {
                      value: item.rangeValue,
                      callback: function ($$v) {
                        _vm.$set(item, "rangeValue", $$v)
                      },
                      expression: "item.rangeValue",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { display: "none" },
                        attrs: { slot: "start" },
                        slot: "start",
                      },
                      [_vm._v(_vm._s(item.one))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { display: "none" },
                        attrs: { slot: "end" },
                        slot: "end",
                      },
                      [_vm._v(_vm._s(item.score))]
                    ),
                  ]
                ),
                _vm._v(" "),
                item.aliquot == true
                  ? _c("div", { staticClass: "weight_subscript" }, [
                      _c("div", { staticClass: "weight_subscriptBoxOne" }, [
                        _c("div", { staticClass: "weight_subscriptOne" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "weight_subscriptTitleOne" },
                          [_vm._v(_vm._s(item.one))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weight_subscriptBoxTwo" }, [
                        _c("div", { staticClass: "weight_subscriptOne" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "weight_subscriptTitleOne" },
                          [_vm._v(_vm._s(item.Two))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weight_subscriptBoxThree" }, [
                        _c("div", { staticClass: "weight_subscriptOne" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "weight_subscriptTitleOne" },
                          [_vm._v(_vm._s(item.three))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weight_subscriptBoxFour" }, [
                        _c("div", { staticClass: "weight_subscriptOne" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "weight_subscriptTitleOne" },
                          [_vm._v(_vm._s(item.four))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weight_subscriptBoxFive" }, [
                        _c("div", { staticClass: "weight_subscriptOne" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "weight_subscriptTitleOne" },
                          [_vm._v(_vm._s(item.five))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weight_subscriptBoxSix" }, [
                        _c("div", { staticClass: "weight_subscriptOne" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "weight_subscriptTitleOne" },
                          [_vm._v(_vm._s(item.score))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("span", { staticClass: "weight_prompt" }, [
      _vm._v(
        "提示：总比重值必须为：" + _vm._s(_vm.total_Score) + "，已分配比重："
      ),
      _c("span", { staticClass: "weight_promptTotal" }, [
        _vm._v(_vm._s(_vm.total)),
      ]),
      _vm.total != _vm.total_Score
        ? _c("span", [_vm._v("，请修改！")])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.percents != _vm.percenter
      ? _c(
          "div",
          { staticClass: "weightTitle_BottonBox", style: { zIndex: 3 } },
          [
            _c(
              "div",
              {
                staticClass: "weightTitle_Botton",
                on: { click: _vm.chooseAnswer },
              },
              [_c("span", [_vm._v("确定")])]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }