"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.array.find");
var _friendLink = require("@/api/friendLink");
var _mchCourse = require("@/api/mchCourse");
var _courseItem = require("@/api/courseItem");
var _courseOrder = require("@/api/courseOrder");
var _evaluation = require("@/api/evaluation");
var _payment = require("@/api/payment");
var _enrollOrder = require("@/api/enrollOrder");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vuex = require("vuex");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _share = require("@/utils/share");
var _user = require("@/api/user");
var _member = require("@/api/member");
var _enterprise = require("@/api/enterprise");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      friends: [],
      block: "A",
      courseId: "",
      infoData: {},
      pageNum: 1,
      pageSize: 100,
      courseItemlist: [],
      paymentType: false,
      sn: "",
      courseItemId: "",
      mch: {},
      courseOrderId: ""
    };
  },
  created: function created() {
    this.courseId = this.$route.query.courseId;
    if (!_utils.default.isNull(this.$route.query.block)) {
      this.block = this.$route.query.block;
    }
    this.find(this.courseId);
    this.getXuid();
    this.getInfoClick();
    this.loadFriendLink(this.courseId);
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId", "userId", "xuid", "token"])),
  components: {
    NoData: _NoData.default
  },
  filters: {
    watchType: function watchType(val) {
      if (val == 3) {
        return "视频";
      } else if (val == 2) {
        return "音频";
      } else if (val == 1) {
        return "图文";
      } else if (val == 0) {
        return "量表";
      } else if (val == 4) {
        return "活动";
      } else if (val == 5) {
        return "文章";
      }
    },
    watchCantry: function watchCantry(val) {
      if (val == 0) {
        return "付费解锁";
      } else if (val == 1) {
        return "试听";
      } else if (val == 2) {
        return "分享解锁";
      }
    }
  },
  mounted: function mounted() {
    //      let _this = this;
    //      if (!utils.isNull(this.$route.query.paymentStatus)){
    //         _this.paymentType = true
    //      }
  },
  methods: {
    toLink: function toLink(item) {
      if (item.linkProductType == 1) {
        this.$router.push({
          path: "/gauge/index",
          query: {
            mchGaugeId: item.linkProductId
          }
        });
      } else if (item.linkProductType == 2) {
        this.$router.push({
          path: "/course/info",
          query: {
            courseId: item.linkProductId
          }
        });
        this.find(item.linkProductId);
        this.loadFriendLink(item.linkProductId);
      } else if (item.linkProductType == 3) {
        this.$router.push({
          path: "/article/index",
          query: {
            articleId: item.linkProductId
          }
        });
      }
    },
    loadFriendLink: function loadFriendLink(query) {
      var _this2 = this;
      var params = new URLSearchParams();
      params.append("mainProductType", 2);
      params.append("mainProductId", query);
      (0, _friendLink.list)(params).then(function (res) {
        _this2.friends = res.data.data;
      });
    },
    getXuid: function getXuid() {
      console.log(this.userId);
      var xuid = _utils.default.getUrlParameter("xuid");
      if (xuid != null) {
        this.$store.dispatch("user/setXuid", xuid);
      }
      if (this.token !== undefined && this.token !== null && this.token !== "") {
        // 如果有token并且参数里有xuid
        // alert(this.xuid)
        (0, _member.lockSysUser)({
          xuid: this.xuid
        }).then(function (res) {
          console.log(res);
          // alert(JSON.stringify(res))
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    complete: function complete() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchCourseId", _this.courseId);
      (0, _courseOrder.create)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.payment)) {
          _this.sn = response.data.payment.sn;
          _this.$router.replace({
            path: "/payment",
            query: {
              sn: _this.sn,
              redirect: "/course/info?courseId=" + _this.courseId
            }
          });
        } else {
          _this.checkExistsClick();
        }
      }).catch(function (error) {});
    },
    checkExistsClick: function checkExistsClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchCourseId", _this.courseId);
      (0, _courseOrder.checkExists)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.courseOrder)) {
          _this.paymentType = true;
          _this.courseOrderId = response.data.courseOrder.id;
        } else {
          _this.paymentType = false;
        }
      }).catch(function (error) {});
    },
    tabsClick: function tabsClick(c) {
      this.block = c;
    },
    find: function find(id) {
      var _this3 = this;
      var _this = this;
      (0, _mchCourse.find)(id).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.course.thumbnail)) {
          response.data.course.thumbnail = _utils.default.thumbnail(response.data.course.thumbnail, 750, 360);
        }
        _this.infoData = response.data.course;
        _this.courseItemId = response.data.courseId;
        _this.courseItem();
        _this.checkExistsClick();
        (0, _share.SHARE)(location.href, {
          title: response.data.course.title,
          desc: response.data.course.subTitle,
          link: _utils.default.host() + "/#/course/info?courseId=".concat(_this3.courseId, "&xuid=").concat(_this3.userId),
          imgUrl: response.data.course.thumbnail
        }, function (res) {});
      }).catch(function (error) {
        console.log(error);
      });
    },
    courseCreateEnrollOrder: function courseCreateEnrollOrder(articleId) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("courseOrderId", _this.courseOrderId);
      params.append("articleId", articleId);
      (0, _enrollOrder.courseCreateEnroll)(params).then(function (res) {
        console.log(res);
        _this.$router.push({
          path: "/article/index",
          query: {
            articleId: articleId
          }
        });
      }).catch(function (error) {});
    },
    courseItem: function courseItem() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("courseId", _this.courseItemId);
      (0, _courseItem.list)(params).then(function (response) {
        console.log(response);
        _this.courseItemlist = response.data.data;
      }).catch(function (error) {});
    },
    courseItenClick: function courseItenClick(id, type, canTry, materialId) {
      var _this = this;
      if (type == 0) {
        if (_this.paymentType == true || canTry == 1) {
          // console.log(gaugeId)
          // this.$router.push({
          //   path: '/gauge/index',
          //   query: {
          //     mchGaugeId: gaugeId
          //   }
          // })
          // this.$router.push({
          //   path: '/gauge/index', query:{mchGaugeId:gaugeId}
          // })
          _this.courseCreateClick(materialId);
        }
      } else if (type == 4) {
        _this.courseCreateEnrollOrder(materialId);
      } else if (type == 5) {
        _this.$router.push({
          path: "/activitySy/details",
          query: {
            activityId: materialId
          }
        });
      } else {
        if (_this.paymentType == true || canTry == 1) {
          this.$router.push({
            path: "/course/courseDetails",
            query: {
              type: type,
              id: id,
              courseId: _this.courseId
            }
          });
        }
      }
    },
    getInfoClick: function getInfoClick() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _enterprise.getInfo)(params).then(function (res) {
        console.log(res);
        if (res.type == "success") {
          _this.mch = res.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    courseCreateClick: function courseCreateClick(materialId) {
      var _this = this;
      var params = new URLSearchParams();
      params.append("courseOrderId", _this.courseOrderId);
      params.append("gaugeId", materialId);
      (0, _evaluation.courseCreate)(params).then(function (response) {
        console.log(response);
        _this.$router.push({
          path: "/gauge/answer",
          query: {
            evaluationId: response.data.evaluation.id
          }
        });
      }).catch(function (error) {});
    }
  }
};