"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _user = require("@/api/user");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../utils/utils.js"));
//
//
//
var _default = exports.default = {
  name: 'AuthRedirect',
  data: function data() {
    return {
      redirect: undefined,
      authCode: undefined,
      uuid: null,
      loading: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  watch: {
    $route: {
      handler: function handler(route) {
        // console.log(route)
        this.redirect = route.query && route.query.redirect ? route.query.redirect : '/';
        //  alert('我的链接' + route.query.redirect)
        // this.redirect = utils.getUrlParameter('redirect') || '/'
        if (route.query && route.query.xuid) {
          this.redirect = this.redirect + '&xuid=' + route.query.xuid;
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.authCode = _utils.default.getUrlParameter('code');
    this.uuid = _utils.default.getUUID();
    this.loading = true;
    if (this.authCode != undefined && this.authCode != null) {
      this.oauth2Login();
    } else {
      this.redirectTo();
    }
  },
  render: function render(h) {
    return h(); // avoid warning message
  },
  methods: {
    redirectTo: function redirectTo() {
      var redirect_uri = _utils.default.host() + '/#/auth-redirect?redirect=' + encodeURIComponent(this.redirect);
      // const redirect_uri = utils.host() + '/#/auth-redirect'
      // sessionStorage.setItem('Auth-redirect', this.redirect)
      (0, _user.getCodeByRedirect)({
        scope: 'snsapi_userinfo',
        redirectUri: redirect_uri,
        pluginId: 'weixinOAuth2Plugin',
        mchId: this.mchId
      }).then(function (response) {
        window.location.replace(response.data.url);
        // location.href = response.data.url
        // this.oauth2Login()
      }).catch(function (error) {});
    },
    oauth2Login: function oauth2Login() {
      var _this = this;
      this.$store.dispatch('user/oauth2login', {
        auth_code: this.authCode,
        uuid: this.uuid,
        pluginId: 'weixinOAuth2Plugin'
      }).then(function () {
        // 登录类型 1：微信登录
        localStorage.setItem('loginType', 1);
        // var redirectUrl = sessionStorage.getItem('Auth-redirect') || '/index'
        // if (
        //   redirectUrl.startsWith('http://') ||
        //   redirectUrl.startsWith('https://')
        // ) {
        //   location.href = redirectUrl
        // } else {
        //   this.$router.replace({ path: redirectUrl })
        // }
        /*this.$router.replace({
           path: sessionStorage.getItem('Auth-redirect')
        })*/
        _this.$router.replace({
          path: _this.redirect || '/'
        });
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    }
  }
};