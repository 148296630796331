var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "result-content" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "result-body" }, [
        _c("div", { staticClass: "title" }, [_vm._v("测评信息")]),
        _vm._v(" "),
        _c("div", { staticClass: "message" }, [
          _vm._v("\n      测验名称："),
          _c("span", [_vm._v(_vm._s(_vm.detailsTitle))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "message" }, [
          _vm._v("\n      完成时间："),
          _c("span", [
            _vm._v(_vm._s(_vm._f("dateTimeFormatter")(_vm.detailsDate))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "message" },
          [
            _vm._v("\n      参与人员："),
            _c("span", [_vm._v(_vm._s(_vm.detailsName))]),
            _vm._v(" "),
            _vm.isIso == false && _vm.downloadFlag == "1"
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "2px" },
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.omDown },
                  },
                  [_vm._v("下载报告")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isIso == true && _vm.downloadFlag == "1"
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "2px" },
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.onIosDown },
                  },
                  [_vm._v("下载报告")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "result-footer" }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onClick(3)
                  },
                },
              },
              [_vm._v("回到首页")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex" },
          [
            _vm.hasPlanFlag == 1
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.onClick(2)
                      },
                    },
                  },
                  [_vm._v("继续测评")]
                )
              : _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClick(1)
                      },
                    },
                  },
                  [_vm._v("退出系统")]
                ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("van-dialog", {
        attrs: {
          title: "是否确定复制链接，到浏览器打开下载？",
          "show-cancel-button": "",
        },
        on: { confirm: _vm.onClickdwon },
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        attrs: {
          alt: "",
          src: "https://makey.oss-cn-hangzhou.aliyuncs.com/%E7%BB%84%2019.png",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "round" }, [_c("span", [_vm._v("✔")])]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("您已完成此次测评")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }