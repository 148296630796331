var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page" }, [
      _c("div", { staticClass: "itemBox" }, [
        _c("div", { staticClass: "tips tipsColor1" }, [_vm._v("心理测评")]),
        _vm._v(" "),
        _c("span", { staticClass: "time" }, [
          _vm._v("发布时间：2022-1-15 12:00"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gaugeBox" }, [
          _c("div", { staticClass: "gaugeImg" }, [
            _c("img", { attrs: { src: "", width: "100%", height: "100%" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gaugeContentBox" }, [
            _c("span", { staticClass: "gaugeTitle" }, [
              _vm._v("你真的了解自己吗？"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "gaugeSubTitle" }, [
              _vm._v("自我概念问卷备份"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gaugeButtonCell" }, [
              _c("div", { staticClass: "buttonBox" }, [_vm._v("去完成")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "textBox" }, [
          _vm._v("请根据你的实际情况进行作答，选项没有正误备份"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "itemBox" }, [
        _c("div", { staticClass: "tips tipsColor2" }, [_vm._v("心理咨询")]),
        _vm._v(" "),
        _c("span", { staticClass: "time" }, [
          _vm._v("发布时间：2022-1-15 12:00"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "consultBox" }, [
          _c("div", { staticClass: "consultImg" }, [
            _c("img", { attrs: { src: "", width: "100%", height: "100%" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "consultContentBox" }, [
            _c("span", { staticClass: "consultName" }, [_vm._v("朱丽君")]),
            _vm._v(" "),
            _c("div", { staticClass: "consultDate" }, [
              _c("div", { staticClass: "consultDateIcon" }, [
                _c("img", {
                  attrs: {
                    src: require("./images/consultDateIcon.png"),
                    width: "100%",
                    height: "100%",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "consultDateText" }, [
                _vm._v("预约时间"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "consultButtonCell" }, [
              _c("span", { staticClass: "consultDateTime" }, [
                _vm._v("2022.1.15 至 2022.1.20 12:00"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttonBox" }, [_vm._v("查看预约")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "textBox" }, [
          _vm._v(
            "请根据你的实际情况进行作答，选项没有正误之分，无需过多思考。"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "itemBox" }, [
        _c("div", { staticClass: "tips tipsColor2" }, [_vm._v("心理咨询")]),
        _vm._v(" "),
        _c("span", { staticClass: "time" }, [
          _vm._v("发布时间：2022-1-15 12:00"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "textBox mt20" }, [
          _vm._v(
            "罗老师邀请你于2022年1月18日上午9：00-10:00到心理咨询中心参加咨询会谈"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "consultButtonCellTwo" }, [
          _c("div", { staticClass: "buttonBox" }, [_vm._v("确认任务")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "itemBox" }, [
        _c("div", { staticClass: "tips tipsColor3" }, [_vm._v("科普文章")]),
        _vm._v(" "),
        _c("span", { staticClass: "time" }, [
          _vm._v("发布时间：2022-1-15 12:00"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gaugeBox" }, [
          _c("div", { staticClass: "gaugeImg" }, [
            _c("img", { attrs: { src: "", width: "100%", height: "100%" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gaugeContentBox" }, [
            _c("span", { staticClass: "gaugeTitle" }, [
              _vm._v("你真的了解自己吗？"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "gaugeSubTitle" }, [
              _vm._v("自我概念问卷备份"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gaugeButtonCell" }, [
              _c("div", { staticClass: "buttonBox" }, [_vm._v("去学习")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "textBox" }, [
          _vm._v("请根据你的实际情况进行作答，选项没有正误备份"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "itemBox" }, [
        _c("div", { staticClass: "tips tipsColor4" }, [_vm._v("其他任务")]),
        _vm._v(" "),
        _c("span", { staticClass: "time" }, [
          _vm._v("发布时间：2022-1-15 12:00"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "textBox mt20" }, [
          _vm._v("请近记录近一周的心情日记～"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "consultButtonCellTwo" }, [
          _c("div", { staticClass: "buttonBox" }, [_vm._v("确认任务")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }