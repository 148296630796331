"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
var _member = require("@/api/member");
var _evaluation = require("@/api/evaluation");
var _courseOrder = require("@/api/courseOrder");
var _columnOrder = require("@/api/columnOrder");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      taskCategory: [{
        id: 1,
        tit: "全部",
        type: 11
      }, {
        id: 2,
        tit: "未完成",
        type: 12
      }, {
        id: 3,
        tit: "已完成",
        type: 13
      }],
      categoryActiveId: 1,
      taskData: [],
      taskUndone: [],
      taskFinished: [],
      materialType: false,
      pageNum: 1,
      pageSize: 20,
      counselorOrderId: "",
      taskStatus: 11
    };
  },
  filters: {
    watchData: function watchData(date) {
      date = new Date(date);
      var fmt = "YYYY/mm/dd";
      var ret;
      var opt = {
        "Y+": date.getFullYear().toString(),
        // 年
        "m+": (date.getMonth() + 1).toString(),
        // 月
        "d+": date.getDate().toString() // 日
        // "H+": date.getHours().toString(), // 时
        // "M+": date.getMinutes().toString() // 分
      };
      for (var k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
        }
      }
      return fmt;
    }
  },
  created: function created() {
    this.counselorOrderId = this.$route.query.counselorOrderId;
    this.getTaskList();
  },
  methods: {
    categoryClick: function categoryClick(item) {
      this.categoryActiveId = item.id;
      this.taskStatus = item.type;
      this.taskData = [];
      this.taskUndone = [];
      this.taskFinished = [];
      this.getTaskList();
    },
    goLearn: function goLearn(item) {
      if (item.type == 1) {
        // 创建咨询测评订单
        this.createGauge(item.counselorOrderId, item.material.id);
      } else if (item.type == 2) {
        // 创建咨询课程订单
        this.createCourse(item.counselorOrderId, item.material.id);
      } else if (item.type == 3) {
        // 创建咨询专栏订单
        this.createColumn(item.counselorOrderId, item.material.id);
      }
    },
    //完成打卡
    clickFinished: function clickFinished(item) {
      item.status = 1;
      var params = new URLSearchParams();
      params.append("id", item.id);
      params.append("status", 1);
      (0, _member.updateStatus)(params).then(function (res) {
        console.log(res);
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 创建咨询测评订单
    createGauge: function createGauge(counselorOrderId, gaugeId) {
      var _this2 = this;
      var params = new URLSearchParams();
      params.append("counselorOrderId", counselorOrderId);
      params.append("gaugeId", gaugeId);
      (0, _evaluation.counselorCreate)(params).then(function (res) {
        console.log(res, "咨询创建测评订单");
        if (res.data.evaluation.evalStatus == 0) {
          _this2.$router.push({
            path: "/gauge/answer",
            query: {
              evaluationId: res.data.evaluation.id
            }
          });
        } else if (res.data.evaluation.evalStatus == 1) {
          _this2.$router.push({
            path: "/gauge/result",
            query: {
              evaluationId: res.data.evaluation.id
            }
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 创建咨询课程订单
    createCourse: function createCourse(counselorOrderId, courseId) {
      var _this3 = this;
      var params = new URLSearchParams();
      params.append("counselorOrderId", counselorOrderId);
      params.append("courseId", courseId);
      (0, _courseOrder.courseCreate)(params).then(function (res) {
        console.log(res, "咨询课程数据");
        _this3.$router.push({
          path: "/course/info",
          query: {
            courseId: res.data.courseOrder.mchCourseId
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 创建咨询专栏订单
    createColumn: function createColumn(counselorOrderId, columnId) {
      var _this4 = this;
      var params = new URLSearchParams();
      params.append("counselorOrderId", counselorOrderId);
      params.append("columnId", columnId);
      (0, _columnOrder.columnCreate)(params).then(function (res) {
        console.log(res);
        _this4.$router.push({
          path: "/column/columnSignUp",
          query: {
            mchColumnId: res.data.columnOrder.mchColumnId
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 获取任务列表
    getTaskList: function getTaskList() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("counselorOrderId", _this.counselorOrderId);
      (0, _member.list)(params).then(function (res) {
        console.log(res, "任务列表");
        if (!_utils.default.isNull(res.data.data) || res.data.data.length != 0) {
          res.data.data.map(function (e) {
            _this.taskData.push(e);
            if (!_utils.default.isNull(e.material)) {
              _this.materialType = true;
            }
            if (e.status == 0) {
              _this.taskUndone.push(e);
            } else if (e.status == 1) {
              _this.taskFinished.push(e);
            }
          });
        }
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};