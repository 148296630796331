var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        height: "100%",
        padding: "15px 0",
        "background-color": "#ffffff",
      },
    },
    [
      _c("div", { staticClass: "consultantqy_title" }, [_vm._v("预约咨询师")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consultantqy_scroll",
          style: {
            scrollLeft: _vm.scrollLeft,
          },
        },
        _vm._l(_vm.categoryData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "consultantqy_scroll_item",
              on: {
                click: function ($event) {
                  return _vm.scrollSlide(index, item.id)
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "consultantqy_scroll_item_title",
                  class: { consultantqy_activ: _vm.activ_index == index },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "consultantqy_body" },
        _vm._l(_vm.counselorList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "consultantqy_content",
              on: {
                click: function ($event) {
                  return _vm.counselorClick(item.id)
                },
              },
            },
            [
              _c("div", { staticClass: "consultantqy_content_logo" }, [
                _c("img", { attrs: { src: item.avatar } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "consultantqy_content_info" }, [
                _c("div", { staticClass: "consultantqy_content_name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "consultantqy_content_title" }, [
                  _vm._v(
                    "\n          " + _vm._s(item.introduction) + "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { display: "flex", width: "180px" } },
                  _vm._l(
                    item.counselor.businessCategories.slice(0, 3),
                    function (a) {
                      return _c(
                        "div",
                        {
                          key: a,
                          staticClass: "consultantqy_content_subtitle",
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(a.name) + "\n          "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "consultantqy_content_price" }, [
                  _c("span", { staticClass: "price_one" }, [
                    _vm._v("￥" + _vm._s(item.price)),
                  ]),
                  _c("span", { staticClass: "price_two" }, [_vm._v(" / 次")]),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(0, true),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "consultantqy_more",
          on: { click: _vm.consultantQychange },
        },
        [
          _vm._v("\n    点击查看更多\n    "),
          _c("div", { staticClass: "consultantqy_more_icon" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "consultantqy_content_icon" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/consultantQy-zxs-yy.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }