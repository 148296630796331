var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "answer-theText-content" },
      [
        _vm._l(_vm.nowAnswer.options, function (item, index) {
          return item.image == null || item.image == ""
            ? _c(
                "div",
                {
                  staticClass: "answer-theText-content-cell flex-space-between",
                  class: [
                    item.id == _vm.chooseId
                      ? "answer-theText-text-cell-article"
                      : "answer-theText-text-cell-noarticle",
                  ],
                  staticStyle: { "box-shadow": "0px 0px 2px 2px #F7F7F7" },
                  on: {
                    click: function ($event) {
                      return _vm.chooseAnswer(index, item)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "answer-theText-content-cell-options" },
                    [_vm._v(_vm._s(item.xid) + "." + _vm._s(item.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "answer-theText-content-cell-btn" },
                    [
                      item.id != _vm.chooseId
                        ? _c("div", {
                            staticClass: "answer-theText-content-cell-btn-bg",
                            class: [
                              item.id == _vm.chooseId
                                ? ""
                                : "answer-theText-text-btn-noarticle",
                            ],
                            staticStyle: { border: "1px #333333 solid" },
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "answer-theText-content-cell-btn-bg",
                              staticStyle: { border: "1px #333333 solid" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/mxs_images/hook.png"),
                                  width: "100%",
                                  height: "100%",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                    ]
                  ),
                ]
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "answer-content-image" },
          _vm._l(_vm.nowAnswer.options, function (item, index) {
            return item.image != null && item.image != ""
              ? _c(
                  "div",
                  {
                    staticClass: "answer-content-imageBox",
                    class: [
                      item.id == _vm.chooseId
                        ? "answer-content-image-activity"
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.chooseAnswer(index, item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: item.image,
                        width: "100%",
                        height: "100%",
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e()
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }