var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scrollNav",
      staticClass: "scroll-nav",
      style:
        _vm.scrollWay == "vertical"
          ? {
              height: _vm.navWH == 0 ? "100%" : _vm.navWH + "px",
              "background-color": _vm.colorBack,
              color: _vm.colorText,
              "justify-content": _vm.scrollWH < _vm.navWH ? "center" : "",
            }
          : {
              width: _vm.navWH == 0 ? "100%" : _vm.navWH + "px",
              "background-color": _vm.colorBack,
              color: _vm.colorText,
              "justify-content": _vm.scrollWH < _vm.navWH ? "center" : "",
            },
    },
    [
      _c(
        "div",
        {
          ref: "scrollBox",
          staticClass: "scroll-box",
          style:
            _vm.scrollWay == "vertical"
              ? {
                  transform: "translate3d(0px," + _vm.xyScroll + "px,0px)",
                  height: _vm.scrollWH + "px",
                  "flex-direction": "column",
                }
              : {
                  transform: "translate3d(" + _vm.xyScroll + "px,0px,0px)",
                  width: _vm.scrollWH + "px",
                  "flex-direction": "row",
                },
        },
        _vm._l(_vm.columnCategeryScrollItem, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "scroll-item",
              class: item.id == _vm.activeScrollId ? "item-active" : "",
              style:
                _vm.scrollWay == "vertical"
                  ? {
                      margin: _vm.scrollMargin + "px" + " 0",
                      padding: _vm.itemPadding,
                      color:
                        item.id == _vm.activeScrollId
                          ? _vm.colorActiveText
                          : "",
                      "background-color":
                        item.id == _vm.activeScrollId
                          ? _vm.colorActiveBack
                          : "",
                    }
                  : {
                      margin: "0 " + _vm.scrollMargin + "px",
                      padding: _vm.itemPadding,
                      color:
                        item.id == _vm.activeScrollId
                          ? _vm.colorActiveText
                          : "",
                      "background-color":
                        item.id == _vm.activeScrollId
                          ? _vm.colorActiveBack
                          : "",
                    },
              on: {
                click: function ($event) {
                  return _vm.chooseItem($event, item.id, index)
                },
              },
            },
            [
              _vm._v("\n      " + _vm._s(item.name) + "\n      "),
              item.id == _vm.activeScrollId
                ? _c("div", { staticClass: "scroll-line" })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }