var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "headerBarBox" }, [
      _c("div", { staticClass: "userBox" }, [
        _c("div", { staticClass: "userLogo" }, [
          !_vm.user.avatar
            ? _c("img", {
                attrs: {
                  src: require("./images/userLogo.png"),
                  width: "100%",
                  height: "100%",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !!_vm.user.avatar
            ? _c("img", {
                attrs: { src: _vm.user.avatar, width: "100%", height: "100%" },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "userName" }, [
          _vm._v(_vm._s(!!_vm.user.name ? _vm.user.name : "点击登录")),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "callMeBox",
          on: {
            click: function ($event) {
              _vm.callMeDialogShow = true
            },
          },
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("span", { staticClass: "callMeText" }, [_vm._v("联系我们")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.callMeDialogShow
      ? _c(
          "div",
          { staticClass: "dialogMask", staticStyle: { "z-index": "999" } },
          [
            _c("input", {
              ref: "copy",
              staticStyle: { opacity: "0", height: "0px" },
              attrs: { type: "text", readonly: "true" },
              domProps: { value: _vm.kefu.phone },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "dialogBox" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialogClose",
                  on: {
                    click: function ($event) {
                      _vm.callMeDialogShow = false
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("./images/close.png"),
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "dialogContentInfoBox" }, [
                _c("div", { staticClass: "qrcode" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.kefu.qrcode,
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "qecodeText" }, [
                  _vm._v("长按二维码联系我们"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dialogCallMeBox" }, [
                _vm._m(3),
                _vm._v(" "),
                _c("span", { staticClass: "phoneTitle" }, [
                  _vm._v("请拨打电话："),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "phoneNum" }, [
                  _vm._v(_vm._s(_vm.kefu.phone)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "copyBox",
                    on: {
                      click: function ($event) {
                        return _vm.copy()
                      },
                    },
                  },
                  [_vm._v("复制")]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "userArrowImg" }, [
      _c("img", {
        attrs: {
          src: require("./images/userArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "callImg" }, [
      _c("img", {
        attrs: {
          src: require("./images/callMe.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dialogContentImg" }, [
      _c("img", {
        attrs: {
          src: require("./images/dialog.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dialogCallMeImg" }, [
      _c("img", {
        attrs: {
          src: require("./images/dialogCallMe.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }