var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "courses_box" },
    [
      _c("div", { staticClass: "courses_header" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "row" } },
          [
            _c("div", { staticClass: "courses_header_line" }),
            _vm._v(" "),
            _c("span", { staticClass: "courses_header_name" }, [
              _vm._v(_vm._s(_vm.content.title)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "courses_header_more",
            on: {
              click: function ($event) {
                return _vm.listClick()
              },
            },
          },
          [_vm._v("查看更多>>")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-carousel",
        { attrs: { arrow: "always", height: "230px", autoplay: false } },
        _vm._l(_vm.group(_vm.coursesForm, 2), function (item) {
          return _c("el-carousel-item", [
            _c(
              "div",
              { staticClass: "courses_content" },
              _vm._l(item, function (items) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "courses_content_item",
                    on: {
                      click: function ($event) {
                        return _vm.indexClick(items.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "courses_content_img" }, [
                      _c("img", {
                        attrs: {
                          src: items.thumbnail,
                          width: "100%",
                          height: "100%",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "courses_content_box" }, [
                      _c("div", { staticClass: "flex-column" }, [
                        _c("span", { staticClass: "courses_content_title" }, [
                          _vm._v(_vm._s(items.title)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "column_content_subTitle" }, [
                          _vm._v(_vm._s(items.subTitle)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "courses_content_price_box" }, [
                        _c("span", { staticClass: "column_content_date" }, [
                          _vm._v(_vm._s(items.sales) + "人购买"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "courses_content_price" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "13px",
                                "font-weight": "400",
                              },
                            },
                            [_vm._v("¥ ")]
                          ),
                          _vm._v(_vm._s(items.price)),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }