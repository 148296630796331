var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search_box" },
    [
      _c("el-input", {
        attrs: {
          placeholder: "请输入内容",
          "prefix-icon": "el-icon-search",
          size: "small",
        },
        model: {
          value: _vm.input2,
          callback: function ($$v) {
            _vm.input2 = $$v
          },
          expression: "input2",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }