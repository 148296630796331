"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _ad = require("@/api/ad");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "articleImg",
  data: function data() {
    return {
      pageNum: 1,
      pageSize: 3,
      adForm: [{
        thumbnail: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/shuffling_img.jpeg'
      }, {
        thumbnail: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/shuffling_img.jpeg'
      }, {
        thumbnail: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/shuffling_img.jpeg'
      }],
      tagIds: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  props: ['content'],
  methods: {
    toItem: function toItem(item) {
      console.log(item);
      if (item.type == 5) {
        if (item.typeId) {
          this.$router.push({
            path: '/course/info',
            query: {
              courseId: item.typeId
            }
          });
        }
      } else if (item.type == 6) {
        if (item.typeId) {
          this.$router.push({
            path: '/gauge/index',
            query: {
              mchGaugeId: item.typeId
            }
          });
        }
      } else if (item.type == 12) {
        window.location.href = item.path;
      } else if (item.type == 1) {
        if (item.typeId) {
          this.$router.push({
            path: '/article/index',
            query: {
              articleId: item.typeId
            }
          });
        }
      } else if (item.type == 9) {
        this.$router.push({
          path: '/index/wxPage',
          query: {
            id: item.typeId
          }
        });
      }
    },
    loadImage: function loadImage() {
      var _this2 = this;
      var _this = this;
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(',');
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      var params = new URLSearchParams();
      params.append('pageNum', this.pageNum);
      params.append('pageSize', this.pageSize);
      params.append('mchId', this.mchId);
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append('tagIds', _this.tagIds[i]);
      }
      (0, _ad.list)(params).then(function (response) {
        if (!_utils.default.isNull(response.data.data)) {
          _this2.adForm = response.data.data;
          _this2.adForm.forEach(function (item) {});
        }
      }).catch(function (error) {
        console.log('error!!');
      });
    }
  },
  created: function created() {
    this.loadImage();
  }
};