var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity_box" }, [
    _c("div", { staticClass: "activity_header_img" }, [
      _c("img", {
        attrs: { src: _vm.infoData.thumbnail, width: "100%", height: "100%" },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "activity_content" }, [
      _c("span", { staticClass: "activity_content_title" }, [
        _vm._v(_vm._s(_vm.infoData.title)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "activity_content_subtitle" }, [
        _vm._v(_vm._s(_vm.infoData.subTitle)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "activity_content_index" }, [
      _c("div", {
        staticClass: "contentBox",
        domProps: { innerHTML: _vm._s(_vm.infoData.content) },
      }),
      _vm._v(" "),
      _vm.friends.length > 0
        ? _c("div", { staticClass: "recommend-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "recommend-image-content" },
              _vm._l(_vm.friends, function (item) {
                return _c("img", {
                  key: item,
                  staticStyle: {
                    "border-radius": "10px",
                    width: "100%",
                    height: "100%",
                    "margin-top": "20px",
                  },
                  attrs: { src: item.thumbnail },
                  on: {
                    click: function ($event) {
                      return _vm.toLink(item)
                    },
                  },
                })
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { width: "100%", height: "50px" } }),
    _vm._v(" "),
    _c(
      "a",
      { staticClass: "courseService", attrs: { href: "tel:" + _vm.mch.phone } },
      [_vm._m(1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          width: "80px",
          "justify-content": "space-between",
          "align-items": "center",
        },
      },
      [
        _c("div", { staticClass: "vertical" }),
        _vm._v(" "),
        _c("div", [_vm._v("相关推荐")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "courseServiceBox" }, [
      _c("img", {
        attrs: {
          src: "http://rzico-weex.oss-cn-hangzhou.aliyuncs.com/images/makeyImg.png",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }