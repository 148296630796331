var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "backImg" }, [
      _c("span", { staticClass: "information_HeaderTitle" }, [
        _vm._v(
          "\n     为了提高测验的有效性和准确性，我们将收集您以下信息，这些信息只会被用于测验本身，并严格保密，请放心填写。\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.inforsContent[0] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[0] + 1) + ".你的姓名："),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inputIcon_Box" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nameBox,
                    expression: "nameBox",
                  },
                ],
                staticClass: "input_BoxTwo",
                attrs: { typeof: "text" },
                domProps: { value: _vm.nameBox },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.nameBox = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[1] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(
                _vm._s(_vm.inforsContent[1] + 1) + ".请选择您的出生日期："
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "information_BoxContent" },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "100%" },
                  attrs: { type: "date", placeholder: "选择日期" },
                  model: {
                    value: _vm.pickerValueBox,
                    callback: function ($$v) {
                      _vm.pickerValueBox = $$v
                    },
                    expression: "pickerValueBox",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[2] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[2] + 1) + ".您的性别："),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gender_Box" },
              _vm._l(_vm.gender, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "gender_Content",
                    on: {
                      click: function ($event) {
                        return _vm.genderBotton(index, item.genderName)
                      },
                    },
                  },
                  [
                    !item.status
                      ? _c("div", { staticClass: "gender_ContentBottonOne" })
                      : _vm._e(),
                    _vm._v(" "),
                    item.status
                      ? _c("div", { staticClass: "gender_ContentBottonOne" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/mxs_images/hook.png"),
                              width: "100%",
                              height: "100%",
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.genderName))]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[3] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[3] + 1) + ".民族"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.national,
                  expression: "national",
                },
              ],
              staticClass: "input_Box",
              attrs: { typeof: "text" },
              domProps: { value: _vm.national },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.national = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[4] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[4] + 1) + ".专业"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.professionalBox,
                  expression: "professionalBox",
                },
              ],
              staticClass: "input_Box",
              attrs: { typeof: "text" },
              domProps: { value: _vm.professionalBox },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.professionalBox = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[5] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[5] + 1) + ".班级"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.className,
                  expression: "className",
                },
              ],
              staticClass: "input_Box",
              attrs: { typeof: "text" },
              domProps: { value: _vm.className },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.className = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[6] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[6] + 1) + ".学号"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.student,
                  expression: "student",
                },
              ],
              staticClass: "input_Box",
              attrs: { typeof: "text" },
              domProps: { value: _vm.student },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.student = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[7] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[7] + 1) + ".工号"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.work_number,
                  expression: "work_number",
                },
              ],
              staticClass: "input_Box",
              attrs: { typeof: "text" },
              domProps: { value: _vm.work_number },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.work_number = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[8] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(
                _vm._s(_vm.inforsContent[8] + 1) + ".请输入你的手机号码："
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inputIcon_Box" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone",
                  },
                ],
                staticClass: "input_BoxTwo",
                attrs: { type: "tel", maxlength: "11" },
                domProps: { value: _vm.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phone = $event.target.value
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[9] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[9] + 1) + ".身高（cm）"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.heightBox,
                  expression: "heightBox",
                },
              ],
              staticClass: "input_Box",
              attrs: { type: "number" },
              domProps: { value: _vm.heightBox },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.heightBox = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[10] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[10] + 1) + ".体重（kg）"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.weightBox,
                  expression: "weightBox",
                },
              ],
              staticClass: "input_Box",
              attrs: { type: "number" },
              domProps: { value: _vm.weightBox },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.weightBox = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[11] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[11] + 1) + ".头围（cm）"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.headWai,
                  expression: "headWai",
                },
              ],
              staticClass: "input_Box",
              attrs: { type: "number" },
              domProps: { value: _vm.headWai },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.headWai = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[12] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[12] + 1) + ".出生胎龄（周）"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.Gestational,
                  expression: "Gestational",
                },
              ],
              staticClass: "input_Box",
              attrs: { type: "number" },
              domProps: { value: _vm.Gestational },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.Gestational = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[13] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[13] + 1) + ".户籍所在地"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "Home_addressBox" },
              _vm._l(_vm.Home_address, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "Home_addressContent",
                    on: {
                      click: function ($event) {
                        return _vm.HomeBotton(index, item.addressName)
                      },
                    },
                  },
                  [
                    !item.status
                      ? _c("div", { staticClass: "Home_addressBotton" })
                      : _vm._e(),
                    _vm._v(" "),
                    item.status
                      ? _c("div", { staticClass: "Home_addressBotton" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/mxs_images/hook.png"),
                              width: "100%",
                              height: "100%",
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "Home_addressTitle" }, [
                      _vm._v(_vm._s(item.addressName)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[14] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[14] + 1) + ".婚姻状况"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("marriage")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.marriageContent)),
                ]),
                _vm._v(" "),
                _vm._m(2),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[15] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[15] + 1) + ".工作所在地"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "address_Box" },
              [
                _c("Distpicker", {
                  attrs: {
                    province: _vm.district.province,
                    city: _vm.district.city,
                    area: _vm.district.area,
                  },
                  on: { selected: _vm.onSelected },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[16] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(
                _vm._s(_vm.inforsContent[16] + 1) + ".文化程度（受教育水平）"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("education")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.educationContent)),
                ]),
                _vm._v(" "),
                _vm._m(3),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[17] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[17] + 1) + ".月收入水平"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("income")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.incomeContent)),
                ]),
                _vm._v(" "),
                _vm._m(4),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[18] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[18] + 1) + ".职业"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("professional")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.professionalContent)),
                ]),
                _vm._v(" "),
                _vm._m(5),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[19] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[19] + 1) + ".政治面貌"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("political")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.politicalContent)),
                ]),
                _vm._v(" "),
                _vm._m(6),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[20] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[20] + 1) + ".生产方式"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "Home_addressBox" },
              _vm._l(_vm.way, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "Home_addressContent",
                    on: {
                      click: function ($event) {
                        return _vm.wayBotton(item.wayName, index)
                      },
                    },
                  },
                  [
                    !item.status
                      ? _c("div", { staticClass: "Home_addressBotton" })
                      : _vm._e(),
                    _vm._v(" "),
                    item.status
                      ? _c("div", { staticClass: "Home_addressBotton" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/mxs_images/hook.png"),
                              width: "100%",
                              height: "100%",
                              alt: "",
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "Home_addressTitle" }, [
                      _vm._v(_vm._s(item.wayName)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[21] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[21] + 1) + ".出生体重（斤）"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.birth_Weight,
                  expression: "birth_Weight",
                },
              ],
              staticClass: "input_Box",
              attrs: { type: "number" },
              domProps: { value: _vm.birth_Weight },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.birth_Weight = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[22] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[22] + 1) + ".主要抚养者"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("raising")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.raisingContent)),
                ]),
                _vm._v(" "),
                _vm._m(7),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[23] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[23] + 1) + ".兄弟姐妹数量"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("Brothers")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.BrothersContent)),
                ]),
                _vm._v(" "),
                _vm._m(8),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[24] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[24] + 1) + ".母亲生产时年龄(岁)"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.Production,
                  expression: "Production",
                },
              ],
              staticClass: "input_Box",
              attrs: { type: "number" },
              domProps: { value: _vm.Production },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.Production = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[25] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[25] + 1) + ".家庭结构"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("family")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.familyContentBox)),
                ]),
                _vm._v(" "),
                _vm._m(9),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[26] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[26] + 1) + ".家庭收入水平"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "marriage_Box",
                on: {
                  click: function ($event) {
                    return _vm.marriageBotton("total")
                  },
                },
              },
              [
                _c("span", { staticClass: "marriage_BoxTitle" }, [
                  _vm._v(_vm._s(_vm.totalContent)),
                ]),
                _vm._v(" "),
                _vm._m(10),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inforsContent[27] != -1
        ? _c("div", { staticClass: "information_Box" }, [
            _c("span", { staticClass: "information_BoxTitle" }, [
              _vm._v(_vm._s(_vm.inforsContent[27] + 1) + ".部门"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.department,
                  expression: "department",
                },
              ],
              staticClass: "input_Box",
              attrs: { typeof: "text" },
              domProps: { value: _vm.department },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.department = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "informationBotton", on: { click: _vm.goanswer } },
        [
          _c("span", { staticClass: "informationBottonTitle" }, [
            _vm._v("提交"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "100%", height: "50px" } }),
      _vm._v(" "),
      _vm.marriageStatus == true
        ? _c("div", { staticClass: "bounced_Box" }, [
            _c("div", {
              staticClass: "isBounced_Box",
              on: { click: _vm.bouncedCancel },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bounced_Content", style: { zIndex: 1 } },
              _vm._l(_vm.bounced, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "bounced_Options",
                    on: {
                      touchend: function ($event) {
                        return _vm.ontouchendTwo(index)
                      },
                      touchstart: function ($event) {
                        return _vm.ontouchstartTwo(index)
                      },
                      click: function ($event) {
                        return _vm.bouncedBotton(item.marriageName, index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        ref: index,
                        refInFor: true,
                        class: [
                          item.marriageName == _vm.bouncedContent
                            ? "bounced_BottonTwo"
                            : "bounced_Botton",
                        ],
                        on: {
                          touchend: function ($event) {
                            return _vm.ontouchend(index)
                          },
                          touchstart: function ($event) {
                            return _vm.ontouchstart(index)
                          },
                        },
                      },
                      [
                        item.marriageName == _vm.bouncedContent
                          ? _c("img", {
                              attrs: {
                                src: require("../../assets/mxs_images/hook.png"),
                                width: "100%",
                                height: "100%",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "bounced_Title" }, [
                      _vm._v(_vm._s(item.marriageName)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inputIcon" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/nameIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inputIcon" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/phoneIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marriageIcon_box" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/downIcon.png"),
          alt: "",
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }