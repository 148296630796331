"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _FirstCategery = _interopRequireDefault(require("./component/FirstCategery"));
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _columnCategory = require("@/api/columnCategory");
var _mchColumn = require("@/api/mchColumn");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    FirstCategery: _FirstCategery.default
  },
  data: function data() {
    return {
      collapse: false,
      scrollWay: "horizontal",
      // vertical  horizontal
      itemStr: "name",
      columnCategeryScrollItem: [],
      navWH: 0,
      scrollMargin: "20",
      colorText: "#808080",
      colorBack: "",
      colorActiveText: "#1A1A1A",
      colorActiveBack: "",
      activeScrollId: "",
      itemPadding: "8px 0",
      columnCategerySecond: [{
        id: "1",
        name: "全部"
      }],
      columnContListItem: [
        // {
        //   id: 1,
        //   ththumbnail:
        //     "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/columnList1.png"
        // },
        // {
        //   id: 2,
        //   ththumbnail:
        //     "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/columnList2.png"
        // },
        // {
        //   id: 3,
        //   ththumbnail:
        //     "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/columnList3.png"
        // }
      ],
      categerySecondShow: true,
      categerySecondActiveId: ["1"],
      pageNum: 1,
      pageSize: 10,
      loading: true
    };
  },
  created: function created() {
    this.loadcolumnCategory();
    this.activeScrollId = this.$route.query.activeScrollId;
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  methods: {
    chooseItem: function chooseItem(val) {
      this.activeScrollId = val[1].id;
      console.log(val[1].id);
      val[1].childrens.forEach(function (item) {
        columnCategerySecond.push(item);
      });
      this.pageNum = 0;
      this.columnContListItem = [];
      this.loadmore();
    },
    secondCategeryItem: function secondCategeryItem(item) {
      if (this.categerySecondActiveId.includes(item.id)) {
        this.categerySecondActiveId.splice(this.categerySecondActiveId.indexOf(item.id), 1);
      } else {
        if (this.categerySecondActiveId.length < 1) {
          this.categerySecondActiveId.push(item.id);
        } else {
          this.categerySecondActiveId = [];
          this.categerySecondActiveId[0] = item.id;
        }
      }
    },
    secondCategeryBtn: function secondCategeryBtn() {
      this.categerySecondShow = !this.categerySecondShow;
    },
    columListTo: function columListTo(item) {
      console.log(item.id);
      this.$router.push({
        path: "/column/columnSignUp",
        query: {
          mchColumnId: item.id
        }
      });
    },
    loadcolumnCategory: function loadcolumnCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _columnCategory.list)(params).then(function (response) {
        response.data.forEach(function (item) {
          _this.columnCategeryScrollItem.push(item);
        });
        if (_this.columnCategeryScrollItem.length > 0 && _this.activeScrollId == undefined) {
          _this.activeScrollId = _this.columnCategeryScrollItem[0].id;
        }
        _this.loadColumn();
      }).catch(function (error) {});
    },
    loadColumn: function loadColumn() {
      var _this2 = this;
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      params.append("mchColumnGategoryId", _this.activeScrollId);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      (0, _mchColumn.list)(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 670, 220);
          item.spots = item.spots.split(",");
          if (_utils.default.isNull(item.itemCount)) {
            item.itemCount = 0;
          }
          _this.columnContListItem.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this2.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.loadColumn();
    }
  }
};