var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "counselor_box" },
    [
      _c("div", { staticClass: "courses_header" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "row" } },
          [
            _c("div", { staticClass: "courses_header_line" }),
            _vm._v(" "),
            _c("span", { staticClass: "courses_header_name" }, [
              _vm._v(_vm._s(_vm.content.title)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "courses_header_more",
            on: {
              click: function ($event) {
                return _vm.listClick()
              },
            },
          },
          [_vm._v("查看更多>>")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.counselorForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "counselor_content",
            on: {
              click: function ($event) {
                return _vm.indexClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "counselorLogoNameBox" }, [
              _c("div", { staticClass: "counselor_content_img" }, [
                _c("img", {
                  attrs: { src: item.avatar, width: "100%", height: "100%" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "counselorContentBox" }, [
                _c("div", { staticClass: "counselorNameBox" }, [
                  _c("span", { staticClass: "counselor_content_name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "counselorMethod" }, [
                    _vm._v(_vm._s(_vm._f("watchMethod")(item.counselorMethod))),
                  ]),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counselor_content_subtitle" }, [
                  _vm._v(_vm._s(item.spots)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "priceBox" }, [
              _c("span", { staticClass: "counselor_content_price" }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "13px", "font-weight": "400" },
                  },
                  [_vm._v("¥ ")]
                ),
                _vm._v(_vm._s(item.price)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "button" }, [_vm._v("详情/预约")]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }