var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#F9F9F9", "padding-top": "15px" } },
    [
      _c(
        "div",
        { staticClass: "announcement_box" },
        [
          _c(
            "el-carousel",
            {
              attrs: { height: "30px", direction: "vertical", autoplay: true },
            },
            [
              _c("el-carousel-item", [
                _c("div", { staticClass: "announcement-cont" }, [
                  _c("div", { staticClass: "titleIcon" }, [
                    _c("img", {
                      staticStyle: { display: "block" },
                      attrs: {
                        width: "100%",
                        height: "100%",
                        src: require("./images/titleIcon5.png"),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v("您有3个测评任务待完成，请及时完成"),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }