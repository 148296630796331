var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "text", staticClass: "singleIndex" }, [
    _c(
      "div",
      { staticClass: "singe-box" },
      _vm._l(_vm.adForm, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "singe-flex",
            on: {
              click: function ($event) {
                return _vm.adClick(item)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "img" },
              [
                _c("el-image", {
                  staticClass: "img-img",
                  attrs: { src: item.logo, fit: "cover" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v("\n        " + _vm._s(item.name) + "\n      "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }