var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "member_header_box" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "member_header_logo" }, [
        _vm.avatar
          ? _c("img", {
              attrs: { width: "100%", height: "100%", src: _vm.avatar },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          attrs: {
            else: "",
            src: require("./images/default_avatar.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "member_header" }, [
        _c("span", { staticClass: "member_header_name" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "member_header_qiehuan" }),
    ]),
    _vm._v(" "),
    _vm.checkPermission([
      "evaluation-list",
      "courseOrder-list",
      "counselorOrder-list",
      "counselor2Order-list",
      "enrollOrder-list",
    ])
      ? _c("div", { staticClass: "member_tool_box" }, [
          _c("span", { staticClass: "member_tool_name" }, [_vm._v("心理档案")]),
          _vm._v(" "),
          _c("div", { staticClass: "member_tool" }, [
            _vm.checkPermission(["evaluation-list"])
              ? _c(
                  "div",
                  {
                    staticClass: "member_tool_item",
                    on: { click: _vm.gaugeOrderClick },
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("span", { staticClass: "member_tool_item_name" }, [
                      _vm._v("心理测评"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right member_class_item_icon",
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.checkPermission(["courseOrder-list"])
              ? _c(
                  "div",
                  {
                    staticClass: "member_tool_item",
                    on: { click: _vm.courseOrderClick },
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("span", { staticClass: "member_tool_item_name" }, [
                      _vm._v("心理课程"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right member_class_item_icon",
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.checkPermission(["counselorOrder-list", "counselor2Order-list"])
              ? _c(
                  "div",
                  {
                    staticClass: "member_tool_item",
                    on: { click: _vm.advisoryClick },
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("span", { staticClass: "member_tool_item_name" }, [
                      _vm._v("心理咨询"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right member_class_item_icon",
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.checkPermission(["enrollOrder-list"])
              ? _c(
                  "div",
                  {
                    staticClass: "member_tool_item",
                    on: { click: _vm.articleClick },
                  },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("span", { staticClass: "member_tool_item_name" }, [
                      _vm._v("活动报名"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right member_class_item_icon",
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.checkPermission(["columnOrder-list"])
              ? _c(
                  "div",
                  {
                    staticClass: "member_tool_item",
                    on: { click: _vm.columnClick },
                  },
                  [
                    _vm._m(5),
                    _vm._v(" "),
                    _c("span", { staticClass: "member_tool_item_name" }, [
                      _vm._v("我的专栏"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right member_class_item_icon",
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.checkPermission(["study-list"])
              ? _c(
                  "div",
                  {
                    staticClass: "member_tool_item",
                    on: { click: _vm.onLearningRecord },
                  },
                  [
                    _vm._m(6),
                    _vm._v(" "),
                    _c("span", { staticClass: "member_tool_item_name" }, [
                      _vm._v("学习记录"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-arrow-right member_class_item_icon",
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "member_class_box" }, [
      _vm.checkPermission(["payment-list"])
        ? _c(
            "div",
            {
              staticClass: "member_class_item_one",
              staticStyle: { "border-top": "1px solid #f2f2f2" },
              on: { click: _vm.billClick },
            },
            [_vm._m(7), _vm._v(" "), _vm._m(8)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checkPermission(["leaveMsg-list"])
        ? _c(
            "div",
            {
              staticClass: "member_class_item_one",
              on: { click: _vm.billClickLeave },
            },
            [_vm._m(9), _vm._v(" "), _vm._m(10)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "member_class_item_one",
          on: { click: _vm.onSetSystem },
        },
        [_vm._m(11), _vm._v(" "), _vm._m(12)]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "member_class_item_one",
          on: { click: _vm.billClickOut },
        },
        [_vm._m(13), _vm._v(" "), _vm._m(14)]
      ),
      _vm._v(" "),
      _vm.distributionIsShow
        ? _c(
            "div",
            {
              staticClass: "member_class_item_two",
              on: { click: _vm.distributionClick },
            },
            [_vm._m(15), _vm._v(" "), _vm._m(16)]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm._m(17),
    _vm._v(" "),
    _c("div", { staticClass: "member_bottom_name_box" }, [
      _c("span", { staticClass: "member_bottom_name" }, [
        _vm._v(_vm._s(_vm.mchRemark)),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_header_Img" }, [
      _c("img", {
        attrs: {
          src: require("./images/memberHBj.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_tool_item_img" }, [
      _c("img", {
        attrs: {
          src: require("./images/member-1.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_tool_item_img" }, [
      _c("img", {
        attrs: {
          src: require("./images/member-2.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_tool_item_img" }, [
      _c("img", {
        attrs: {
          src: require("./images/member-3.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_tool_item_img" }, [
      _c("img", {
        attrs: {
          src: require("./images/member-4.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_tool_item_img" }, [
      _c("img", {
        attrs: {
          src: require("./images/member-5.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_tool_item_img" }, [
      _c("img", {
        attrs: {
          src: require("./images/member-6.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_right" }, [
      _c("div", { staticClass: "member_class_item_logo" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-7.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "member_class_item_name" }, [
        _vm._v("我的账单"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_left" }, [
      _c("i", { staticClass: "el-icon-arrow-right member_class_item_icon" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_right" }, [
      _c("div", { staticClass: "member_class_item_logo" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-8.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "member_class_item_name" }, [_vm._v("留言")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_left" }, [
      _c("i", { staticClass: "el-icon-arrow-right member_class_item_icon" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_right" }, [
      _c("div", { staticClass: "member_class_item_logo" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-11.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "member_class_item_name" }, [_vm._v("设置")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_left" }, [
      _c("i", { staticClass: "el-icon-arrow-right member_class_item_icon" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_right" }, [
      _c("div", { staticClass: "member_class_item_logo" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-9.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "member_class_item_name" }, [_vm._v("退出")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_left" }, [
      _c("i", { staticClass: "el-icon-arrow-right member_class_item_icon" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_right" }, [
      _c("div", { staticClass: "member_class_item_logo" }, [
        _c("img", {
          attrs: {
            src: require("./images/member-10.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "member_class_item_name" }, [
        _vm._v("推广中心"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_class_item_left" }, [
      _c("span", { staticClass: "member_class_item_subtitle" }, [
        _vm._v("推广赚佣金"),
      ]),
      _vm._v(" "),
      _c("i", { staticClass: "el-icon-arrow-right member_class_item_icon" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member_bottom_logo" }, [
      _c("div", { staticClass: "member_bottom_logo_img" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }