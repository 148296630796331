var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "courses_box" }, [
    _c("div", { staticClass: "headerBox" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "header_name" }, [
            _vm._v(_vm._s(_vm.content.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
          on: {
            click: function ($event) {
              return _vm.listClick()
            },
          },
        },
        [_vm._m(1)]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "courses_content" }, [
      _c(
        "div",
        { staticClass: "courses_content_rollingBox" },
        _vm._l(_vm.coursesForm, function (items) {
          return _c(
            "div",
            {
              key: items.id,
              staticClass: "courses_content_item",
              on: {
                click: function ($event) {
                  return _vm.indexClick(items.id)
                },
              },
            },
            [
              _c("div", { staticClass: "courses_content_img" }, [
                _c("img", {
                  attrs: {
                    src: items.thumbnail,
                    width: "100%",
                    height: "100%",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "courses_content_titleBox" }, [
                _c("span", { staticClass: "courses_content_title" }, [
                  _vm._v(_vm._s(items.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "column_content_subTitle" }, [
                  _vm._v(_vm._s(items.subTitle)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "priceBox" }, [
                  _c("span", { staticClass: "priceText" }, [
                    _vm._v("¥" + _vm._s(items.price)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "button" }, [_vm._v("去学习")]),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_titleIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/titleLine.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_moreIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/arrowStyle2.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }