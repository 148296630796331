"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _navigation = require("@/api/navigation");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _vant = require("vant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 1,
      pageSize: 9,
      adForm: [
        // {
        //   thumbnail:
        //     'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/shuffling_img.jpeg'
        // },
        // {
        //   thumbnail:
        //     'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/shuffling_img.jpeg'
        // },
        // {
        //   thumbnail:
        //     'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/shuffling_img.jpeg'
        // }
      ],
      tagIds: [],
      Bgheight: 0,
      bottonHeight: {}
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  created: function created() {},
  mounted: function mounted() {
    this.Bgheight = this.$refs.text.offsetHeight;
    console.log(this.Bgheight);
    this.loadAd();
  },
  props: ['content'],
  methods: {
    loadAd: function loadAd() {
      var _this2 = this;
      var _this = this;
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(',');
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append('tagIds', _this.tagIds[i]);
      }
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      (0, _navigation.list)(params).then(function (response) {
        console.log(response);
        if (!_utils.default.isNull(response.data.data)) {
          _this2.adForm = response.data.data;
          // _this.adForm[0].thumbnail = utils.thumbnail(
          //   _this.adForm[0].thumbnail,
          //   750,
          //   _this.Bgheight * 2
          // )
          // if (_this.adForm.length >= 2) {
          //   console.log(this.getImgSize(_this.adForm[1].thumbnail))
          //   this.getImgSize(_this.adForm[1].thumbnail).then((res) => {
          //     console.log(res)
          //     _this.bottonHeight = res
          //     _this.adForm[1].thumbnail = utils.thumbnail(
          //       _this.adForm[1].thumbnail,
          //       res.width,
          //       res.height
          //     )
          //   })
          // }
        }
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    // 获取图片尺寸
    getImgSize: function getImgSize(url) {
      return new Promise(function (resolve, reject) {
        var imgObj = new Image();
        imgObj.src = url;
        imgObj.onload = function () {
          resolve({
            width: imgObj.width,
            height: imgObj.height
          });
        };
      });
    },
    adClick: function adClick(item) {
      // if (item.url.indexOf('http') != -1) {
      //   window.location.href = item.url
      // } else {
      //   this.$router.push({
      //     path: item.url
      //   })
      // }

      if (item.type == 11) {
        this.$router.push({
          path: '/course/list',
          query: {
            categoryId: item.typeId
          }
        });
      } else if (item.type == 9) {
        this.$router.push({
          path: '/gauge/list',
          query: {
            categoryId: item.typeId
          }
        });
      } else if (item.type == 12) {
        window.location.href = item.url;
      } else if (item.type == 0) {
        this.$router.push({
          path: '/article/list',
          query: {
            categoryId: item.typeId
          }
        });
      } else if (item.type == 10) {
        if (this.checkPermission(['counselor2-list'])) {
          this.$router.push({
            path: '/counselorNew/list'
          });
        } else if (this.checkPermission(['counselor-list'])) {
          this.$router.push({
            path: '/counselor/list'
          });
        }
      } else if (item.type == 2) {
        this.$router.push({
          path: '/index/wxPage',
          query: {
            id: item.typeId
          }
        });
      } else if (item.type == 3) {
        this.$router.push({
          path: '/activitySy/list',
          query: {
            activityCategoryId: item.typeId
          }
        });
      } else if (item.type == 4) {
        this.$router.push({
          path: '/column/columnCategery',
          query: {
            activeScrollId: item.typeId
          }
        });
      }
    }
  }
};