"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _counselorOrder = require("../../../api/counselorOrder");
var _NoData = _interopRequireDefault(require("@/components/NoData"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "orderList",
  data: function data() {
    return {
      counselorOrderList: []
    };
  },
  components: {
    NoData: _NoData.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  filters: {
    watchData: function watchData(date) {
      date = new Date(date);
      var fmt = "YYYY-mm-dd HH:MM";
      var ret;
      var opt = {
        "Y+": date.getFullYear().toString(),
        // 年
        "m+": (date.getMonth() + 1).toString(),
        // 月
        "d+": date.getDate().toString(),
        // 日
        "H+": date.getHours().toString(),
        // 时
        "M+": date.getMinutes().toString() // 分
      };
      for (var k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
        }
      }
      return fmt;
    },
    watchCounselorMethod: function watchCounselorMethod(val) {
      if (val === 0) {
        return "面对面/线上咨询";
      } else if (val === 1) {
        return "面对面咨询";
      } else if (val === 2) {
        return "线上咨询";
      }
    },
    watchPaymentStatus: function watchPaymentStatus(val) {
      if (val === 0) {
        return "待完善信息";
      } else if (val === 1) {
        return "待确认";
      } else if (val === 2) {
        return "已确认";
      } else if (val === 3) {
        return "已完成";
      } else if (val === 4) {
        return "已取消";
      }
    }
  },
  created: function created() {
    this.loadCounselorOrder();
  },
  methods: {
    toTask: function toTask(item) {
      this.$router.push({
        path: "/counselorNew/task",
        query: {
          counselorOrderId: item.id
        }
      });
    },
    orderClick: function orderClick(id, orderStatus, mchCounselorId) {
      console.log(orderStatus);
      if (orderStatus === 3) {
        this.$router.push({
          path: "/counselorNew/fourth/" + id,
          query: {
            id: id,
            orderStatus: orderStatus,
            mchCounselorId: mchCounselorId
          }
        });
      } else if (orderStatus == 2 || orderStatus == 1) {
        this.$router.push({
          path: "/counselorNew/third/" + id,
          query: {
            id: id,
            orderStatus: orderStatus,
            mchCounselorId: mchCounselorId
          }
        });
      } else if (orderStatus == 0) {
        this.$router.push({
          path: "/counselorNew/second/" + id,
          query: {
            id: id,
            orderStatus: "0",
            mchCounselorId: mchCounselorId
          }
        });
      }
    },
    loadCounselorOrder: function loadCounselorOrder() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", this.mchId);
      (0, _counselorOrder.list)(params).then(function (response) {
        // console.log(response.data.data);
        _this.counselorOrderList = response.data.data;
      });
    }
  }
};