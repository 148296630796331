"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _gaugePlan = require("@/api/gaugePlan");
var _companyEmployee = require("@/api/companyEmployee");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      gaugeList: [],
      pageNum: 1,
      pageSize: 10,
      loading: true,
      isMaskType: false,
      empName: '',
      empNo: ''
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  created: function created() {
    // this.loadIsBind()
    this.isMaskType = this.$route.query.isMaskType == 1;
    if (this.isMaskType === true) {
      this.load();
    }
  },
  filters: {
    watchStatus: function watchStatus(val) {
      switch (val) {
        case 0:
          return "未完成";
          break;
        case 1:
          return "已完成";
          break;
      }
    }
  },
  methods: {
    loadIsBind: function loadIsBind() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchId', _this.mchId);
      (0, _companyEmployee.isBind)(params).then(function (response) {
        if (response.data == true) {
          _this.isMaskType = true;
          _this.load();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    submitClick: function submitClick() {
      var _this2 = this;
      var _this = this;
      if (_utils.default.isNull(_this.empName) || _utils.default.isNull(_this.empNo)) {
        this.$message('请输入姓名和编号');
        return;
      }
      var params = new URLSearchParams();
      params.append('empName', _this.empName);
      params.append('empNo', _this.empNo);
      params.append('mchId', _this.mchId);
      (0, _companyEmployee.bind)(params).then(function (response) {
        _this.isMaskType = true;
        _this.load();
      }).catch(function (error) {
        console.log(error);
        _this2.$router.go(-1);
      });
    },
    assessmentInfo: function assessmentInfo(id) {
      var _this = this;
      var params = new URLSearchParams();
      params.append('planId', id);
      (0, _gaugePlan.create)(params).then(function (response) {
        console.log(response);
        _this.$router.replace({
          path: '/gauge/answer',
          query: {
            evaluationId: response.data.evaluation.id
          }
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    load: function load() {
      var _this = this;
      this.loading = true;
      console.log(_this.mchId);
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      (0, _gaugePlan.list)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.gaugeList.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
        console.log(_this.loading);
        console.log(_this.gaugeList);
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.load();
    }
  }
};