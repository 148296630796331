"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkPermission;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
function checkPermission(value) {
  // console.log(store)
  if (value && value instanceof Array && value.length > 0) {
    var menus = _store.default.getters && _store.default.getters.menus;
    var permissionMenus = value;
    var hasPermission = '';
    if (menus) {
      hasPermission = menus.some(function (menu) {
        return permissionMenus.includes(menu);
      });
    }
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}