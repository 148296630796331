var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gauge_box" },
    [
      _c("div", { staticClass: "courses_header" }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "row" } },
          [
            _c("div", { staticClass: "courses_header_line" }),
            _vm._v(" "),
            _c("span", { staticClass: "courses_header_name" }, [
              _vm._v(_vm._s(_vm.content.title)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "courses_header_more",
            on: {
              click: function ($event) {
                return _vm.listClick()
              },
            },
          },
          [_vm._v("查看更多>>")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.gaugeForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "gauge_content",
            on: {
              click: function ($event) {
                return _vm.gaugeClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "gauge_content_img" }, [
              _c("img", {
                attrs: { src: item.thumbnail, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gauge_content_content" }, [
              _c("div", { staticClass: "gauge_content_top" }, [
                _c("span", { staticClass: "gauge_content_title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "gauge_content_subtitle" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gauge_content_bottom" }, [
                _c("span", { staticClass: "gauge_content_date" }, [
                  _vm._v(_vm._s(item.sales) + "人已测"),
                ]),
                _vm._v(" "),
                item.price > 0
                  ? _c("span", { staticClass: "gauge_content_price" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "13px",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("¥ ")]
                      ),
                      _vm._v(_vm._s(item.price)),
                    ])
                  : _c("span", { staticClass: "freePrice" }, [_vm._v("免费")]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }