var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "assessment_info_content" }, [
        _vm.dataList.indexOf("姓名") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("姓名")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("姓名"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.form.empName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "empName", $$v)
                      },
                      expression: "form.empName",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("性别") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("性别")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("性别"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sex", $$v)
                        },
                        expression: "form.sex",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "男" } }, [
                        _vm._v("男"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "女" } }, [
                        _vm._v("女"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("电话") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("电话")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("电话"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: {
                      placeholder: "请输入电话",
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("出生日期") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("出生日期")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("出生日期"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请选择出生日期", readonly: "" },
                    on: { focus: _vm.onBirthday },
                    model: {
                      value: _vm.form.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "birthday", $$v)
                      },
                      expression: "form.birthday",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("民族") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("民族")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("民族"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择民族" },
                      model: {
                        value: _vm.form.national,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "national", $$v)
                        },
                        expression: "form.national",
                      },
                    },
                    _vm._l(_vm.nations, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("户籍所在地") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("户籍所在地")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("户籍所在地"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.registerPlace,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "registerPlace", $$v)
                        },
                        expression: "form.registerPlace",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "城市" } }, [
                        _vm._v("城市"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "农村" } }, [
                        _vm._v("农村"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("婚姻状况") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("婚姻状况")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("婚姻状况"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择婚姻状况" },
                      model: {
                        value: _vm.form.maritalStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "maritalStatus", $$v)
                        },
                        expression: "form.maritalStatus",
                      },
                    },
                    _vm._l(_vm.marriage, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("工作所在地") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("工作所在地")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("工作所在地"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("Distpicker", {
                    attrs: {
                      province: _vm.district.province,
                      city: _vm.district.city,
                      area: _vm.district.area,
                    },
                    on: { selected: _vm.onSelected },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("文化程度") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("文化程度")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("文化程度"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择文化程度" },
                      model: {
                        value: _vm.form.education,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "education", $$v)
                        },
                        expression: "form.education",
                      },
                    },
                    _vm._l(_vm.education, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("职业") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("职业")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("职业"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择职业" },
                      on: { focus: _vm.setMinWidth },
                      model: {
                        value: _vm.form.job,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "job", $$v)
                        },
                        expression: "form.job",
                      },
                    },
                    _vm._l(_vm.professional, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        style: { width: _vm.minWidth + 2 + "px" },
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("月收入") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("月收入")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("月收入"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择月收入" },
                      model: {
                        value: _vm.form.salary,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "salary", $$v)
                        },
                        expression: "form.salary",
                      },
                    },
                    _vm._l(_vm.income, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("政治面貌") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("政治面貌")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("政治面貌"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择政治面貌" },
                      model: {
                        value: _vm.form.political,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "political", $$v)
                        },
                        expression: "form.political",
                      },
                    },
                    _vm._l(_vm.political, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("身高") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("身高")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("身高(cm)"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: {
                      placeholder: "请输入身高",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.height,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "height", $$v)
                      },
                      expression: "form.height",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("体重") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("体重")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("体重(kg)"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: {
                      placeholder: "请输入体重",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "weight", $$v)
                      },
                      expression: "form.weight",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("头围") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("头围")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("头围(cm)"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: {
                      placeholder: "请输入头围",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.hc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hc", $$v)
                      },
                      expression: "form.hc",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("生产方式") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("生产方式")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("生产方式"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.childbirthType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "childbirthType", $$v)
                        },
                        expression: "form.childbirthType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "顺产" } }, [
                        _vm._v("顺产"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "剖腹产" } }, [
                        _vm._v("剖腹产"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("抚养者") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("抚养者")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("抚养者"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择抚养者" },
                      model: {
                        value: _vm.form.foster,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "foster", $$v)
                        },
                        expression: "form.foster",
                      },
                    },
                    _vm._l(_vm.raising, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("母亲生产年龄") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("母亲生产年龄")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("母亲生产年龄"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: {
                      placeholder: "请输入母亲生产年龄",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.motherChildbirthAge,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "motherChildbirthAge", $$v)
                      },
                      expression: "form.motherChildbirthAge",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("兄弟姐妹数量") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("兄弟姐妹数量")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("兄弟姐妹数量"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择兄弟姐妹数量" },
                      model: {
                        value: _vm.form.brotherNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "brotherNum", $$v)
                        },
                        expression: "form.brotherNum",
                      },
                    },
                    _vm._l(_vm.Brothers, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家庭结构") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("家庭结构")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("家庭结构"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择家庭结构" },
                      on: { focus: _vm.setMinWidth },
                      model: {
                        value: _vm.form.familyStructure,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "familyStructure", $$v)
                        },
                        expression: "form.familyStructure",
                      },
                    },
                    _vm._l(_vm.family, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        style: { width: _vm.minWidth + 2 + "px" },
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家庭收入水平") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("家庭收入水平")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("家庭收入水平"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择家庭收入水平" },
                      model: {
                        value: _vm.form.familyIncome,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "familyIncome", $$v)
                        },
                        expression: "form.familyIncome",
                      },
                    },
                    _vm._l(_vm.total, function (item) {
                      return _c("el-option", {
                        key: item.marriageName,
                        attrs: {
                          label: item.marriageName,
                          value: item.marriageName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("证件类型") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("证件类型")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("证件类型"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择证件类型" },
                      model: {
                        value: _vm.form.certificatesType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "certificatesType", $$v)
                        },
                        expression: "form.certificatesType",
                      },
                    },
                    _vm._l(_vm.documentList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("证件号码") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("证件号码")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("证件号码"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入证件号码" },
                    model: {
                      value: _vm.form.certificatesNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "certificatesNo", $$v)
                      },
                      expression: "form.certificatesNo",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("社会保险卡号") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("社会保险卡号")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("社会保险卡号"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入社会保险卡号" },
                    model: {
                      value: _vm.form.sin,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sin", $$v)
                      },
                      expression: "form.sin",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("出生胎龄") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("出生胎龄")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("出生胎龄"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入出生胎龄" },
                    model: {
                      value: _vm.form.gestationalAge,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gestationalAge", $$v)
                      },
                      expression: "form.gestationalAge",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("所属组织单位") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("所属组织单位")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("所属组织单位"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择所属组织单位" },
                      model: {
                        value: _vm.form.company,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "company", $$v)
                        },
                        expression: "form.company",
                      },
                    },
                    _vm._l(_vm.treeList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "font-size": "12px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.companyCategoryName) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("学号") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("学号")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("学号"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入学号" },
                    model: {
                      value: _vm.form.studyNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "studyNo", $$v)
                      },
                      expression: "form.studyNo",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("身体健康状况") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("身体健康状况")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("身体健康状况"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入身体健康状况" },
                    model: {
                      value: _vm.form.health,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "health", $$v)
                      },
                      expression: "form.health",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("个人精神疾病史") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("个人精神疾病史")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("个人精神疾病史"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入个人精神疾病史" },
                    model: {
                      value: _vm.form.personalNeuropathy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "personalNeuropathy", $$v)
                      },
                      expression: "form.personalNeuropathy",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家族精神疾病史") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("家族精神疾病史")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("家族精神疾病史"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入家族精神疾病史" },
                    model: {
                      value: _vm.form.familyNeuropathy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "familyNeuropathy", $$v)
                      },
                      expression: "form.familyNeuropathy",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("学习状况") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("学习状况")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("学习状况"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择学习状况" },
                      model: {
                        value: _vm.form.learning,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "learning", $$v)
                        },
                        expression: "form.learning",
                      },
                    },
                    _vm._l(_vm.learningList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("居住地址") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("居住地址")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("居住地址"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入居住地址" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("父母姓名及联系方式") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("父母姓名及联系方式")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("父母姓名及联系方式"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入父母姓名及联系方式" },
                    model: {
                      value: _vm.form.parentContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentContact", $$v)
                      },
                      expression: "form.parentContact",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("紧急联系人姓名及联系方式") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("紧急联系人姓名及联系方式")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("紧急联系人姓名及联系方式"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入紧急联系人姓名及联系方式" },
                    model: {
                      value: _vm.form.emergencyContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "emergencyContact", $$v)
                      },
                      expression: "form.emergencyContact",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家庭关系") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("家庭关系")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("家庭关系"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择家庭关系" },
                      model: {
                        value: _vm.form.familyRelation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "familyRelation", $$v)
                        },
                        expression: "form.familyRelation",
                      },
                    },
                    _vm._l(_vm.RelationList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("准考证号") != -1
          ? _c("div", { staticClass: "assessment-box" }, [
              _c("span", { staticClass: "assessment_info_content_title" }, [
                _vm.getReqire("准考证号")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("准考证号"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "assessment_info_content_input" },
                [
                  _c("el-input", {
                    staticClass: "assessment_info_content_input_box",
                    attrs: { placeholder: "请输入准考证号" },
                    model: {
                      value: _vm.form.examineeNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "examineeNumber", $$v)
                      },
                      expression: "form.examineeNumber",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "assessment_info_botton",
            attrs: { loading: _vm.isSubmit },
            on: { click: _vm.addSubmit },
          },
          [_vm._v("\n      提交\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          style: { height: "320px" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择年月日",
              formatter: _vm.formatter,
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.onDataCofig,
              cancel: function ($event) {
                _vm.show = false
              },
            },
            model: {
              value: _vm.currentTime,
              callback: function ($$v) {
                _vm.currentTime = $$v
              },
              expression: "currentTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "心理测验知情同意书",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "show-close": false,
            center: true,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", { staticClass: "agreement_content" }, [
            _c("div", [_vm._v("尊敬的参与者：")]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v("您好，感谢您参与本次心理测验研究！"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "本知情同意书旨在向您详细说明此次测验的目的、过程以及您的权利和义务，请您仔细阅读与知晓。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "本次测验所得数据仅用于科学研究。通过您的认真参与，我们能够获得宝贵的数据资源，这将对我们的研究提供有力的支持。通过数据收集与进一步分析所得出的最终结论将推动心理健康评估领域的进步，对未来心理健康检测有重要的启示作用。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "本次测验内容主要有基本信息收集、一系列的心理量表测试与阅读一段话的录音数据采集。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "我们将严格遵守保密原则，所有收集到的信息将仅用于专业人员的科学研究，并且不会透露给无关人员。除非法律或政府机关要求，否则不会泄露您的个人资料与数据。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "参与此次心理测验完全基于您的自愿。请您详细阅读以上说明，您有权随时决定是否继续参与。"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                "您已经仔细阅读了本知情同意书，并且了解本次测验的目的、内容等信息。您已充分理解并同意参与此次心理测验。再次感谢您的配合与参与！"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "agree" },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { "text-color": "#818181" },
                  model: {
                    value: _vm.agreement_checked,
                    callback: function ($$v) {
                      _vm.agreement_checked = $$v
                    },
                    expression: "agreement_checked",
                  },
                },
                [_vm._v("我已仔细阅读并同意")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "agreement_tltle" }, [
                _vm._v("《读一段话测试协议》"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "primary",
                    round: "true",
                    disabled: !_vm.agreement_checked,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("同 意")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "assessment_info_header" }, [
      _c("div", { staticClass: "assessment_info_header_img" }, [
        _c("img", {
          attrs: {
            width: "100%",
            height: "100%",
            alt: "",
            src: "http://rzico.oss-cn-shenzhen.aliyuncs.com/meixinshuo/assessment_header.png",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "assessment_info_header_box" }, [
        _c("div", { staticClass: "assessment_info_header_title" }, [
          _vm._v(
            "\n        为了提高测验的有效性和准确性，我们将收集您以下信息，这些信息只会被用于测验本身，并严格保密，请放心填写。\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }