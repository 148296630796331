var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article_content" }, [
    _c("div", { staticClass: "article_imageLeft" }, [
      _vm.adForm[0] != undefined
        ? _c("img", {
            attrs: {
              src: _vm.adForm[0].thumbnail,
              width: "100%",
              height: "100%",
            },
            on: {
              click: function ($event) {
                return _vm.toItem(_vm.adForm[0])
              },
            },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "article_imageRight" }, [
      _c("div", { staticClass: "article_imageRight_on" }, [
        _vm.adForm[1] != undefined
          ? _c("img", {
              attrs: {
                src: _vm.adForm[1].thumbnail,
                width: "100%",
                height: "100%",
              },
              on: {
                click: function ($event) {
                  return _vm.toItem(_vm.adForm[1])
                },
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "article_imageRight_down" }, [
        _vm.adForm[2] != undefined
          ? _c("img", {
              attrs: {
                src: _vm.adForm[2].thumbnail,
                width: "100%",
                height: "100%",
              },
              on: {
                click: function ($event) {
                  return _vm.toItem(_vm.adForm[2])
                },
              },
            })
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }