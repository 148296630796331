var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "third_box" },
    [
      _c("div", { staticClass: "third_header_prompt" }, [
        _c("i", { staticClass: "el-icon-success third_header_prompt_icon" }),
        _vm._v(" "),
        _vm.orderInfo.orderStatus === 1
          ? _c("span", { staticClass: "third_header_prompt_title" }, [
              _vm._v("订单已提交至咨询师，待咨询师确认订单"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.orderInfo.orderStatus === 2
          ? _c("span", { staticClass: "third_header_prompt_title" }, [
              _vm._v("您已和咨询师确认好咨询时间，请等待开始咨询"),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.orderInfo.orderStatus === 2
        ? _c("div", { staticClass: "third_header" }, [
            _c("span", { staticClass: "third_header_title" }, [
              _vm._v("咨询信息"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.orderItems, function (item) {
        return _vm.orderInfo.orderStatus === 2
          ? _c("div", { key: item.id, staticClass: "third_message" }, [
              _c("div", { staticClass: "message_box" }, [
                _vm._m(0, true),
                _vm._v(" "),
                _c("div", { staticClass: "third_line_box" }, [
                  _c("span", { staticClass: "third_line_left" }, [
                    _vm._v("咨询预约时间"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "third_line_right",
                      staticStyle: { "font-size": "12px", color: "#2B2B2B" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.appointmentDate) +
                            " " +
                            _vm._s(item.appointmentHour.slice(0, 5)) +
                            "开始"
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "line" }),
              ]),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _c("div", { staticClass: "third_header" }, [
        _c("span", { staticClass: "third_header_title" }, [_vm._v("预约信息")]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "third_header_subtitle",
            on: {
              click: function ($event) {
                return _vm.openClick("1")
              },
            },
          },
          [_vm._v("查看预约须知")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "third_content" }, [
        _c("div", { staticClass: "third_line_box" }, [
          _c("span", { staticClass: "third_line_left" }, [_vm._v("预约老师")]),
          _vm._v(" "),
          _c("div", { staticClass: "third_line_right" }, [
            _c("div", { staticClass: "third_line_img" }, [
              _c("img", {
                attrs: {
                  width: "100%",
                  height: "100%",
                  src: _vm.counselor.avatar,
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "third_line_name" }, [
              _vm._v(_vm._s(_vm.counselor.name)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "third_line_box" }, [
          _c("span", { staticClass: "third_line_left" }, [_vm._v("咨询方式")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "third_line_right",
              staticStyle: { "font-size": "13px" },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("watchCounselorMethod")(
                      _vm.orderInfo.counselorMethod
                    )
                  )
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "third_line_box" }, [
          _c("span", { staticClass: "third_line_left" }, [_vm._v("咨询次数")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.orderInfo.quantity) + "次")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "third_line_box" }, [
          _c("span", { staticClass: "third_line_left" }, [_vm._v("预约时间")]),
          _vm._v(" "),
          _c("div", { staticClass: "third_line_right" }, [
            _c("span", { staticClass: "third_line_date_title" }, [
              _vm._v(
                _vm._s(_vm.yearTime) +
                  " " +
                  _vm._s(_vm.dataTime.slice(0, 5)) +
                  "开始"
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "third_describe" }, [
        _c("span", [_vm._v(_vm._s(_vm.orderInfo.problemDescr))]),
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "third_describe" }, [
        _c("span", [_vm._v(_vm._s(_vm.orderInfo.expectResult))]),
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "third_card_box" },
        [
          _c(
            "el-form",
            {
              ref: "basis",
              attrs: { size: "small", model: _vm.basis, "label-width": "80px" },
            },
            [
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("真实姓名")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.selfRealName))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("联系电话")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.selfPhone))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("真实年龄")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.selfAge))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("性别")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.selfGender))]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "third_card_box" },
        [
          _c(
            "el-form",
            {
              ref: "emergency",
              attrs: {
                size: "small",
                model: _vm.emergency,
                "label-width": "80px",
              },
            },
            [
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("真实姓名")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.urgentRealName))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("联系电话")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.urgentPhone))]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "message_line_box" }, [
                _c("span", { staticClass: "span_title" }, [_vm._v("关系")]),
                _vm._v(" "),
                _c("div", { staticClass: "message_line" }, [
                  _c("span", [_vm._v(_vm._s(_vm.orderInfo.urgentRelation))]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("prompt", {
        attrs: {
          title: _vm.title,
          promptData: _vm.promptData,
          promptType: _vm.promptType,
        },
        on: { downClick: _vm.downClick },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "third_line_box" }, [
      _c("span", { staticClass: "third_line_left" }, [_vm._v("咨询完成时间")]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "third_line_right",
          staticStyle: { "font-size": "12px" },
        },
        [_c("span", [_vm._v("待咨询")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "third_header" }, [
      _c("span", { staticClass: "third_header_title" }, [_vm._v("问题描述")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "third_header" }, [
      _c("span", { staticClass: "third_header_title" }, [_vm._v("咨询预期")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "third_title_header" }, [
      _c("span", { staticClass: "third_title_header_title" }, [
        _vm._v("基本信息"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "third_title_header_subtitle" }, [
        _vm._v("(真实信息有助于老师准确判断)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "third_title_header" }, [
      _c("span", { staticClass: "third_title_header_title" }, [
        _vm._v("紧急联系人"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "third_title_header_subtitle" }, [
        _vm._v("(严格保密，请放心填写)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }