var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#F9F9F9", "padding-top": "6px" } },
    [
      _c(
        "div",
        { staticClass: "announcement_box" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-carousel",
            {
              attrs: { height: "33px", direction: "vertical", autoplay: true },
            },
            _vm._l(_vm.announcementMessage, function (item) {
              return _c("el-carousel-item", { key: item }, [
                _c("div", { staticClass: "announcement-cont" }, [
                  _c("div", { domProps: { innerHTML: _vm._s(item.title) } }),
                ]),
              ])
            }),
            1
          ),
          _vm._v(" "),
          _vm._m(1),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBox" }, [
      _c("div", { staticClass: "titleIcon" }, [
        _c("img", {
          staticStyle: { display: "block" },
          attrs: {
            width: "100%",
            height: "100%",
            src: require("./images/titleIcon.png"),
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "announcement-cont-title" }, [_vm._v("公告")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "suffixIcon" }, [
      _c("img", {
        staticStyle: { display: "block" },
        attrs: {
          width: "100%",
          height: "100%",
          src: require("./images/suffix.png"),
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }