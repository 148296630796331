import { render, staticRenderFns } from "./articleImg.vue?vue&type=template&id=5daf7435&scoped=true"
import script from "./articleImg.vue?vue&type=script&lang=js"
export * from "./articleImg.vue?vue&type=script&lang=js"
import style0 from "./articleImg.vue?vue&type=style&index=0&id=5daf7435&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5daf7435",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\28658\\Desktop\\美心数科开发\\gitee\\mxs-online-h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5daf7435')) {
      api.createRecord('5daf7435', component.options)
    } else {
      api.reload('5daf7435', component.options)
    }
    module.hot.accept("./articleImg.vue?vue&type=template&id=5daf7435&scoped=true", function () {
      api.rerender('5daf7435', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/index/components/articleImage/articleImg.vue"
export default component.exports