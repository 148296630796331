"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      audioStatus: require("../../assets/mxs_images/icon_pause.png"),
      musicTime: 0,
      isPlay: false
    };
  },
  props: {
    nowAnswer: {
      default: ''
    },
    musicName: {
      default: ''
    }
  },
  methods: {
    //      控制音乐
    openPlayer: function openPlayer() {
      var _this = this;
      var audio = this.$el.querySelector('audio');
      if (this.isPlay) {
        this.isPlay = false;
        this.audioStatus = require("../../assets/mxs_images/icon_play.png");
        audioHeader.pause();
      } else {
        this.audioStatus = require("../../assets/mxs_images/icon_pause.png");
        audioHeader.play();
        this.isPlay = true;
        //          获取音乐时长
        setTimeout(function () {
          _this.musicTime = audioHeader.duration;
          if (!_utils.default.isNull(_this.musicTime) && !isNaN(_this.musicTime)) {
            _this.musicTime = audioHeader.duration;
          } else {
            _this.musicTime = 0;
          }
        }, 1000);
      }
    }
  }
};