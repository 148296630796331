"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _mchCourse = require("@/api/mchCourse");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      counselorForm: [],
      avatar: "",
      pageNum: 1,
      pageSize: 1,
      tagIds: [],
      firstActivity: {}
    };
  },
  created: function created() {
    this.loadCounselor();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  props: ["content"],
  methods: {
    checkPermission: _permission.default,
    loadCounselor: function loadCounselor() {
      var _this2 = this;
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(",");
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", this.mchId);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append("tagIds", _this.tagIds[i]);
      }
      (0, _mchCourse.list)(params).then(function (response) {
        _this.counselorForm = response.data.data;
        // _this.avatar = _this.counselorForm[0].avatar;
        if (_this.counselorForm.length > 0) {
          _this2.firstActivity = response.data.data[0];
          _this.counselorForm.forEach(function (item) {
            item.thumbnail = _utils.default.thumbnail(item.thumbnail, 690, 312);
          });
        }
        console.log(response, "列表");
      }).catch(function (error) {
        console.log(error);
      });
    },
    indexClick: function indexClick(id) {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/info/info",
          query: {
            id: id
          }
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/info",
          query: {
            id: id
          }
        });
      }
    },
    listClick: function listClick() {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/list"
          //query: { categoryId: item.typeId }
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/list"
          //query: { categoryId: item.typeId }
        });
      }
    }
  }
};