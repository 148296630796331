"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.object.values");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _districts = _interopRequireDefault(require("./districts"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var DEFAULT_CODE = 100000;
var _default2 = exports.default = {
  name: 'distpicker',
  props: {
    province: {
      type: [String, Number],
      default: ''
    },
    city: {
      type: [String, Number],
      default: ''
    },
    area: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    hideArea: {
      type: Boolean,
      default: false
    },
    onlyProvince: {
      type: Boolean,
      default: false
    },
    staticPlaceholder: {
      type: Boolean,
      default: false
    },
    placeholders: {
      type: Object,
      default: function _default() {
        return {
          province: '省',
          city: '市',
          area: '区'
        };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    provinceDisabled: {
      type: Boolean,
      default: false
    },
    cityDisabled: {
      type: Boolean,
      default: false
    },
    areaDisabled: {
      type: Boolean,
      default: false
    },
    addressHeader: {
      type: String,
      default: 'address-header'
    },
    addressContainer: {
      type: String,
      default: 'address-container'
    },
    wrapper: {
      type: String,
      default: 'distpicker-address-wrapper'
    }
  },
  data: function data() {
    return {
      tab: 1,
      showCityTab: false,
      showAreaTab: false,
      provinces: [],
      cities: [],
      areas: [],
      currentProvince: this.determineType(this.province) || this.placeholders.province,
      currentCity: this.determineType(this.city) || this.placeholders.city,
      currentArea: this.determineType(this.area) || this.placeholders.area
    };
  },
  created: function created() {
    if (this.type != 'mobile') {
      this.provinces = this.getDistricts();
      this.cities = this.province ? this.getDistricts(this.getAreaCode(this.determineType(this.province))) : [];
      this.areas = this.city ? this.getDistricts(this.getAreaCode(this.determineType(this.city), this.area)) : [];
    } else {
      if (this.area && !this.hideArea && !this.onlyProvince) {
        this.tab = 3;
        this.showCityTab = true;
        this.showAreaTab = true;
        this.areas = this.getDistricts(this.getAreaCode(this.determineType(this.city), this.area));
      } else if (this.city && this.hideArea && !this.onlyProvince) {
        this.tab = 2;
        this.showCityTab = true;
        this.cities = this.getDistricts(this.getAreaCode(this.determineType(this.province)));
      } else {
        this.provinces = this.getDistricts();
      }
    }
  },
  watch: {
    currentProvince: function currentProvince(vaule) {
      this.$emit('province', this.setData(vaule));
      if (this.onlyProvince) this.emit('selected');
    },
    currentCity: function currentCity(value) {
      this.$emit('city', this.setData(value, this.currentProvince));
      if (value != this.placeholders.city && this.hideArea) this.emit('selected');
    },
    currentArea: function currentArea(value) {
      this.$emit('area', this.setData(value, this.currentProvince, true));
      this.emit('selected');
    },
    province: function province(value) {
      this.currentProvince = this.province || this.placeholders.province;
      this.cities = this.determineValue(this.currentProvince, this.placeholders.province);
    },
    city: function city(value) {
      this.currentCity = this.city || this.placeholders.city;
      this.areas = this.determineValue(this.currentCity, this.placeholders.city, this.currentProvince);
    },
    area: function area(value) {
      this.currentArea = this.area || this.placeholders.area;
    }
  },
  methods: {
    setData: function setData(value) {
      var check = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var isArea = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var code;
      if (isArea) {
        code = this.getCodeByArea(value);
      } else {
        code = this.getAreaCode(value, check);
      }
      return {
        code: code,
        value: value
      };
    },
    getCodeByArea: function getCodeByArea(value) {
      var _this = this;
      var code;
      Object.values(this.areas).forEach(function (item, key) {
        if (item === value) {
          code = Object.keys(_this.areas)[key];
        }
      });
      return code;
    },
    emit: function emit(name) {
      var data = {
        province: this.setData(this.currentProvince)
      };
      if (!this.onlyProvince) {
        this.$set(data, 'city', this.setData(this.currentCity));
      }
      if (!this.onlyProvince || this.hideArea) {
        this.$set(data, 'area', this.setData(this.currentArea, this.currentCity));
      }
      this.$emit(name, data);
    },
    getCities: function getCities() {
      this.currentCity = this.placeholders.city;
      this.currentArea = this.placeholders.area;
      this.cities = this.determineValue(this.currentProvince, this.placeholders.province);
      this.cleanList('areas');
      if (this.cities.length === 0) {
        this.emit('selected');
        this.tab = 1;
        this.showCityTab = false;
      }
    },
    getAreas: function getAreas() {
      this.currentArea = this.placeholders.area;
      this.areas = this.determineValue(this.currentCity, this.placeholders.city, this.currentProvince);
      if (this.areas.length === 0) {
        this.emit('selected');
        this.tab = 2;
        this.showAreaTab = false;
      }
    },
    resetProvince: function resetProvince() {
      this.tab = 1;
      this.provinces = this.getDistricts();
      this.showCityTab = false;
      this.showAreaTab = false;
    },
    resetCity: function resetCity() {
      this.tab = 2;
      this.showCityTab = true;
      this.showAreaTab = false;
      this.getCities();
    },
    chooseProvince: function chooseProvince(name) {
      this.currentProvince = name;
      if (this.onlyProvince) return;
      this.tab = 2;
      this.showCityTab = true;
      this.showAreaTab = false;
      this.getCities();
    },
    chooseCity: function chooseCity(name) {
      this.currentCity = name;
      if (this.hideArea) return;
      this.tab = 3;
      this.showCityTab = true;
      this.showAreaTab = true;
      this.getAreas();
    },
    chooseArea: function chooseArea(name) {
      this.currentArea = name;
    },
    getAreaCodeByPreCode: function getAreaCodeByPreCode(name, preCode) {
      var codes = [];
      for (var x in _districts.default) {
        for (var y in _districts.default[x]) {
          if (name === _districts.default[x][y]) {
            codes.push(y);
          }
        }
      }
      if (codes.length > 1) {
        var index;
        codes.forEach(function (item, i) {
          if (item.slice(0, 2) == preCode) {
            index = i;
          }
        });
        return codes[index];
      } else {
        return codes[0];
      }
    },
    getAreaCode: function getAreaCode(name) {
      var check = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      for (var x in _districts.default) {
        for (var y in _districts.default[x]) {
          if (name === _districts.default[x][y]) {
            if (check.length > 0) {
              var code = this.getAreaCodeByPreCode(check, y.slice(0, 2));
              if (!code || y.slice(0, 2) !== code.slice(0, 2)) {
                continue;
              } else {
                return y;
              }
            } else {
              return y;
            }
          }
        }
      }
    },
    getCodeValue: function getCodeValue(code) {
      for (var x in _districts.default) {
        for (var y in _districts.default[x]) {
          if (code === parseInt(y)) {
            return _districts.default[x][y];
          }
        }
      }
    },
    getDistricts: function getDistricts() {
      var code = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_CODE;
      return _districts.default[code] || [];
    },
    determineValue: function determineValue(currentValue, placeholderValue) {
      var check = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      if (currentValue === placeholderValue) {
        return [];
      } else {
        return this.getDistricts(this.getAreaCode(currentValue, check));
      }
    },
    determineType: function determineType(value) {
      if (typeof value === 'number') {
        return this.getCodeValue(value);
      }
      return value;
    },
    cleanList: function cleanList(name) {
      this[name] = [];
    }
  }
};