"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _gaugePlan = require("@/api/gaugePlan");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      count: 0
    };
  },
  props: ["content"],
  created: function created() {
    this.init();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  methods: {
    init: function init() {
      var _this = this;
      (0, _gaugePlan.unfinish)().then(function (res) {
        var data = res.data;
        _this.count = (data.teamUnfinish || 0) + (data.personalUnfinish || 0);
      });
    }
  }
};