"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _mchGauge = require("@/api/mchGauge");
var _category = _interopRequireDefault(require("./components/category"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      categoryId: '',
      gaugeList: [],
      pageNum: 1,
      pageSize: 10,
      mchGaugeGategoryId: '',
      loading: true
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  components: {
    Category: _category.default
  },
  filters: {},
  created: function created() {
    this.categoryId = this.$route.query.categoryId;
  },
  methods: {
    indexClick: function indexClick(id) {
      this.$router.push({
        path: '/gauge/index',
        query: {
          mchGaugeId: id
        }
      });
    },
    load: function load() {
      var _this = this;
      this.loading = true;
      console.log(_this.mchId);
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchGaugeGategoryId', _this.mchGaugeGategoryId);
      params.append('mchId', _this.mchId);
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      (0, _mchGauge.list)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.gaugeList.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
        console.log(_this.loading);
        console.log(_this.gaugeList);
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.load();
    },
    categoryClick: function categoryClick(id) {
      console.log(id);
      this.gaugeList = [];
      this.mchGaugeGategoryId = id;
      this.pageNum = 0;
      this.loadmore();
    }
  }
};