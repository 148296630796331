"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _binding = require("@/api/binding");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'bindUser',
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId', 'token'])),
  data: function data() {
    return {
      title: '',
      name: '',
      avatar: '',
      code: '',
      success: '',
      shareTitle: '邀请员工绑定',
      refresh: false,
      redirect: undefined,
      authCode: undefined,
      loading: false,
      uuid: null
    };
  },
  created: function created() {
    var userId = _utils.default.getUrlParameter("id");
    var _this = this;
    setTimeout(function () {
      _this.bindUser(userId);
    }, 2000);
    this.getQrcode();
  },
  methods: {
    bindUser: function bindUser(id) {
      var _this2 = this;
      if (this.token !== undefined && this.token !== null && this.token !== '') {
        (0, _binding.bindUser)(id).then(function (response) {
          console.log(response);
          //          this.name = response.data.userInfo.username
          //          this.avatar = response.data.userInfo.avatar
          _this2.title = '绑定成功';
          _this2.success = 'success';
          _this2.initialCode();
        }).catch(function (error) {
          _this2.title = '绑定失败';
          _this2.success = 'error';
          console.log(error);
        });
      }
    },
    getQrcode: function getQrcode() {
      var _this3 = this;
      var params = new URLSearchParams();
      params.append('mchId', this.mchId);
      (0, _binding.getQrcode)(params).then(function (response) {
        console.log(response);
        _this3.code = response.data.qrcode;
        console.log('token', _this3.token);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};