var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "moreBox_content" },
      _vm._l(_vm.nowAnswer.options, function (item, index) {
        return _c(
          "div",
          {
            staticClass: "moreContent_class",
            on: {
              click: function ($event) {
                return _vm.sortbotton(index, item.status)
              },
            },
          },
          [
            _c("div", { staticClass: "moreClass_TitleBox" }, [
              _c("span", { staticClass: "moreClass_Title" }, [
                _vm._v(_vm._s(item.xid) + "." + _vm._s(item.name)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "moreClass_choose" }, [
              item.status == true
                ? _c("img", {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      alt: "",
                      src: require("../../assets/mxs_images/hook.png"),
                    },
                  })
                : _vm._e(),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.percents != _vm.percenter
      ? _c(
          "div",
          { staticClass: "satisfactionTitle_BottonBox", style: { zIndex: 3 } },
          [
            _c(
              "div",
              {
                staticClass: "satisfactionTitle_Botton",
                on: { click: _vm.chooseAnswer },
              },
              [_c("span", [_vm._v("确定")])]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }