var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        {
          staticClass: "nav-content",
          class:
            _vm.item.children[0].meta.title == "学习训练" &&
            _vm.checkPermission(["discovery-index"]) == false
              ? "active"
              : "",
        },
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                      [
                        _vm.onlyOneChild.meta.title == "学习训练" &&
                        _vm.checkPermission(["discovery-index"]) == true
                          ? _c("item", {
                              attrs: {
                                icon:
                                  _vm.onlyOneChild.meta.icon ||
                                  (_vm.item.meta && _vm.item.meta.icon),
                                iconTwo:
                                  _vm.onlyOneChild.meta.iconTwo ||
                                  (_vm.item.meta && _vm.item.meta.iconTwo),
                                title: _vm.onlyOneChild.meta.title,
                                routeName: _vm.onlyOneChild.name,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.onlyOneChild.meta.title != "学习训练"
                          ? _c("item", {
                              attrs: {
                                icon:
                                  _vm.onlyOneChild.meta.icon ||
                                  (_vm.item.meta && _vm.item.meta.icon),
                                iconTwo:
                                  _vm.onlyOneChild.meta.iconTwo ||
                                  (_vm.item.meta && _vm.item.meta.iconTwo),
                                title: _vm.onlyOneChild.meta.title,
                                routeName: _vm.onlyOneChild.name,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }