"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.array.find");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _payment = require("@/api/payment");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      redirect: undefined,
      sn: '',
      paymentType: false,
      url: '',
      paymentCode: 0,
      infoName: null,
      infoContent: null
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.url = _utils.default.host();
    this.sn = this.$route.query.sn;
    this.info();
  },
  mounted: function mounted() {
    var _this = this;
    //h5支付重定向回来检查是否支付成功
    if (_utils.default.isNull(this.$route.query.status)) {
      _this.paymentCode = 0;
      if (_utils.default.isWeiXin()) {
        _this.wxPay();
      } else {
        _this.h5Pay();
      }
    }
    if (!_utils.default.isNull(this.$route.query.status)) {
      setTimeout(function () {
        var params = new URLSearchParams();
        params.append('sn', _this.sn);
        (0, _payment.query)(params).then(function (response) {
          if (response.data && response.data.return_code == '0000') {
            _this.$router.replace({
              path: _this.redirect,
              query: {
                paymentStatus: _this.paymentType
              }
            });
            _this.paymentCode = 1;
            _this.paymentType = false;
          } else {
            _this.paymentType = true;
            _this.paymentCode = 2;
          }
        }).catch(function (error) {
          _this.paymentType = true;
          _this.paymentCode = 2;
        });
      }, 2000);
    }
  },
  methods: {
    //浏览器支付
    h5Pay: function h5Pay() {
      var _this2 = this;
      var _this = this;
      // this.loading = this.$loading({
      //   lock: true,
      //   text: '发起支付中...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      var params = new URLSearchParams();
      params.append('paymentPluginId', 'weixinPayPlugin');
      params.append('sn', this.sn);
      (0, _payment.submit)(params).then(function (response) {
        console.log(response);
        _this2.paymentType = false;
        _this.paymentCode = 1;
        window.location.replace(response.data.mweb_url + '&redirect_url=' + encodeURIComponent(_utils.default.host() + '/#/payment?sn=' + _this.sn + '&status=1&redirect=' + _this.redirect));
      }).catch(function (error) {
        // this.loading.close();
        _this2.paymentType = true;
        _this.paymentCode = 2;
      });
    },
    //发起微信付款
    wxPay: function wxPay() {
      var _this = this;
      // this.loading = this.$loading({
      //   lock: true,
      //   text: '发起支付中...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      var params = new URLSearchParams();
      params.append('paymentPluginId', 'weixinPayPlugin');
      params.append('sn', this.sn);
      (0, _payment.submit)(params).then(function (response) {
        console.log(response);
        if (typeof WeixinJSBridge == "undefined") {
          //微信浏览器内置对象。参考微信官方文档
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', _this.onBridgeReady(response.data), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', _this.onBridgeReady(response.data));
            document.attachEvent('onWeixinJSBridgeReady', _this.onBridgeReady(response.data));
          }
        } else {
          _this.onBridgeReady(response.data);
        }
      }).catch(function (error) {
        // this.loading.close();
        _this.paymentType = true;
        _this.paymentCode = 2;
      });
    },
    onBridgeReady: function onBridgeReady(data) {
      var _this = this;
      // this.loading.close();
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": data.appId,
        //公众号名称，由商户传入
        "timeStamp": data.timeStamp,
        //时间戳，自1970年以来的秒数
        "nonceStr": data.nonceStr,
        //随机串
        "package": data.package,
        "signType": data.signType,
        //微信签名方式：
        "paySign": data.paySign //微信签名
      }, function (res) {
        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          var params = new URLSearchParams();
          params.append('sn', _this.sn);
          (0, _payment.query)(params).then(function (response) {
            _this.$router.replace({
              path: _this.redirect,
              query: {
                paymentStatus: _this.paymentType
              }
            });
            _this.paymentType = false;
            _this.paymentCode = 1;
          }).catch(function (error) {
            _this.paymentType = true;
            _this.paymentCode = 2;
          });
        } else {
          _this.paymentType = true;
          _this.paymentCode = 2;
        }
      });
    },
    continueClick: function continueClick() {
      var _this = this;
      if (_utils.default.isWeiXin()) {
        _this.wxPay();
      } else {
        _this.h5Pay();
      }
    },
    returnClick: function returnClick() {
      this.$router.replace({
        path: this.redirect
      });
    },
    info: function info() {
      var _this = this;
      (0, _payment.find)(this.sn).then(function (response) {
        console.log(response);
        _this.infoContent = response.data.payment;
        _this.infoName = JSON.parse(response.data.payment.attach);
      }).catch(function (error) {});
    }
  }
};