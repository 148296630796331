"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _store = _interopRequireDefault(require("@/store"));
var _index = _interopRequireDefault(require("./index.vue"));
var _mine = _interopRequireDefault(require("./mine.vue"));
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    indexLogin: _index.default,
    mineLogin: _mine.default
  },
  data: function data() {
    return {
      institutionsType: ''
    };
  },
  created: function created() {
    this.institutionsType = _store.default.getters.institutionsType || '';
  },
  methods: {}
};