"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.column = column;
exports.counselor = counselor;
exports.course = course;
exports.enroll = enroll;
exports.evaluation = evaluation;
exports.goods = goods;
var _request = _interopRequireDefault(require("@/utils/request"));
function evaluation(params) {
  return (0, _request.default)({
    url: '/member/evaluation/evaluationList',
    method: 'get',
    params: params
  });
}
function course(params) {
  return (0, _request.default)({
    url: '/member/courseOrder/courseOrderList',
    method: 'get',
    params: params
  });
}
function column(params) {
  return (0, _request.default)({
    url: '/member/columnOrder/columnOrderList',
    method: 'get',
    params: params
  });
}
function counselor(params) {
  return (0, _request.default)({
    url: '/member/counselorOrder/counselorOrderList',
    method: 'get',
    params: params
  });
}
function enroll(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/enrollOrderList',
    method: 'get',
    params: params
  });
}
function goods(params) {
  return (0, _request.default)({
    url: '/member/order/orderList',
    method: 'get',
    params: params
  });
}