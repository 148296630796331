var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "system-content",
    },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "lt" }, [
          _c("img", { attrs: { src: _vm.avatar, alt: "" } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "rt" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _vm.loginType == 0
            ? _c("div", { staticClass: "edit", on: { click: _vm.onEdit } }, [
                _vm._v("修改密码"),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info-body" }, [
        _vm.dataList.indexOf("姓名") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("姓名")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        姓名：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.form.empName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "empName", $$v)
                      },
                      expression: "form.empName",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("性别") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("性别")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        性别：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择性别" },
                      model: {
                        value: _vm.form.sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sex", $$v)
                        },
                        expression: "form.sex",
                      },
                    },
                    _vm._l(_vm.sexList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("电话") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("电话")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        电话：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入电话" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("出生日期") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("出生日期")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        出生日期：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请选择出生日期", readonly: "" },
                    on: { focus: _vm.onBirthday },
                    model: {
                      value: _vm.form.birthday,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "birthday", $$v)
                      },
                      expression: "form.birthday",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("民族") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("民族")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        民族：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择民族" },
                      model: {
                        value: _vm.form.national,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "national", $$v)
                        },
                        expression: "form.national",
                      },
                    },
                    _vm._l(_vm.nations, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("户籍所在地") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("户籍所在地")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        户籍所在地：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择户籍所在地" },
                      model: {
                        value: _vm.form.registerPlace,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "registerPlace", $$v)
                        },
                        expression: "form.registerPlace",
                      },
                    },
                    _vm._l(_vm.placeTyoe, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("婚姻状况") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("婚姻状况")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        婚姻状况：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择婚姻状况" },
                      model: {
                        value: _vm.form.maritalStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "maritalStatus", $$v)
                        },
                        expression: "form.maritalStatus",
                      },
                    },
                    _vm._l(_vm.marriage, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("工作所在地") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("工作所在地")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        工作所在地：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("Distpicker", {
                    staticClass: "rtdata",
                    attrs: {
                      province: _vm.district.province,
                      city: _vm.district.city,
                      area: _vm.district.area,
                    },
                    on: { selected: _vm.onSelected },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("文化程度") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("文化程度")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        文化程度：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择文化程度" },
                      model: {
                        value: _vm.form.education,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "education", $$v)
                        },
                        expression: "form.education",
                      },
                    },
                    _vm._l(_vm.education, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("职业") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("职业")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        职业：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择职业" },
                      on: { focus: _vm.setMinWidth },
                      model: {
                        value: _vm.form.job,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "job", $$v)
                        },
                        expression: "form.job",
                      },
                    },
                    _vm._l(_vm.professional, function (item) {
                      return _c("el-option", {
                        key: item,
                        style: { width: _vm.minWidth + 2 + "px" },
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("月收入") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("月收入")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        月收入：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择月收入" },
                      model: {
                        value: _vm.form.salary,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "salary", $$v)
                        },
                        expression: "form.salary",
                      },
                    },
                    _vm._l(_vm.income, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("政治面貌") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("政治面貌")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        政治面貌：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择政治面貌" },
                      model: {
                        value: _vm.form.political,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "political", $$v)
                        },
                        expression: "form.political",
                      },
                    },
                    _vm._l(_vm.political, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("身高") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("身高")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        身高(cm)：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: {
                      placeholder: "请输入身高",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.height,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "height", $$v)
                      },
                      expression: "form.height",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("体重") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("体重")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        体重(kg)：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: {
                      placeholder: "请输入体重",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "weight", $$v)
                      },
                      expression: "form.weight",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("头围") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("头围")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        头围(cm)：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: {
                      placeholder: "请输入头围",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.hc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hc", $$v)
                      },
                      expression: "form.hc",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("生产方式") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("生产方式")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        生产方式：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择生产方式" },
                      model: {
                        value: _vm.form.childbirthType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "childbirthType", $$v)
                        },
                        expression: "form.childbirthType",
                      },
                    },
                    _vm._l(_vm.childbirthType, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("抚养者") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("抚养者")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        抚养者：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择抚养者" },
                      model: {
                        value: _vm.form.foster,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "foster", $$v)
                        },
                        expression: "form.foster",
                      },
                    },
                    _vm._l(_vm.raising, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("母亲生产年龄") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("母亲生产年龄")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        母亲生产年龄：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: {
                      placeholder: "请输入母亲生产年龄",
                      oninput: "value=value.replace(/[^\\d.]/g,'')",
                    },
                    model: {
                      value: _vm.form.motherChildbirthAge,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "motherChildbirthAge", $$v)
                      },
                      expression: "form.motherChildbirthAge",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("兄弟姐妹数量") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("兄弟姐妹数量")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        兄弟姐妹数量：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择兄弟姐妹数量" },
                      model: {
                        value: _vm.form.brotherNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "brotherNum", $$v)
                        },
                        expression: "form.brotherNum",
                      },
                    },
                    _vm._l(_vm.Brothers, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家庭结构") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("家庭结构")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        家庭结构：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择家庭结构" },
                      on: { focus: _vm.setMinWidth },
                      model: {
                        value: _vm.form.familyStructure,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "familyStructure", $$v)
                        },
                        expression: "form.familyStructure",
                      },
                    },
                    _vm._l(_vm.family, function (item) {
                      return _c("el-option", {
                        key: item,
                        style: { width: _vm.minWidth + 2 + "px" },
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家庭收入水平") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("家庭收入水平")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        家庭收入水平：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择家庭收入水平" },
                      model: {
                        value: _vm.form.familyIncome,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "familyIncome", $$v)
                        },
                        expression: "form.familyIncome",
                      },
                    },
                    _vm._l(_vm.total, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("证件类型") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("证件类型")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        证件类型：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择证件类型" },
                      model: {
                        value: _vm.form.certificatesType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "certificatesType", $$v)
                        },
                        expression: "form.certificatesType",
                      },
                    },
                    _vm._l(_vm.documentList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("证件号码") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("证件号码")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        证件号码：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入证件号码" },
                    model: {
                      value: _vm.form.certificatesNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "certificatesNo", $$v)
                      },
                      expression: "form.certificatesNo",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("社会保险卡号") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("社会保险卡号")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        社会保险卡号：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入社会保险卡号" },
                    model: {
                      value: _vm.form.sin,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sin", $$v)
                      },
                      expression: "form.sin",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("出生胎龄") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("出生胎龄")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        出生胎龄：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入出生胎龄" },
                    model: {
                      value: _vm.form.gestationalAge,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gestationalAge", $$v)
                      },
                      expression: "form.gestationalAge",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("所属组织单位") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("所属组织单位")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        所属组织单位：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择所属组织单位" },
                      model: {
                        value: _vm.form.company,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "company", $$v)
                        },
                        expression: "form.company",
                      },
                    },
                    _vm._l(_vm.treeList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "font-size": "12px",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(item.companyCategoryName) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.name))]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("学号") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("学号")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        学号：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入学号" },
                    model: {
                      value: _vm.form.studyNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "studyNo", $$v)
                      },
                      expression: "form.studyNo",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("身体健康状况") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("身体健康状况")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        身体健康状况：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入身体健康状况" },
                    model: {
                      value: _vm.form.health,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "health", $$v)
                      },
                      expression: "form.health",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("个人精神疾病史") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("个人精神疾病史")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        个人精神疾病史：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入个人精神疾病史" },
                    model: {
                      value: _vm.form.personalNeuropathy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "personalNeuropathy", $$v)
                      },
                      expression: "form.personalNeuropathy",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家族精神疾病史") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("家族精神疾病史")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        家族精神疾病史：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入家族精神疾病史" },
                    model: {
                      value: _vm.form.familyNeuropathy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "familyNeuropathy", $$v)
                      },
                      expression: "form.familyNeuropathy",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("学习状况") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("学习状况")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        学习状况：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择学习状况" },
                      model: {
                        value: _vm.form.learning,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "learning", $$v)
                        },
                        expression: "form.learning",
                      },
                    },
                    _vm._l(_vm.learningList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("居住地址") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("居住地址")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        居住地址：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入居住地址" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("父母姓名及联系方式") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("父母姓名及联系方式")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        父母姓名及联系方式：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入父母姓名及联系方式" },
                    model: {
                      value: _vm.form.parentContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentContact", $$v)
                      },
                      expression: "form.parentContact",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("紧急联系人姓名及联系方式") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("紧急联系人姓名及联系方式")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        紧急联系人及联系方式：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入紧急联系人及联系方式" },
                    model: {
                      value: _vm.form.emergencyContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "emergencyContact", $$v)
                      },
                      expression: "form.emergencyContact",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("家庭关系") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("家庭关系")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        家庭关系：\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "rts-input",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择家庭关系" },
                      model: {
                        value: _vm.form.familyRelation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "familyRelation", $$v)
                        },
                        expression: "form.familyRelation",
                      },
                    },
                    _vm._l(_vm.RelationList, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.dataList.indexOf("准考证号") != -1
          ? _c("div", { staticClass: "info-row" }, [
              _c("div", { staticClass: "lt" }, [
                _vm.getReqire("准考证号")
                  ? _c("span", { staticClass: "red" }, [_vm._v("*")])
                  : _vm._e(),
                _vm._v("\n        准考证号\n      "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    staticClass: "rt-input",
                    attrs: { placeholder: "请输入准考证号" },
                    model: {
                      value: _vm.form.examineeNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "examineeNumber", $$v)
                      },
                      expression: "form.examineeNumber",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-row" }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c(
              "el-button",
              {
                staticClass: "cancel",
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.loading },
                on: { click: _vm.addSubmit },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.show
        ? _c("div", { staticClass: "edit-fixed" }, [
            _c("div", { staticClass: "edit-abs" }, [
              _c("div", { staticClass: "title" }, [_vm._v("修改密码")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入旧密码", "show-password": "" },
                    model: {
                      value: _vm.passForm.oriPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.passForm, "oriPassword", $$v)
                      },
                      expression: "passForm.oriPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入新密码", "show-password": "" },
                    model: {
                      value: _vm.passForm.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.passForm, "newPassword", $$v)
                      },
                      expression: "passForm.newPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message" },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请再次输入新密码",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "abs-footer" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "cancel",
                        on: {
                          click: function ($event) {
                            _vm.show = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "primary",
                        attrs: { type: "primary", loading: _vm.loading },
                        on: { click: _vm.addEditSubmit },
                      },
                      [_vm._v("确定")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          style: { height: "320px" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.showDate,
            callback: function ($$v) {
              _vm.showDate = $$v
            },
            expression: "showDate",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "选择年月日",
              formatter: _vm.formatter,
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.onDataCofig,
              cancel: function ($event) {
                _vm.showDate = false
              },
            },
            model: {
              value: _vm.currentTime,
              callback: function ($$v) {
                _vm.currentTime = $$v
              },
              expression: "currentTime",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }