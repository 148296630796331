"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkExists = checkExists;
exports.columnCreateEnroll = columnCreateEnroll;
exports.count = count;
exports.courseCreateEnroll = courseCreateEnroll;
exports.create = create;
exports.find = find;
exports.list = list;
var _request = _interopRequireDefault(require("@/utils/request"));
function list(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/list',
    method: 'get',
    params: params
  });
}
function count(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/count',
    method: 'get',
    params: params
  });
}
function find(id) {
  return (0, _request.default)({
    url: '/member/enrollOrder/find/' + id,
    method: 'get'
  });
}
function create(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/create',
    method: 'post',
    data: params
  });
}
function columnCreateEnroll(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/columnCreate',
    method: 'post',
    data: params
  });
}
function courseCreateEnroll(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/courseCreate',
    method: 'post',
    data: params
  });
}
function checkExists(params) {
  return (0, _request.default)({
    url: '/member/enrollOrder/checkExists',
    method: 'get',
    params: params
  });
}