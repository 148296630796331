"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      rangeValue: 0,
      optionList: [],
      average: 0,
      aliquot: false
    };
  },
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    },
    percenter: {
      default: ''
    },
    beforeTopic: {
      default: ''
    }
  },
  mounted: function mounted() {
    this.reset();
  },
  watch: {
    percents: function percents(newVal, oldVal) {
      this.reset();
    }
  },
  components: {},
  methods: {
    topic: function topic() {
      var _this = this;
      console.log(_this.beforeTopic);
      console.log(_this.nowAnswer);
      if (!_utils.default.isNull(_this.beforeTopic)) {
        _this.beforeTopic.forEach(function (item) {
          _this.nowAnswer.options.forEach(function (items) {
            if (item.questionId == _this.nowAnswer.id && item.optionId == items.id) {
              items.rangeValue = Math.round(item.score / items.score * (parseInt(items.max) - parseInt(items.min)) * 100) / 100;
              _this.rangeValue = Math.round((item.score / items.score * (parseInt(items.max) - parseInt(items.min)) + parseInt(items.min)) * 100) / 100;
              console.log(_this.rangeValue);
            }
            console.log(_this.nowAnswer.options[0].rangeValue);
          });
        });
      }
    },
    reset: function reset() {
      var _this = this;
      console.log(this.nowAnswer);
      this.nowAnswer.options.forEach(function (item) {
        item.minBox = 0;
        item.maxBox = parseInt(item.max) - parseInt(item.min);
        _this.average = item.maxBox / 5;
        item.Two = parseInt(item.min) + _this.average;
        item.three = parseInt(item.min) + _this.average * 2;
        item.four = parseInt(item.min) + _this.average * 3;
        item.five = parseInt(item.min) + _this.average * 4;
        item.rangeValue = 0;
      });
      if (this.nowAnswer.options[0].maxBox % 5 === 0) {
        _this.aliquot = true;
      } else {
        _this.aliquot = false;
      }
      if (this.nowAnswer.options[0].maxBox % 2 === 0) {
        console.log(this.nowAnswer.options[0].maxBox / 2);
        this.nowAnswer.options[0].rangeValue = this.nowAnswer.options[0].maxBox / 2;
        this.rangeValue = parseInt(this.nowAnswer.options[0].min) + this.nowAnswer.options[0].maxBox / 2;
      } else {
        this.nowAnswer.options[0].rangeValue = 0;
        this.rangeValue = this.nowAnswer.options[0].min;
      }
      this.topic();
      this.$forceUpdate();
      console.log(this.nowAnswer);
    },
    ontouchmove: function ontouchmove() {
      var _this = this;
      _this.rangeValue = 0;
      for (var i = 0; i < _this.nowAnswer.options.length; i++) {
        _this.rangeValue = _this.nowAnswer.options[i].rangeValue + parseInt(_this.nowAnswer.options[0].min);
      }
      this.$forceUpdate();
    },
    search: function search() {
      var _this = this;
      if (this.rangeValue < parseInt(_this.nowAnswer.options[0].min)) {
        _this.rangeValue = parseInt(_this.nowAnswer.options[0].min);
      } else if (_this.rangeValue > parseInt(_this.nowAnswer.options[0].max)) {
        _this.rangeValue = parseInt(_this.nowAnswer.options[0].max);
      }
      this.nowAnswer.options[0].rangeValue = 0;
      this.nowAnswer.options[0].rangeValue = Number(this.rangeValue) - parseInt(this.nowAnswer.options[0].min);
    },
    chooseAnswer: function chooseAnswer() {
      var _this = this;
      _this.optionList = [];
      _this.nowAnswer.options.forEach(function (item) {
        console.log(parseInt(item.rangeValue) + parseInt(item.min) - parseInt(item.min));
        console.log(parseInt(item.max) - parseInt(item.min));
        var data = {
          gene: item.gene,
          optionId: item.id,
          rangeValue: Math.round((parseInt(item.rangeValue) + parseInt(item.min) - parseInt(item.min)) / (parseInt(item.max) - parseInt(item.min)) * Number(item.score) * 100) / 100
        };
        _this.optionList.push(data);
      });
      var data = {
        optionList: _this.optionList
      };
      _this.$emit("chooseAnswer", data);
    }
  }
};