var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadmore,
            expression: "loadmore",
          },
        ],
        staticStyle: { "overflow-y": "auto", height: "100vh" },
        attrs: { "infinite-scroll-disabled": "loading" },
      },
      [
        _vm._l(_vm.articleOrderForm, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "article_order_box page" },
            [
              _c("div", { staticClass: "article_order_top" }, [
                _c("span", { staticClass: "article_order_title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "article_order_subtitle" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "article_order_subtitle" }, [
                  _vm._v("时间：" + _vm._s(item.articleMeta.time)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "article_order_subtitle" }, [
                  _vm._v("地址：" + _vm._s(item.articleMeta.address)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "article_order_bottom" }, [
                _c("span", { staticClass: "article_order_bottom_subtitle" }, [
                  _vm._v(
                    "报名时间：" +
                      _vm._s(_vm._f("datetimeFormatter")(item.createDate))
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "article_order_price" }, [
                  _vm._v("¥" + _vm._s(item.price)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "article_order_bottom_left" }),
                _vm._v(" "),
                _c("div", { staticClass: "article_order_bottom_right" }),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _vm.articleOrderForm.length == 0
          ? _c("noData", {
              attrs: { onDataTitle: "您暂未有活动订单", marginTop: "150" },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }