var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-carousel",
        { staticClass: "content", attrs: { height: "165px" } },
        _vm._l(_vm.adForm, function (item) {
          return _c("el-carousel-item", { key: item.id, staticClass: "item" }, [
            _c("div", { staticClass: "img" }, [
              _c("img", {
                staticStyle: { "border-radius": "5px" },
                attrs: { src: item.thumbnail, width: "100%", height: "100%" },
                on: {
                  click: function ($event) {
                    return _vm.adClick(item)
                  },
                },
              }),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }