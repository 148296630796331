"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _enrollOrder = require("@/api/enrollOrder");
var _courseOrder = require("@/api/courseOrder");
var _columnOrder = require("@/api/columnOrder");
var _counselorOrder = require("@/api/counselorOrder");
var _evaluation = require("@/api/evaluation");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      enrollOrderCount: 0,
      courseOrderCount: 0,
      columnOrderCount: 0,
      counselorOrderCount: 0,
      evaluationCount: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  created: function created() {
    this.init();
  },
  props: ["content"],
  methods: {
    init: function init() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('orderStatus', 1);
      params.append('evalStatus', 0);
      (0, _enrollOrder.count)(params).then(function (res) {
        return _this.enrollOrderCount = res.data;
      });
      (0, _courseOrder.count)(params).then(function (res) {
        return _this.courseOrderCount = res.data;
      });
      (0, _columnOrder.count)(params).then(function (res) {
        return _this.columnOrderCount = res.data;
      });
      (0, _counselorOrder.count)(params).then(function (res) {
        return _this.counselorOrderCount = res.data;
      });
      (0, _evaluation.count)(params).then(function (res) {
        return _this.evaluationCount = res.data;
      });
    },
    clickTask: function clickTask() {
      this.$router.push({
        path: "/member/task"
      });
    }
  }
};