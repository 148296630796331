"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _columnOrder = require("@/api/columnOrder");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 0,
      pageSize: 10,
      loading: true,
      columnOrder: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  methods: {
    loadColumOrder: function loadColumOrder() {
      var _this2 = this;
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", _this.mchId);
      (0, _columnOrder.list)(params).then(function (response) {
        console.log(_this.columnOrder);
        response.data.data.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 622, 200);
          _this.columnOrder.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this2.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.loadColumOrder();
    },
    goSubjectStage: function goSubjectStage(item) {
      this.$router.push({
        path: "/column/columnSubjectStage",
        query: {
          stageTitle: item.title,
          stageSubtitle: item.subTitle,
          columnId: item.mchColumnId,
          columnOrderId: item.id
        }
      });
    }
  },
  created: function created() {
    this.loadColumOrder();
  }
};