"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    userId: '',
    xuid: '',
    mchId: '',
    name: '',
    avatar: '',
    mchLogo: '',
    mchName: '',
    menus: [],
    enableAuth: '',
    isAllFinish: '',
    h5IndexPageCode: '',
    institutionsType: '',
    wechatFlag: ''
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    //Object.assign(state, getDefaultState())
    state.token = (0, _auth.getToken)();
    state.userId = '';
    state.xuid = '';
    state.name = '';
    state.avatar = '';
    state.menus = '';
    state.isAllFinish = '';
  },
  SET_USERID: function SET_USERID(state, userId) {
    state.userId = userId;
  },
  SET_XUID: function SET_XUID(state, xuid) {
    state.xuid = xuid;
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_MCHID: function SET_MCHID(state, mchId) {
    state.mchId = mchId;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_MCHNAME: function SET_MCHNAME(state, mchName) {
    state.mchName = mchName;
  },
  SET_MENUS: function SET_MENUS(state, menus) {
    state.menus = menus;
  },
  SET_MCHLOGO: function SET_MCHLOGO(state, mchLogo) {
    state.mchLogo = mchLogo;
  },
  SET_ENABLEAUTH: function SET_ENABLEAUTH(state, enableAuth) {
    state.enableAuth = enableAuth;
  },
  SET_ISALLFINSH: function SET_ISALLFINSH(state, isAllFinish) {
    state.isAllFinish = isAllFinish;
  },
  SET_INSTITUTIONSTYPE: function SET_INSTITUTIONSTYPE(state, institutionsType) {
    state.institutionsType = institutionsType;
  },
  SET_H5: function SET_H5(state, h5) {
    state.h5IndexPageCode = h5;
  },
  SET_WECHAT: function SET_WECHAT(state, wechat) {
    state.wechatFlag = wechat;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      verifyCode = userInfo.verifyCode,
      verifyCodeKey = userInfo.verifyCodeKey;
    return new Promise(function (resolve, reject) {
      var mch_username = 'mch_' + state.mchId + '_' + username.trim();
      (0, _user.login)({
        username: mch_username,
        verifyCode: verifyCode,
        verifyCodeKey: verifyCodeKey,
        mchId: state.mchId
      }).then(function (response) {
        console.log(response);
        var data = response.data;
        commit('SET_TOKEN', data.token);
        (0, _auth.setToken)(data.token);
        resolve();
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // 新的登录（移动端成员登录）
  loginPassword: function loginPassword(_ref2, userInfo) {
    var commit = _ref2.commit;
    var username = userInfo.username,
      password = userInfo.password,
      captchaUid = userInfo.captchaUid,
      captcha = userInfo.captcha;
    var mch_username = username;
    if (state.mchId == '11419' && username != '' && username.indexOf('QZ') == -1) {
      mch_username = 'QZ' + username.trim();
    } else if (state.mchId == '10260' && username != '' && username.indexOf('ERSHIHAINANSHIDA') == -1) {
      mch_username = 'ERSHIHAINANSHIDA' + username.trim();
    }
    console.log('用户名：' + mch_username);
    return new Promise(function (resolve, reject) {
      // const mch_username = 'mch_' + state.mchId + '_' + username.trim()
      (0, _user.loginPassowrd)({
        username: mch_username,
        password: password,
        mchId: state.mchId,
        captchaUid: captchaUid,
        captcha: captcha
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.token);
        (0, _auth.setToken)(data.token);
        localStorage.setItem('companyEmployee', data.companyEmployee);
        resolve();
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  // 判断是否完成资料信息
  getFinishData: function getFinishData(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      (0, _user.getFinishData)().then(function (response) {
        commit('SET_ISALLFINSH', response.data.isAllFinish);
        resolve();
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  },
  oauth2login: function oauth2login(_ref4, userInfo) {
    var commit = _ref4.commit;
    var auth_code = userInfo.auth_code,
      uuid = userInfo.uuid,
      pluginId = userInfo.pluginId,
      encryptData = userInfo.encryptData,
      iv = userInfo.iv;
    return new Promise(function (resolve, reject) {
      (0, _user.getTokenByCodeV2)({
        auth_code: auth_code,
        uuid: uuid,
        pluginId: pluginId,
        mchId: state.mchId,
        encryptData: encryptData,
        iv: iv
      }).then(function (response) {
        var data = response.data;
        if (data != null && !!data.token) {
          commit('SET_TOKEN', data.token);
          (0, _auth.setToken)(data.token);
          resolve();
        } else {
          reject('注册用户失败');
        }
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
      // getTokenByCode({ auth_code: auth_code, uuid: uuid, pluginId: pluginId, mchId: state.mchId }).then(response => {
      //   const { data } = response
      //   if (data.token == null) {
      //     register({ uuid: uuid, encryptData: encryptData, iv: iv }).then(response => {
      //       const { data } = response
      //       if (data != null) {
      //         commit('SET_TOKEN', data.token)
      //         setToken(data.token)
      //         resolve()
      //       } else {
      //         reject('注册用户失败')
      //       }
      //     }).catch(error => {
      //       console.log(error)
      //       reject(error)
      //     })
      //   } else {
      //     commit('SET_TOKEN', data.token)
      //     setToken(data.token)
      //     resolve()
      //   }
      // }).catch(error => {
      //   console.log(error)
      //   reject(error)
      // })
    });
  },
  // get user info
  getInfo: function getInfo(_ref5) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(location.hostname).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('获取用户信息失败.');
        }
        var userInfo = data.userInfo,
          mchId = data.mchId,
          mchName = data.mchName,
          mchLogo = data.mchLogo,
          menus = data.menus,
          enableAuth = data.enableAuth,
          institutionsType = data.institutionsType,
          h5IndexPageCode = data.h5IndexPageCode,
          wechatFlag = data.wechatFlag;
        if (userInfo) {
          if (userInfo.avatar == null) {
            userInfo.avatar = 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png';
          }
          commit('SET_NAME', userInfo.nickname);
          commit('SET_AVATAR', userInfo.avatar);
          commit('SET_USERID', userInfo.id);
          state.userId = userInfo.id;
        }
        state.mchId = mchId;
        state.mchName = mchName;
        state.mchLogo = mchLogo;
        state.menus = menus;
        state.enableAuth = enableAuth;
        commit('SET_ENABLEAUTH', enableAuth);
        commit('SET_MENUS', menus);
        commit('SET_MCHID', mchId);
        commit('SET_MCHNAME', mchName);
        commit('SET_MCHLOGO', mchLogo);
        commit('SET_INSTITUTIONSTYPE', institutionsType);
        commit('SET_H5', h5IndexPageCode);
        commit('SET_WECHAT', wechatFlag);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref6) {
    var commit = _ref6.commit,
      state = _ref6.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        (0, _auth.removeToken)(); // must remove  token  first
        (0, _router.resetRouter)();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref7) {
    var commit = _ref7.commit;
    return new Promise(function (resolve) {
      (0, _auth.removeToken)(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  },
  // set xuid
  setXuid: function setXuid(_ref8, value) {
    var commit = _ref8.commit,
      state = _ref8.state;
    return new Promise(function (resolve) {
      commit('SET_XUID', value);
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};