"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
var _store = _interopRequireDefault(require("@/store"));
var utilsFunc = {
  // 创建全局唯一标识符
  getUUID: function getUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
  },
  // 判空
  isNull: function isNull(value) {
    if (typeof value !== 'boolean') {
      if (value == null || value == 'null' || value == undefined || value == '' || value == 'undefined') {
        return true;
      } else {
        return false;
      }
    } else {
      return value;
    }
  },
  isWeiXin: function isWeiXin() {
    var wechatFlag = _store.default.getters.wechatFlag;
    var ua = window.navigator.userAgent.toLowerCase();
    if (wechatFlag != 0 && ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true; // 是微信端
    } else {
      return false;
    }
  },
  isIos: function isIos() {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      return true;
    } else {
      return false;
    }
  },
  getUrlParameter: function getUrlParameter(name) {
    var url = window.location.href;
    if (url.indexOf('/#/') == -1 && url.indexOf("code=") == -1) {
      url = url.substring(0, url.indexOf('?')) + url.substring(url.indexOf('#/'));
    }
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = url.slice(url.indexOf('?') + 1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  },
  getUrlPathWithParameter: function getUrlPathWithParameter(url) {
    var relUrl = url;
    if (relUrl.indexOf('://' > -1)) {
      var arrUrl = url.split("://");
      var start = arrUrl[1].indexOf("/");
      relUrl = arrUrl[1].substring(start);
    }
    if (relUrl.indexOf('/#/') > -1) {
      relUrl = '/' + relUrl.substring(relUrl.indexOf('/#/') + 3);
    }
    return relUrl;
  },
  thumbnail: function thumbnail(url, w, h) {
    if (this.isNull(url)) {
      return "";
    }
    h = Math.round(h);
    w = Math.round(w);
    if (url.substring(0, 11) == "http://cdnx") {
      return url + "?x-oss-process=image/resize,m_fill,w_" + w + ",h_" + h + "/quality,q_90";
    } else if (url.substring(0, 10) == "http://cdn") {
      return url + "@" + w + "w_" + h + "h_1e_1c_100Q";
    } else {
      return url;
    }
  },
  timestampToTime: function timestampToTime(cjsj) {
    var date = new Date(cjsj); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '年';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
    return Y + M;
  },
  // 获取主机
  host: function host() {
    var url = window.location.href;
    var len = url.indexOf('//');
    var http = url.substring(0, len);
    return http + '//' + window.location.host;
  },
  mapToUrl: function mapToUrl(data) {
    if (this.isNull(data)) {
      return "";
    }
    var _result = [];
    for (var key in data) {
      var value = data[key];
      if (value.constructor == Array) {
        value.forEach(function (_value) {
          _result.push(key + "=" + _value);
        });
      } else {
        _result.push(key + '=' + value);
      }
    }
    var url = _result.join('&');
    if (!this.isNull(url) && url.length > 0) {
      return "?" + url;
    } else {
      return "";
    }
  }
};
var _default = exports.default = utilsFunc;