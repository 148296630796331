var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapper },
    [
      _vm.type != "mobile"
        ? [
            _c(
              "el-select",
              {
                attrs: { disabled: _vm.disabled || _vm.provinceDisabled },
                on: { change: _vm.getCities },
                model: {
                  value: _vm.currentProvince,
                  callback: function ($$v) {
                    _vm.currentProvince = $$v
                  },
                  expression: "currentProvince",
                },
              },
              [
                _c(
                  "el-option",
                  { attrs: { value: _vm.placeholders.province } },
                  [_vm._v(_vm._s(_vm.placeholders.province))]
                ),
                _vm._v(" "),
                _vm._l(_vm.provinces, function (item, index) {
                  return _c(
                    "el-option",
                    { key: index, attrs: { value: item } },
                    [_vm._v("\n        " + _vm._s(item) + "\n      ")]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.onlyProvince
              ? [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.disabled || _vm.cityDisabled },
                      on: { change: _vm.getAreas },
                      model: {
                        value: _vm.currentCity,
                        callback: function ($$v) {
                          _vm.currentCity = $$v
                        },
                        expression: "currentCity",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { value: _vm.placeholders.city } },
                        [_vm._v(_vm._s(_vm.placeholders.city))]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.cities, function (item, index) {
                        return _c(
                          "el-option",
                          { key: index, attrs: { value: item } },
                          [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.hideArea
                    ? _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.disabled || _vm.areaDisabled },
                          model: {
                            value: _vm.currentArea,
                            callback: function ($$v) {
                              _vm.currentArea = $$v
                            },
                            expression: "currentArea",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            { attrs: { value: _vm.placeholders.area } },
                            [_vm._v(_vm._s(_vm.placeholders.area))]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.areas, function (item, index) {
                            return _c(
                              "el-option",
                              { key: index, attrs: { value: item } },
                              [
                                _vm._v(
                                  "\n          " + _vm._s(item) + "\n        "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        : [
            _c("div", { class: _vm.addressHeader }, [
              _c(
                "ul",
                [
                  _c(
                    "li",
                    {
                      class: { active: _vm.tab === 1 },
                      on: { click: _vm.resetProvince },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.currentProvince && !_vm.staticPlaceholder
                            ? _vm.currentProvince
                            : _vm.placeholders.province
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.onlyProvince
                    ? [
                        _vm.showCityTab
                          ? _c(
                              "li",
                              {
                                class: { active: _vm.tab === 2 },
                                on: { click: _vm.resetCity },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentCity && !_vm.staticPlaceholder
                                      ? _vm.currentCity
                                      : _vm.placeholders.city
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showAreaTab && !_vm.hideArea
                          ? _c("li", { class: { active: _vm.tab === 3 } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentArea && !_vm.staticPlaceholder
                                    ? _vm.currentArea
                                    : _vm.placeholders.area
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { class: _vm.addressContainer },
              [
                _vm.tab === 1
                  ? _c(
                      "ul",
                      _vm._l(_vm.provinces, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: { active: item === _vm.currentProvince },
                            on: {
                              click: function ($event) {
                                return _vm.chooseProvince(item)
                              },
                            },
                          },
                          [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.onlyProvince
                  ? [
                      _vm.tab === 2
                        ? _c(
                            "ul",
                            _vm._l(_vm.cities, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  class: { active: item === _vm.currentCity },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseCity(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tab === 3 && !_vm.hideArea
                        ? _c(
                            "ul",
                            _vm._l(_vm.areas, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  class: { active: item === _vm.currentArea },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseArea(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item) +
                                      "\n          "
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }