"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _binding = require("@/api/binding");
var _share = require("@/utils/share");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _vuex = require("vuex");
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CounselorInvite',
  data: function data() {
    return {
      title: '',
      name: '',
      avatar: '',
      code: '',
      success: '',
      refresh: false,
      redirect: undefined,
      authCode: undefined,
      loading: false,
      uuid: null
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId', 'token'])),
  created: function created() {
    this.isToken();
    // this.initial()
  },
  methods: {
    isToken: function isToken() {
      if (this.token) {
        this.share();
        this.initial();
      } else {
        this.authCode = _utils.default.getUrlParameter("code");
        this.uuid = _utils.default.getUUID();
        this.loading = true;
        this.refresh = true;
        this.title = '入驻失败';
      }
    },
    initial: function initial() {
      var _this = this;
      // let params = new URLSearchParams()
      // params.append('pageNum', this.pageNum)
      (0, _binding.bindingCounselor)(this.$route.query.id).then(function (response) {
        // console.log(response)
        _this.name = response.data.counselor.name;
        _this.avatar = response.data.counselor.avatar;
        _this.name = response.data.counselor.name;
        _this.title = '入驻成功';
        _this.success = true;
        _this.initialCode();
      }).catch(function (error) {
        _this.title = '入驻失败';
        _this.success = false;
        console.log(error);
      });
    },
    initialCode: function initialCode() {
      var _this2 = this;
      var params = new URLSearchParams();
      params.append('mchId', this.mchId);
      (0, _binding.bindingCode)(params).then(function (response) {
        console.log(response);
        _this2.code = response.data.qrcode;
        console.log('token', _this2.token);
      }).catch(function (error) {
        console.log(error);
      });
    },
    share: function share() {
      (0, _share.SHARE)(location.href, {
        title: this.title,
        desc: '邀请咨询师进行绑定',
        link: _utils.default.host() + '/#/binding/counselor?id=' + this.$route.query.id,
        imgUrl: './images/share.png'
      }, function (res) {});
    }
    /* goLogin () {
     if (this.success === '') {
       this.$store.dispatch('user/oauth2login', {"auth_code":this.authCode,"uuid":this.uuid,"pluginId":"weixinOAuth2Plugin"}).then(() => {
         this.$router.replace({ path: encodeURIComponent(this.$route.query.id) || '/' })
         this.loading = false
       }).catch(() => {
         this.loading = false
       })
      }
    } */
  }
};