var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gauge_box" }, [
    _c("div", { staticClass: "headerBox" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "flex-end",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "header_name" }, [
            _vm._v(_vm._s(_vm.content.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
          on: {
            click: function ($event) {
              return _vm.listClick()
            },
          },
        },
        [_vm._m(1)]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { display: "flex", "flex-direction": "column" } },
      _vm._l(_vm.gaugeForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "gauge_content",
            on: {
              click: function ($event) {
                return _vm.gaugeClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "gauge_content_img" }, [
              _c("img", {
                attrs: { src: item.thumbnail, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gauge_content_content" }, [
              _c("div", { staticClass: "gauge_content_top" }, [
                _c("span", { staticClass: "gauge_content_title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "gauge_content_subtitle" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(2, true),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_titleIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/gaugeStyle2Icon.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_moreIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/arrowStyle3.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gauge_content_bottom" }, [
      _c("div", { staticClass: "numBox" }, [
        _c("div", { staticClass: "numIcon" }, [
          _c("img", {
            attrs: {
              src: require("../images/gaugeNumIcon.png"),
              width: "100%",
              height: "100%",
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "numText" }, [_vm._v("0/20")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "timeBox" }, [
        _c("div", { staticClass: "timeIcon" }, [
          _c("img", {
            attrs: {
              src: require("../images/gaugeTimeIcon.png"),
              width: "100%",
              height: "100%",
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "timeText" }, [_vm._v("30分钟")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }