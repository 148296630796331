var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "third_box" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "third_header" }, [
          _c("span", { staticClass: "third_header_title" }, [
            _vm._v("预约信息"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "third_header_subtitle",
              on: {
                click: function ($event) {
                  return _vm.openClick("1")
                },
              },
            },
            [_vm._v("查看预约须知")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "third_content" }, [
          _c("div", { staticClass: "third_line_box" }, [
            _c("span", { staticClass: "third_line_left" }, [
              _vm._v("已选服务"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "third_line_right" }, [
              _c("span", { staticClass: "third_line_name" }, [
                _vm.counselorBusiness.type == 2
                  ? _c("span", [_vm._v("套餐")])
                  : _vm._e(),
                _vm._v("\n            " + _vm._s(_vm.counselorBusiness.title)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "third_line_box" }, [
            _c("span", { staticClass: "third_line_left" }, [
              _vm._v("咨询方式"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "third_line_right",
                staticStyle: { "font-size": "13px" },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("watchCounselorMethod")(
                        _vm.orderInfo.counselorMethod
                      )
                    )
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "third_line_box" }, [
            _c("span", { staticClass: "third_line_left" }, [
              _vm._v("预约时间"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "third_line_right" }, [
              _c("span", { staticClass: "third_line_date_title" }, [
                _vm._v(
                  _vm._s(_vm._f("watchData")(_vm.orderInfo.createDate)) + "开始"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "third_line_box" }, [
            _c("span", { staticClass: "third_line_left" }, [
              _vm._v("咨询时长"),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.duration) + "分钟")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "third_detailed_box" }, [
            _c("div", { staticClass: "third_detailed_content" }, [
              _c("div", { staticClass: "third_detailed_content_img" }, [
                _c("div", { staticClass: "third_detailed_content_img_img" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.orderInfo.counselorAvatar,
                      alt: "",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "third_detailed_content_left" }, [
                  _vm._v(_vm._s(_vm.orderInfo.counselorName)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "third_detailed_content_right" }, [
                  _vm._v("¥" + _vm._s(_vm.counselorBusiness.price)),
                ]),
                _c("span", { staticClass: "third_detailed_content_time" }, [
                  _vm._v("/30"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "third_detailed_content" }, [
              _c("span", { staticClass: "third_detailed_content_left" }, [
                _vm._v("次数"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "third_detailed_bottom" }, [
                _c("span", { staticClass: "third_detailed_bottom_title" }, [
                  _vm._v(_vm._s(_vm.orderInfo.quantity)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "third_line_subtotal" }, [
            _c("div", { staticClass: "third_line_subtotal_title" }, [
              _vm._v("小计："),
            ]),
            _vm._v(" "),
            _vm.counselorBusiness.type == 1
              ? _c("div", { staticClass: "third_line_subtotal_price" }, [
                  _vm._v(
                    "\n          ¥" +
                      _vm._s(
                        (_vm.counselorBusiness.price * _vm.duration) / 30
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.counselorBusiness.type == 1
              ? _c("div", { staticClass: "third_line_subtotal_time" }, [
                  _vm._v("\n          /" + _vm._s(_vm.duration) + "\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.counselorBusiness.type == 2
              ? _c("div", { staticClass: "third_line_subtotal_price" }, [
                  _vm._v(
                    "\n          ¥" +
                      _vm._s(
                        _vm.counselorBusiness.price * _vm.orderInfo.quantity
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.counselorBusiness.type == 2
              ? _c("div", { staticClass: "third_line_subtotal_time" }, [
                  _vm._v("\n          /" + _vm._s(_vm.duration) + "\n        "),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("prompt", {
          attrs: {
            title: _vm.title,
            promptData: _vm.promptData,
            promptType: _vm.promptType,
          },
          on: { downClick: _vm.downClick },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "third_box_bottom" }, [
      _vm._v("\n    咨询任务"),
      _c("span", [
        _vm._v(
          "（" + _vm._s(_vm.taskNum) + "/" + _vm._s(_vm.unfinishedNum) + "）"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "third_header_prompt" }, [
      _c("i", { staticClass: "el-icon-success third_header_prompt_icon" }),
      _vm._v(" "),
      _c("span", { staticClass: "third_header_prompt_title" }, [
        _vm._v("咨询已完成 "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }