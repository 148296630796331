"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _mchGauge = require("@/api/mchGauge");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("../../../../utils/utils.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      columnForm: [{
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）',
        subTitle: '全国小学生必背经典内容',
        date: '24课时',
        price: '¥99.0'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）',
        subTitle: '全国小学生必背经典内容',
        date: '24课时',
        price: '¥99.0'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）大范德萨范德萨发生大事撒大发生的发生',
        subTitle: '全国小学生必背经典内容发达撒发生的发生的范德萨发发呆萨芬撒的发生范德萨范德萨范德萨地方三大范德萨发顺丰',
        date: '24课时',
        price: '¥99.0'
      }, {
        img: 'http://rzico.oss-cn-shenzhen.aliyuncs.com/img/292f7e12c273f0f51f7aa6d81040dcaf@2x.png',
        title: '小学必背75首古诗词（全）',
        subTitle: '全国小学生必背经典内容',
        date: '24课时',
        price: '¥99.0'
      }],
      pageNum: 1,
      pageSize: 3,
      tagIds: []
    };
  },
  created: function created() {
    this.loadColumn();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  props: ['content'],
  methods: {
    loadColumn: function loadColumn() {
      var _this = this;
      console.log(this.content);
      if (!_utils.default.isNull(this.content.tagids)) {
        this.tagIds = this.content.tagids.split(',');
        for (var i = 0; i < _this.tagIds.length; i++) {
          _this.tagIds[i] = parseInt(_this.tagIds[i]);
        }
      }
      _this.pageSize = _this.content.rows;
      console.log(this.content.tagids);
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      for (var i = 0; i < _this.tagIds.length; i++) {
        params.append('tagIds', _this.tagIds[i]);
      }
      params.append('sortField', 'orders');
      params.append('direction', 'asc');
      (0, _mchGauge.list)(params).then(function (response) {
        console.log(response);
        _this.columnForm = response.data.data;
        _this.columnForm.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 330, 210);
        });
      }).catch(function (error) {
        console.log('error!!');
      });
    },
    indexClick: function indexClick(id) {
      this.$router.push({
        path: '/gauge/index',
        query: {
          mchGaugeId: id
        }
      });
    } // listClick(){
    //   this.$router.push({
    //     path:'/column/columnCategery',query:{categoryId:this.columnForm[0].id}
    //   })
    // }
  }
};