"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateFormatter = dateFormatter;
exports.dateTimeNoSFormatter = dateTimeNoSFormatter;
exports.datetimeFormatter = datetimeFormatter;
exports.numberFormatter = numberFormatter;
exports.thumbnail = thumbnail;
exports.thumbnail_cover = thumbnail_cover;
exports.timeAgo = timeAgo;
exports.toThousandFilter = toThousandFilter;
exports.uppercaseFirst = uppercaseFirst;
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
// import parseTime, formatTime and set to filter

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' 分钟');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' 小时');
  } else {
    return pluralize(~~(between / 86400), ' 天');
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => '10,000'
 * @param {number} num
 */
function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * datetime 2019-01-01 00:00:00
 * @param {String} string
 */
function datetimeFormatter(date) {
  date = new Date(date);
  var fmt = 'YYYY-mm-dd HH:MM:SS';
  var opt = {
    'Y+': date.getFullYear().toString(),
    // 年
    'm+': (date.getMonth() + 1).toString(),
    // 月
    'd+': date.getDate().toString(),
    // 日
    'H+': date.getHours().toString(),
    // 时
    'M+': date.getMinutes().toString(),
    // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (var k in opt) {
    var ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}

/**
 * datetime 2019-01-01 00:00
 * @param {String} string
 */
function dateTimeNoSFormatter(date) {
  date = new Date(date);
  var fmt = 'YYYY-mm-dd HH:MM';
  var opt = {
    'Y+': date.getFullYear().toString(),
    // 年
    'm+': (date.getMonth() + 1).toString(),
    // 月
    'd+': date.getDate().toString(),
    // 日
    'H+': date.getHours().toString(),
    // 时
    'M+': date.getMinutes().toString() // 分
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (var k in opt) {
    var ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}
function dateFormatter(date) {
  date = new Date(date);
  var fmt = 'YYYY-mm-dd';
  var opt = {
    'Y+': date.getFullYear().toString(),
    // 年
    'm+': (date.getMonth() + 1).toString(),
    // 月
    'd+': date.getDate().toString(),
    // 日
    'H+': date.getHours().toString(),
    // 时
    'M+': date.getMinutes().toString(),
    // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (var k in opt) {
    var ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}
function thumbnail(url, w, h) {
  if (url == null || url == 'null' || url == undefined || url == '' || url == 'undefined') {
    return '';
  }
  h = Math.round(h);
  w = Math.round(w);
  if (url.substring(0, 11) == 'http://cdnx') {
    return url + '?x-oss-process=image/resize,m_fill,w_' + w + ',h_' + h + '/quality,q_90';
  } else if (url.substring(0, 10) == 'http://cdn') {
    return url + '@' + w + 'w_' + h + 'h_1e_1c_100Q';
  } else {
    return url;
  }
}
function thumbnail_cover(url, w) {
  if (w > 1080) {
    w = 1080;
  }
  w = Math.round(w);
  if (url.substring(0, 11) == 'http://cdnx') {
    return url + '?x-oss-process=image/resize,w_' + w + '/quality,q_90';
  } else if (url.substring(0, 10) == 'http://cdn') {
    return url + '@' + w + 'w_1e_1c_100Q';
  } else {
    return url;
  }
}