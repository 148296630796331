var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "center-bottom" }, [
    _c("div", [
      _c("span", { on: { click: _vm.indexClick } }, [_vm._v("回到首页")]),
      _vm._v(" |\n    "),
      _c("span", { on: { click: _vm.memberClick } }, [_vm._v("个人中心")]),
      _vm._v(" |\n    "),
      _c("span", { on: { click: _vm.promptClick } }, [_vm._v("关注我们")]),
      _vm._v(" |\n    "),
      _c("span", { on: { click: _vm.promptClick } }, [_vm._v("意见反馈")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }