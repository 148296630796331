"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
var _counselor = require("../../../api/counselor");
var _member = require("@/api/member");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _share = require("@/utils/share");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      mchCounselorId: '',
      counselorId: '',
      audioPlay: false,
      counselorDetailShow: false,
      category: [{
        id: 1,
        type: 21,
        tit: '服务'
      }, {
        id: 2,
        type: 22,
        tit: '案例'
      }, {
        id: 3,
        type: 23,
        tit: '评价'
      }, {
        id: 4,
        type: 24,
        tit: '文章'
      }, {
        id: 5,
        type: 25,
        tit: '推荐'
      }],
      categorySecond: [{
        id: 0,
        name: '全部'
      }],
      activeCategoryId: 1,
      activeSecondCategoryId: 0,
      toTopShow: false,
      servicePopShow: false,
      counselorData: [],
      serviceData: [],
      caseData: [],
      assessData: [],
      activityData: [],
      recomData: [],
      pageNum: 1,
      pageSize: 20,
      businessCategoryId: 0,
      activityOpen: false,
      recomOpen: false,
      unfinished: false,
      counselorOrderId: '',
      phone: '',
      qrcode: '',
      firstDiscount: '',
      show: false //  是否显示弹框
    };
  },
  filters: {
    watchMethod: function watchMethod(val) {
      if (val === 0) {
        return '面对面 / 线上咨询';
      } else if (val === 1) {
        return '面对面咨询';
      } else if (val === 2) {
        return '线上咨询';
      }
    },
    watchRecomType: function watchRecomType(val) {
      if (val == 1) {
        return '测试';
      } else if (val == 2) {
        return '课程';
      } else if (val == 3) {
        return '专栏';
      }
    },
    // 时间戳转换
    watchData: function watchData(date) {
      date = new Date(date);
      var fmt = 'YYYY/mm/dd';
      var ret;
      var opt = {
        'Y+': date.getFullYear().toString(),
        // 年
        'm+': (date.getMonth() + 1).toString(),
        // 月
        'd+': date.getDate().toString() // 日
        // "H+": date.getHours().toString(), // 时
        // "M+": date.getMinutes().toString() // 分
      };
      for (var k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
        }
      }
      return fmt;
    }
  },
  mounted: function mounted() {},
  created: function created() {
    this.mchCounselorId = this.$route.query.id;
    this.getInfo();
    this.getUnfinished();
    this.loadPhone();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['userId', 'xuid', 'token', 'mchId'])),
  methods: {
    // 音频播放暂停
    audioClick: function audioClick() {
      this.audioPlay = !this.audioPlay;
      var audio = document.querySelector('.counselor-info__audio-cont');
      if (this.audioPlay == true) {
        audio.play();
      } else {
        audio.pause();
      }
    },
    //二级分类点击事件
    infoSecondCategoryClick: function infoSecondCategoryClick(item) {
      this.activeSecondCategoryId = item.id;
      this.businessCategoryId = item.id;
      this.serviceData = [];
      this.getServiceList();
    },
    //服务跳转，下单
    toReservation: function toReservation(item) {
      if (item.firstDiscount == undefined) {
        this.firstDiscount = 100;
      } else {
        this.firstDiscount = item.firstDiscount;
      }
      this.$router.push({
        path: '/counselorNew/steps',
        query: {
          mchCounselorId: this.mchCounselorId,
          serviceName: item.title,
          serviceType: item.type,
          servicePrice: item.price,
          serviceTime: item.duration,
          limitNum: item.limitNum,
          firstDiscount: this.firstDiscount,
          counselorBusinessId: item.id,
          dateIndex: 50,
          timeIndex: 50
        }
      });
    },
    //案例跳转
    toCase: function toCase(item) {
      this.$router.push({
        path: '/counselorNew/info/case',
        query: {
          title: item.title,
          shareName: this.counselorData.name,
          shareTime: item.createDate,
          hits: item.hits,
          content: item.content
        }
      });
    },
    //文章跳转
    activityClick: function activityClick(id) {
      this.$router.push({
        path: '/activitySy/details',
        query: {
          activityId: id
        }
      });
    },
    //点击更多文章
    activityMore: function activityMore() {
      this.activityOpen = !this.activityOpen;
    },
    //推荐跳转
    recomClick: function recomClick(item) {
      if (item.type == 1) {
        this.$router.push({
          path: '/gauge/index',
          query: {
            mchGaugeId: item.typeId
          }
        });
      } else if (item.type == 2) {
        this.$router.push({
          path: '/course/info',
          query: {
            courseId: item.typeId
          }
        });
      } else if (item.type == 3) {
        this.$router.push({
          path: '/column/columnSignUp',
          query: {
            mchColumnId: item.typeId
          }
        });
      }
    },
    //点击更多推荐
    recomMore: function recomMore() {
      this.recomOpen = !this.recomOpen;
    },
    //前往任务页面
    toTask: function toTask() {
      this.$router.push({
        path: '/counselorNew/task',
        query: {
          counselorOrderId: this.counselorOrderId
        }
      });
    },
    // info页面数据
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _counselor.findWithInfo)(this.mchCounselorId).then(function (res) {
        console.log(res, 'info数据');
        //咨询师数据
        _this2.counselorData = res.data.counselor;
        _utils.default.thumbnail(_this2.counselorData.avatar, 120, 120);
        if (res.data.counselor != '') {
          _this2.counselorId = res.data.counselor.id;
        }

        //二级分类数据
        res.data.counselor.businessCategories.map(function (e) {
          _this2.categorySecond.push(e);
        });
        //案例数据
        if (!_utils.default.isNull(res.data.counselor.cases) || res.data.counselor.cases.length != 0) {
          res.data.counselor.cases.map(function (e) {
            _this2.caseData.push(e);
          });
        } else {
          _this2.caseData = '';
          _this2.category.map(function (e, i) {
            if (e.id == 2) {
              console.log(i);
              _this2.category.splice(i, 1);
            }
          });
        }
        //评价数据
        if (!_utils.default.isNull(res.data.counselor.evaluations) || res.data.counselor.evaluations.length != 0) {
          res.data.counselor.evaluations.map(function (e) {
            _this2.assessData.push(e);
          });
        } else {
          _this2.assessData = '';
          _this2.category.map(function (e, i) {
            if (e.id == 3) {
              _this2.category.splice(i, 1);
            }
          });
        }
        //文章数据
        if (!_utils.default.isNull(res.data.activities) || res.data.activities.length != 0) {
          res.data.activities.map(function (e) {
            _this2.activityData.push(e);
            e.thumbnail = _utils.default.thumbnail(e.thumbnail, 188, 94);
          });
        } else {
          _this2.activityData = '';
          _this2.category.map(function (e, i) {
            if (e.id == 4) {
              _this2.category.splice(i, 1);
            }
          });
        }
        //推荐数据
        if (!_utils.default.isNull(res.data.recommends) || res.data.recommends.length != 0) {
          res.data.recommends.map(function (e) {
            _this2.recomData.push(e);
            e.thumbnail = _utils.default.thumbnail(e.thumbnail, 256, 128);
          });
        } else {
          _this2.recomData = '';
          _this2.category.map(function (e, i) {
            if (e.id == 5) {
              console.log(i);
              _this2.category.splice(i, 1);
            }
          });
        }
        _this2.getServiceList();
        (0, _share.SHARE)(location.href, {
          title: res.data.counselor.name,
          desc: res.data.counselor.introduction,
          link: _utils.default.host() + "/#/counselorNew/info/info?id=".concat(_this2.mchCounselorId, "&xuid=").concat(_this2.userId),
          imgUrl: res.data.counselor.avatar
        }, function (res) {});
      }).catch(function (err) {
        console.log(err);
      });
    },
    //服务列表
    getServiceList: function getServiceList() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('counselorId', _this.counselorId);
      params.append('businessCategoryId', _this.businessCategoryId);
      (0, _counselor.serviceList)(params).then(function (res) {
        console.log(res, 'ServiceList');
        if (!_utils.default.isNull(res.data.data) || res.data.data.length !== 0) {
          res.data.data.map(function (e) {
            _this.serviceData.push(e);
          });
        } else {
          _this.serviceData = '';
        }
        setTimeout(function () {
          _this.scrollMonitor();
        }, 300);
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 判断有无未完成任务
    getUnfinished: function getUnfinished() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('mchCounselorId', _this.mchCounselorId);
      (0, _member.hasUnfinished)(params).then(function (res) {
        if (res.data.counselorOrderTask == null) {
          _this.unfinished = false;
        } else {
          _this.unfinished = true;
          _this.counselorOrderId = res.data.counselorOrderTask.counselorOrderId;
        }
        console.log(res, '判断有无未完成任务');
      }).catch(function (err) {
        console.log(err);
      });
    },
    //客服信息
    loadPhone: function loadPhone() {
      var _this3 = this;
      (0, _counselor.getPhone)(this.mchCounselorId).then(function (res) {
        console.log(res, 'getPhone');
        _this3.phone = res.data.phone;
        _this3.qrcode = res.data.qrcode;
      });
    },
    // 滑动页面 展示对应分类
    scrollMonitor: function scrollMonitor() {
      var _this4 = this;
      //一级分类的高度
      var categoryHeight = document.querySelector('.counselor-info__category').offsetHeight;
      //body到顶部距离
      var bodyTopScroll = document.querySelector('.counselor-info__body').offsetTop;
      //服务到body顶部距离
      var serviceTopScroll = document.querySelector('.counselor-info__service').offsetTop;
      //案例到body顶部距离
      var caseTopScroll = document.querySelector('.counselor-info__case').offsetTop;
      //评价到body顶部距离
      var assessTopScroll = document.querySelector('.counselor-info__assess').offsetTop;
      //文章到body顶部距离
      var activityTopScroll = document.querySelector('.counselor-info__activity').offsetTop;
      //推荐到body顶部距离
      var recomTopScroll = document.querySelector('.counselor-info__recom').offsetTop;
      //滚动监听
      window.addEventListener('scroll', function () {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(scrollTop);
        // console.log(bodyTopScroll);
        // console.log(serviceTopScroll, "服务高度");
        // console.log(caseTopScroll, "案例到顶部");
        //滚动到某一项
        if (scrollTop < bodyTopScroll + caseTopScroll - categoryHeight - 24) {
          _this4.activeCategoryId = 1;
        } else if (scrollTop > bodyTopScroll + caseTopScroll - categoryHeight - 24 && scrollTop < bodyTopScroll + assessTopScroll - categoryHeight - 24) {
          _this4.activeCategoryId = 2;
        } else if (scrollTop > bodyTopScroll + assessTopScroll - categoryHeight - 34 && scrollTop < bodyTopScroll + activityTopScroll - categoryHeight - 34) {
          _this4.activeCategoryId = 3;
        } else if (scrollTop > bodyTopScroll + activityTopScroll - categoryHeight && scrollTop < bodyTopScroll + recomTopScroll - categoryHeight - 32) {
          _this4.activeCategoryId = 4;
        } else if (scrollTop > bodyTopScroll + recomTopScroll - 68) {
          _this4.activeCategoryId = 5;
        }
        //回到顶部显示判断
        if (scrollTop > 480) {
          _this4.toTopShow = true;
        } else {
          _this4.toTopShow = false;
        }
      });
    },
    // 查看咨询师详情点击事件 展开
    counselorDetailOpen: function counselorDetailOpen() {
      this.counselorDetailShow = true;
    },
    // 查看咨询师详情点击事件 关闭
    counselorDetailOff: function counselorDetailOff() {
      this.counselorDetailShow = false;
    },
    // 一级分类 点击到页面对应块
    infoCategoryClick: function infoCategoryClick(item) {
      var _this = this;
      _this.activeCategoryId = item.id;
      if (item.type == 21) {
        var serviceTop = document.querySelector('.counselor-info__body').offsetTop;
        window.scroll(0, serviceTop + 13);
      } else if (item.type == 22) {
        var caseTop = document.querySelector('.counselor-info__case').offsetTop + document.querySelector('.counselor-info__body').offsetTop - document.querySelector('.counselor-info__category').offsetHeight;
        window.scroll(0, caseTop - 24);
      } else if (item.type == 23) {
        var assessTop = document.querySelector('.counselor-info__assess').offsetTop + document.querySelector('.counselor-info__body').offsetTop - document.querySelector('.counselor-info__category').offsetHeight;
        window.scroll(0, assessTop - 24);
      } else if (item.type == 24) {
        var activityTop = document.querySelector('.counselor-info__activity').offsetTop + document.querySelector('.counselor-info__body').offsetTop - document.querySelector('.counselor-info__category').offsetHeight;
        window.scroll(0, activityTop);
      } else if (item.type == 25) {
        var recomTop = document.querySelector('.counselor-info__recom').offsetTop + document.querySelector('.counselor-info__body').offsetTop - document.querySelector('.counselor-info__category').offsetHeight;
        window.scroll(0, recomTop - 24);
      }
    },
    // 回到顶部
    toTop: function toTop() {
      // this.show = true
      window.scroll(0, 0);
    }
  }
};