"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.column = column;
exports.course = course;
exports.gauge = gauge;
exports.getInfo = getInfo;
exports.percentage = percentage;
exports.promotion = promotion;
exports.summary = summary;
var _request = _interopRequireDefault(require("@/utils/request"));
function getInfo() {
  return (0, _request.default)({
    url: '/member/getInfo',
    method: 'get'
  });
}

/* 累计 */
function summary() {
  return (0, _request.default)({
    url: '/member/promoter/summary',
    method: 'get'
  });
}

/* 推广 */
function promotion(params) {
  return (0, _request.default)({
    url: '/article/list',
    method: 'get',
    params: params
  });
}
function gauge(params) {
  return (0, _request.default)({
    url: '/mchGauge/list',
    method: 'get',
    params: params
  });
}
function course(params) {
  return (0, _request.default)({
    url: '/mchCourse/list',
    method: 'get',
    params: params
  });
}
function column(params) {
  return (0, _request.default)({
    url: '/mchColumn/list',
    method: 'get',
    params: params
  });
}
/* 获取分销百分比 */
function percentage(params) {
  return (0, _request.default)({
    url: '/admin/distribution/list',
    method: 'get',
    params: params
  });
}