"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _utils = _interopRequireDefault(require("../../utils/utils.js"));
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error('请输入手机号/邮箱'));
      } else {
        callback();
      }
    };
    var validateVerifyCode = function validateVerifyCode(rule, value, callback) {
      if (value.length > 6) {
        callback(new Error('请输入6位以内验证码'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '13860431130',
        verifyCode: '654321',
        verifyCodeKey: ''
      },
      loginRules: {
        //        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        //        verifyCode: [{ required: true, trigger: 'blur', validator: validateVerifyCode }]
      },
      loading: false,
      redirect: undefined,
      verifyCodeTip: '发送验证码',
      counterTimer: null
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
        if (route.query && route.query.xuid) {
          this.redirect = this.redirect + '&xuid=' + route.query.xuid;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;
    this.counterTimer = setInterval(function () {
      if (_this.counter > 0) {
        _this.verifyCodeTip = "剩余时间" + _this.counter + "秒";
        _this.counter = _this.counter - 1;
      } else {
        _this.verifyCodeTip = "发送验证码";
      }
    }, 1000);
    this.loginForm.verifyCodeKey = _utils.default.getUUID();
  },
  destroyed: function destroyed() {
    if (this.counterTimer != null) {
      clearInterval(this.counterTimer);
    }
  },
  methods: {
    // 发送验证码
    sendMsg: function sendMsg() {
      if (this.counter > 0) {
        return;
      }
      var _this = this;
      _this.loading = true;
      (0, _user.sendMsg)({
        'username': this.loginForm.username,
        tplKey: 'register'
      }).then(function (response) {
        console.log(response);
        _this.loading = false;
        _this.counter = 60;
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            // 登录类型 1：账号密码登录
            localStorage.setItem('loginType', 0);
            _this2.$router.push({
              path: _this2.redirect || '/'
            });
            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};