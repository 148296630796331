"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _courseCategory = require("@/api/courseCategory");
var _gaugeCategory = require("@/api/gaugeCategory");
var _articleCategory = require("@/api/articleCategory");
var _counselorCategory = require("@/api/counselorCategory");
var _activitySyQy = require("@/api/activitySyQy");
var _columnCategory = require("@/api/columnCategory");
var _vuex = require("vuex");
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      gaugeCategoryForm: [],
      courseCategoryForm: [],
      articleCategoryForm: [],
      counselorCategoryForm: [],
      activityCategoryForm: [],
      columnCategoryForm: [],
      courseType: true,
      gaugeType: true,
      articleType: true,
      counselorType: true,
      activityType: true,
      columnType: true
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    // 添加背景色
    document.querySelector("body").setAttribute("style", "background-color:#f2f2f2");
    next();
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector("body").setAttribute("style", "");
    next();
  },
  created: function created() {
    this.loadGaugeCategory();
    this.loadCourseCategory();
    this.loadarticleCategory();
    this.loadCounselorCategory();
    this.loadActivityCategory();
    this.loadColumnCategory();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"])),
  methods: {
    checkPermission: _permission.default,
    loadGaugeCategory: function loadGaugeCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _gaugeCategory.list)(params).then(function (response) {
        console.log(response);
        _this.gaugeCategoryForm = response.data;
      }).catch(function (error) {});
    },
    loadCourseCategory: function loadCourseCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _courseCategory.list)(params).then(function (response) {
        console.log(response);
        _this.courseCategoryForm = response.data;
      }).catch(function (error) {});
    },
    loadarticleCategory: function loadarticleCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _articleCategory.list)(params).then(function (response) {
        console.log(response);
        _this.articleCategoryForm = response.data;
      }).catch(function (error) {});
    },
    loadCounselorCategory: function loadCounselorCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _counselorCategory.list)(params).then(function (response) {
        console.log(response);
        _this.counselorCategoryForm = response.data;
      }).catch(function (error) {
        console.log(error);
      });
    },
    loadActivityCategory: function loadActivityCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _activitySyQy.category)(params).then(function (response) {
        console.log(response);
        _this.activityCategoryForm = response.data;
      }).catch(function (error) {});
    },
    loadColumnCategory: function loadColumnCategory() {
      var _this = this;
      var params = new URLSearchParams();
      params.append("mchId", _this.mchId);
      (0, _columnCategory.list)(params).then(function (response) {
        console.log(response);
        _this.columnCategoryForm = response.data;
      }).catch(function (error) {});
    },
    courseClick: function courseClick() {
      this.courseType = !this.courseType;
    },
    gaugeClick: function gaugeClick() {
      this.gaugeType = !this.gaugeType;
    },
    articleClick: function articleClick() {
      this.articleType = !this.articleType;
    },
    counselorClick: function counselorClick() {
      this.counselorType = !this.counselorType;
    },
    activityClick: function activityClick() {
      this.activityType = !this.activityType;
    },
    columnClick: function columnClick() {
      this.columnType = !this.columnType;
    },
    gaugeItme: function gaugeItme(id) {
      this.$router.push({
        path: "/gauge/list",
        query: {
          categoryId: id
        }
      });
    },
    courseItem: function courseItem(id) {
      this.$router.push({
        path: "/course/list",
        query: {
          categoryId: id
        }
      });
    },
    articleItem: function articleItem(id) {
      this.$router.push({
        path: "/article/list",
        query: {
          categoryId: id
        }
      });
    },
    promptClick: function promptClick(id) {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/list",
          query: {
            categoryId: id
          }
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/list",
          query: {
            categoryId: id
          }
        });
      }
    },
    activityItem: function activityItem(id) {
      this.$router.push({
        path: "/activitySy/list",
        query: {
          activityCategoryId: id
        }
      });
    },
    columnItem: function columnItem(id) {
      this.$router.push({
        path: "/column/columnCategery",
        query: {
          activeScrollId: id
        }
      });
    }
  }
};