import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Distpicker from '@/components/Distpicker/Distpicker';
import utils from '@/utils/utils';
import { update, find } from '@/api/evaluation';
export default {
  data: function data() {
    return {
      district: {
        province: 440000,
        city: '广州市',
        area: '海珠区'
      },
      gender: [{
        genderName: '男',
        status: false
      }, {
        genderName: '女',
        status: false
      }],
      Home_address: [{
        addressName: '城市',
        status: false
      }, {
        addressName: '农村',
        status: false
      }],
      marriage: [{
        marriageName: '请选择'
      }, {
        marriageName: '单身（无恋爱经验）'
      }, {
        marriageName: '单身（有恋爱经验）'
      }, {
        marriageName: '恋爱'
      }, {
        marriageName: '已婚（未育）'
      }, {
        marriageName: '已婚（已育）'
      }, {
        marriageName: '离异'
      }, {
        marriageName: '丧偶'
      }],
      education: [{
        marriageName: '请选择'
      }, {
        marriageName: '文盲'
      }, {
        marriageName: '小学'
      }, {
        marriageName: '初中'
      }, {
        marriageName: '高中或中专'
      }, {
        marriageName: '大专'
      }, {
        marriageName: '本科'
      }, {
        marriageName: '硕士研究生'
      }, {
        marriageName: '博士研究生'
      }],
      income: [{
        marriageName: '请选择'
      }, {
        marriageName: '2-4K'
      }, {
        marriageName: '4-6K'
      }, {
        marriageName: '6-8K'
      }, {
        marriageName: '8K-1万'
      }, {
        marriageName: '1-5万'
      }, {
        marriageName: '5-10万'
      }, {
        marriageName: '10-30万'
      }, {
        marriageName: '30万以上'
      }],
      bounced: [],
      marriageStatus: false,
      bouncedContent: '请选择',
      marriageContent: '请选择',
      marriageContentNum: '',
      educationContent: '请选择',
      educationContentNum: '',
      incomeContent: '请选择',
      incomeContentNum: '',
      bouncedType: '',
      professional: [{
        marriageName: '请选择'
      }, {
        marriageName: '国家机关、党群组织、企业、事业单位负责人'
      }, {
        marriageName: '专业技术人员'
      }, {
        marriageName: '办事人员和有关人员'
      }, {
        marriageName: '商业、服务业人员'
      }, {
        marriageName: '农、林、牧、渔、水利业生产人员'
      }, {
        marriageName: '生产、运输设备操作人员及有关人员'
      }, {
        marriageName: '军人'
      }, {
        marriageName: '学生'
      }, {
        marriageName: '其他职业'
      }],
      professionalContent: '请选择',
      professionalContentNum: '',
      political: [{
        marriageName: '请选择'
      }, {
        marriageName: '群众'
      }, {
        marriageName: '无党派人士'
      }, {
        marriageName: '共青团员'
      }, {
        marriageName: '中共党员（预备党员）'
      }, {
        marriageName: '其他党派/团体'
      }],
      politicalContent: '请选择',
      politicalContentNum: '',
      way: [{
        wayName: '顺产',
        status: false
      }, {
        wayName: '剖腹产',
        status: false
      }],
      wayStatus: '',
      raising: [{
        marriageName: '请选择'
      }, {
        marriageName: '父母'
      }, {
        marriageName: '爷爷奶奶'
      }, {
        marriageName: '外公外婆'
      }, {
        marriageName: '保姆'
      }, {
        marriageName: '其他'
      }],
      raisingContent: '请选择',
      raisingContentNum: '',
      Brothers: [{
        marriageName: '请选择'
      }, {
        marriageName: '独生子女'
      }, {
        marriageName: '1个'
      }, {
        marriageName: '2个'
      }, {
        marriageName: '3个'
      }, {
        marriageName: '4个以上'
      }],
      BrothersContent: '请选择',
      BrothersContentNum: '',
      family: [{
        marriageName: '请选择'
      }, {
        marriageName: '核心家庭（夫妻未育/父母和未婚子女）'
      }, {
        marriageName: '主干家庭（父母和一对已婚子女一起生活）'
      }, {
        marriageName: '联合家庭（父母和多对已婚子女一起生活）'
      }, {
        marriageName: '单亲家庭（离婚者与未婚者与子女组成的家庭）'
      }, {
        marriageName: '残缺家庭（未婚子女组成的残缺父母或者残缺一方的家庭叫残缺家庭）'
      }],
      familyContentBox: '请选择',
      familyContentBoxNum: '',
      total: [{
        marriageName: '请选择'
      }, {
        marriageName: '0.3万及以下'
      }, {
        marriageName: '0-3万'
      }, {
        marriageName: '3-8万'
      }, {
        marriageName: '8-15万'
      }, {
        marriageName: '15-30万'
      }, {
        marriageName: '30-100万'
      }, {
        marriageName: '100-500万'
      }, {
        marriageName: '500万以上'
      }],
      totalContent: '请选择',
      totalContentNum: '',
      provinces: [],
      myAddressCity: [],
      myAddressId: '',
      myAddresscounty: [],
      myAddresscountyId: '',
      address_Choose: false,
      addressTotal: '',
      newAddress: '',
      workAddress: '',
      addressType: '',
      id: '',
      evaluationId: '',
      userType: '',
      pageType: '',
      subjectId: '',
      infors: [],
      inforsBox: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'],
      inforsContent: [],
      nameBox: '',
      genderName: '',
      national: '',
      professionalBox: '',
      className: '',
      student: '',
      work_number: '',
      phone: '',
      heightBox: '',
      weightBox: '',
      headWai: '',
      Gestational: '',
      addressName: '',
      wayName: '',
      birth_Weight: '',
      Production: '',
      pickerValueBox: '',
      hoverCss: false,
      department: ''
    };
  },
  components: {
    Distpicker: Distpicker
  },
  filters: {},
  created: function created() {
    var _this = this;
    this.id = this.$route.query.id;
    this.evaluationId = this.$route.query.evaluationId;
    this.infors = this.$route.query.infors.split(',');
    _this.inforsBox.forEach(function (item) {
      _this.inforsContent.push(_this.infors.indexOf(item));
    });
    this.getEvaluation();
    console.log(_this.inforsContent);
  },
  mounted: function mounted() {},
  methods: {
    getEvaluation: function getEvaluation() {
      var _this = this;
      find(_this.evaluationId).then(function (res) {
        var evaluation = res.data.evaluation;
        _this.nameBox = evaluation.attr1;
        _this.pickerValueBox = evaluation.attr2;
        _this.genderName = evaluation.attr3;
        _this.national = evaluation.attr4;
        _this.professionalBox = evaluation.attr5;
        _this.className = evaluation.attr6;
        _this.student = evaluation.attr7;
        _this.work_number = evaluation.attr8;
        _this.phone = evaluation.attr9;
        _this.heightBox = evaluation.attr10;
        _this.weightBox = evaluation.attr11;
        _this.headWai = evaluation.attr12;
        _this.Gestational = evaluation.attr13;
        _this.addressName = evaluation.attr14;
        _this.marriageContentNum = evaluation.attr15;
        _this.workAddress = evaluation.attr16;
        _this.educationContentNum = evaluation.attr17;
        _this.incomeContentNum = evaluation.attr18;
        _this.professionalContentNum = evaluation.attr19;
        _this.politicalContentNum = evaluation.attr20;
        _this.wayName = evaluation.attr21;
        _this.birth_Weight = evaluation.attr22;
        _this.raisingContentNum = evaluation.attr23;
        _this.BrothersContentNum = evaluation.attr24;
        _this.Production = evaluation.attr25;
        _this.familyContentBoxNum = evaluation.attr26;
        _this.totalContentNum = evaluation.attr27;
        _this.department = evaluation.attr28;
        _this.genderBotton(0, _this.genderName);
      }).catch(function (error) {
        console.log('获取测评订单evelation[id=' + _this.evaluationId + ']失败', error);
      });
    },
    genderBotton: function genderBotton(index, genderName) {
      console.log(genderName);
      var _this = this;
      _this.genderName = genderName; //index+1
      if (genderName == '男') {
        _this.gender[0].status = true;
        _this.gender[1].status = false;
      } else if (genderName == '女') {
        _this.gender[0].status = false;
        _this.gender[1].status = true;
      }
      console.log(_this.gender);
    },
    HomeBotton: function HomeBotton(index, addressName) {
      var _this = this;
      _this.addressName = index + 1;
      if (addressName == '城市') {
        _this.Home_address[0].status = true;
        _this.Home_address[1].status = false;
      } else {
        _this.Home_address[0].status = false;
        _this.Home_address[1].status = true;
      }
    },
    marriageBotton: function marriageBotton(bouncedType) {
      var _this = this;
      console.log(bouncedType);
      if (bouncedType == 'marriage') {
        _this.bounced = _this.marriage;
      } else if (bouncedType == 'education') {
        _this.bounced = _this.education;
      } else if (bouncedType == 'income') {
        _this.bounced = _this.income;
      } else if (bouncedType == 'professional') {
        _this.bounced = _this.professional;
      } else if (bouncedType == 'political') {
        _this.bounced = _this.political;
      } else if (bouncedType == 'raising') {
        _this.bounced = _this.raising;
      } else if (bouncedType == 'Brothers') {
        _this.bounced = _this.Brothers;
      } else if (bouncedType == 'family') {
        _this.bounced = _this.family;
      } else if (bouncedType == 'total') {
        _this.bounced = _this.total;
      }
      this.bouncedType = bouncedType;
      this.marriageStatus = !this.marriageStatus;
    },
    bouncedBotton: function bouncedBotton(marriageName, index) {
      var _this = this;
      var index = index;
      this.bouncedContent = marriageName;
      if (_this.bouncedType == 'marriage') {
        _this.marriageContent = marriageName;
        _this.marriageContentNum = index;
      } else if (_this.bouncedType == 'education') {
        _this.educationContent = marriageName;
        _this.educationContentNum = index;
      } else if (_this.bouncedType == 'income') {
        _this.incomeContent = marriageName;
        _this.incomeContentNum = index;
      } else if (_this.bouncedType == 'professional') {
        _this.professionalContent = marriageName;
        _this.professionalContentNum = index;
      } else if (_this.bouncedType == 'political') {
        _this.politicalContent = marriageName;
        _this.politicalContentNum = index;
      } else if (_this.bouncedType == 'raising') {
        _this.raisingContent = marriageName;
        _this.raisingContentNum = index;
      } else if (_this.bouncedType == 'Brothers') {
        _this.BrothersContent = marriageName;
        _this.BrothersContentNum = index;
      } else if (_this.bouncedType == 'family') {
        _this.familyContentBox = marriageName;
        _this.familyContentBoxNum = index;
      } else if (_this.bouncedType == 'total') {
        _this.totalContent = marriageName;
        _this.totalContentNum = index;
      }
      this.marriageStatus = false;
    },
    ontouchstartTwo: function ontouchstartTwo(index) {
      this.ontouchstart(index);
    },
    ontouchendTwo: function ontouchendTwo(index) {
      this.ontouchend(index);
    },
    ontouchstart: function ontouchstart(index) {
      console.log(this.$refs[index][0]);
      this.$refs[index][0].style.background = '#ff700b';
      this.$refs[index][0].style.opacity = '0.2';
    },
    ontouchend: function ontouchend(index) {
      console.log(this.$refs[index][0]);
      this.$refs[index][0].style.background = '';
      this.$refs[index][0].style.opacity = '';
    },
    bouncedCancel: function bouncedCancel() {
      this.marriageStatus = false;
    },
    wayBotton: function wayBotton(wayName, index) {
      var _this = this;
      _this.wayName = index + 1;
      if (wayName == '顺产') {
        _this.way[0].status = true;
        _this.way[1].status = false;
      } else {
        _this.way[0].status = false;
        _this.way[1].status = true;
      }
    },
    onSelected: function onSelected(val) {
      this.workAddress = val.province.value + '-' + val.city.value + '-' + val.area.value;
      console.log(this.workAddress);
    },
    goanswer: function goanswer() {
      var _this2 = this;
      var _this = this;
      console.log(this.pickerValueBox);
      if (_this.inforsContent[0] != -1) {
        if (_this.nameBox == '') {
          _this.$message({
            showClose: true,
            message: '请输入名字！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[1] != -1) {
        if (_this.pickerValueBox == '') {
          _this.$message({
            showClose: true,
            message: '请输入出生日期！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[2] != -1) {
        if (_this.genderName == '') {
          _this.$message({
            showClose: true,
            message: '请选择性别！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[3] != -1) {
        if (_this.national == '') {
          _this.$message({
            showClose: true,
            message: '请输入民族！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[4] != -1) {
        if (_this.professionalBox == '') {
          _this.$message({
            showClose: true,
            message: '请输入专业！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[5] != -1) {
        if (_this.className == '') {
          _this.$message({
            showClose: true,
            message: '请输入班级！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[6] != -1) {
        if (_this.student == '') {
          _this.$message({
            showClose: true,
            message: '请输入学号！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[7] != -1) {
        if (_this.work_number == '') {
          _this.$message({
            showClose: true,
            message: '请输入工号！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[8] != -1) {
        if (_this.phone == '') {
          _this.$message({
            showClose: true,
            message: '请输入手机号码！',
            type: 'warning'
          });
          return;
        }
        if (_this.phone.length != 11) {
          _this.$message({
            showClose: true,
            message: '请输入11位有效手机号！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[9] != -1) {
        if (_this.heightBox == '') {
          _this.$message({
            showClose: true,
            message: '请输入身高！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[10] != -1) {
        if (_this.weightBox == '') {
          _this.$message({
            showClose: true,
            message: '请输入体重！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[11] != -1) {
        if (_this.headWai == '') {
          _this.$message({
            showClose: true,
            message: '请输入头围！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[12] != -1) {
        if (_this.Gestational == '') {
          _this.$message({
            showClose: true,
            message: '请输入出生胎龄！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[13] != -1) {
        if (_this.addressName == '') {
          _this.$message({
            showClose: true,
            message: '请输入户籍所在地！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[14] != -1) {
        if (_this.marriageContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择婚姻状况！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.marriageContentNum = '';
      }
      if (_this.inforsContent[15] != -1) {
        if (_this.workAddress == '') {
          _this.$message({
            showClose: true,
            message: '请输入工作所在地！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[16] != -1) {
        if (_this.educationContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择文化程度！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.educationContentNum = '';
      }
      if (_this.inforsContent[17] != -1) {
        if (_this.incomeContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择月收入水平！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.incomeContentNum = '';
      }
      if (_this.inforsContent[18] != -1) {
        if (_this.professionalContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择职业！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.professionalContentNum = '';
      }
      if (_this.inforsContent[19] != -1) {
        if (_this.politicalContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择政治面貌！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.politicalContentNum = '';
      }
      if (_this.inforsContent[20] != -1) {
        if (_this.wayName == '') {
          _this.$message({
            showClose: true,
            message: '请选择出生方式！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[21] != -1) {
        if (_this.birth_Weight == '') {
          _this.$message({
            showClose: true,
            message: '请输入出生体重！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[22] != -1) {
        if (_this.raisingContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择主要抚养者！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.raisingContentNum = '';
      }
      if (_this.inforsContent[23] != -1) {
        if (_this.BrothersContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择兄弟姐妹数量！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.BrothersContentNum = '';
      }
      if (_this.inforsContent[24] != -1) {
        if (_this.Production == '') {
          _this.$message({
            showClose: true,
            message: '请输入母亲生产时年龄！',
            type: 'warning'
          });
          return;
        }
      }
      if (_this.inforsContent[25] != -1) {
        console.log(_this.familyContentBox);
        if (_this.familyContentBox == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择家庭结构！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.familyContentBox = '';
      }
      if (_this.inforsContent[26] != -1) {
        if (_this.totalContent == '请选择') {
          _this.$message({
            showClose: true,
            message: '请选择家庭收入水平！',
            type: 'warning'
          });
          return;
        }
      } else {
        _this.totalContentNum = '';
      }
      if (_this.inforsContent[27] != -1) {
        if (_this.department == '') {
          _this.$message({
            showClose: true,
            message: '请输入部门！',
            type: 'warning'
          });
          return;
        }
      }
      var jsonData = {
        id: _this.evaluationId,
        attr1: _this.nameBox,
        attr2: _this.pickerValueBox,
        attr3: _this.genderName,
        attr4: _this.national,
        attr5: _this.professionalBox,
        attr6: _this.className,
        attr7: _this.student,
        attr8: _this.work_number,
        attr9: _this.phone,
        attr10: _this.heightBox,
        attr11: _this.weightBox,
        attr12: _this.headWai,
        attr13: _this.Gestational,
        attr14: _this.addressName,
        attr15: _this.marriageContentNum,
        attr16: _this.workAddress,
        attr17: _this.educationContentNum,
        attr18: _this.incomeContentNum,
        attr19: _this.professionalContentNum,
        attr20: _this.politicalContentNum,
        attr21: _this.wayName,
        attr22: _this.birth_Weight,
        attr23: _this.raisingContentNum,
        attr24: _this.BrothersContentNum,
        attr25: _this.Production,
        attr26: _this.familyContentBoxNum,
        attr27: _this.totalContentNum,
        attr28: _this.department
      };
      update(jsonData).then(function (response) {
        console.log(response);
        if (_this.$route.query.type == 1) {
          _this.$router.replace({
            path: '/gauge/answer',
            query: {
              evaluationId: _this.evaluationId,
              reportFlag: _this.$route.query.reportFlag,
              planId: _this.$route.query.planId,
              isTeam: _this.$route.query.isTeam,
              recordingFlag: _this2.$route.query.recordingFlag
            }
          });
        } else {
          localStorage.setItem('information' + _this.evaluationId, 'information');
          _this.$router.replace({
            path: '/gauge/answer',
            query: {
              evaluationId: _this.evaluationId,
              recordingFlag: _this2.$route.query.recordingFlag
            }
          });
        }
      }).catch(function (error) {});
    }
  }
};