var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "introduce-content" }, [
    _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.details.title))]),
    _vm._v(" "),
    _c("div", {
      staticClass: "message",
      domProps: { innerHTML: _vm._s(_vm.details.introduce) },
    }),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer-btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", loading: _vm.isLoading },
            on: { click: _vm.assessmentInfo },
          },
          [_vm._v("进入测试")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.show
      ? _c("div", { staticClass: "contact-fixed" }, [
          _c("div", { staticClass: "contact-abs" }, [
            _c("div", { staticClass: "contact-title" }, [
              _vm._v(
                "\n        您当前还未开通该体检项目，如需开通请联系客服。\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "erweima-name" }, [_vm._v("客服二维码")]),
            _vm._v(" "),
            _c("div", { staticClass: "img" }, [
              _c("img", { attrs: { src: _vm.kefu.qrcode, alt: "" } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "abs-box" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "phone" }, [
                _vm._v("请拨打电话：" + _vm._s(_vm.kefu.phone)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "btn", on: { click: _vm.onPhone } }, [
                _c("div", [_vm._v("拨打")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "abs-lt" }),
            _vm._v(" "),
            _c("div", { staticClass: "abs-rt" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-close", on: { click: _vm.onClose } }, [
            _c("img", {
              attrs: { src: require("../../assets/cha_round@2x.png"), alt: "" },
            }),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/mxs_images/logo-img.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-l" }, [
      _c("i", { staticClass: "el-icon-phone-outline icon-phone" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }