var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "infinite-scroll",
            rawName: "v-infinite-scroll",
            value: _vm.loadmore,
            expression: "loadmore",
          },
        ],
        staticClass: "column-order__list",
        staticStyle: { "overflow-y": "auto", height: "100vh" },
        attrs: { "infinite-scroll-disabled": "loading" },
      },
      _vm._l(_vm.columnOrder, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "column-order__list-item" },
          [
            _c("div", { staticClass: "column-order__list-item__img" }, [
              _c("img", { attrs: { src: item.thumbnail, alt: "" } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column-order__list-item__bottom" }, [
              _c(
                "div",
                { staticClass: "column-order__list-item__bottom__tit" },
                [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "column-order__list-item__bottom__btn",
                  on: {
                    click: function ($event) {
                      return _vm.goSubjectStage(item)
                    },
                  },
                },
                [_vm._v("\n          继续学习\n        ")]
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-order__heade" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/memberHBj.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }