"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _permission = _interopRequireDefault(require("@/utils/permission"));
var _user = require("@/api/user");
var _leave = require("@/api/leave");
var _store = _interopRequireDefault(require("@/store"));
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      completedList: [],
      courseOrderForm: [],
      counselorOrderList: [],
      articleOrderForm: [],
      distributionIsShow: false,
      mchRemark: '',
      receiveMsgFlag: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'avatar', 'menus', 'mchId'])),
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    // 添加背景色
    document.querySelector('body').setAttribute('style', 'background-color:#f2f2f2');
    next();
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector('body').setAttribute('style', '');
    next();
  },
  created: function created() {
    this.getInfo();
    for (var i = 0; i < this.menus.length; i++) {
      if (this.menus[i] == 'distribution-list') {
        this.distributionIsShow = true;
        return;
      }
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this2 = this;
      (0, _user.getInfo)(location.hostname).then(function (res) {
        console.log(res);
        _this2.mchRemark = res.data.mchRemark;
      }).catch(function (err) {
        console.log(err);
      });
      var params = new URLSearchParams();
      params.append('receiveMsgFlag', 1);
      (0, _leave.usrList)(params).then(function (res) {
        _this2.receiveMsgFlag = (res.data ? res.data.length : 0) > 0 ? 1 : 0;
      }).catch(function (err) {});
    },
    openCompleted: function openCompleted() {
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNumTwo);
      params.append('pageSize', _this.pageSize);
      params.append('evalStatus', 1);
      params.append('mchId', _this.mchId);
      list(params).then(function (response) {
        response.data.data.forEach(function (item) {
          _this.completedList.push(item);
        });
        _this.headerList[1].number = response.data.total;
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    loadCourse: function loadCourse() {
      var _this3 = this;
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      list(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 270, 200);
          _this.courseOrderForm.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this3.loading = false;
      });
    },
    loadCounselorOrder: function loadCounselorOrder() {
      var _this4 = this;
      var params = new URLSearchParams();
      params.append('mchId', this.mchId);
      list(params).then(function (response) {
        response.data.data.forEach(function (item) {
          if (item.appointmentDate) {
            item.appointmentDate = item.appointmentDate.substring(15, 0);
          }
        });
        _this4.counselorOrderList = response.data.data;
      });
    },
    loadArticle: function loadArticle() {
      var _this5 = this;
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      list(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.thumbnail = _utils.default.thumbnail(item.thumbnail, 270, 200);
          _this.articleOrderForm.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this5.loading = false;
      });
    },
    checkPermission: _permission.default,
    gaugeOrderClick: function gaugeOrderClick() {
      this.$router.push({
        path: '/gauge/order'
      });
    },
    promptClick: function promptClick() {
      var _this = this;
      _this.$message({
        message: 'ild:p',
        offset: 120,
        center: true
      });
    },
    billClick: function billClick() {
      this.$router.push({
        path: '/member/bill'
      });
    },
    courseOrderClick: function courseOrderClick() {
      this.$router.push({
        path: '/course/order'
      });
    },
    indexClick: function indexClick() {
      this.$router.push({
        path: '/'
      });
    },
    memberClick: function memberClick() {
      this.$router.push({
        path: '/member/member'
      });
    },
    articleClick: function articleClick() {
      this.$router.push({
        path: '/article/order'
      });
    },
    columnClick: function columnClick() {
      this.$router.push({
        path: '/column/columnOrder'
      });
    },
    advisoryClick: function advisoryClick() {
      if (this.checkPermission(['counselor2-list'])) {
        this.$router.push({
          path: '/counselorNew/order/orderList'
        });
      } else if (this.checkPermission(['counselor-list'])) {
        this.$router.push({
          path: '/counselor/order/orderList'
        });
      }
    },
    distributionClick: function distributionClick() {
      this.$router.push({
        path: '/distribution/promote'
      });
    },
    assessmentClick: function assessmentClick() {
      this.$router.push({
        path: '/assessment/list'
      });
    },
    //  学习记录
    onLearningRecord: function onLearningRecord() {
      this.$router.push({
        path: '/activitySy/learningRecord'
      });
    },
    // 设置
    onSetSystem: function onSetSystem() {
      this.$router.push({
        path: '/system'
      });
    },
    // 退出登录
    billClickOut: function billClickOut() {
      var _this6 = this;
      _store.default.dispatch('user/resetToken').then(function () {
        localStorage.removeItem('companyEmployee');
        _this6.$router.replace({
          path: '/'
        });
        // if (utils.isWeiXin()) {
        //   if (store.getters.enableAuth == 1) {
        //     this.$router.replace({
        //       path: '/newLogin'
        //     })
        //   } else {
        //     this.$router.replace({
        //       path: '/auth-redirect'
        //     })
        //   }
        // } else {
        //   this.$router.replace({
        //     path: '/newLogin'
        //   })
        // }
        // location.reload();
      });
    },
    //跳转留言
    billClickLeave: function billClickLeave() {
      this.$router.push({
        path: '/leave'
      });
    }
  }
};