var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "courses_box" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.coursesForm, function (item) {
        return _c("div", { key: item.id, staticClass: "courses_content" }, [
          _c("div", { staticClass: "courses_content_img" }, [
            _c("img", {
              attrs: { src: item.img, width: "100%", height: "100%" },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "courses_content_content" }, [
            _c("div", { staticClass: "courses_content_top" }, [
              _c("span", { staticClass: "courses_content_title" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "courses_content_subtitle" }, [
                _vm._v(_vm._s(item.subtitle)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "courses_content_bottom" }, [
              _c("div", { staticClass: "courses_content_label" }, [
                _c("span", { staticClass: "courses_content_labelTitle" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "courses_content_price" }, [
                _vm._v(_vm._s(item.price)),
              ]),
            ]),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "courses_header" }, [
      _c("div", { staticClass: "courses_header_line" }),
      _vm._v(" "),
      _c("span", { staticClass: "courses_header_name" }, [_vm._v("面授课程")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }