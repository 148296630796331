import Cookies from 'js-cookie';
var TokenKey = 'rzico_boot_web_token';
export function getToken() {
  return localStorage.getItem(TokenKey);
}
export function setToken(token) {
  console.log('我是token', token);
  return localStorage.setItem(TokenKey, token);
}
export function removeToken() {
  console.log('我是token,我被删除了');
  return localStorage.removeItem(TokenKey);
}