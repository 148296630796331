var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "column-recommend-box" },
      [
        _c("div", { staticClass: "column-recommend-name" }, [
          _vm._v("\n      " + _vm._s(_vm.content.title) + "\n    "),
        ]),
        _vm._v(" "),
        _vm._l(_vm.columnForm, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "column-recommend__cont__box" },
            [
              _c("div", { staticClass: "column-recommend__cont" }, [
                _vm._m(0, true),
                _vm._v(" "),
                _c("div", { staticClass: "column-recommend__cont__tit" }, [
                  _vm._v("\n          " + _vm._s(item.title) + "\n        "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "column-recommend__cont__subtit" }, [
                  _vm._v("\n          " + _vm._s(item.subTitle) + "\n        "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "column-recommend__cont__bottom" }, [
                  _c(
                    "div",
                    { staticClass: "column-recommend__cont__bottom-sales" },
                    [
                      _vm._m(1, true),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "column-recommend__cont__bottom-sales-cont",
                        },
                        [
                          _vm._v(
                            "\n              已有" +
                              _vm._s(item.sales) +
                              "人报名\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "column-recommend__cont__bottom-btn",
                      on: {
                        click: function ($event) {
                          return _vm.purchasing(item.id)
                        },
                      },
                    },
                    [_vm._v("\n            立即报名\n          ")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column-recommend__tit" }, [
                _c("div", { staticClass: "column-recommend__tit__tit" }, [
                  _vm._v("# 推荐语"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "column-recommend__tit__cont" }, [
                  _vm._v(
                    "\n          " + _vm._s(item.recommend) + "\n        "
                  ),
                ]),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-recommend__cont__bgc" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/column-recommend-bgc.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "column-recommend__cont__bottom-sales-icon" },
      [
        _c("img", {
          attrs: {
            src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/column-recommend__cont__bottom-sales-icon.png",
            alt: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }