var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "userInfoBox" }, [
        _c("div", { staticClass: "userLogo" }, [
          _c("img", {
            attrs: { src: _vm.avatar, width: "100%", height: "100%" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "userNameBox" }, [
          _c("span", { staticClass: "userName" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "passwordBox",
              on: {
                click: function ($event) {
                  _vm.showPassword = true
                },
              },
            },
            [_vm._v("修改密码")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cellBox",
          on: {
            click: function ($event) {
              _vm.showSex = true
            },
          },
        },
        [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "span",
              { staticClass: "value", class: [_vm.gender ? "" : "gray"] },
              [_vm._v(_vm._s(_vm.gender ? _vm.gender : "请选择性别"))]
            ),
            _vm._v(" "),
            _vm._m(3),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cellBox",
          on: {
            click: function ($event) {
              _vm.showBirthDay = true
            },
          },
        },
        [
          _vm._m(4),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "span",
              { staticClass: "value", class: [_vm.birthDayText ? "" : "gray"] },
              [
                _vm._v(
                  _vm._s(_vm.birthDayText ? _vm.birthDayText : "请选择生日")
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(5),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cellBox",
          on: {
            click: function ($event) {
              _vm.showEdu = true
            },
          },
        },
        [
          _vm._m(6),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "span",
              { staticClass: "value", class: [_vm.eduText ? "" : "gray"] },
              [_vm._v(_vm._s(_vm.eduText ? _vm.eduText : "请选择文化程度"))]
            ),
            _vm._v(" "),
            _vm._m(7),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cellBox",
          on: {
            click: function ($event) {
              _vm.showFamily = true
            },
          },
        },
        [
          _vm._m(8),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "span",
              { staticClass: "value", class: [_vm.family ? "" : "gray"] },
              [_vm._v(_vm._s(_vm.family ? _vm.family : "请选择家庭结构"))]
            ),
            _vm._v(" "),
            _vm._m(9),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "cellBox",
          on: {
            click: function ($event) {
              _vm.showContact = true
            },
          },
        },
        [
          _vm._m(10),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c(
              "span",
              { staticClass: "value", class: [_vm.contact ? "" : "gray"] },
              [_vm._v(_vm._s(_vm.contact ? _vm.contact : "请选择家庭关系"))]
            ),
            _vm._v(" "),
            _vm._m(11),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "van-action-sheet",
        {
          model: {
            value: _vm.showBirthDay,
            callback: function ($$v) {
              _vm.showBirthDay = $$v
            },
            expression: "showBirthDay",
          },
        },
        [
          _c("van-datetime-picker", {
            attrs: {
              type: "date",
              title: "请选择生日",
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: {
              confirm: _vm.onSelectBirthDay,
              cancel: function ($event) {
                _vm.showBirthDay = false
              },
            },
            model: {
              value: _vm.birthDay,
              callback: function ($$v) {
                _vm.birthDay = $$v
              },
              expression: "birthDay",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-action-sheet",
        {
          model: {
            value: _vm.showSex,
            callback: function ($$v) {
              _vm.showSex = $$v
            },
            expression: "showSex",
          },
        },
        [
          _c("van-picker", {
            attrs: { columns: _vm.genderList, "show-toolbar": "" },
            on: {
              cancel: function ($event) {
                _vm.showSex = false
              },
              confirm: _vm.genderDone,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-action-sheet",
        {
          model: {
            value: _vm.showEdu,
            callback: function ($$v) {
              _vm.showEdu = $$v
            },
            expression: "showEdu",
          },
        },
        [
          _c("van-picker", {
            attrs: { columns: _vm.education, "show-toolbar": "" },
            on: {
              cancel: function ($event) {
                _vm.showEdu = false
              },
              confirm: _vm.eduDone,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-action-sheet",
        {
          model: {
            value: _vm.showFamily,
            callback: function ($$v) {
              _vm.showFamily = $$v
            },
            expression: "showFamily",
          },
        },
        [
          _c("van-picker", {
            attrs: { columns: _vm.familyList, "show-toolbar": "" },
            on: {
              cancel: function ($event) {
                _vm.showFamily = false
              },
              confirm: _vm.familyDone,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-action-sheet",
        {
          model: {
            value: _vm.showContact,
            callback: function ($$v) {
              _vm.showContact = $$v
            },
            expression: "showContact",
          },
        },
        [
          _c("van-picker", {
            attrs: { columns: _vm.contactList, "show-toolbar": "" },
            on: {
              cancel: function ($event) {
                _vm.showContact = false
              },
              confirm: _vm.contactDone,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPassword
        ? _c("div", { staticClass: "dialogMask" }, [
            _c("div", { staticClass: "dialogBox" }, [
              _c("span", { staticClass: "dialogTitle" }, [_vm._v("修改密码")]),
              _vm._v(" "),
              _c("input", {
                staticClass: "dialogInput",
                attrs: { type: "password", placeholder: "请输入旧密码" },
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "dialogInput",
                attrs: { type: "password", placeholder: "请输入新密码" },
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "dialogInput",
                attrs: { type: "password", placeholder: "请再次输入新密码" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "dialogButtonBox" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialogButtonLeft",
                    on: {
                      click: function ($event) {
                        _vm.showPassword = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialogButtonRight",
                    on: {
                      click: function ($event) {
                        _vm.showPassword = false
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerBg" }, [
      _c("img", {
        attrs: {
          src: require("./images/settingHeaderBg.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellBox" }, [
      _c("div", { staticClass: "flex-row" }, [
        _c("div", { staticClass: "cellIcon" }, [
          _c("img", {
            attrs: {
              src: require("./images/icon-st-name.png"),
              width: "100%",
              height: "100%",
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "cellName" }, [_vm._v("姓名：")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-row" }, [
        _c("input", {
          staticClass: "input mr3",
          attrs: { typeof: "text", placeholder: "请输入姓名" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/icon-st-gender.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellName" }, [_vm._v("性别：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/icon-st-gender.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellName" }, [_vm._v("生日：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/icon-st-culture.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellName" }, [_vm._v("文化程度：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/icon-st-family.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellName" }, [_vm._v("家庭结构：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row" }, [
      _c("div", { staticClass: "cellIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/icon-st-familyTwo.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "cellName" }, [_vm._v("家庭关系：")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cellArrow" }, [
      _c("img", {
        attrs: {
          src: require("./images/cellArrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }