var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leave-content" },
    [
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "leave-clumn" }, [
          item.isMeSend == false
            ? _c("div", { staticClass: "rows-lt" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(item.senderVo.nickName) + ":"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "message" }, [
                  _vm._v(_vm._s(item.msgContent)),
                ]),
              ])
            : _c("div", { staticClass: "rows-rt" }, [
                _c("div", { staticClass: "message" }, [
                  _vm._v(_vm._s(item.msgContent)),
                ]),
              ]),
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "lt" },
          [
            _c("el-input", {
              attrs: { size: "small", placeholder: "请输入" },
              model: {
                value: _vm.content,
                callback: function ($$v) {
                  _vm.content = $$v
                },
                expression: "content",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rt" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm()
                  },
                },
              },
              [_vm._v("发送")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }