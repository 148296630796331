//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getList, send } from '@/api/leave';
export default {
  data: function data() {
    return {
      list: [],
      content: '' // 回复信息
    };
  },
  created: function created() {
    this.Init();
  },
  methods: {
    Init: function Init() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('pageNum', 1);
      params.append('pageSize', 10000);
      params.append('dialogId', this.$route.query.id);
      getList(params).then(function (res) {
        _this.list = res.data.data;
      }).catch(function (err) {});
    },
    submitForm: function submitForm() {
      var _this2 = this;
      if (!this.content) {
        this.$message({
          message: '请输入信息',
          type: 'warning'
        });
        return;
      }
      var params = new URLSearchParams();
      params.append('receiverId', this.$route.query.id);
      params.append('content', this.content);
      send(params).then(function (res) {
        _this2.Init();
        _this2.content = '';
      }).catch(function (err) {});
    }
  }
};