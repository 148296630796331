var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search_box" },
    [
      _c("el-input", {
        attrs: {
          size: "small",
          placeholder: "请输入内容",
          "prefix-icon": "el-icon-search",
        },
        on: { focus: _vm.promptClick },
        model: {
          value: _vm.input2,
          callback: function ($$v) {
            _vm.input2 = $$v
          },
          expression: "input2",
        },
      }),
      _vm._v(" "),
      _c(
        "el-button",
        {
          staticClass: "search_focus_botton",
          attrs: { size: "small", type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.promptClick },
        },
        [_vm._v("关注")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }