"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _counselor = require("../../api/counselor");
var _dict = require("@/api/dict");
var _vuex = require("vuex");
var _category = _interopRequireDefault(require("./components/category"));
var _permission = _interopRequireDefault(require("@/utils/permission"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { adminList as list } from '../../api/counselor'
// 权限判断函数
var _default = exports.default = (0, _defineProperty2.default)({
  name: "List",
  components: {
    Category: _category.default
  },
  filters: {
    watchMethod: function watchMethod(val) {
      if (val === 0) {
        return "面对面/线上咨询";
      } else if (val === 1) {
        return "面对面咨询";
      } else if (val === 2) {
        return "线上咨询";
      }
    }
  },
  data: function data() {
    return {
      categoryId: "",
      mchCounselorGategoryId: "",
      counselorList: [],
      startPrice: "",
      crowdList: [],
      endPrice: "",
      specialityList: [],
      genderType: false,
      pageSizeS: 20,
      genderData: [{
        name: "男性",
        value: "1"
      }, {
        name: "女性",
        value: "2"
      }, {
        name: "不限",
        value: ""
      }],
      genderTitle: "",
      genderValue: "",
      fieldData: [{
        name: "情绪压力"
      }, {
        name: "个人成长"
      }, {
        name: "人际关系"
      }, {
        name: "心理健康"
      }, {
        name: "学业职场"
      }, {
        name: "亲子教育"
      }, {
        name: "家庭困扰"
      }, {
        name: "婚姻恋爱"
      }],
      crowdSearch: [],
      fieldtitle: "",
      specialityId: "",
      fieldChooseTitle: "",
      fieldType: false,
      wayData: [{
        name: "不限",
        value: ""
      }, {
        name: "面对面咨询",
        value: "1"
      }, {
        name: "线上咨询",
        value: "2"
      }],
      waytitle: "",
      wayValue: "",
      wayType: false,
      groupData: [],
      priceData: [{
        name: "300元以下",
        startPrice: 0,
        endPrice: 300
      }, {
        name: "300-499元",
        startPrice: 300,
        endPrice: 499
      }, {
        name: "500-799元",
        startPrice: 500,
        endPrice: 799
      }, {
        name: "800元以上",
        startPrice: 800,
        endPrice: 100000000
      }],
      pricetitle: "",
      dateData: [{
        name: "工作日上午",
        type: false
      }, {
        name: "工作日下午",
        type: false
      }, {
        name: "工作日晚间",
        type: false
      }, {
        name: "周末上午",
        type: false
      }, {
        name: "周末下午",
        type: false
      }, {
        name: "周末晚间",
        type: false
      }],
      moreType: false,
      pageNum: 0,
      pageSize: 10,
      loading: true
    };
  },
  created: function created() {
    this.categoryId = this.$route.query.categoryId;
  },
  methods: {
    checkPermission: _permission.default,
    categoryClick: function categoryClick(id) {
      this.mchCounselorGategoryId = id;
      this.pageNum = 0;
      this.counselorList = [];
      this.loadmore();
    },
    counselorClick: function counselorClick(id) {
      if (this.checkPermission(["counselor2-list"])) {
        this.$router.push({
          path: "/counselorNew/info/info",
          query: {
            id: id
          }
        });
      } else if (this.checkPermission(["counselor-list"])) {
        this.$router.push({
          path: "/counselor/info",
          query: {
            id: id
          }
        });
      }
    },
    loadDict: function loadDict() {
      var _this2 = this;
      var params = new URLSearchParams();
      params.append("dictType", "speciality");
      params.append("pageSize", this.pageSizeS);
      (0, _dict.dictList)(params).then(function (response) {
        _this2.specialityList = response.data.data;
      });
    },
    loadCounselor: function loadCounselor() {
      var _this3 = this;
      var _this = this;
      var params = new URLSearchParams();
      params.append("pageNum", _this.pageNum);
      params.append("pageSize", _this.pageSize);
      params.append("mchId", this.mchId);
      params.append("gender", this.genderValue);
      params.append("counselorMethod", this.wayValue);
      params.append("speciality", this.specialityId);
      params.append("startPrice", this.startPrice);
      params.append("endPrice", this.endPrice);
      params.append("sortField", "orders");
      params.append("direction", "asc");
      params.append("mchCounselorGategoryId", this.mchCounselorGategoryId);
      this.crowdSearch.forEach(function (item) {
        params.append("crowd", item);
      });
      (0, _counselor.list)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          item.specialitys = item.specialitys.split(",");
          _this.counselorList.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
      }).catch(function (error) {
        _this3.loading = false;
      });
    },
    loadCrowd: function loadCrowd() {
      var _this4 = this;
      var params = new URLSearchParams();
      params.append("dictType", "crowd");
      params.append("pageSize", this.pageSizeS);
      (0, _dict.dictList)(params).then(function (response) {
        response.data.data.forEach(function (item) {
          item.type = false;
        });
        _this4.crowdList = response.data.data;
        _this4.$forceUpdate();
        console.log(_this4.crowdList);
      });
    },
    stepsClick: function stepsClick() {
      this.$router.push({
        path: "/counselor/steps"
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      this.loadCounselor();
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["mchId"]))
}, "created", function created() {
  // this.loadmore()
  this.loadDict();
  this.loadCrowd();
});