var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invite" }, [
    _c("div", { staticClass: "banner" }, [
      _c("img", { attrs: { src: require("./images/banner.png"), alt: "" } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _vm._v(" "),
    _vm.success
      ? _c("div", { staticClass: "success" }, [
          _c("div", { staticClass: "avatar" }, [
            _c("img", { attrs: { src: _vm.avatar, alt: "" } }),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c("p", [_vm._v("您已成功入驻美心说心理")]),
          _vm._v(" "),
          _c("p", [_vm._v("我们将通过微信服务号给您发送预约咨询通知")]),
          _vm._v(" "),
          _c("p", [_vm._v("请您务必关注下方公众号")]),
          _vm._v(" "),
          _c("div", { staticClass: "code" }, [
            _c("img", { attrs: { src: _vm.code, alt: "" } }),
          ]),
        ])
      : _c("div", { staticClass: "error" }, [
          _c("p", [_vm._v("当前邀请链接已失效")]),
          _vm._v(" "),
          _c("p", [_vm._v("请联系系统管理员重新发送邀请链接")]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }