var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answerPage", attrs: { id: "target" } },
    [
      _c(
        _vm.nowAnswer.mediaType == 2
          ? "audioHeader"
          : _vm.nowAnswer.mediaType == 3
          ? "videoHeader"
          : _vm.nowAnswer.type != 9
          ? "imgTextHeader"
          : "",
        {
          ref:
            _vm.nowAnswer.mediaType == 2
              ? "audioHeader"
              : _vm.nowAnswer.mediaType == 3
              ? "videoHeader"
              : _vm.nowAnswer.type != 9
              ? "imgTextHeader"
              : "",
          tag: "component",
          attrs: { musicName: _vm.musicName, nowAnswer: _vm.nowAnswer },
        }
      ),
      _vm._v(" "),
      _c(
        _vm.nowAnswer.type == 0 && _vm.nowAnswer.title.indexOf("<img") == -1
          ? "textTopic"
          : _vm.nowAnswer.type == 0 && _vm.nowAnswer.title.indexOf("<img") != -1
          ? "imageTopic"
          : _vm.nowAnswer.type == 1
          ? "multiTopic"
          : _vm.nowAnswer.type == 2
          ? "sortTopic"
          : _vm.nowAnswer.type == 3
          ? "weightTopic"
          : _vm.nowAnswer.type == 4
          ? "satisfactionTopic"
          : _vm.nowAnswer.type == 5
          ? "matrixTopic"
          : "guideTopic",
        {
          ref:
            _vm.nowAnswer.type == 0 && _vm.nowAnswer.title.indexOf("<img") == -1
              ? "textTopic"
              : _vm.nowAnswer.type == 0 &&
                _vm.nowAnswer.title.indexOf("<img") != -1
              ? "imageTopic"
              : _vm.nowAnswer.type == 1
              ? "multiTopic"
              : _vm.nowAnswer.type == 2
              ? "sortTopic"
              : _vm.nowAnswer.type == 3
              ? "weightTopic"
              : _vm.nowAnswer.type == 4
              ? "satisfactionTopic"
              : _vm.nowAnswer.type == 5
              ? "matrixTopic"
              : "guideTopic",
          tag: "component",
          attrs: {
            chooseId: _vm.chooseId,
            beforeTopic: _vm.beforeTopic,
            nowAnswer: _vm.nowAnswer,
            percenter: _vm.percenter,
            percents: _vm.percents,
          },
          on: { chooseAnswer: _vm.chooseAnswer },
        }
      ),
      _vm._v(" "),
      _vm.isSubmit ? _c("div", { staticStyle: { height: "100px" } }) : _vm._e(),
      _vm._v(" "),
      _vm.isSubmit
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.loading,
                  expression: "loading",
                  modifiers: { fullscreen: true, lock: true },
                },
              ],
              staticClass: "answer-footer-commitBox",
              style: { zIndex: 3 },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [
              !_vm.recordingFlag
                ? _c("div", { staticClass: "answer-footer-commit" }, [
                    _c("span", [_vm._v("提交")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.needRecordFlag && _vm.recordingFlag
                ? _c("div", { staticClass: "answer-footer-commit_record" }, [
                    _c("span", [_vm._v("提交进入下一步")]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "answer-footer flex-space-between flex-row",
          style: { zIndex: 3 },
        },
        [
          _c("div", [
            _vm.isBefore
              ? _c(
                  "span",
                  {
                    staticClass: "answer-footer-btn",
                    on: {
                      click: function ($event) {
                        return _vm.before()
                      },
                    },
                  },
                  [_vm._v("上一题")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c("span", { staticClass: "answer-footer-number" }, [
              _vm._v(_vm._s(_vm.percents) + "/"),
              _c("span", { staticStyle: { color: "#b2b2b2" } }, [
                _vm._v(_vm._s(_vm.percenter)),
              ]),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "answer-footer-time" }, [
              _vm._v(_vm._s(_vm._f("watchTime")(_vm.seconds))),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }