"use strict";

var _interopRequireWildcard = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("C:\\Users\\28658\\Desktop\\\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1\\gitee\\mxs-online-h5\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("C:\\Users\\28658\\Desktop\\\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1\\gitee\\mxs-online-h5\\node_modules\\core-js\\modules\\es6.promise.js");
require("C:\\Users\\28658\\Desktop\\\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1\\gitee\\mxs-online-h5\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("C:\\Users\\28658\\Desktop\\\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1\\gitee\\mxs-online-h5\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var filters = _interopRequireWildcard(require("./filters"));
require("@/icons");
require("@/permission");
var _vant = _interopRequireDefault(require("vant"));
require("vant/lib/index.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
require("swiper/css/swiper.css");
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _echarts = _interopRequireDefault(require("echarts"));
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets

// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

// global css

// global filters

// icon
// permission control

_vue.default.use(_vant.default);
_vue.default.use(_elementUi.default);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}

// import ELEMENT from 'element-ui'
// Vue.use(ELEMENT)

// import 'swiper/swiper-bundle.css'
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.use(_vueClipboard.default);

// 引入echarts

_vue.default.prototype.$echarts = _echarts.default;
_vue.default.config.productionTip = false;

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  },
  beforeCreate: function beforeCreate() {
    _vue.default.prototype.$bus = this; //安装全局事件总线
  }
});