var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("category", {
        attrs: { categoryId: _vm.categoryId },
        on: { categoryClick: _vm.categoryClick },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadmore,
              expression: "loadmore",
            },
          ],
          staticClass: "classify-content",
          staticStyle: { "overflow-y": "auto", height: "100vh" },
          attrs: { "infinite-scroll-disabled": "loading" },
        },
        _vm._l(_vm.gaugeList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "flex-space-between classify-content-cell",
              on: {
                click: function ($event) {
                  return _vm.indexClick(item.id)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "classify-content-cell-left flex-column-space-between ",
                },
                [
                  _c("div", { staticClass: "flex-column" }, [
                    _c(
                      "span",
                      {
                        staticClass: "classify-content-cell-title text-lines1",
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "classify-content-cell-sign text-lines1" },
                      [_vm._v(_vm._s(item.subTitle))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.price != 0,
                          expression: "item.price != 0",
                        },
                      ],
                      staticClass: "flex-row classifyContent",
                    },
                    [
                      _c("span", { staticClass: "classify-content-price" }, [
                        _vm._v("¥" + _vm._s(item.price)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "classify-content-cell-number" },
                        [_vm._v(_vm._s(item.sales) + "人已测")]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "classify-content-cell-imgBox" }, [
                _c("img", {
                  attrs: {
                    src: _vm._f("thumbnail")(item.thumbnail, 160, 160),
                    width: "100%",
                    height: "100%",
                    alt: "",
                  },
                }),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }