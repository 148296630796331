var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c("app-main"),
        _vm._v(" "),
        _c(
          "div",
          {
            class: { "fixed-header": _vm.fixedHeader },
            staticStyle: { "z-index": "99!important" },
          },
          [_c("navbar")],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }