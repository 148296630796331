var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "counselor-case" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "counselor-case__tit" }, [
        _vm._v("\n      " + _vm._s(_vm.shareTitle) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "counselor-case__prompt" }, [
        _c("div", { staticClass: "counselor-case__prompt-left" }, [
          _vm._v("\n        分享者："),
          _c("span", [_vm._v(_vm._s(_vm.shareName))]),
          _c("span", { staticClass: "separate" }, [_vm._v("|")]),
          _c("span", [_vm._v(_vm._s(_vm._f("watchData")(_vm.shareTime)))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "counselor-case__prompt-right" }, [
          _c("i", { staticClass: "el-icon-view" }),
          _vm._v(" "),
          _c("span", [_vm._v(" " + _vm._s(_vm.hits))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "counselor-case__cont",
        domProps: { innerHTML: _vm._s(_vm.shareContent) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "counselor-case__bottom" }, [
      _c(
        "div",
        {
          staticClass: "counselor-case__bottom-btn",
          on: { click: _vm.goBack },
        },
        [_vm._v("返回")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "counselor-case__img" }, [
      _c("img", {
        attrs: {
          src: "https://makeyimages.oss-cn-hangzhou.aliyuncs.com/counselor-case__img.png",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }