var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "promote-rules" },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "proportion" }, [
          _c("span", [_vm._v("推广员佣金比例")]),
          _vm._v(" "),
          _c("div", { staticClass: "proportion-main" }, [
            _c("div", { staticClass: "proportion-top" }, [
              _c("div", [
                _c("i", { staticClass: "el-icon-question" }),
                _vm._v(" "),
                _c("span", [_vm._v("推广员")]),
                _c("span", [_vm._v("(" + _vm._s(_vm.status) + ")")]),
              ]),
              _vm._v(" "),
              _c("span", { on: { click: _vm.lookUpRule } }, [
                _vm._v("查看升级条件"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "proportion-bottom" }, [
              _c("span", [
                _vm._v("直接推广佣金比例：" + _vm._s(_vm.percent1) + "%"),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v("间接推广佣金比例：" + _vm._s(_vm.percent2) + "%"),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("达成推广条件，自动升级成推广员")]),
              _vm._v(" "),
              _c("i", { staticClass: "el-icon-warning" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "部分商品的佣金比例因商家设置而有所不同，以商品列表显示为准"
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "problem" }, [
          _c("span", [_vm._v("常见问题")]),
          _vm._v(" "),
          _c("div", { staticClass: "problem-main" }, [
            _c("div", { staticClass: "problem-box" }, [
              _c("span", [_vm._v("如何成为推广员")]),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _vm._v(
                    "达成推广条件即可自动升级成为推广员，具体推广条件可\n              点击当前页面上方"
                  ),
                  _c(
                    "span",
                    { staticClass: "active", on: { click: _vm.lookUpRule } },
                    [_vm._v("查看升级条件")]
                  ),
                  _vm._v("进行查看。"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "problem-box" }, [
              _c("span", [_vm._v("如何查看推广业绩")]),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _vm._v("点击推广中心页面上的"),
                  _c(
                    "span",
                    { staticClass: "active", on: { click: _vm.goMemberDeal } },
                    [_vm._v("累计订单")]
                  ),
                  _vm._v(
                    "，即可查看所有的直接推\n              广订单和间接推广订单。"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "problem-box" }, [
              _c("span", [_vm._v("如何提现")]),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _vm._v("点击推广中心页面上的"),
                  _c(
                    "span",
                    { staticClass: "active", on: { click: _vm.goCash } },
                    [_vm._v("立即提现")]
                  ),
                  _vm._v("，即可将钱包余额中的金 额提现到微信钱包。"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "problem-box" }, [
              _c("span", [_vm._v("如何查看账单记录")]),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _vm._v("点击推广中心页面上的"),
                  _c(
                    "span",
                    { staticClass: "active", on: { click: _vm.goCash } },
                    [_vm._v("立即提现")]
                  ),
                  _vm._v("，进入提现页面，即可查 看所有的账单记录。"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("center-bottom", { staticStyle: { "background-color": "#f8f8f8" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "problem-box" }, [
      _c("span", [_vm._v("如何成为推广员")]),
      _vm._v(" "),
      _c("div", [
        _c("span", [
          _vm._v(
            "1.在商品详情页直接点击页面右上角菜单按钮，分享给好\n              友即可推广商品。好友通过分享的链接购买商品就算您成\n              功推广商品。"
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n              2.在推广中心的推广商品列表，点击推广按钮分享给好友\n              即可推广商品。好友通过分享的链接购买商品就算您成功\n              推广商品。"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }