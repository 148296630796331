"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _payment = require("@/api/payment");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
var _NoData = _interopRequireDefault(require("@/components/NoData"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pageNum: 1,
      pageSize: 20,
      billForm: [],
      loading: true
    };
  },
  created: function created() {
    this.loadBill();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  components: {
    NoData: _NoData.default
  },
  methods: {
    loadBill: function loadBill() {
      var _this = this;
      this.loading = true;
      var params = new URLSearchParams();
      params.append('pageNum', _this.pageNum);
      params.append('pageSize', _this.pageSize);
      params.append('mchId', _this.mchId);
      (0, _payment.list)(params).then(function (response) {
        console.log(response);
        response.data.data.forEach(function (item) {
          item.date = _utils.default.timestampToTime(item.createDate);
          _this.billForm.push(item);
        });
        _this.loading = response.data.data.length < _this.pageSize;
        for (var i = 0; i < _this.billForm.length; i++) {
          if (i == 0) {
            _this.billForm[i].spec1Reapet = '0';
          } else {
            if (_this.billForm[i].date == _this.billForm[i - 1].date) {
              _this.billForm[i].spec1Reapet = '1';
            } else {
              _this.billForm[i].spec1Reapet = '0';
            }
          }
          if (JSON.parse(_this.billForm[i].attach).queue.slice(0, 12) == 'queue.course') {
            _this.billForm[i].billType = 'course';
          } else if (JSON.parse(_this.billForm[i].attach).queue.slice(0, 11) == 'queue.gauge') {
            _this.billForm[i].billType = 'gauge';
          }
        }
        _this.$forceUpdate();
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    loadmore: function loadmore() {
      this.pageNum = this.pageNum + 1;
      console.log(this.pageNum);
      this.loadBill();
    }
  }
};