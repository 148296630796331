var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type == "1"
      ? _c("div", { staticClass: "details_graphic" }, [
          _c("span", { staticClass: "details_graphic_title" }, [
            _vm._v(_vm._s(_vm.courseItemForm.title)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "details_graphic_subtitle" }, [
            _vm._v(_vm._s(_vm.courseItemForm.subTitle)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type == "2"
      ? _c("div", { staticClass: "details_audio" }, [
          _c("div", { staticClass: "details_audio_img" }, [
            _c("img", {
              attrs: {
                width: "100%",
                height: "100%",
                src: _vm.courseItemForm.thumbnail,
              },
            }),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "details_audio_name" }, [
            _vm._v(_vm._s(_vm.courseItemForm.title)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "details_audio_player" }, [
            _c("audio", {
              attrs: {
                id: "audio",
                controls: "controls",
                src: _vm.courseItemForm.mediaUrl,
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type == "3"
      ? _c("div", { staticClass: "details_video" }, [
          _c("div", { staticClass: "details_video_player" }, [
            _c("video", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                controls: "controls",
                id: "video",
                "webkit-playsinline": "",
                playsinline: "",
                "x5-playsinline": "",
                "x-webkit-airplay": "allow",
                poster: _vm.courseItemForm.thumbnail,
                src: _vm.courseItemForm.mediaUrl,
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "details_video_info" }, [
            _c("span", { staticClass: "details_video_info_name" }, [
              _vm._v(_vm._s(_vm.courseItemForm.title)),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "details_video_info_subtitle" }, [
              _vm._v(_vm._s(_vm.courseItemForm.subTitle)),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "details_tabs" }, [
      _c("div", { staticClass: "details_tabs_item" }, [
        _c(
          "span",
          {
            staticClass: "details_tabs_name",
            on: {
              click: function ($event) {
                return _vm.func("A")
              },
            },
          },
          [_vm._v("详情")]
        ),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.block == "A",
              expression: "block=='A'",
            },
          ],
          staticClass: "details_tabs_line",
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "details_tabs_item" }, [
        _c(
          "span",
          {
            staticClass: "details_tabs_name",
            on: {
              click: function ($event) {
                return _vm.func("B")
              },
            },
          },
          [_vm._v("相关推荐")]
        ),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.block == "B",
              expression: "block=='B'",
            },
          ],
          staticClass: "details_tabs_line",
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.block == "A",
            expression: "block=='A'",
          },
        ],
        staticClass: "details_details",
      },
      [
        _c("div", {
          staticClass: "contentBox",
          domProps: { innerHTML: _vm._s(_vm.courseItemForm.content) },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.block == "B",
            expression: "block=='B'",
          },
        ],
        staticClass: "details_details",
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.courseForm, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "details_recommended_content" },
            [
              _c("div", { staticClass: "details_recommended_content_img" }, [
                _c("img", {
                  attrs: { src: item.thumbnail, width: "100%", height: "100%" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details_recommended_content_info" }, [
                _c("span", { staticClass: "details_info_name" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "details_info_subtitle" }, [
                  _vm._v(_vm._s(item.subTitle)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details_info_box" }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("span", { staticClass: "details_info_date" }, [
                        _vm._v(_vm._s(item.itemCount) + "课时"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "details_info_label" }, [
                        _c(
                          "span",
                          { staticClass: "details_info_label_title" },
                          [_vm._v(_vm._s(item.spots[0]))]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "details_info_price" }, [
                    _vm._v("¥" + _vm._s(item.price)),
                  ]),
                ]),
              ]),
            ]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "background-color": "rgb(238, 238, 238)" } }, [
      _c(
        "div",
        { staticClass: "details_bottom_botton" },
        [
          _c(
            "span",
            {
              staticClass: "details_bottom_botton_title",
              on: { click: _vm.indexClick },
            },
            [_vm._v("回到首页")]
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "details_bottom_botton_title",
              on: { click: _vm.memberClick },
            },
            [_vm._v("个人中心")]
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "details_bottom_botton_title",
              on: { click: _vm.promptClick },
            },
            [_vm._v("关注我们")]
          ),
          _vm._v(" "),
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "details_bottom_botton_title",
              on: { click: _vm.promptClick },
            },
            [_vm._v("意见反馈")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { width: "100%", height: "44px" } }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "details_courseitem",
        style: { zIndex: 9999 },
        on: { click: _vm.returnClick },
      },
      [
        _c("i", { staticClass: "el-icon-tickets" }),
        _vm._v(" "),
        _c("span", { staticClass: "details_courseitem_name" }, [
          _vm._v("目录"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "details_recommended_header" }, [
      _c("div", { staticClass: "details_recommended_header_line" }),
      _vm._v(" "),
      _c("span", { staticClass: "details_recommended_header_name" }, [
        _vm._v("精品课程"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "details_bottom_logo" }, [
      _c("div", { staticClass: "details_bottom_logo_img" }, [
        _c("img", {
          attrs: {
            src: "http://rzico.oss-cn-shenzhen.aliyuncs.com/img/logo@2x.png",
            width: "100%",
            height: "100%",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "details_bottom_name_box" }, [
      _c("span", { staticClass: "details_bottom_name" }, [
        _vm._v("美心说提供技术支持"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }