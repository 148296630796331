//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {};
  },
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    },
    chooseId: {
      default: ''
    }
  },
  methods: {
    chooseAnswer: function chooseAnswer(index, item) {
      var _this = this;
      var data = {
        index: index,
        item: item
      };
      console.log(data);
      _this.$emit("chooseAnswer", data);
    }
  }
};