var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "counselor_box" }, [
    _c("div", { staticClass: "headerBox" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "header_name" }, [
            _vm._v(_vm._s(_vm.content.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
          on: {
            click: function ($event) {
              return _vm.listClick()
            },
          },
        },
        [
          _c("span", { staticClass: "header_more" }, [_vm._v("查看更多")]),
          _vm._v(" "),
          _vm._m(1),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "counselor_content_list" },
      _vm._l(_vm.counselorForm, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "counselor_content",
            on: {
              click: function ($event) {
                return _vm.indexClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "counselor_content_img" }, [
              _c("img", {
                attrs: { src: item.avatar, width: "100%", height: "100%" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "counselorContentBox" }, [
              _c("div", { staticClass: "counselorNameBox" }, [
                _c("span", { staticClass: "counselor_content_name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counselorMethod" }, [
                  _vm._v(_vm._s(_vm._f("watchMethod")(item.counselorMethod))),
                ]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "counselor_content_subtitle" }, [
                _vm._v(_vm._s(item.spots)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "button" }, [_vm._v("预约")]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_titleIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/counselor.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_moreIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/arrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }