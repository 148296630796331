var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "assessment_info_header" }, [
        _c("div", { staticClass: "assessment_info_header_img" }, [
          _c("img", {
            attrs: {
              width: "100%",
              height: "100%",
              alt: "",
              src: "http://rzico.oss-cn-shenzhen.aliyuncs.com/meixinshuo/assessment_header.png",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "assessment_info_header_box" }, [
          _c("span", { staticClass: "assessment_info_header_title" }, [
            _vm._v(
              "海南师范大学小学教育专业“乡村教师支持计划”项目\n定向公培生招生面试"
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "assessment_info_content" }, [
        _c("span", { staticClass: "assessment_info_content_title" }, [
          _vm._v("编号"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "assessment_info_content_input" }, [
          _c("input", {
            staticClass: "assessment_info_content_input_box",
            attrs: { placeholder: "请输入您的成员编号" },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "assessment_info_content_title" }, [
          _vm._v("姓名"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "assessment_info_content_input" }, [
          _c("input", {
            staticClass: "assessment_info_content_input_box",
            attrs: { placeholder: "请输入您的姓名" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "assessment_info_botton" }, [
          _c("span", { staticClass: "assessment_info_botton_title" }, [
            _vm._v("提交"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }