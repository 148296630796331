var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leave-content" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "leave-clumn",
          on: {
            click: function ($event) {
              return _vm.onUser(item)
            },
          },
        },
        [
          _c("div", { staticClass: "lt" }, [
            _c("img", {
              attrs: {
                src: item.avatar
                  ? item.avatar
                  : "../../assets/mxs_images/audioImg.png",
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "rt" }, [_vm._v(_vm._s(item.nickname))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }