"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _centerBottom = _interopRequireDefault(require("./components/centerBottom.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    centerBottom: _centerBottom.default
  },
  data: function data() {
    return {
      percent1: 0,
      percent2: 0,
      status: '未完成'
    };
  },
  methods: {
    goMemberDeal: function goMemberDeal() {
      this.$router.push({
        path: '/distribution/memberDeal'
      });
    },
    goCash: function goCash() {
      this.$router.push({
        path: '/distribution/cash'
      });
    },
    lookUpRule: function lookUpRule() {
      alert('暂无条件');
    }
  }
};