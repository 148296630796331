var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "header", attrs: { id: "header" } }, [
        _c(
          "div",
          {
            staticClass: "scroll",
            style: {
              scrollLeft: _vm.scrollLeft,
            },
          },
          _vm._l(_vm.activitySyCategory, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "scroll_item",
                on: {
                  click: function ($event) {
                    return _vm.scrollSlide(index, item.id)
                  },
                },
              },
              [
                _c("span", { staticClass: "scroll_item_title" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _vm.activityCategoryId == item.id
                  ? _c("div", { staticClass: "line" })
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.activityOrderForm, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              click: function ($event) {
                return _vm.courseClick(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "body" }, [
              _c("div", { staticClass: "tit_num" }, [
                _c("div", { staticClass: "tit" }, [_vm._v(_vm._s(item.title))]),
                _vm._v(" "),
                _c("div", { staticClass: "num" }, [
                  _vm._v(_vm._s(item.hitsRaw + item.hits) + "人已读"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "body-img" }, [
                _c("img", { attrs: { src: item.thumbnail, alt: "" } }),
              ]),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "end" }, [_vm._v("已经到底啦~")]),
      _vm._v(" "),
      _vm.activitySyCategory == 0
        ? _c("div", { staticClass: "end" }, [_vm._v("这里没有东西哦~")])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }