"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _gaugePlan = require("@/api/gaugePlan");
var _user = require("@/api/user");
var _vuex = require("vuex");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isLoading: false,
      show: false,
      kefu: '',
      // 获取客服信息
      details: '' // 获取专题数据
    };
  },
  created: function created() {
    this.Init();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['mchId'])),
  methods: {
    Init: function Init() {
      var _this = this;
      var params = new URLSearchParams();
      params.append('planId', this.$route.query.id);
      (0, _gaugePlan.getTopic)(params).then(function (res) {
        _this.details = res.data;
      }).catch(function (err) {});

      // 获取客服信息
      (0, _user.getkefu)({
        mchId: this.mchId
      }).then(function (res) {
        _this.kefu = res.data || {};
      }).catch(function (err) {});
    },
    // 关闭弹框
    onClose: function onClose() {
      this.show = false;
    },
    // 拨打电话
    onPhone: function onPhone() {
      var isIos = _utils.default.isIos();
      if (isIos) {
        // iOS
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = 'tel://' + this.kefu.phone;
        link.click();
      } else {
        // 安卓
        window.location.href = 'tel://' + this.kefu.phone;
      }
    },
    assessmentInfo: function assessmentInfo() {
      var _this2 = this;
      var company = localStorage.getItem('companyEmployee');
      if (!company) {
        this.show = true;
        return;
      }

      // 查询是否有专题
      var params = new URLSearchParams();
      params.append('pageNum', 1);
      params.append('pageSize', 1);
      params.append('mchId', this.mchId);
      params.append('specialTopicId', this.details.id || 0);
      (0, _gaugePlan.perList)(params).then(function (response) {
        if (response.data.data.length == 0) {
          _this2.show = true;
          return;
        }
        var isTeam = response.data.data[0].type === 1;
        _this2.$router.push({
          path: isTeam ? '/assessment/list' : '/assessment/personalList',
          query: {
            id: _this2.details.id // 专题id
          }
        });
      }).catch(function (error) {
        _this2.show = true;
      });
    }
  }
};