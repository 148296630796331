var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "article_content" }, [
    _c("div", { staticClass: "headerBox" }, [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "header_name" }, [
            _vm._v(_vm._s(_vm.content.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
          on: {
            click: function ($event) {
              return _vm.listClick()
            },
          },
        },
        [_vm._m(1)]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "articleBox" }, [
      _c(
        "div",
        { staticClass: "articleBox_rollingBox" },
        _vm._l(_vm.articleForm, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "article_box_cell",
              on: {
                click: function ($event) {
                  return _vm.articleClick(item.id)
                },
              },
            },
            [
              _c("div", { staticClass: "article_box_cell_img" }, [
                _c("img", {
                  attrs: { src: item.thumbnail, width: "100%", height: "100%" },
                }),
              ]),
              _vm._v(" "),
              item.tags.length
                ? _c(
                    "div",
                    { staticClass: "tags" },
                    _vm._l(item.tags, function (t) {
                      return _c("span", [_vm._v(_vm._s(t.name))])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "article_box_cell_content" }, [
                _c(
                  "div",
                  { staticClass: "article_box_cell_content_titleBox" },
                  [
                    _c(
                      "span",
                      { staticClass: "article_box_cell_content_titleText" },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "article_box_cell_content_subtitleText" },
                      [_vm._v(_vm._s(item.subTitle))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "article_box_cell_content_buttonBox" },
                  [
                    _c(
                      "div",
                      { staticClass: "article_box_cell_content_price" },
                      [_vm._v("¥" + _vm._s(item.price))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "article_box_cell_content_button" },
                      [_vm._v("去报名")]
                    ),
                  ]
                ),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_titleIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/titleLine.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_moreIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/arrowStyle2.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }