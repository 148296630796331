"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _utils = _interopRequireDefault(require("@/utils/utils"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      total: 0,
      total_Score: 0,
      optionList: [],
      totalBox: 0
    };
  },
  components: {},
  props: {
    percents: {
      default: ''
    },
    nowAnswer: {
      default: ''
    },
    percenter: {
      default: ''
    },
    beforeTopic: {
      default: ''
    }
  },
  mounted: function mounted() {
    this.reset();
  },
  watch: {
    percents: function percents(newVal, oldVal) {
      this.reset();
      this.total = 0;
    }
  },
  methods: {
    topic: function topic() {
      var _this = this;
      console.log(_this.beforeTopic);
      console.log(_this.nowAnswer);
      if (!_utils.default.isNull(_this.beforeTopic)) {
        _this.beforeTopic.forEach(function (item) {
          _this.nowAnswer.options.forEach(function (items) {
            if (item.questionId == _this.nowAnswer.id && item.optionId == items.id) {
              items.rangeValue = item.score;
              console.log(items.rangeValue);
              _this.total += parseInt(items.rangeValue);
            }
          });
        });
      }
      // this.$forceUpdate();
    },
    reset: function reset() {
      console.log(this.nowAnswer);
      this.nowAnswer.options.forEach(function (item) {
        item.one = 0;
        item.Two = item.score / 5;
        item.three = item.Two * 2;
        item.four = item.Two * 3;
        item.five = item.Two * 4;
        item.rangeValue = 0;
        if (item.score % 5 === 0) {
          item.aliquot = true;
        } else {
          item.aliquot = false;
        }
      });
      this.total_Score = this.nowAnswer.options[0].score;
      this.topic();
      this.$forceUpdate();
    },
    ontouchmove: function ontouchmove(index) {
      var _this = this;
      _this.total = 0;
      this.nowAnswer = this.nowAnswer;
      for (var i = 0; i < _this.nowAnswer.options.length; i++) {
        _this.total += parseInt(_this.nowAnswer.options[i].rangeValue);
      }
      this.$forceUpdate();
    },
    search: function search(index) {
      var _this = this;
      _this.total = 0;
      if (_this.nowAnswer.options[index].rangeValue < 0) {
        _this.nowAnswer.options[index].rangeValue = 0;
      } else if (_this.nowAnswer.options[index].rangeValue > _this.nowAnswer.options[index].score) {
        _this.nowAnswer.options[index].rangeValue = _this.nowAnswer.options[index].score;
      }
      this.nowAnswer = this.nowAnswer;
      for (var i = 0; i < _this.nowAnswer.options.length; i++) {
        _this.total += parseInt(_this.nowAnswer.options[i].rangeValue);
      }
      this.$forceUpdate();
    },
    chooseAnswer: function chooseAnswer() {
      var _this = this;
      if (_this.total < _this.total_Score) {
        _this.$message({
          showClose: true,
          message: '请分配所有的比重值',
          type: 'warning'
        });
        return;
      } else if (_this.total > _this.total_Score) {
        _this.$message({
          showClose: true,
          message: '分配的比重值不能超过上限值',
          type: 'warning'
        });
        return;
      }
      _this.optionList = [];
      _this.nowAnswer.options.forEach(function (item) {
        var data = {
          gene: item.gene,
          optionId: item.id,
          rangeValue: item.rangeValue
        };
        _this.optionList.push(data);
      });
      var data = {
        optionList: _this.optionList
      };
      _this.$emit("chooseAnswer", data);
    }
  }
};