"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.endTape = endTape;
exports.startTape = startTape;
var _request = _interopRequireDefault(require("@/utils/request"));
function startTape() {
  return (0, _request.default)({
    url: '/audioRecorder/start',
    method: 'get'
  });
}
function endTape() {
  return (0, _request.default)({
    url: '/audioRecorder/end',
    method: 'get'
  });
}