import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validUsername } from '@/utils/validate';
import utils from "../../utils/utils.js";
import ElRow from 'element-ui/packages/row/src/row';
import ElCol from 'element-ui/packages/col/src/col';
import { getRefresh, getFinishData } from '@/api/user';
export default {
  name: 'evalLogin',
  props: ['title', 'setName', 'setCode'],
  data: function data() {
    var _this = this;
    var validateUsername = function validateUsername(rule, value, callback) {
      if (value.length < 1) {
        callback(new Error((_this.setName || '账号') + '不能为空'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error((_this.setCode || '密码') + '不能少于6位'));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: '',
        captcha: '',
        captchaUid: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        captcha: [{
          required: true,
          trigger: 'blur',
          message: '验证码不能为空'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
      codeImg: ''
    };
  },
  components: {
    ElCol: ElCol,
    ElRow: ElRow
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
        if (route.query && route.query.xuid) {
          this.redirect = this.redirect + '&xuid=' + route.query.xuid;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.loginForm.verifyCodeKey = utils.getUUID();
    this.createCaptcha();
  },
  destroyed: function destroyed() {},
  methods: {
    forget: function forget() {
      this.$router.push('/password');
    },
    register: function register() {
      this.$router.push('/register');
    },
    // 图片验证码
    createCaptcha: function createCaptcha() {
      var _this2 = this;
      getRefresh().then(function (res) {
        _this2.codeImg = res.data.captcha;
        _this2.loginForm.captchaUid = res.data.captchaUid;
      }).catch(function (err) {});
    },
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this3 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this3.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this4 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.$store.dispatch('user/loginPassword', _this4.loginForm).then(function () {
            // 登录类型 1：账号密码登录
            localStorage.setItem('loginType', 0);
            _this4.loading = false;
            getFinishData().then(function (data) {
              localStorage.setItem('isAllFinish', data.data.isAllFinish);
              if (data.data.isAllFinish == 1) {
                _this4.$router.push({
                  path: _this4.redirect || '/'
                });
              } else {
                _this4.$router.replace({
                  path: '/material'
                });
              }
            }).catch(function (err) {});
          }).catch(function () {
            _this4.loading = false;
            _this4.createCaptcha();
          });

          // this.$store
          //   .dispatch('user/login', this.loginForm)
          //   .then(() => {
          //     console.log('login success!!')
          //     this.createCaptcha()
          //     this.$router.push({ path: this.redirect || '/' })
          //     this.loading = false
          //   })
          //   .catch(() => {
          //     console.log('login error!!')
          //     this.createCaptcha()
          //     this.loading = false
          //   })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};