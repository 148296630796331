"use strict";

var _interopRequireDefault = require("C:/Users/28658/Desktop/\u7F8E\u5FC3\u6570\u79D1\u5F00\u53D1/gitee/mxs-online-h5/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addFinishData = addFinishData;
exports.getCodeByRedirect = getCodeByRedirect;
exports.getConfig = getConfig;
exports.getFinishData = getFinishData;
exports.getInfo = getInfo;
exports.getOrgTree = getOrgTree;
exports.getRefresh = getRefresh;
exports.getTokenByCode = getTokenByCode;
exports.getTokenByCodeV2 = getTokenByCodeV2;
exports.getkefu = getkefu;
exports.login = login;
exports.loginPassowrd = loginPassowrd;
exports.logout = logout;
exports.onUpdPwd = onUpdPwd;
exports.register = register;
exports.sendMsg = sendMsg;
var _request = _interopRequireDefault(require("@/utils/request"));
function getInfo(host) {
  return (0, _request.default)({
    url: '/user/getInfo',
    method: 'get',
    params: {
      'host': host
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}
function sendMsg(params) {
  return (0, _request.default)({
    url: '/user/sendMsg',
    method: 'post',
    params: params
  });
}
function login(params) {
  return (0, _request.default)({
    url: '/user/msgLogin',
    method: 'post',
    params: params
  });
}
function getCodeByRedirect(params) {
  return (0, _request.default)({
    url: '/user/getCodeByRedirect',
    method: 'get',
    params: params
  });
}
function getTokenByCode(params) {
  return (0, _request.default)({
    url: '/user/getTokenByCode',
    method: 'get',
    params: params
  });
}
function getTokenByCodeV2(params) {
  return (0, _request.default)({
    url: '/user/getTokenByCodeV2',
    method: 'get',
    params: params
  });
}
function register(params) {
  return (0, _request.default)({
    url: '/user/tokenRegister',
    method: 'get',
    params: params
  });
}
function getConfig(params) {
  return (0, _request.default)({
    url: '/user/getConfig',
    method: 'get',
    params: params
  });
}

// 新的登录
function loginPassowrd(params) {
  return (0, _request.default)({
    url: '/companyEmployee/empLogin',
    method: 'post',
    params: params
  });
}
// 移动端成员获取未完善资料
function getFinishData() {
  return (0, _request.default)({
    url: '/companyEmployee/unFinishData',
    method: 'post'
  });
}

// 新的登录
function addFinishData(params) {
  return (0, _request.default)({
    url: '/companyEmployee/fillData',
    method: 'post',
    data: params
  });
}

// 获取组织
function getOrgTree() {
  return (0, _request.default)({
    url: '/member/company/list',
    method: 'get'
  });
}

// 获取验证码
function getRefresh() {
  return (0, _request.default)({
    url: '/captcha/refresh',
    method: 'get'
  });
}

// 修改密码
function onUpdPwd(params) {
  return (0, _request.default)({
    url: '/member/companyEmployee/updPwd',
    method: 'post',
    data: params
  });
}

// 获取当前企业客服资料
function getkefu(params) {
  return (0, _request.default)({
    url: '/kefu/getInfo',
    method: 'get',
    params: params
  });
}