var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "courses_box" }, [
    _c("div", { staticClass: "headerBox" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "align-items": "center",
          },
          on: {
            click: function ($event) {
              return _vm.listClick()
            },
          },
        },
        [
          _c("span", { staticClass: "header_more" }, [_vm._v("查看更多")]),
          _vm._v(" "),
          _vm._m(1),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "contentBox" },
      _vm._l(_vm.listData, function (item) {
        return _c("div", { key: item.id, staticClass: "contentItemBox" }, [
          _vm._m(2, true),
          _vm._v(" "),
          _c("span", { staticClass: "contentTitle" }, [
            _vm._v(_vm._s(item.title)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "contentSubTitle" }, [
            _vm._v(_vm._s(item.subTitle)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "flex-direction": "row",
          "align-items": "center",
        },
      },
      [
        _c("div", { staticClass: "header_titleIcon" }, [
          _c("img", {
            attrs: {
              src: require("../images/titleIcons/psychology2.png"),
              width: "100%",
              height: "100%",
            },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "header_name" }, [_vm._v("心理品质提升")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header_moreIcon" }, [
      _c("img", {
        attrs: {
          src: require("../images/titleIcons/arrow.png"),
          width: "100%",
          height: "100%",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contentImgBox" }, [
      _c("div", { staticClass: "contentImg" }, [
        _c("img", {
          attrs: {
            src: require("./images/img.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "videoIcon" }, [
        _c("img", {
          attrs: {
            src: require("./images/videoIcon.png"),
            width: "100%",
            height: "100%",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }